import { EditOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const reorderReportTemplatesOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      librarySchemaId: string;
      orderedIds: string[];
    }>()
    .expected((aggregate, payload) => {
      const { orderedIds } = payload;
      aggregate.reportTemplates.sort((a, b) => {
        const aIndex = orderedIds.indexOf(a.id);
        const bIndex = orderedIds.indexOf(b.id);
        return bIndex - aIndex;
      });
      return aggregate;
    })
    .mutate(async (payload) => {
      const { librarySchemaId, orderedIds } = payload;

      const { updatedAt } = await Api.reorderLibrarySchemaReportTemplates({
        librarySchemaId,
        reportTemplateIds: orderedIds,
      });

      return { updatedAt };
    });
