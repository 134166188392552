import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Api } from "@kraaft/shared/core/services/api";

export type Warnings = {
  emailConflictText?: string;
  phoneNumberConflictText?: string;
};

type HandlerForSuperAdmin = (
  payload: Parameters<typeof Api.superAdminEditUserAuth>[0],
) => Promise<Warnings | undefined>;

const useConfirmEditHandler = (
  poolId: string,
  handleClose: () => void,
): HandlerForSuperAdmin => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleConfirmSuperAdmin = useCallback<HandlerForSuperAdmin>(
    async (payload) => {
      const result = await Api.superAdminEditUserAuth(payload);

      if (result.success) {
        handleClose();
      } else {
        const warnings: Warnings = {};
        if (result.emailConflict) {
          warnings.emailConflictText = t(
            "emailConflictAdvanced",
            result.emailConflict,
          );
        }
        if (result.phoneNumberConflict) {
          warnings.phoneNumberConflictText = t(
            "phoneConflictAdvanced",
            result.phoneNumberConflict,
          );
        }

        if (!result.emailConflict && !result.phoneNumberConflict) {
          dispatch(
            showError({
              title: t("editUserAuthError", {
                message: result.message,
              }),
            }),
          );
        }

        return warnings;
      }
    },
    [dispatch, handleClose, t],
  );

  return handleConfirmSuperAdmin;
};

export { useConfirmEditHandler };
