import { ReactElement, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keyBy, maxBy, minBy, pickBy } from "lodash";

import { BidirectionalLoader } from "@kraaft/shared/components/bidirectionalMessageList/bidirectionalLoader";
import { MessageListTop } from "@kraaft/shared/components/bidirectionalMessageList/messageListTop";
import { MessageLimitReached } from "@kraaft/shared/components/freemium/messageLimitReached";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { MessageDataStateActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import {
  selectAllMessageDocs,
  selectMessageLinkedList,
  selectRoomOptimisticMessages,
} from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { fetchAnsweredMessages } from "@kraaft/shared/core/modules/message/messageData/sagas/manuallyLoadMessages";
import { MessageLoader } from "@kraaft/shared/core/modules/message/messageLoader";
import { selectFreemiumFriendlyMessages } from "@kraaft/shared/core/modules/message/messageSelectors";
import {
  getIdFromMessage,
  sortMessages,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  DocumentData,
  QueryDocumentSnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";
import { nullId } from "@kraaft/shared/core/utils";
import {
  CursorState,
  LinkedList,
} from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";
import { ScrollPosition } from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList.props";
import { LinkedListHelpers } from "@kraaft/shared/core/utils/useBidirectional/linkedList";
import { useBidirectional } from "@kraaft/shared/core/utils/useBidirectional/useBidirectional";

const MESSAGE_PAGE_SIZE = 30;

export function useBidirectionalMessageProps(
  startId: string | undefined,
  roomId: string,
  scrollToMessage: (
    messageId: string,
    position: ScrollPosition,
    shouldAnimate: boolean,
    isAlreadyDisplayed: boolean,
  ) => Promise<void>,
) {
  const dispatch = useDispatch();
  const docs = useSelector(selectAllMessageDocs);
  const roomLinkedList = useSelector(selectMessageLinkedList(roomId));
  const currentUserId = useSelector(selectCurrentUserId) ?? "";
  const { messages, didStripMessages } = useSelector(
    selectFreemiumFriendlyMessages(roomId),
  );
  const optimisticMessages = useSelector(selectRoomOptimisticMessages(roomId));

  const getLinkedList = useCallback(() => roomLinkedList, [roomLinkedList]);

  const storeLinkedList = useCallback(
    (linkedList: LinkedList) => {
      dispatch(MessageDataStateActions.addLinkedList({ roomId, linkedList }));
    },
    [dispatch, roomId],
  );

  const storeMessages = useCallback(
    async (
      newMessages: Record<string, AnyMessage>,
      newDocs: Record<string, QueryDocumentSnapshot<DocumentData>>,
    ) => {
      const answeredMessages = await fetchAnsweredMessages(
        currentUserId,
        roomId,
        pickBy(newMessages, MessageHelper.isUserMessage),
        false,
      );

      dispatch(
        MessageDataStateActions.addMessages({
          roomId,
          messages: { ...newMessages, ...answeredMessages },
          messageDocs: keyBy(newDocs, (d) => d.id),
          shouldActAsNewMessage: false,
        }),
      );
    },
    [currentUserId, dispatch, roomId],
  );

  const fetchBeforeId = useCallback(
    async (id: string, pageSize: number) => {
      const cached = docs[id];
      if (!cached) {
        console.warn("Could not find document in cache");
        return { items: [], end: true };
      }
      const {
        messages: newMessages,
        snapshots,
        end,
      } = await MessageLoader.fetchBefore(
        roomId,
        cached,
        pageSize,
        currentUserId,
      );
      await storeMessages(newMessages, snapshots);
      return {
        items: sortMessages(Object.values(newMessages)),
        end,
      };
    },
    [currentUserId, docs, roomId, storeMessages],
  );

  const fetchAfterId = useCallback(
    async (id: string, pageSize: number) => {
      const cached = docs[id];
      if (!cached) {
        console.warn("Could not find document in cache");
        return { items: [], end: true };
      }
      const {
        snapshots,
        messages: newMessages,
        end,
      } = await MessageLoader.fetchAfter(
        roomId,
        cached,
        pageSize,
        currentUserId,
      );
      await storeMessages(newMessages, snapshots);
      return { items: sortMessages(Object.values(newMessages)), end };
    },
    [currentUserId, docs, roomId, storeMessages],
  );

  const fetchAroundId = useCallback(
    async (id: string, pageSize: number) => {
      // id === "1" is a legacy way to tell the user has not read anything
      if (id === "1") {
        const {
          messages: lastMessages,
          snapshots,
          end,
        } = await MessageLoader.fetchRoomLastMessages(
          roomId,
          pageSize,
          currentUserId,
        );
        await storeMessages(lastMessages, snapshots);
        const firstMessage = maxBy(Object.values(lastMessages), (m) =>
          m.createdAt.getTime(),
        );
        if (!firstMessage) {
          return undefined;
        }
        delete lastMessages[firstMessage.id];
        return {
          before: sortMessages(Object.values(lastMessages)),
          item: firstMessage,
          after: [],
          ends: {
            before: end,
            after: true,
          },
        };
      }
      if (id === nullId) {
        const {
          snapshots,
          messages: firstMessages,
          end,
        } = await MessageLoader.fetchRoomFirstMessages(
          roomId,
          pageSize,
          currentUserId,
        );
        await storeMessages(firstMessages, snapshots);
        const firstMessage = minBy(Object.values(firstMessages), (m) =>
          m.createdAt.getTime(),
        );
        if (!firstMessage) {
          return undefined;
        }
        delete firstMessages[firstMessage.id];
        return {
          before: [],
          item: firstMessage,
          after: sortMessages(Object.values(firstMessages)),
          ends: {
            before: true,
            after: end,
          },
        };
      }
      const result = await MessageLoader.fetchMessagesAround(
        roomId,
        id,
        pageSize,
        currentUserId,
      );
      if (!result) {
        return undefined;
      }
      const {
        before,
        beforeEnd,
        beforeSnapshots,
        doc,
        item,
        after,
        afterEnd,
        afterSnapshots,
      } = result;
      await storeMessages(
        { ...before, [item.id]: item, ...after },
        {
          ...beforeSnapshots,
          [doc.id]: doc,
          ...afterSnapshots,
        },
      );
      return {
        before: sortMessages(Object.values(before)),
        item,
        after: sortMessages(Object.values(after)),
        ends: {
          before: beforeEnd,
          after: afterEnd,
        },
      };
    },
    [currentUserId, roomId, storeMessages],
  );

  const fetchLast = useCallback(
    async (pageSize: number) => {
      const {
        snapshots,
        messages: newMessages,
        end,
      } = await MessageLoader.fetchRoomLastMessages(
        roomId,
        pageSize,
        currentUserId,
      );
      await storeMessages(newMessages, snapshots);
      return { items: sortMessages(Object.values(newMessages)), end };
    },
    [currentUserId, roomId, storeMessages],
  );

  const { anchor, onScroll, startFromId } = useBidirectional<AnyMessage>({
    startId,
    pageSize: MESSAGE_PAGE_SIZE,
    fetchBeforeId,
    fetchAfterId,
    fetchAroundId,
    fetchLast,
    getIdFromItem: getIdFromMessage,
    getLinkedList,
    storeLinkedList,
    scrollToDataId: scrollToMessage,
  });

  const loadingBefore =
    !anchor ||
    !LinkedListHelpers.getEarliestAnchored(
      roomLinkedList,
      anchor,
      CursorState.FINISHED,
    );

  const loadingAfter =
    !anchor ||
    !LinkedListHelpers.getLatestAnchored(
      roomLinkedList,
      anchor,
      CursorState.FINISHED,
    );

  const atLeastOneChunkLoaded =
    anchor && LinkedListHelpers.lenFrom(roomLinkedList, anchor) !== 0;
  const laterEdgeComponent = useMemo<ReactElement | undefined>(
    () =>
      !atLeastOneChunkLoaded || loadingAfter ? (
        <BidirectionalLoader nativeID="bottom-messages-loader" />
      ) : undefined,
    [atLeastOneChunkLoaded, loadingAfter],
  );

  const earlierEdgeComponent = useMemo<ReactElement | undefined>(() => {
    // Prevents double loader from being in view
    if (!atLeastOneChunkLoaded) {
      return undefined;
    }
    if (didStripMessages) {
      return <MessageLimitReached from="conversation" />;
    }

    if (loadingBefore) {
      return <BidirectionalLoader nativeID="top-messages-loader" />;
    }

    return <MessageListTop roomId={roomId} />;
  }, [atLeastOneChunkLoaded, didStripMessages, loadingBefore, roomId]);

  // eslint-disable-next-line complexity
  const messagesToDisplay = useMemo(() => {
    const _messagesToDisplay: AnyMessage[] = [];
    const addedMessagesId = new Set<string>();

    const origin =
      anchor && LinkedListHelpers.getOrigin(roomLinkedList, anchor);

    if (!origin) {
      return [];
    }

    for (const { item } of LinkedListHelpers.crawl(roomLinkedList, {
      from: origin,
    })) {
      const message = messages[item.itemId];
      if (
        !message ||
        addedMessagesId.has(message.id) ||
        (MessageHelper.isUserMessage(message) &&
          message.optimisticId &&
          addedMessagesId.has(message.optimisticId))
      ) {
        continue;
      }
      addedMessagesId.add(message.id);
      if (MessageHelper.isUserMessage(message) && message.optimisticId) {
        addedMessagesId.add(message.optimisticId);
      }
      _messagesToDisplay.unshift(message);
    }

    return _messagesToDisplay;
  }, [anchor, messages, roomLinkedList]);

  const firstChunkIsInView = useMemo(
    () =>
      Boolean(
        anchor &&
          LinkedListHelpers.containsEarliestAnchored(
            roomLinkedList,
            anchor.itemId,
          ),
      ),
    [anchor, roomLinkedList],
  );

  const lastChunkIsInView = useMemo(
    () =>
      Boolean(
        anchor &&
          LinkedListHelpers.containsLatestAnchored(
            roomLinkedList,
            anchor.itemId,
          ),
      ),
    [anchor, roomLinkedList],
  );

  const messagesWithOptimistic = useMemo(() => {
    if (lastChunkIsInView) {
      return [...[...optimisticMessages].reverse(), ...messagesToDisplay];
    }
    return messagesToDisplay;
  }, [lastChunkIsInView, messagesToDisplay, optimisticMessages]);

  return {
    earlierEdgeComponent,
    laterEdgeComponent,
    messagesToDisplay: messagesWithOptimistic,
    onScroll,
    startFromId,
    firstChunkIsInView,
    lastChunkIsInView,
  };
}
