import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { compact } from "lodash";

import { useBooleanState } from "@kraaft/helper-hooks";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { LibrarySchemaActions } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions";
import { getCopySchemaInLibraryLoaderId } from "@kraaft/shared/core/modules/librarySchema/librarySchema.actions.utils";
import { KFolderSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { OfflineSchemaActions } from "@kraaft/shared/core/modules/schema/schema.offline";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUserIsSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";
import { useLoaderState } from "@kraaft/shared/core/utils/useLoader";
import { ColorStyle, Icon, Text } from "@kraaft/ui";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";
import { DraggableLineDecoration } from "@kraaft/web/src/components/draggableLineDecoration";
import {
  SchemaDefaultVisibilityButton,
  useSchemaDefaultVisibilityActionSheetItem,
} from "@kraaft/web/src/components/schemaDefaultVisibilityButton";
import { DuplicateSchemaPopup } from "@kraaft/web/src/views/schemasView/poolSchemaList/orderablePoolSchemaItem/duplicateSchemaPopup";

import { DeleteSchemaSheet } from "./deleteSchemaSheet";

import { useStyles } from "./orderablePoolSchemaItem.styles";

interface OrderablePoolSchemaItemProps {
  schema: KFolderSchema;
}

const OrderablePoolSchemaItem = ({ schema }: OrderablePoolSchemaItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigationService = useNavigationService();
  const [isDuplicationPopupOpen, openDuplicationPopup, closeDuplicationPopup] =
    useBooleanState();

  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);

  const { loading: isCopyInLibraryButtonLoading } = useLoaderState(
    getCopySchemaInLibraryLoaderId(schema.id),
  );

  const handleRowPress = useCallback(() => {
    navigationService.navigate("FolderSchemaBuilder", { schemaId: schema.id });
  }, [navigationService, schema.id]);

  const handleCopyInLibrary = useCallback(() => {
    dispatch(
      LibrarySchemaActions.copySchemaInLibrary({
        schemaId: schema.id,
      }),
    );
  }, [dispatch, schema.id]);

  const handleDelete = useCallback(async () => {
    dispatch(OfflineSchemaActions.delete({ id: schema.id }));
  }, [dispatch, schema.id]);

  const defaultVisibilityAction = useSchemaDefaultVisibilityActionSheetItem(
    schema.id,
    "schema_list",
  );

  const { open: openDeleteSheet, element: deleteSheetElement } =
    DeleteSchemaSheet.use({
      schemaId: schema.id,
      onDelete: handleDelete,
      schemaName: schema.name,
    });

  const [copyLoading, setCopyLoading] = useState(false);

  const handleCopyJsonInClipboard = useCallback(async () => {
    const json = await Api.getSchemaAsJson({ schemaId: schema.id });
    copyTextToClipboard(json);
  }, [schema.id]);

  const actions = useMemo<ActionSheetItem[]>(
    () =>
      compact([
        isSuperadmin
          ? ({
              label: t("schemaLibrary.copyInLibrary"),
              icon: "copy-07",
              onPress: handleCopyInLibrary,
              disabled: isCopyInLibraryButtonLoading,
            } as const)
          : undefined,
        {
          label: t("schemaLibrary.configure.row.duplicate"),
          icon: "copy-06",
          onPress: openDuplicationPopup,
        },
        defaultVisibilityAction,
        schema.type === "custom"
          ? {
              label: t("schemaLibrary.configure.row.delete"),
              style: "destructive",
              icon: "trash-01",
              onPress: openDeleteSheet,
            }
          : undefined,
        isSuperadmin
          ? ({
              label: "Copy as JSON (dev)",
              icon: "copy-07",
              onPress: handleCopyJsonInClipboard,
              loading: copyLoading,
            } as const)
          : undefined,
      ]),
    [
      isSuperadmin,
      t,
      handleCopyInLibrary,
      isCopyInLibraryButtonLoading,
      openDuplicationPopup,
      defaultVisibilityAction,
      schema.type,
      openDeleteSheet,
      handleCopyJsonInClipboard,
      copyLoading,
    ],
  );

  return (
    <>
      <DraggableLineDecoration>
        <div
          id={`draggable-${schema.id}`}
          className={classes.root}
          role="button"
          onClick={handleRowPress}
        >
          <Icon
            name={KSchemaUtils.getSchemaIconName(schema.icon)}
            size="MEDIUM"
            className={classes.icon}
            style={{ color: ColorStyle.FONT_HIGH_EMPHASIS }}
          />
          <div className={classes.name}>
            <Text color="FONT_HIGH_EMPHASIS" weight="bold" size="TITLE">
              {schema.name}
            </Text>
          </div>
          <span className={classes.actionsContainer}>
            <SchemaDefaultVisibilityButton
              source="schema_list"
              schemaId={schema.id}
            />
            {actions.length > 0 && (
              <LegacyContextMenuButton
                testID={`context-menu-button-${schema.id}`}
                actions={actions}
                vertical
              />
            )}
          </span>
        </div>
      </DraggableLineDecoration>
      <DuplicateSchemaPopup
        open={isDuplicationPopupOpen}
        onClose={closeDuplicationPopup}
        schemaId={schema.id}
      />
      {deleteSheetElement}
    </>
  );
};

export { OrderablePoolSchemaItem };
