import { createAction } from "@reduxjs/toolkit";

import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  actionUpdateLoaderMetaFailure,
  actionUpdateLoaderMetaSuccess,
  createLoaderMeta,
  WithLoaderPayload,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

interface AddDirectoryPayload extends WithLoaderPayload {
  roomId: string;
  name: string;
  parentId: string;
}

interface RemoveDirectoryPayload {
  roomId: string;
  directoryId: string;
}

interface RenameDirectoryPayload {
  roomId: string;
  directoryId: string;
  newDirectoryName: string;
}

interface MoveDirectoryPayload {
  roomId: string;
  directoryId: string;
  newParentDirectoryId: string;
  afterSiblingDirectoryId?: string;
}

export const DirectoryActions = {
  setDefaultTreeForPool: createAction<{
    poolId: string;
    tree: SerializedDirectory[];
  }>("@directory/SET_DEFAULT_TREE_FOR_POOL"),

  subscribe: createAction<{ roomId: string }>("@directory/SUBSCRIBE"),
  unsubscribe: createAction<{ roomId: string }>("@directory/UNSUBSCRIBE"),

  add: createAction("@directory/ADD", (payload: AddDirectoryPayload) => ({
    payload,
    meta: createLoaderMeta({
      id: payload.loaderId,
      status: LoaderStatus.LOADING,
    }),
  })),

  addSuccess: createAction(
    "@directory/ADD_SUCCESS",
    actionUpdateLoaderMetaSuccess,
  ),

  addFailure: createAction(
    "@directory/ADD_FAILURE",
    actionUpdateLoaderMetaFailure,
  ),

  remove: createAction(
    "@directory/REMOVE",
    (payload: RemoveDirectoryPayload) => ({
      payload,
    }),
  ),

  rename: createAction(
    "@directory/RENAME",
    (payload: RenameDirectoryPayload) => ({
      payload,
    }),
  ),

  move: createAction("@directory/MOVE", (payload: MoveDirectoryPayload) => ({
    payload,
  })),

  downloadDirectory: createAction(
    "@directory/DOWNLOAD_Directory",
    (payload: DownloadDirectoryPayloadPayload) => ({
      payload,
      meta: createLoaderMeta({
        id: payload.loaderId,
        status: LoaderStatus.LOADING,
      }),
    }),
  ),
  downloadDirectorySelectionSuccess: createAction(
    "@directory/DOWNLOAD_MEDIA_SELECTION_SUCCESS",
    actionUpdateLoaderMetaSuccess,
  ),
  downloadDirectorySelectionFailure: createAction(
    "@directory/DOWNLOAD_MEDIA_SELECTION_FAILURE",
    actionUpdateLoaderMetaFailure,
  ),
};

export const DirectoryStateActions = {
  setForRoom: createAction<{
    roomId: string;
    directories: Directory[];
  }>("@directory/state/SET_FOR_ROOM"),

  add: createAction<{ directory: Directory }>("@directory/state/ADD"),
  delete: createAction<{ directoryId: string }>("@directory/state/DELETE"),
  rename: createAction<{ directoryId: string; newName: string }>(
    "@directory/state/RENAME",
  ),
  openCarousel: createAction<{
    roomId: string;
    directoryId: string;
    messageId: string;
  }>("@directory/state/OPEN_CAROUSEL"),
};

interface DownloadDirectoryPayloadPayload extends WithLoaderPayload {
  roomId: string;
  directoryId: string;
}
