import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";

import { getEditMessageMenuLabel } from "@kraaft/shared/components/messageTextEditor/messageTextEditorUtils";
import { MessageWithTextCapability } from "@kraaft/shared/core/modules/message/core/message.helper";
import { Icon, Text } from "@kraaft/ui";

import { styles } from "./messageTextEditorHeader.styles";

export const MessageTextEditorHeader = (props: {
  message: MessageWithTextCapability | undefined;
  onClose: () => void;
}) => {
  const { message, onClose } = props;
  const { t } = useTranslation();

  const label = message ? getEditMessageMenuLabel(t, message) : "";

  return (
    <View style={styles.messageEditorHeader}>
      <Text id="message-text-editor-header-text" size="MEDIUM" color="WHITE">
        {label}
      </Text>
      <TouchableOpacity accessibilityLabel={t("close")} onPress={onClose}>
        <Icon name="x-close" size="MEDIUM" color="WHITE" />
      </TouchableOpacity>
    </View>
  );
};
