import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import { useMeshContext } from "@kraaft/helper-hooks";
import { ImagePreview } from "@kraaft/shared/components/replyPreview/imagePreview";
import { InformationPreview } from "@kraaft/shared/components/replyPreview/informationsPreview";
import { MessageListContext } from "@kraaft/shared/components/replyPreview/replyHooks";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { Button, ColorStyle, Icon } from "@kraaft/ui";

import { styles } from "./replyPreview.style";

interface ReplyPreviewProps {
  onLongPress?: () => void;
  message: AnyUserMessage;
  isReply?: boolean;
  onClose?: (messageId: AnyUserMessage | null) => void;
  isPreview?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const ReplyPreview = ({
  onLongPress,
  message,
  isReply = true,
  isPreview = false,
  onClose,
  style,
}: ReplyPreviewProps): JSX.Element => {
  const { t } = useTranslation();

  const { messageListHandle, highlightMessage } =
    useMeshContext(MessageListContext);

  const handlePress = useCallback(async () => {
    await messageListHandle.current?.scrollToMessage(message.id);
    highlightMessage(message.id);
  }, [highlightMessage, message.id, messageListHandle]);

  const containerStyle = useMemo(
    () => [
      styles.previewContainer,
      isPreview
        ? styles.previewContainerPreview
        : isReply
          ? styles.previewContainerMessageReply
          : styles.previewContainerMessageSelf,
      style,
    ],
    [isPreview, isReply, style],
  );

  const content = (
    <>
      <InformationPreview
        isPreview={isPreview}
        message={message}
        isReply={isReply}
      />
      {!MessageHelper.isDeleted(message) && (
        <>
          {MessageHelper.isImage(message) && (
            <ImagePreview message={message} attachment={message.attachment} />
          )}
          {MessageHelper.isDocument(message) && (
            <View style={styles.iconContainer}>
              <Icon name="file-04" size="MEDIUM" style={styles.icon} />
              <Text
                numberOfLines={1}
                style={styles.iconMessage}
                ellipsizeMode="middle"
              >
                {message.attachment.original.filename}
              </Text>
            </View>
          )}
          {MessageHelper.isVideo(message) && (
            <View style={styles.iconContainer}>
              <Icon name="play" size="MEDIUM" style={styles.icon} />
              <Text
                numberOfLines={1}
                style={styles.iconMessage}
                ellipsizeMode="middle"
              >
                {message.attachment.original.filename}
              </Text>
            </View>
          )}
        </>
      )}
      {onClose && (
        <Button
          accessibilityLabel={t("close")}
          icon="x-close"
          onPress={() => onClose(null)}
          variant="QUATERNARY"
          size="SMALL"
          style={styles.previewCancelButton}
          contentColor={ColorStyle.FONT_HIGH_EMPHASIS}
        />
      )}
    </>
  );

  if (!isPreview) {
    return (
      <TouchableOpacity
        accessibilityLabel={t("seeMessage")}
        style={containerStyle}
        onPress={handlePress}
        onLongPress={onLongPress}
      >
        {content}
      </TouchableOpacity>
    );
  }

  return <View style={containerStyle}>{content}</View>;
};
