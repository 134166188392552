import { useTranslation } from "react-i18next";

import { Button, ButtonProps } from "@kraaft/ui";

import { MapButtonBackground } from "./mapButtonBackground";

export type MapButtonProps = Required<Pick<ButtonProps, "onPress">> & {
  type: "modularFolders" | "modularFoldersDetails" | "photos" | "rooms";
  isSelected?: boolean;
  center?: boolean;
};

const MapButton = ({
  type,
  isSelected,
  onPress,
  center,
  ...props
}: MapButtonProps) => {
  const { t } = useTranslation();

  const accessibilityLabel = t("openMap");

  return (
    <MapButtonBackground
      type={type}
      onPress={onPress}
      isSelected={isSelected}
      center={center}
    >
      <Button
        accessibilityLabel={accessibilityLabel}
        id="room-map-button"
        text={t("map.toggle")}
        variant="SECONDARY"
        size="SMALL"
        selected={isSelected}
        onPress={onPress}
        {...props}
      />
    </MapButtonBackground>
  );
};

export { MapButton };
