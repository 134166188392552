import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useImageDownload } from "@kraaft/web/src/components/menuMessageContainer/hooks/useImageDownload";
import { useVideoDownload } from "@kraaft/web/src/components/menuMessageContainer/hooks/useVideoDownload";

export function useDownloadMediaMenuItems(
  roomId: string,
  message: AnyUserMessage,
) {
  const handleDownloadImage = useImageDownload(message);
  const handleDownloadVideo = useVideoDownload(message);

  const { t } = useTranslation();

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (MessageHelper.isImage(message)) {
      items.push({
        label: t("downloadImage"),
        onPress: async () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "download",
          });
          await handleDownloadImage();
        },
        icon: "download-01",
      });
    }

    if (MessageHelper.isVideo(message)) {
      items.push({
        label: t("downloadVideo"),
        onPress: async () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "download",
          });

          await handleDownloadVideo();
        },
        icon: "download-01",
      });
    }
    return items;
  }, [handleDownloadImage, handleDownloadVideo, message, roomId, t]);
}
