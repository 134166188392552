import { useDraggable as useKitDraggable } from "@dnd-kit/react";

import {
  DndContract,
  DraggableOnDrop,
  DraggableOnEnd,
  DraggableOnOver,
} from "./dnd.types";

export function useDraggable<
  OwnContract extends DndContract,
  DroppableOn extends DndContract,
>(
  params: {
    id: string;
    data: OwnContract;
    onDrop?: DraggableOnDrop<DroppableOn>;
    onOver?: DraggableOnOver<DroppableOn>;
    onEnd?: DraggableOnEnd;
  } & Omit<Parameters<typeof useKitDraggable>["0"], "id" | "type" | "data">,
) {
  return useKitDraggable({
    id: params.id,
    data: {
      ...params.data,
      onDrop: params.onDrop,
      onOver: params.onOver,
      onEnd: params.onEnd,
    },
    type: params.data.type,
  });
}
