import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";

import { useStyles } from "./dialog.styles";

const MigrateRoomsDialog = ({
  userId,
  open,
  onClose,
  refetch,
}: {
  userId: string;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();

  const [input, setInput] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const title = t("migrateRooms");

  const content = (
    <div className={classes.dialogContentContainer}>
      <KInput
        label={t("migrateRoomsInputLabel")}
        value={input}
        onChangeText={setInput}
      />
    </div>
  );

  useEffect(() => {
    setInput("");
  }, [userId]);

  const onConfirm = async () => {
    try {
      setisLoading(true);
      await SuperadminApi.migrateUserRooms({
        toUserId: userId,
        fromUserId: input,
      });
      refetch();
      onClose();
    } catch (e) {
      dispatch(showError({ title: t("internalError") }));
    } finally {
      setisLoading(false);
    }
  };

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirm"),
      text: t("confirm"),
      onPress: onConfirm,
      loading: isLoading,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      actions={actions}
    />
  );
};

export { MigrateRoomsDialog };
