import { EditOneOperationBuilder } from "@kraaft/offline";
import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { GeoLocation } from "@kraaft/shared/core/types";

export const updateAttachmentGeolocationOperation =
  EditOneOperationBuilder.create<ModularFolder>()
    .payload<{
      attachmentId: string;
      columnKey: string;
      geolocation: GeoLocation | undefined;
    }>()
    .expected((modularFolder, payload) => {
      incrementPendingOperations(modularFolder);
      const value = modularFolder.properties[payload.columnKey];
      if (value?.columnType !== KColumnType.attachment || !value.value) {
        return modularFolder;
      }
      const attachment = value.value.find(
        (att) => att.id === payload.attachmentId,
      );
      if (
        !attachment ||
        (attachment.type !== "image" && attachment.type !== "video")
      ) {
        return modularFolder;
      }
      attachment.geolocation = payload.geolocation;
      return modularFolder;
    })
    .mutate((payload, task) =>
      Api.updateModularFolderAttachmentGeolocation({
        requestId: task.id,
        folderId: payload.id,
        geolocation: payload.geolocation,
        attachmentId: payload.attachmentId,
      }),
    );
