import { useCallback, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { cloneDeep } from "lodash";
import { assert } from "ts-essentials";

import { UnknownObject } from "@kraaft/shared/core/types";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { DraggableRowProps } from "@kraaft/web/src/components/orderableList/draggableRow";
import { isOrderableRow } from "@kraaft/web/src/components/orderableList/draggableRow/draggableRow.utils";
import { DragObjectWithType } from "@kraaft/web/src/core/types/dnd";

import { AnimatedDraggableRow } from "./animatedDraggableRow";
import {
  AdditionalTypes,
  OrderableListDraggedItem,
  OrderableListHoveredItem,
  OrderedListRows,
  ReorderFunction,
} from "./orderableList.types";
import {
  getDirectionFromIndexes,
  getNewIndexAfterAsc,
  getNewIndexAfterDesc,
  getNewIndexBeforeAsc,
  getNewIndexBeforeDesc,
  getNewSourceIndex,
  listRowsAsArray,
  useDroppableAcceptTypes,
} from "./orderableList.utils";

export type OrderableListProps<
  T extends UnknownObject,
  K extends string = string,
> = AdditionalTypes<K> & {
  testId?: string;
  listIdentifier: string;
  rows: OrderedListRows<T>;
  updateRows: (newRows: OrderedListRows<T>) => void;
  renderRow: DraggableRowProps<T>["renderItem"];
  withHandle?: boolean;
  rowContainerClassName?: string;
  additionalTypeRowHeight?: number;
  customScrollThreshold?: number;
  disabled?: boolean;
};

const OrderableListScrollable = <
  T extends UnknownObject,
  K extends string = string,
>(
  props: OrderableListProps<T, K>,
) => {
  const {
    testId,
    listIdentifier,
    rows,
    updateRows,
    renderRow,
    withHandle,
    rowContainerClassName,
    additionalTypes,
    additionalTypeRowHeight,
    disabled,
  } = props;

  const draggedRowDataReference = useRef<OrderableListDraggedItem | null>(null);
  const [hoveredRowData, setHoveredRowData] =
    useState<OrderableListHoveredItem | null>(null);

  const orderedRows = useMemo(() => listRowsAsArray(rows), [rows]);

  const setDraggedRowData = useCallback((data: OrderableListDraggedItem) => {
    if (draggedRowDataReference.current) {
      draggedRowDataReference.current.key = data.key;
      draggedRowDataReference.current.index = data.index;
      draggedRowDataReference.current.height = data.height;
    } else {
      draggedRowDataReference.current = {
        key: data.key,
        index: data.index,
        height: data.height,
      };
    }
  }, []);

  const resetDraggedRowData = useCallback(() => {
    draggedRowDataReference.current = null;
  }, []);

  const resetHoveredRowData = useCallback(() => {
    setHoveredRowData(null);
  }, []);

  const reorder = useCallback<ReorderFunction>(
    (sourceKey, placement, targetKey) => {
      const oldRows = cloneDeep(rows);

      const source = oldRows[sourceKey];
      const target = oldRows[targetKey];

      assert(source, "source is missing");
      assert(target, "target is missing");

      const sourceIndex = source.index;
      const targetIndex = target.index;

      if (sourceIndex === targetIndex) {
        return oldRows;
      }

      for (const oldListRow of Object.values(oldRows)) {
        if (oldListRow.key === sourceKey) {
          oldListRow.index = getNewSourceIndex(
            placement,
            sourceIndex,
            targetIndex,
          );
        } else if (placement === "before") {
          if (sourceIndex < targetIndex) {
            getNewIndexBeforeAsc(oldListRow, sourceIndex, targetIndex);
          } else {
            getNewIndexBeforeDesc(oldListRow, sourceIndex, targetIndex);
          }
        } else if (placement === "after") {
          if (sourceIndex < targetIndex) {
            getNewIndexAfterAsc(oldListRow, sourceIndex, targetIndex);
          } else {
            getNewIndexAfterDesc(oldListRow, sourceIndex, targetIndex);
          }
        }
      }

      updateRows(oldRows);
    },
    [rows, updateRows],
  );

  const onDragEnd = useCallbackRealtime(
    (
      [currentHoveredRowData, refOrderedRows],
      item: DragObjectWithType | undefined,
    ) => {
      if (!item) {
        return;
      }
      if (currentHoveredRowData === null) {
        return;
      }
      const draggedRowData = draggedRowDataReference.current;

      if (!isOrderableRow(item)) {
        const lastRow = refOrderedRows[refOrderedRows.length - 1]?.key;
        if (!lastRow) {
          return;
        }
        additionalTypes?.[item.type as K]?.(
          item,
          currentHoveredRowData?.placement ?? "after",
          currentHoveredRowData?.key ?? lastRow,
        );
        resetDraggedRowData();
        resetHoveredRowData();
        return;
      }

      if (draggedRowData && currentHoveredRowData) {
        reorder(
          draggedRowData.key,
          currentHoveredRowData.placement,
          currentHoveredRowData.key,
        );
        resetDraggedRowData();
        resetHoveredRowData();
      }
    },
    [additionalTypes, reorder, resetDraggedRowData, resetHoveredRowData],
    [hoveredRowData, orderedRows],
  );

  const acceptTypes = useDroppableAcceptTypes(props.additionalTypes);
  const [{ isDragging }, drop] = useDrop({
    accept: acceptTypes,
    drop: (item: DragObjectWithType) => {
      onDragEnd(item);
    },
    collect: (monitor) => ({
      isDragging: monitor.isOver() && monitor.canDrop(),
    }),
  });

  const renderRenderedRows = useMemo(
    () =>
      orderedRows.map((item) => {
        const direction = getDirectionFromIndexes(
          item.index,
          draggedRowDataReference.current?.index,
          hoveredRowData?.index,
          hoveredRowData?.placement,
        );

        return (
          <AnimatedDraggableRow
            key={item.key}
            item={item}
            listIdentifier={listIdentifier}
            renderItem={renderRow}
            containerClassName={rowContainerClassName}
            withHandle={withHandle}
            onDragBegin={setDraggedRowData}
            onDragEnd={onDragEnd}
            onHovered={setHoveredRowData}
            isDragging={isDragging}
            direction={direction}
            draggedRowHeight={
              draggedRowDataReference.current?.height ?? additionalTypeRowHeight
            }
            additionalTypes={additionalTypes}
            disabled={disabled}
          />
        );
      }),
    [
      orderedRows,
      hoveredRowData?.index,
      hoveredRowData?.placement,
      listIdentifier,
      renderRow,
      rowContainerClassName,
      withHandle,
      setDraggedRowData,
      onDragEnd,
      isDragging,
      additionalTypeRowHeight,
      additionalTypes,
      disabled,
    ],
  );

  return (
    <div data-testid={testId} ref={drop}>
      {renderRenderedRows}
    </div>
  );
};

export { OrderableListScrollable };
