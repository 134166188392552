import { EditOneOperationBuilder } from "@kraaft/offline";
import { Api } from "@kraaft/shared/core/services/api";

import {
  PoolMemberId,
  PoolMemberIdHelper,
  PoolMemberPreview,
} from "../poolMember.types";

export const unassignRole = EditOneOperationBuilder.create<PoolMemberPreview>()
  .payload<{ value: "room" }>()
  .expected((aggregate, payload) => {
    aggregate.roles = aggregate.roles?.filter((role) => role !== payload.value);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { id, value } = payload;
    const { poolId, userId } = PoolMemberIdHelper.split(id as PoolMemberId);
    return Api.unassignRole({
      poolId,
      memberId: userId,
      roleId: value,
    });
  });
