import { spawn, takeEvery } from "typed-redux-saga/macro";

import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { addCreatedRoomLocationSaga } from "@kraaft/shared/core/modules/room/sagas/addCreatedRoomLocation";
import { changeNotificationFilter } from "@kraaft/shared/core/modules/room/sagas/changeNotificationFilter";
import { changeRoomEmojiSaga } from "@kraaft/shared/core/modules/room/sagas/changeRoomEmoji";
import { changeRoomUsers } from "@kraaft/shared/core/modules/room/sagas/changeRoomUsers";
import { connectRoomToExternalSaga } from "@kraaft/shared/core/modules/room/sagas/connectRoomToExternal";
import { disconnectRoomToExternalSaga } from "@kraaft/shared/core/modules/room/sagas/disconnectRoomToExternal";
import { importRooms } from "@kraaft/shared/core/modules/room/sagas/importRooms";
import { markRoomReadSaga } from "@kraaft/shared/core/modules/room/sagas/markRoomRead";
import { markRoomUnreadSaga } from "@kraaft/shared/core/modules/room/sagas/markRoomUnread";
import { pinRoomSaga } from "@kraaft/shared/core/modules/room/sagas/pinRoom";
import { removeMessage } from "@kraaft/shared/core/modules/room/sagas/removeMessage";
import { uploadFilesSaga } from "@kraaft/shared/core/modules/room/sagas/roomUploads";
import { subscribeToPoolRoomsSaga } from "@kraaft/shared/core/modules/room/sagas/subscribeToPoolRooms";
import { subscribeToRoomSaga } from "@kraaft/shared/core/modules/room/sagas/subscribeToRoom";
import {
  subscribeToRoomUsersSaga,
  unsubscribeFromRoomUsersSaga,
} from "@kraaft/shared/core/modules/room/sagas/subscribeToRoomUsers";
import { superadminDeleteMessage } from "@kraaft/shared/core/modules/room/sagas/superadminDeleteMessage";
import { unpinRoomSaga } from "@kraaft/shared/core/modules/room/sagas/unpinRoom";
import { updateRoomSaga } from "@kraaft/shared/core/modules/room/sagas/updateRoom";
import { updateRoomVisibilitySaga } from "@kraaft/shared/core/modules/room/sagas/updateRoomVisibility";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* roomSagas() {
  yield* spawn(subscribeToPoolRoomsSaga);
  yield* spawn(subscribeToRoomSaga);

  yield takeCountedDeep(
    roomActions.subscribeToRoomUsers,
    roomActions.unsubscribeFromRoomUsers,
    subscribeToRoomUsersSaga,
    unsubscribeFromRoomUsersSaga,
    (action) => action.payload.roomId,
  );

  yield* takeEvery(roomActions.uploadFilesRequest, uploadFilesSaga);

  yield* takeEvery(roomActions.changeRoomUsersRequest, changeRoomUsers);
  yield* takeEvery(roomActions.importRooms, importRooms);
  yield* takeEvery(roomActions.removeMessageRequest, removeMessage);
  yield* takeEvery(
    roomActions.superadminDeleteMessageRequest,
    superadminDeleteMessage,
  );

  yield* takeEvery(roomActions.updateRoomVisibility, updateRoomVisibilitySaga);
  yield* takeEvery(roomActions.updateRoomRecord, updateRoomSaga);
  yield* takeEvery(roomActions.markRoomUnread, markRoomUnreadSaga);
  yield* takeEvery(roomActions.markRoomRead, markRoomReadSaga);
  yield* takeEvery(
    roomActions.RoomActions.addCreatedRoomLocation,
    addCreatedRoomLocationSaga,
  );

  yield* takeEvery(
    roomActions.connectRoomToExternal,
    connectRoomToExternalSaga,
  );
  yield* takeEvery(
    roomActions.disconnectRoomToExternal,
    disconnectRoomToExternalSaga,
  );

  yield* takeEvery(
    roomActions.RoomActions.changeNotificationFilter,
    changeNotificationFilter,
  );

  yield* takeEvery(
    roomActions.RoomActions.changeRoomEmoji,
    changeRoomEmojiSaga,
  );
  yield* takeEvery(roomActions.RoomActions.pin, pinRoomSaga);
  yield* takeEvery(roomActions.RoomActions.unpin, unpinRoomSaga);
}
