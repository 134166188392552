import { useState } from "react";
import {
  runOnJS,
  type SharedValue,
  useAnimatedReaction,
} from "react-native-reanimated";

export function useBooleanFromSharedValue(sharedValue: SharedValue<boolean>) {
  const [value, setValue] = useState(false);

  useAnimatedReaction(
    () => sharedValue.value,
    (svValue) => {
      runOnJS(setValue)(svValue);
    },
  );

  return value;
}
