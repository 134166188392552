import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { KDialogFrame } from "@kraaft/shared/components/kDialog/kDialogFrame";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { SelectChecklistTemplateDialog } from "@kraaft/shared/components/selectChecklistTemplate/selectChecklistTemplateDialog";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import {
  applySchemaTemplates,
  startEditExistingSchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import {
  selectEditedSchemaTemplate,
  selectSchemaTemplate,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateSelectors";
import { createTaskTemplate } from "@kraaft/shared/core/modules/schemaTemplate/taskTemplate/taskTemplateActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ChecklistTemplateReview } from "@kraaft/web/src/components/checklistTemplate/checklistTemplateReview/checklistTemplateReview";
import { CreateNewChecklistTemplateDialog } from "@kraaft/web/src/components/importChecklistTemplateDialog/CreateNewChecklistTemplateDialog";
interface ImportChecklistTemplateProps {
  roomId: string;
  open: boolean;
  onClose: () => void;
}

export const ImportChecklistTemplate = ({
  roomId,
  open,
  onClose,
}: ImportChecklistTemplateProps) => {
  const dispatch = useDispatch();
  const isRoomExternal = useSelector(RoomSelectors.selectHasExternals(roomId));

  const [importState, setImportState] = useState("list");
  const [selectedSchemaTemplateId, setSelectedSchemaTemplateId] = useState<
    string | undefined
  >();
  const selectedSchemaTemplate = useSelector(
    selectSchemaTemplate(selectedSchemaTemplateId),
  );
  const selectedSchemaTemplateSchemaName = useSelector(
    selectSchemaName(selectedSchemaTemplate?.schemaId ?? ""),
  );

  const internOnClose = useCallback(() => {
    setImportState("list");
    setSelectedSchemaTemplateId(undefined);
    onClose();
  }, [onClose]);

  const onChecklistCreate = useCallback(() => {
    setImportState("list");
  }, []);

  const onTaskTemplateImport = useCallback(() => {
    if (!selectedSchemaTemplateId) {
      return;
    }
    if (selectedSchemaTemplate) {
      trackEvent({
        eventName: "Create Object Record",
        room_id: roomId,
        schema_id: selectedSchemaTemplate.schemaId,
        is_shared: isRoomExternal,
        schema_name: selectedSchemaTemplateSchemaName ?? "",
        record_title: null,
        from: "import",
      });
    }

    dispatch(
      applySchemaTemplates({
        schemaTemplateIds: [selectedSchemaTemplateId],
        roomId,
      }),
    );
    internOnClose();
  }, [
    selectedSchemaTemplateId,
    selectedSchemaTemplate,
    dispatch,
    roomId,
    internOnClose,
    isRoomExternal,
    selectedSchemaTemplateSchemaName,
  ]);

  const setSelected = useCallback(() => {
    if (!selectedSchemaTemplateId) {
      return;
    }
    setSelectedSchemaTemplateId(selectedSchemaTemplateId);
    setImportState("review");
    dispatch(
      startEditExistingSchemaTemplate({
        schemaTemplateId: selectedSchemaTemplateId,
      }),
    );
  }, [dispatch, selectedSchemaTemplateId]);

  const editSchemaTemplate = useSelector(selectEditedSchemaTemplate);

  const onCreateNewChecklist = useCallback(() => {
    dispatch(createTaskTemplate({ name: "" }));
    setImportState("create");
  }, [dispatch, setImportState]);

  return (
    <>
      <SelectChecklistTemplateDialog
        open={open && importState === "list"}
        multiple={false}
        forceSelection
        selected={selectedSchemaTemplateId ? [selectedSchemaTemplateId] : []}
        onCreateNewList={onCreateNewChecklist}
        onSelectTemplate={setSelected}
        onChange={(t) => setSelectedSchemaTemplateId(t.id)}
        onClose={internOnClose}
      />
      <CreateNewChecklistTemplateDialog
        open={open && importState === "create"}
        onClose={internOnClose}
        onCreate={onChecklistCreate}
      />
      {editSchemaTemplate?.id && selectedSchemaTemplate && (
        <KDialogFrame
          id="ide2e-reviewChecklistTemplate-dialog"
          size={KDialogSize.MEDIUM}
          open={open && importState === "review"}
          onClose={internOnClose}
        >
          <ChecklistTemplateReview
            onApply={onTaskTemplateImport}
            schemaTemplateId={editSchemaTemplate.id}
            schemaTemplate={editSchemaTemplate}
            schemaId={selectedSchemaTemplate.schemaId}
          />
        </KDialogFrame>
      )}
    </>
  );
};
