import { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { useMeshContext } from "@kraaft/helper-hooks";
import { DialogProvider } from "@kraaft/shared/components/kDialog/context";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  folderFormBuilderColumns,
  roomFormBuilderColumns,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/columnUtils";
import { ElementPicker } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementPicker";
import { ElementsEditor } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor";
import { ContextAwareFormPreview } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/formPreview/contextAwareformPreview";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { PopupMenuProvider } from "../../../popupMenu/popupMenuProvider";
import { EditSchemaDragLayer } from "./editSchemaDragLayer";

import { useStyles } from "./editSchema.styles";

const EditSchema_ = () => {
  const styles = useStyles();
  const currentUserId = useSelector(selectCurrentUserId);

  const { blurEverything, hidePreview } = useMeshContext(SchemaBuilderContext);
  const collection = useMeshContext(
    SchemaBuilderContext,
    (context) => context.schema.collection,
  );

  const availableColumnForPicker =
    collection === "folder" ? folderFormBuilderColumns : roomFormBuilderColumns;

  const availableColumnForEditor = useMemo(
    () =>
      collection === "folder"
        ? [KColumnType.automatedAutoIncrement, ...folderFormBuilderColumns]
        : roomFormBuilderColumns,
    [collection],
  );

  const columnsContext = useMemo<ModularColumnsContext>(
    () => ({
      columns: {
        [KSchemaRemarkableColumns.TITLE]: {
          nonReorderable: true,
        },
      },
    }),
    [],
  );

  if (!currentUserId) {
    return null;
  }

  return (
    <DialogProvider>
      <PopupMenuProvider>
        {currentUserId ? (
          <div className={styles.root} onClick={blurEverything}>
            <div className={styles.columns}>
              <div className={styles.columnPicker}>
                <ElementPicker
                  availableColumnTypes={availableColumnForPicker}
                />
              </div>
              <div className={styles.columnsEditor}>
                <ElementsEditor
                  columnsContext={columnsContext}
                  availableColumnTypes={availableColumnForEditor}
                />
              </div>
              {!hidePreview && (
                <div className={styles.preview}>
                  <ContextAwareFormPreview currentUserId={currentUserId} />
                </div>
              )}
            </div>
          </div>
        ) : null}
        <EditSchemaDragLayer />
      </PopupMenuProvider>
    </DialogProvider>
  );
};

export const EditSchema = memo(EditSchema_) as unknown as typeof EditSchema_;
