import {
  firestore,
  FirestoreTypes,
} from "@kraaft/shared/core/services/firestore/sdk";

export abstract class FirestoreCollection<
  T extends FirestoreTypes.DocumentData,
> {
  abstract collectionName: string;

  protected get collection() {
    return firestore().collection(
      this.collectionName,
    ) as FirestoreTypes.CollectionReference<T>;
  }
}
