import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const DRAWER_WIDTH = 401;

export const makeMessengerStyle = makeStyles({
  conversations: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: 0,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightStyle: "solid",
    borderRightColor: ColorStyle.SEPARATOR,
    position: "relative",
  },
});

export const styles = StyleSheet.create({
  main: {
    position: "relative",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexDirection: "row",
    height: "100%",
    overflow: "hidden",
  },
  npsAnchor: {
    position: "absolute",
    bottom: Spacing.S24,
    left: -Spacing.S24,
  },
});
