import { Context, createElement, ReactNode, useContext } from "react";

export function wrapElementInContextProviders(
  element: ReactNode,
  contexts: Array<Context<any>> | undefined,
) {
  return (
    contexts?.reduce((acc, Forwarded) => {
      return createElement(Forwarded.Provider, {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        value: useContext(Forwarded),
        // biome-ignore lint/correctness/noChildrenProp: <explanation>
        children: acc,
      });
    }, element) ?? element
  );
}
