import { filterInPlace, Listeners } from "@kraaft/helper-functions";
import { KeyValueStorage } from "@kraaft/shared/core/modules/storage/storage";
import { PromiseQueue } from "@kraaft/shared/core/utils/queue";

import { registeredLogger } from "../logger";
import { Task } from "./task";
import {
  TaskExecutionStopReason,
  taskExecutionStopReasons,
} from "./taskStore.types";

export interface TaskStoreModel {
  tasks: Array<Task>;
  taskExecutionStopReason: TaskExecutionStopReason;
}

export class TaskStore {
  private readonly queue = new PromiseQueue();

  public readonly onUpdate = new Listeners<() => void>();

  constructor(
    private readonly storage: KeyValueStorage,
    private readonly storageKey: string,
  ) {}

  private checkTaskStoreModelValidity(taskStoreModel: any) {
    return (
      taskStoreModel &&
      Array.isArray(taskStoreModel.tasks) &&
      taskExecutionStopReasons.includes(taskStoreModel.taskExecutionStopReason)
    );
  }

  private async getNamespace() {
    const existing: TaskStoreModel | undefined = await this.storage.getItem(
      this.storageKey,
    );
    if (!existing || !this.checkTaskStoreModelValidity(existing)) {
      registeredLogger.current.warn(
        `Creating pristine TaskStoreModel for ${this.storageKey}`,
      );
      const pristine: TaskStoreModel = {
        taskExecutionStopReason: "never-ran",
        tasks: [],
      };
      await this.storage.setItem(this.storageKey, pristine);
      return pristine;
    }
    return existing;
  }

  loadAll(): Promise<Array<Task>> {
    return this.queue.queue(async () => {
      const stored = await this.getNamespace();
      return stored.tasks;
    });
  }

  take(): Promise<Task | undefined> {
    return this.queue.queue(async () => {
      const stored = await this.getNamespace();
      return stored.tasks[0];
    });
  }

  async append(task: Task): Promise<void> {
    await Promise.all([
      this.queue.queue(async () => {
        const stored = await this.getNamespace();
        stored.tasks.push(task);
        await this.storage.setItem(this.storageKey, stored);
      }),
    ]);
    this.onUpdate.trigger().catch(console.error);
  }

  async edit(editor: (task: Task) => void): Promise<void> {
    await this.queue.queue(async () => {
      const stored = await this.getNamespace();
      for (const task of stored.tasks) {
        editor(task);
      }
      await this.storage.setItem(this.storageKey, stored);
    });
    this.onUpdate.trigger().catch(console.error);
  }

  async delete(predicate: (task: Task) => boolean): Promise<void> {
    await this.queue.queue(async () => {
      const stored = await this.getNamespace();
      filterInPlace(stored.tasks, (task) => !predicate(task));
      await this.storage.setItem(this.storageKey, stored);
    });
    this.onUpdate.trigger().catch(console.error);
  }

  async setLastStopReason(reason: TaskExecutionStopReason): Promise<void> {
    await this.queue.queue(async () => {
      const stored = await this.getNamespace();
      stored.taskExecutionStopReason = reason;
      await this.storage.setItem(this.storageKey, stored);
    });
    this.onUpdate.trigger().catch(console.error);
  }

  async getLastStopReason() {
    return await this.queue.queue(async () => {
      const stored = await this.getNamespace();
      return stored.taskExecutionStopReason;
    });
  }
}
