import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Pressable } from "react-native";
import { useDispatch } from "react-redux";

import { showInfo } from "@kraaft/shared/core/modules/alert/alertActions";
import { GroupedEvent } from "@kraaft/shared/core/modules/message/core/log.message";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";

interface FolderGroupLogWrapperProps {
  children: React.ReactNode;
  roomId: string;
  event:
    | GroupedEvent<"folderCompleted">
    | GroupedEvent<"folderCreated">
    | GroupedEvent<"folderUnchecked">;
}

export const FolderGroupLogWrapper = ({
  children,
  roomId,
  event,
}: FolderGroupLogWrapperProps) => {
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const [firstMessage] = event.messages;
  const dispatch = useDispatch();

  const schemaId = firstMessage?.event.schemaId;

  const handlePress = useCallback(() => {
    if (
      !schemaId ||
      !event.messages.some(
        (message) => message.event.relatedRessourceDeleted === false,
      )
    ) {
      dispatch(
        showInfo({
          title: t("modularFoldersNoLongerExist"),
        }),
      );
      return;
    }
    navigationService.navigate("ModularFolders", {
      roomId,
      schemaId,
    });
  }, [navigationService, roomId, schemaId, dispatch, event.messages, t]);

  return (
    <Pressable accessibilityLabel={t("open")} onPress={handlePress}>
      {children}
    </Pressable>
  );
};
