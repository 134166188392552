import { EditOneOperationBuilder } from "@kraaft/offline";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { optimisticSchemaEditSection } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";

export const editSectionOperation = EditOneOperationBuilder.create<KSchema>()
  .payload<{ key: string; edits: { name?: string; color?: string } }>()
  .expected((aggregate, payload) => {
    optimisticSchemaEditSection(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editSchemaSection({
      schemaId: payload.id,
      key: payload.key,
      edits: payload.edits,
    });
    return { updatedAt };
  });
