import { EditOperationBuilder } from "@kraaft/offline";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Api } from "@kraaft/shared/core/services/api";

export const reorderSchemaLibraryTagsOperation =
  EditOperationBuilder.create<SchemaLibraryTag>()
    .payload<{
      language: LibrarySchemaLanguage;
      orderedIds: string[];
    }>({
      gatherIds(payload) {
        return payload.orderedIds;
      },
      replaceId(payload, oldId, newId) {
        for (const [index, id] of payload.orderedIds.entries()) {
          if (id === oldId) {
            payload.orderedIds[index] = newId;
          }
        }
      },
    })
    .expected((aggregates, payload) => {
      for (const [index, id] of payload.orderedIds.entries()) {
        const aggregate = aggregates[id];
        if (!aggregate) {
          continue;
        }
        aggregate.index = index;
      }
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.reorderSchemaLibraryTags(payload);

      return updatedAt;
    })
    .acknowledgeOn((aggregates, payload, updatedAt) => {
      for (const id of payload.orderedIds) {
        const aggregate = aggregates[id];
        if (!aggregate) {
          continue;
        }
        if (aggregate.updatedAt.getTime() >= updatedAt.getTime()) {
          return true;
        }
      }
      return false;
    });
