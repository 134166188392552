import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

import { dismissNativeKeyboard, isNative } from "@kraaft/helper-functions";
import { DeleteConfirmationInput } from "@kraaft/shared/components/input/deleteConfirmationInput";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";
import { RoomStateActions } from "@kraaft/shared/core/modules/room/roomActions";
import { Api } from "@kraaft/shared/core/services/api";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { opacify } from "@kraaft/shared/core/utils/colorsUtils";
import { Button, Color, Radius, Sheet, Spacing, Text } from "@kraaft/ui";

interface DeleteRoomSheetProps {
  roomName: string;
  roomId: string;
}

export const DeleteRoomSheet = Sheet({
  web: "popup",
  native: "bottom",
})
  .create<DeleteRoomSheetProps>(
    ({ Content, Header, Paper, Footer }) =>
      ({ onClose, roomName, roomId }) => {
        const [canDelete, setCanDelete] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);
        const { alertError, alertSuccess } = useAlert();
        const { t } = useTranslation();
        const navigation = useNavigationService();
        const dispatch = useDispatch();

        const handleDelete = useCallback(async () => {
          setIsDeleting(true);
          dismissNativeKeyboard();
          try {
            await Api.deleteRoom({ roomId });
            onClose();
            alertSuccess({
              message: t("deleteRoom.success", { title: roomName }),
            });
            dispatch(RoomStateActions.roomDeleted({ roomId }));
            navigation.navigate("Home");
          } catch (error) {
            alertError(error, "deleteRoom");
            setIsDeleting(false);
          }
        }, [
          alertError,
          alertSuccess,
          dispatch,
          navigation,
          onClose,
          roomId,
          roomName,
          t,
        ]);

        return (
          <Paper>
            <Header onClose={onClose}>{t("deleteRoom.title")}</Header>
            <Content>
              <View style={styles.container}>
                <DeleteConfirmationInput
                  autoFocus={!isNative()}
                  expectedValue={roomName}
                  onValueMatches={setCanDelete}
                />
                <View style={styles.warning}>
                  <Text size="BODY">
                    {t("deleteRoom.subtitle", { title: roomName })}
                  </Text>
                </View>
              </View>
            </Content>
            <Footer>
              {!isNative() && (
                <Button
                  accessibilityLabel={t("cancel")}
                  text={t("cancel")}
                  onPress={onClose}
                  variant="QUATERNARY"
                />
              )}
              <Button
                onPress={handleDelete}
                text={t("deleteRoom.delete")}
                accessibilityLabel={t("deleteRoom.delete")}
                disabled={!canDelete}
                loading={isDeleting}
                destructive
                style={Platform.select({ native: styles.nativeButton })}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "MEDIUM" });

const styles = StyleSheet.create({
  container: {
    paddingTop: !isNative() ? Spacing.S12 : undefined,
    gap: Spacing.S12,
  },
  warning: {
    padding: Spacing.S12,
    backgroundColor: opacify(Color.ORANGE_BASIC, 0.15),
    borderColor: Color.ORANGE_BASIC,
    borderWidth: 1,
    borderRadius: Radius.SMALL,
  },
  nativeButton: {
    marginBottom: Spacing.S8,
    width: "100%",
  },
});
