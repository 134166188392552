type MaxWidthClamp = [number, number];

type Config = {
  maxPortaitWidthClamp: MaxWidthClamp;
  maxLandscapeWidthClamp: MaxWidthClamp;
  maxPortraitRatio: number;
};

const WEB_CONFIG = {
  maxPortaitWidthClamp: [0.35, 300],
  maxLandscapeWidthClamp: [0.45, 300],
  maxPortraitRatio: 2 / 2.7,
} satisfies Config;

const NATIVE_CONFIG = {
  maxPortaitWidthClamp: [0.6, 500],
  maxLandscapeWidthClamp: [0.8, 500],
  maxPortraitRatio: 2 / 3,
} satisfies Config;

interface Size {
  width: number;
  height: number;
}

type Rotation = "landscape" | "portrait";

export function getRotation(imageSize: Size): Rotation {
  if (imageSize.width > imageSize.height) {
    return "landscape";
  }
  return "portrait";
}

export function getResizedDimensions(
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  imageSize: Size | undefined = { width: 1, height: 1 },
  layoutWidth: number,
  isNative: boolean,
): Size {
  const config = isNative ? NATIVE_CONFIG : WEB_CONFIG;

  if (imageSize.width === 0 || imageSize.height === 0) {
    const w = Math.min(
      config.maxLandscapeWidthClamp[0] * layoutWidth,
      config.maxLandscapeWidthClamp[1],
    );
    return { width: w, height: w * 0.7 };
  }

  const rotation = getRotation(imageSize);

  const maxWidthClamp =
    rotation === "landscape"
      ? config.maxLandscapeWidthClamp
      : config.maxPortaitWidthClamp;

  const maxWidth = Math.min(maxWidthClamp[0] * layoutWidth, maxWidthClamp[1]);
  const resizeRatio = maxWidth / imageSize.width;
  const width = imageSize.width * resizeRatio;
  let height = imageSize.height * resizeRatio;

  if (rotation === "portrait") {
    const imageRatio = width / height;
    const fitRatio =
      imageRatio > config.maxPortraitRatio
        ? 1
        : imageRatio / config.maxPortraitRatio;

    height *= fitRatio;
  }

  return { width, height };
}
