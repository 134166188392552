import { DummyOfflineFeature } from "@kraaft/shared/core/modules/dummy/dummy.offline";
import { selectRawDummies } from "@kraaft/shared/core/modules/dummy/dummy.selectors";
import { offlineReduxBundle } from "@kraaft/shared/core/store/offline";

export const {
  Actions: DummyActions,
  Selectors: DummySelectors,
  StateActions: DummyStateActions,
} = offlineReduxBundle.create(
  DummyOfflineFeature,
  {
    selectRawAggregate: selectRawDummies,
  },
  (getState, selectDummy) => {
    return {
      getDummy(id: string) {
        return selectDummy(id)(getState());
      },
    };
  },
);
