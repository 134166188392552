import { replaceLogger, replaceTracking } from "@kraaft/offline";
import { PersistentLogger } from "@kraaft/shared/core/utils/logger/persistentLogger";

import { trackEvent } from "../../utils/tracking/trackEvent";

replaceLogger(PersistentLogger.create("OfflineFeature").setEnabled(true));
replaceTracking((payload) =>
  trackEvent({
    eventName: "OfflineFeature",
    ...payload,
  }),
);
