import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  DirectoryActions,
  DirectoryStateActions,
} from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* renameDirectorySaga() {
  yield* takeEvery(DirectoryActions.rename, renameDirectory);
}

function* renameDirectory({
  payload: { roomId, directoryId, newDirectoryName },
}: ReturnType<(typeof DirectoryActions)["rename"]>) {
  const directory = yield* select(selectDirectory(directoryId));
  if (!directory) {
    return;
  }

  const trimmedName = newDirectoryName.trim();

  yield* put(
    DirectoryStateActions.rename({
      directoryId,
      newName: trimmedName,
    }),
  );

  try {
    yield* call(Api.renameDirectory, {
      roomId,
      directoryId,
      newDirectoryName: trimmedName,
    });
  } catch (e) {
    yield* put(
      DirectoryStateActions.rename({ directoryId, newName: directory.name }),
    );
    yield* put(showError({ title: i18n.t("directory.cannotRename") }));
  }
}
