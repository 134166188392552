import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import { GradientLogHolder } from "@kraaft/shared/components/message/messageLog/holders/gradientLogHolder";
import { FolderGroupLogWrapper } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/folderGroupLogWrapper";
import { LogText } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/logText";
import { OpenWithArrow } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/openWithArrow";
import { PrependWithSchemaIcon } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/prependWithSchemaIcon";
import {
  GroupedEvent,
  LogMessageEvent,
} from "@kraaft/shared/core/modules/message/core/log.message";
import { selectUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Color, Text } from "@kraaft/ui";

interface GroupedFolderEventProps {
  roomId: string;
  event: GroupedEvent<"folderCreated">;
}

const FolderCreatedGrouped = ({ roomId, event }: GroupedFolderEventProps) => {
  const [firstMessage] = event.messages;
  const user = useSelector(selectUser(firstMessage?.senderId));

  if (!firstMessage) {
    return null;
  }

  return (
    <GradientLogHolder>
      <FolderGroupLogWrapper event={event} roomId={roomId}>
        <Text style={styles.center}>
          <PrependWithSchemaIcon event={firstMessage.event} />
          <LogText
            color={Color.WHITE}
            i18nKey="logFolderCreatedGrouped"
            userId={firstMessage?.senderId}
            values={{
              username: getUsername(user),
              count: event.messages.length,
              schemaName: firstMessage.event.schemaName,
            }}
            userColor={Color.WHITE}
          />
        </Text>
        <OpenWithArrow />
      </FolderGroupLogWrapper>
    </GradientLogHolder>
  );
};

const styles = StyleSheet.create({
  center: {
    textAlign: "center",
  },
});

interface GroupMessageProps {
  roomId: string;
  event: GroupedEvent<LogMessageEvent["type"]>;
}

export const GroupedMessage = ({ roomId, event }: GroupMessageProps) => {
  if (event.groupedBy === "folderCreated") {
    return <FolderCreatedGrouped roomId={roomId} event={event} />;
  }
  console.warn(`${event.groupedBy} should not be grouped`);
  return null;
};
