import { useSelector } from "react-redux";

import { selectOrderedEnabledTemplatesBySchemaId } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { getReportTemplateIcon } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";
import { ActionSheet } from "@kraaft/ui";

import { getReportTemplateName } from "../../core/modules/reportTemplate/reportTemplate.utils";

interface ReportTemplateSelectionSheetProps {
  schemaId: string | undefined;

  onTemplateSelected: (template: ReportTemplate) => void;
}

export const ReportTemplateSelectionSheet =
  ActionSheet().create<ReportTemplateSelectionSheetProps>(
    ({ ActionSheetItem, ActionSheetContent }) =>
      ({ onTemplateSelected: onTemplatSelected, schemaId }) => {
        const reportTemplates = useSelector(
          selectOrderedEnabledTemplatesBySchemaId(schemaId),
        );

        return (
          <ActionSheetContent>
            {reportTemplates.map((template) => (
              <ActionSheetItem
                key={template.id}
                icon={getReportTemplateIcon(template.format)}
                label={getReportTemplateName(template)}
                onClick={() => onTemplatSelected(template)}
              />
            ))}
          </ActionSheetContent>
        );
      },
  );
