import { createSelector } from "@reduxjs/toolkit";
import { flatten, memoize, orderBy } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import {
  Directory,
  DIRECTORY_MAX_DEPTH,
  ROOT_DIRECTORY_ID,
} from "@kraaft/shared/core/modules/directory/directory";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
  nullId,
} from "@kraaft/shared/core/utils";

import { onlyMiniMediaOfType } from "../miniMedia/miniMedia.utils";

export const selectState = ({ directory }: RootState) => directory;

export const selectRootDirectories = memoize((roomId: string) =>
  createSelector(selectState, ({ directories }) => {
    const root = directories.filter(
      (d) => d.roomId === roomId && d.parentId === ROOT_DIRECTORY_ID,
    );

    return orderBy(root, (d) => d.index);
  }),
);

export const selectDirectory = memoize((directoryId: string) =>
  createSelector(selectState, ({ directories }) =>
    directories.find((directory) => directory.id === directoryId),
  ),
);

export const selectDirectoryIsMaxDepth = memoize((directoryId: string) =>
  createSelector(selectState, ({ directories }) => {
    if (directoryId === nullId) {
      return false;
    }
    const directory = directories.find((dir) => dir.id === directoryId);
    if (directory === undefined) {
      return true;
    }
    return directory.depth >= DIRECTORY_MAX_DEPTH;
  }),
);

export const selectChildDirectories = memoize(
  (roomId: string, parentId: string) =>
    createSelector(selectState, ({ directories }) => {
      const children = directories
        .filter((directory) => directory.roomId === roomId)
        .filter((directory) => directory.parentId === parentId);

      return orderBy(children, (d) => d.index);
    }),
  lodashKeyResolver,
);

export const selectChildDirectoryCount = memoize(
  (roomId: string, directoryId: string) =>
    createSelector(
      selectChildDirectories(roomId, directoryId),
      (directories) => directories.length,
    ),
);

export const selectChildFileCount = memoize((directoryId: string) =>
  createSelector(
    selectState,
    selectDirectory(directoryId),
    ({ directories }, parentDirectory) => {
      const countChildren = (directory: Directory) => {
        let count = directory.files.length;

        for (const dir of directories) {
          if (dir.parentId !== directory.id) {
            continue;
          }
          count += countChildren(dir);
        }
        return count;
      };
      return parentDirectory ? countChildren(parentDirectory) : 0;
    },
  ),
);

export const selectRoomDirectories = memoize(
  (roomId: string) =>
    createSelector(selectState, ({ directories }) => {
      const children = directories.filter(
        (directory) => directory.roomId === roomId,
      );

      return orderBy(children, (d) => d.index);
    }),
  lodashKeyResolver,
);

const selectDirectoryIdsOfMessage = memoize((messageId: string) =>
  createSelector(
    selectState,
    (state) => state.messageIdToDirectoryIds[messageId],
  ),
);

export const selectDirectoriesOfMessage = memoize((messageId: string) =>
  createSelector(
    selectState,
    selectDirectoryIdsOfMessage(messageId),
    (state, directoryIds) =>
      directoryIds
        ? compactMap(directoryIds, (id) =>
            state.directories.find((e) => e.id === id),
          )
        : getEmptyArray<Directory>(),
  ),
);

// Optimized used if you want to select conditionnally, improves performance
export const selectSubDirectoriesOfDirectoryId = memoize(
  (rootDirectoryId: string, optimized?: boolean) =>
    createSelector(selectState, (state) => {
      if (optimized) {
        return [];
      }

      const directory = state.directories.find((d) => d.id === rootDirectoryId);
      if (!directory) {
        return [];
      }
      function recursivelyGetSubDirectories(directoryId: string): Directory[] {
        const subDirectories = state.directories.filter(
          (d) => d.parentId === directoryId,
        );
        const subs = subDirectories.map((s) =>
          recursivelyGetSubDirectories(s.id),
        );
        return [...subDirectories, ...flatten(subs)];
      }
      return [directory, ...recursivelyGetSubDirectories(rootDirectoryId)];
    }),
  lodashKeyResolver,
);

export const selectDirectoryCarousel = createSelector(
  selectState,
  (state) => state.carousel,
);

export const selectDirectoryCarouselData = createSelector(
  selectState,
  (state) => {
    const carousel = state.carousel;
    if (!carousel.show) {
      return undefined;
    }

    const directory = state.directories.find(
      ({ id }) => id === carousel.directoryId,
    );

    if (!directory) {
      return undefined;
    }

    const mediaFiles = onlyMiniMediaOfType(directory.files, ["image", "video"]);

    let initialIndex = mediaFiles.findIndex(
      (file) => file.messageId === carousel.messageId,
    );
    if (initialIndex === -1) {
      initialIndex = 0;
    }

    return {
      initialIndex,
      medias: mediaFiles,
    };
  },
);
