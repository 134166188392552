import React, { ReactNode, useLayoutEffect, useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import clsx from "clsx";
import { assert } from "ts-essentials";

import { UnknownObject } from "@kraaft/shared/core/types";
import { useCombinedRefs } from "@kraaft/shared/core/utils/useRefUtils";
import { ColorStyle, Icon } from "@kraaft/ui";
import {
  getPlacementFromTargetAndDraggedSourcePosition,
  isOrderableRow,
} from "@kraaft/web/src/components/orderableList/draggableRow/draggableRow.utils";

import {
  AdditionalTypes,
  OrderableListDraggedItem,
  OrderableListDragItem,
  OrderableListHoveredItem,
  WithOrderableListItemData,
} from "../orderableList.types";
import {
  ORDERABLE_LIST_ROW_TYPE,
  useDroppableAcceptTypes,
} from "../orderableList.utils";

import { useStyles } from "./draggableRow.styles";

export type DraggableRowProps<
  T extends UnknownObject,
  K extends string = string,
> = AdditionalTypes<K> & {
  item: WithOrderableListItemData<T>;
  listIdentifier: string;
  renderItem: (props: T) => ReactNode;
  containerClassName?: string;
  withHandle?: boolean;
  onDragBegin: (orderableListDragItem: OrderableListDraggedItem) => void;
  onDragEnd: (item: OrderableListDragItem<T> | undefined) => void;
  onHovered: (orderableListHoveredItem: OrderableListHoveredItem) => void;
  disabled?: boolean;
};

const DraggableRow_ = <T extends UnknownObject, K extends string = string>(
  props: DraggableRowProps<T, K>,
) => {
  const {
    item,
    listIdentifier,
    renderItem,
    containerClassName,
    withHandle,
    onDragBegin,
    onDragEnd,
    onHovered,
    additionalTypes,
    disabled,
  } = props;

  const lastSentHoveredItem = useRef<OrderableListHoveredItem | undefined>(
    undefined,
  );
  const rowReference = useRef<HTMLDivElement>(null);

  const draggableItem = useMemo<OrderableListDragItem<T>>(
    () => ({
      type: ORDERABLE_LIST_ROW_TYPE,
      sourceKey: item.key,
      sourceIndex: item.index,
      listIdentifier,
      width: undefined,
      data: item.data,
    }),
    [item.data, item.index, item.key, listIdentifier],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: ORDERABLE_LIST_ROW_TYPE,
    item: () => {
      if (rowReference.current) {
        onDragBegin({
          key: item.key,
          index: item.index,
          height: rowReference.current.clientHeight,
        });

        return { ...draggableItem, width: rowReference.current.clientWidth };
      }
      return draggableItem;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: onDragEnd,
    canDrag: !disabled,
  });

  const classes = useStyles({ isDragging });

  const acceptTypes = useDroppableAcceptTypes(additionalTypes);
  const [, drop] = useDrop({
    accept: acceptTypes,
    hover: (draggedItem: OrderableListDragItem<T>, monitor) => {
      const sourceKey = isOrderableRow(draggedItem)
        ? draggedItem.sourceKey
        : undefined;
      const targetKey = item.key;

      if (sourceKey === targetKey) {
        return;
      }

      assert(rowReference.current !== null, "rowReference.current is null");
      const targetBoundingRect = rowReference.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      const placement = getPlacementFromTargetAndDraggedSourcePosition(
        targetBoundingRect,
        clientOffset,
      );

      const hoveredItem: OrderableListHoveredItem = {
        index: item.index,
        key: item.key,
        placement,
      };

      if (
        lastSentHoveredItem.current === undefined ||
        hoveredItem.placement !== lastSentHoveredItem.current.placement
      ) {
        onHovered(hoveredItem);
        lastSentHoveredItem.current = hoveredItem;
      }
    },
  });

  useLayoutEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const content = useMemo(() => renderItem(item.data), [item.data, renderItem]);

  const conbinedRefs = useCombinedRefs(
    rowReference,
    drop,
    !withHandle ? drag : undefined,
  );

  return (
    <div
      ref={conbinedRefs}
      className={clsx(classes.container, containerClassName)}
    >
      {withHandle && (
        <div ref={drag}>
          <Icon
            className={classes.handleIcon}
            name="dots-six"
            color={ColorStyle.SEPARATOR}
          />
        </div>
      )}
      {content}
    </div>
  );
};

const DraggableRow = React.memo(DraggableRow_) as typeof DraggableRow_;

export { DraggableRow };
