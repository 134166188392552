import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { DeleteConfirmationInput } from "@kraaft/shared/components/input/deleteConfirmationInput";
import { useAlert } from "@kraaft/shared/core/modules/alert/useAlert";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { opacify } from "@kraaft/shared/core/utils/colorsUtils";
import {
  Button,
  Color,
  ColorStyle,
  FontSize,
  Preloader,
  Radius,
  Sheet,
  Spacing,
  Text,
} from "@kraaft/ui";

interface DeleteOptionSheetProps {
  optionName: string;
  onDelete: () => void | Promise<void>;
  collection: "folder" | "room";
  recordsCount: number | undefined;
}

export const DeleteOptionSheet = Sheet({
  web: "popup",
})
  .create<DeleteOptionSheetProps>(
    ({ Paper, Header, Footer, Content }) =>
      ({ onClose, optionName, onDelete, collection, recordsCount }) => {
        const { t } = useTranslation();
        const { alertError } = useAlert();
        const [canDelete, setCanDelete] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);

        const handleDelete = useCallback(async () => {
          setIsDeleting(true);
          try {
            await onDelete();
            onClose();
          } catch (error) {
            alertError(error, "deleteOption");
          }
          setIsDeleting(false);
        }, [alertError, onClose, onDelete]);

        return (
          <Paper>
            <Header onClose={onClose}>{t("deleteOption.title")}</Header>
            <Content>
              <View style={styles.container}>
                <DeleteConfirmationInput
                  expectedValue={optionName}
                  onValueMatches={setCanDelete}
                  autoFocus
                />
                <View style={styles.warning}>
                  <Text size="BODY">
                    <Trans
                      i18nKey={`deleteOption.warning.${collection}`}
                      values={{ count: recordsCount }}
                      components={{
                        Loading:
                          recordsCount !== undefined ? (
                            <Text />
                          ) : (
                            <Preloader
                              size={FontSize.BODY}
                              color={ColorStyle.FONT_HIGH_EMPHASIS}
                            />
                          ),
                      }}
                    />
                  </Text>
                </View>
              </View>
            </Content>
            <Footer>
              <Button
                accessibilityLabel={t("cancel")}
                text={t("cancel")}
                onPress={onClose}
                variant="QUATERNARY"
              />
              <Button
                onPress={handleDelete}
                text={t("deleteOption.delete")}
                accessibilityLabel={t("deleteOption.delete")}
                disabled={!canDelete}
                loading={isDeleting}
                destructive
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "MEDIUM" });

const styles = StyleSheet.create({
  container: {
    paddingTop: Spacing.S12,
    gap: Spacing.S12,
  },
  warning: {
    padding: Spacing.S12,
    backgroundColor: opacify(Color.ORANGE_BASIC, 0.15),
    borderColor: Color.ORANGE_BASIC,
    borderWidth: 1,
    borderRadius: Radius.SMALL,
  },
});
