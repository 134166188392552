import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { compareStrings } from "@kraaft/shared/core/utils";

export type InputString = string | undefined;

export const compareSchemasByName = (
  schema1: KSchema | undefined,
  schema2: KSchema | undefined,
) => {
  return compareStrings(schema1?.name ?? "", schema2?.name ?? "");
};

export const splitUsername = (username: string) => {
  const [firstName, ...lastName] = username.split(" ").filter(Boolean);
  return [firstName || "-", lastName.join(" ")] as const;
};

export const getEmailForPayload = (email: InputString) => {
  if (email === undefined) {
    return undefined;
  }

  if (email.length > 0) {
    return email;
  }

  return null;
};

export const getPhoneNumberForPayload = (phoneNumber: InputString) => {
  if (phoneNumber === undefined) {
    return undefined;
  }

  if (phoneNumber.length > 0) {
    return phoneNumber;
  }

  return null;
};
