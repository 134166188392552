import { put, select, takeEvery } from "typed-redux-saga/macro";

import { RoomStateActions } from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardStateActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomMemberCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";

function* roomJoined(action: ReturnType<typeof RoomStateActions.roomJoined>) {
  const userId = yield* select(selectCurrentUserId);
  const currentRoomCard = yield* select(
    RoomCardSelectors.select(action.payload.roomId),
  );

  if (!userId || !currentRoomCard) {
    return;
  }

  if (currentRoomCard.type === "member") {
    return;
  }

  yield* put(
    RoomCardStateActions.setRoomCard({
      roomId: action.payload.roomId,
      roomCard: {
        ...currentRoomCard,
        type: "member",
        isArchivedForUser: false,
        userId,
        unread: false,
        notificationSource: "inherit",
        pinnedAt: undefined,
      } satisfies RoomMemberCard,
    }),
  );
}

export function* roomJoinedSaga() {
  yield* takeEvery(RoomStateActions.roomJoined, roomJoined);
}
