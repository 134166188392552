export interface Logger {
  log(...args: any[]): void;
  warn(...args: any[]): void;
  error(...args: any[]): void;
  createSubLogger(path: Array<string>): Logger;
}

export const noopLogger: Logger = {
  log() {},
  warn() {},
  error() {},
  createSubLogger(path) {
    return this;
  },
};

export type Tracking = (payload: any) => void;

const noopTracking: Tracking = () => {};

export const registeredLogger = {
  current: noopLogger,
};

export const registeredTracking = {
  current: noopTracking,
};

export function replaceLogger(logger: Logger) {
  registeredLogger.current = logger;
}

export function replaceTracking(tracking: Tracking) {
  registeredTracking.current = tracking;
}
