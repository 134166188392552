import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentPoolId } from "../../pool/poolSelectors";
import { PoolAddonsActions } from "..";

export function useSubscribeToPoolAddons() {
  const dispatch = useDispatch();

  const currentPoolId = useSelector(selectCurrentPoolId);

  useEffect(() => {
    if (!currentPoolId) {
      return;
    }

    dispatch(PoolAddonsActions.subscribe({ poolId: currentPoolId }));

    return () => {
      dispatch(PoolAddonsActions.unsubscribe({ poolId: currentPoolId }));
    };
  }, [currentPoolId, dispatch]);
}
