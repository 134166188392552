import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

import { SchemaCollection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Button, Sheet, Text } from "@kraaft/ui";

interface ElementsThresholdSheetProps {
  schemaCollection: SchemaCollection;
}

export const ElementsThresholdSheet = Sheet({
  web: "popup",
})
  .create<ElementsThresholdSheetProps>(
    ({ Paper, Header, Content, Footer }) =>
      ({ onClose, schemaCollection }) => {
        const { t } = useTranslation();

        return (
          <Paper>
            <Header onClose={onClose}>
              <Text size="TITLE" weight="bold">
                {t(
                  schemaCollection === "folder"
                    ? "formBuilder.schema.questionThreshold.title.folderSchema"
                    : "formBuilder.schema.questionThreshold.title.roomSchema",
                )}
              </Text>
            </Header>
            <Content>
              <Text size="BODY">
                {t(
                  schemaCollection === "folder"
                    ? "formBuilder.schema.questionThreshold.description.folderSchema"
                    : "formBuilder.schema.questionThreshold.description.roomSchema",
                )}
              </Text>
            </Content>
            <Footer>
              <Button
                style={styles.validate}
                text={t("ok")}
                onPress={() => onClose()}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  validate: {
    marginLeft: "auto",
  },
});
