import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* markRoomReadSaga(
  action: ReturnType<typeof roomActions.markRoomRead>,
) {
  const roomCard = yield* select(
    RoomCardSelectors.select(action.payload.roomId),
  );
  if (!roomCard || !roomCard.lastMessageInfo?.id) {
    return;
  }
  try {
    yield* call(Api.acknowledgeReading, {
      roomId: action.payload.roomId,
      messageId: roomCard.lastMessageInfo.id,
    });
  } catch (error) {
    if (error) {
      yield* put(showError({ title: i18n.t("errorServer") }));
    }
    yield* put(
      roomActions.markRoomReadFailure({
        roomId: action.payload.roomId,
        oldValue: action.payload.currentValue,
      }),
    );
  }
}
