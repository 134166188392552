import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { useMessageStyles } from "@kraaft/shared/components/message/messageUtils";
import { ForwardedUserMessage } from "@kraaft/shared/core/modules/message/core/message.helper";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";
import { FontSize, Icon } from "@kraaft/ui";

interface Props {
  message: ForwardedUserMessage;
  isReply: boolean;
}

const ForwardedBy = (props: Props): JSX.Element => {
  const { message, isReply } = props;
  const { t } = useTranslation();

  const forwardedByUsername = useSelector(
    selectUsername(message.forwarded?.by),
  );

  const { textStyle, textColor } = useMessageStyles({
    isReply,
    hasReactions: messageHasReactions(message),
  });

  return (
    <View style={styles.container}>
      <Icon
        name="corner-up-right"
        size="MINI"
        style={styles.icon}
        color={textColor}
      />
      <View style={styles.textContainer}>
        <Text
          style={[styles.text, textStyle]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {`${t("forwardedBy")} `}
          <Text style={styles.textBold}>{forwardedByUsername}</Text>
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 8,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  icon: {
    // Pixel perfect : icon is size MINI=16px, font size is 14px
    marginTop: 1,
    marginRight: 4,
  },
  textContainer: {
    flexShrink: 1,
  },
  text: {
    fontSize: FontSize.MINI,
    fontFamily: "Apercu",
  },
  textBold: {
    fontFamily: "Apercu-Bold",
  },
});

export { ForwardedBy };
