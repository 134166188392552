import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StrictOmit } from "ts-essentials";

import { SelectOption } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/select";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumn } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useDebounceOptimistic } from "@kraaft/shared/core/utils/useDebounce";
import { KColors } from "@kraaft/ui";
import { ColumnHeaderEditorProps } from "@kraaft/web/src/components/modularTable/components/types";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import { ColoredOrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/coloredOrderableOptionRenderer/coloredOrderableOption";
import {
  ColoredOrderableOptionRenderer,
  ColoredOrderableOptionRendererProps,
} from "@kraaft/web/src/components/sortableListWithAddAndDelete/coloredOrderableOptionRenderer/coloredOrderableOptionRenderer";
import { SortableListWithAddAndDelete } from "@kraaft/web/src/components/sortableListWithAddAndDelete/sortableListWithAddAndDelete";

import { DeleteOptionSheet } from "./deleteOptionSheet";
import { usePromiseAndCallbacks } from "./usePromiseAndCallbacks";

import { useStyles } from "./selectColumnEditor.styles";

const SelectColumnEditor = (
  props:
    | ColumnHeaderEditorProps<KColumnType.selectSingle>
    | ColumnHeaderEditorProps<KColumnType.selectMultiple>,
) => {
  const {
    columnDefinition: pristineColumnDefinition,
    setColumnDefinition,
    recordsCount,
  } = props;
  const [optionToDeleteName, setOptionToDeleteName] = useState("");
  const [newItems, setNewItems] = useState(new Set<string>());

  const { callbacks, newPromise } = usePromiseAndCallbacks<boolean>();

  const { t } = useTranslation();
  const styles = useStyles();

  const { update, value: columnDefinition } = useDebounceOptimistic(
    pristineColumnDefinition,
    1000,
    useCallback(
      (optimisticColumnDefinition) => {
        if (!optimisticColumnDefinition) {
          return;
        }
        (
          setColumnDefinition as ColumnHeaderEditorProps<
            KColumnType.selectSingle | KColumnType.selectMultiple
          >["setColumnDefinition"]
        )(optimisticColumnDefinition);
      },
      [setColumnDefinition],
    ),
  );

  const options = useMemo(
    () =>
      columnDefinition
        ? prepareForOrderableList<
            [string, SelectOption],
            ColoredOrderableOption
          >(Object.entries(columnDefinition.options), ([key, option]) => ({
            key,
            index: option.index,
            data: {
              id: key,
              text: option.label,
              color: option.color,
            },
          }))
        : {},
    [columnDefinition],
  );

  const handleChange = useCallback(
    (newOptions: OrderedListRows<ColoredOrderableOption>) => {
      const columnOptions: KSchemaColumn<
        KColumnType.selectSingle | KColumnType.selectMultiple
      >["options"] = {};

      for (const option of Object.values(newOptions)) {
        columnOptions[option.key] = {
          index: option.index,
          label: option.data.text,
          color: option.data.color,
        };
      }

      update((old) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          options: {
            ...columnOptions,
          },
        };
      });
    },
    [update],
  );

  const handleDelete = () => {
    callbacks.current.resolve(true);
  };

  const handleClosed = () => {
    callbacks.current.resolve(false);
  };

  const { open: openDeleteOptionSheet, element: deleteOptionSheetElement } =
    DeleteOptionSheet.use({
      optionName: optionToDeleteName,
      collection: props.collection,
      onDelete: handleDelete,
      onClosed: handleClosed,
      recordsCount,
    });

  const isOptionDeletionInstant = recordsCount === 0;

  const handleDeleteConfirmation = useCallback(
    async (option: ColoredOrderableOption) => {
      if (isOptionDeletionInstant) {
        return true;
      }
      setOptionToDeleteName(option.text);
      openDeleteOptionSheet();
      return newPromise();
    },
    [isOptionDeletionInstant, newPromise, openDeleteOptionSheet],
  );

  const handleCreate = useCallback(
    (id: string, index: number) => {
      setNewItems((current) => new Set([...current, id]));
      return {
        id,
        text: t("formBuilder.schema.columnSpecific.select.newOption", {
          index,
        }),
        color: KColors.Colors[index % KColors.Colors.length],
      };
    },
    [t],
  );

  const renderRow = useCallback(
    (
      rendererProps: StrictOmit<
        ColoredOrderableOptionRendererProps,
        "allowColor" | "collection"
      >,
    ) => (
      <ColoredOrderableOptionRenderer
        allowColor={columnDefinition?.allowColor}
        collection={props.collection}
        isNew={newItems.has(rendererProps.option.id)}
        {...rendererProps}
      />
    ),
    [columnDefinition?.allowColor, newItems, props.collection],
  );

  return (
    <div className={styles.root}>
      <SortableListWithAddAndDelete
        noBackground
        id="create-select-sortable"
        addButtonText={t("addOption")}
        options={options}
        onChange={handleChange}
        onDeleteConfirmation={handleDeleteConfirmation}
        renderRow={renderRow}
        onCreate={handleCreate}
      />
      {deleteOptionSheetElement}
    </div>
  );
};

export { SelectColumnEditor };
