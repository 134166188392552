import { useEffect, useState } from "react";

import { Api } from "@kraaft/shared/core/services/api";

export function useCountAffectedBySchemaId(
  poolId: string,
  schemaId: string,
  collection: "folder" | "room",
) {
  const [count, setCount] = useState<number>();

  useEffect(() => {
    (async () => {
      const countAffectedFolders = await (collection === "folder"
        ? Api.countModularFoldersForSchema({ schemaId })
        : Api.countRoomsWithSchemaId({ schemaId, poolId }));

      setCount(countAffectedFolders.count);
    })().catch(console.error);
  }, [collection, poolId, schemaId]);

  return count;
}
