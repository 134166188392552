import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Toastr from "react-redux-toastr";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { compact } from "lodash";

import { selectNavigationSwitch } from "@kraaft/shared/core/modules/app/appSelector";
import { NavigationSwitchValue } from "@kraaft/shared/core/modules/app/appState";
import { PlanningScreen } from "@kraaft/shared/core/modules/planning/screens/planningScreen.screen";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectCurrentContextAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { useRefreshSSOConnection } from "@kraaft/shared/core/services/auth/useRefreshSSOConnection";
import { useShouldElevateToSSO } from "@kraaft/shared/core/services/auth/useSSO";
import { conditionalEntry, isWebMobile } from "@kraaft/shared/core/utils";
import { PortalHost } from "@kraaft/ui";
import { Layout } from "@kraaft/web/src/components/layout";
import { history } from "@kraaft/web/src/core/services/history";
import { AuthFlow } from "@kraaft/web/src/views/app/appRouter/authFlow";
import { EnterUsernameWeb } from "@kraaft/web/src/views/auth/enterUsernameWeb";
import { InvalidInvitationScreenWeb } from "@kraaft/web/src/views/auth/invalidInvitationScreenWeb";
import { InviteCongratsScreenWeb } from "@kraaft/web/src/views/auth/inviteCongratsScreenWeb";
import { MobileWebCongratsScreenWeb } from "@kraaft/web/src/views/auth/mobileWebCongratsScreenWeb";
import { SSOElevationWeb } from "@kraaft/web/src/views/auth/ssoElevationWeb";
import { VerifyInvitationScreenWeb } from "@kraaft/web/src/views/auth/verifyInvitationScreenWeb";
import { CreateWorkspaceWeb } from "@kraaft/web/src/views/createWorkspace/createWorkspaceWeb";
import { Database } from "@kraaft/web/src/views/database/database";
import { FolderSchemaBuilder } from "@kraaft/web/src/views/folderSchemaBuilder";
import { Messenger } from "@kraaft/web/src/views/messenger";
import { AddMembersAtPoolCreationWeb } from "@kraaft/web/src/views/onboarding/addMembersAtPoolCreationWeb";
import { AskPoolAcquisitionSourceWeb } from "@kraaft/web/src/views/onboarding/askPoolAcquisitionSourceWeb";
import { AskPoolCompanyIndustryWeb } from "@kraaft/web/src/views/onboarding/askPoolCompanyIndustryWeb";
import { AskPoolCompanySizeWeb } from "@kraaft/web/src/views/onboarding/askPoolCompanySizeWeb";
import { AskUserJobWeb } from "@kraaft/web/src/views/onboarding/askUserJobWeb";
import { AskUserMailWeb } from "@kraaft/web/src/views/onboarding/askUserMailWeb";
import { LoaderCreatingPoolWeb } from "@kraaft/web/src/views/onboarding/loaderCreatingPoolWeb";
import { Sandbox } from "@kraaft/web/src/views/sandbox";
import { SchemaLibrary } from "@kraaft/web/src/views/schemaLibrary";
import { LibrarySchemaShowcase } from "@kraaft/web/src/views/schemaLibrary/librarySchemaShowcase";
import { SchemasView } from "@kraaft/web/src/views/schemasView";
import { Settings } from "@kraaft/web/src/views/settings/settings";
import { WorkflowsView } from "@kraaft/web/src/views/settings/workflows";
import { SharepointFailure } from "@kraaft/web/src/views/sharepoint/sharepointFailure";
import { SharepointSuccess } from "@kraaft/web/src/views/sharepoint/sharepointSuccess";
import { WelcomeOnKraaftWeb } from "@kraaft/web/src/views/welcomeOnKraaft";

import { routes } from "./routes";
import { useStoreUtmParams } from "./useStoreUtmParams";

// TODO: Find a better way of typing the component and its props
interface LayoutRoute {
  path: string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: Record<string, any>;
  exact?: boolean;
}

function useNavigationSwitch() {
  let navigationSwitch = useSelector(selectNavigationSwitch);
  const currentPoolId = useSelector(selectCurrentPoolId);

  // wait for location to allow direct arrival on admin pages
  if (!currentPoolId && navigationSwitch === "ok") {
    navigationSwitch = "loading";
  }
  return navigationSwitch;
}

const AppRouter = () => {
  const navigationSwitch = useNavigationSwitch();
  const isMobileWeb = isWebMobile();

  const isAtLeastPoolAdminOrSuperadmin = useSelector(
    selectCurrentContextAtLeastAdminOrSuperadmin,
  );

  useStoreUtmParams();

  const shouldElevationToSSO = useShouldElevateToSSO();
  useRefreshSSOConnection();

  const layoutRoutes: LayoutRoute[] = useMemo(
    () =>
      compact([
        // Conversation
        { path: [routes.Room, routes.Messenger], component: Messenger },
        { path: routes.Settings, component: Settings },

        // Dev sandbox
        { path: [routes.Sandbox], component: Sandbox },

        // Database
        conditionalEntry(
          {
            path: [
              routes.SchemaView,
              routes.ModularFoldersTableForRoom,
              routes.ModularFoldersTable,
              routes.Database,
            ],
            component: Database,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),

        conditionalEntry(
          { path: routes.ManageWorkflows, component: WorkflowsView },
          isAtLeastPoolAdminOrSuperadmin,
        ),

        conditionalEntry(
          {
            path: routes.SchemaLibraryView,
            component: SchemaLibrary,
            exact: true,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),

        // Settings
        conditionalEntry(
          { path: routes.Settings, component: Settings },
          isAtLeastPoolAdminOrSuperadmin,
        ),

        // Form builders
        conditionalEntry(
          {
            path: routes.FolderSchemaBuilder,
            component: FolderSchemaBuilder,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),
        conditionalEntry<LayoutRoute>(
          {
            path: [routes.SchemasView, routes.SchemaLibraryView],
            component: SchemasView,
            exact: true,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),
        conditionalEntry(
          {
            path: routes.LibrarySchemaShowcase,
            component: LibrarySchemaShowcase,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),
        conditionalEntry(
          {
            path: routes.LibrarySchemaShowcase,
            component: LibrarySchemaShowcase,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),
        conditionalEntry(
          {
            path: routes.Planning,
            component: PlanningScreen,
          },
          isAtLeastPoolAdminOrSuperadmin,
        ),
      ]),
    [isAtLeastPoolAdminOrSuperadmin],
  );

  const navigationSwitchRouter = useMemo<
    Record<NavigationSwitchValue, React.ReactNode>
  >(
    () => ({
      "sso-elevation": <SSOElevationWeb />,
      "signed-out": <AuthFlow />,

      "invalid-invitation": (
        <Switch>
          <Route path={routes.InvalidInvitation}>
            <InvalidInvitationScreenWeb />
          </Route>
          <Redirect to={routes.InvalidInvitation} />
        </Switch>
      ),

      "invite-congrats": (
        <Switch>
          <Route path={routes.CongratsScreen}>
            <InviteCongratsScreenWeb />
          </Route>
          <Redirect to={routes.CongratsScreen} />
        </Switch>
      ),
      "need-username": (
        <Switch>
          <Route path={routes.EnterUsername}>
            <EnterUsernameWeb />
          </Route>
          <Redirect to={routes.EnterUsername} />
        </Switch>
      ),
      "signed-in-no-workspace": (
        <Switch>
          <Route path={routes.WelcomeOnKraaft}>
            <WelcomeOnKraaftWeb />
          </Route>
          <Route path={routes.CreateWorkspace}>
            <CreateWorkspaceWeb />
          </Route>
          <Redirect to={routes.WelcomeOnKraaft} />
        </Switch>
      ),
      "onboarding-need-company-size": (
        <Switch>
          <Route path={routes.OnboardingAskPoolCompanySize}>
            <AskPoolCompanySizeWeb />
          </Route>
          <Redirect to={routes.OnboardingAskPoolCompanySize} />
        </Switch>
      ),
      "onboarding-need-company-industry": (
        <Switch>
          <Route path={routes.OnboardingAskPoolCompanyIndustry}>
            <AskPoolCompanyIndustryWeb />
          </Route>
          <Redirect to={routes.OnboardingAskPoolCompanyIndustry} />
        </Switch>
      ),
      "onboarding-need-job": (
        <Switch>
          <Route path={routes.OnboardingAskUserJob}>
            <AskUserJobWeb />
          </Route>
          <Redirect to={routes.OnboardingAskUserJob} />
        </Switch>
      ),
      "onboarding-need-acquisition-source": (
        <Switch>
          <Route path={routes.OnboardingAskPoolAcquisitionSource}>
            <AskPoolAcquisitionSourceWeb />
          </Route>
          <Redirect to={routes.OnboardingAskPoolAcquisitionSource} />
        </Switch>
      ),
      "onboarding-need-email": (
        <Switch>
          <Route path={routes.OnboardingAskUserEmail}>
            <AskUserMailWeb />
          </Route>
          <Redirect to={routes.OnboardingAskUserEmail} />
        </Switch>
      ),
      "onboarding-invite-to-pool": (
        <Switch>
          <Route path={routes.OnboardingInviteToPool}>
            <AddMembersAtPoolCreationWeb />
          </Route>
          <Route path={routes.OnboardingPoolCreationLoader}>
            <LoaderCreatingPoolWeb />
          </Route>
          <Redirect to={routes.OnboardingInviteToPool} />
        </Switch>
      ),

      ok: isMobileWeb ? (
        <Switch>
          <Route path={routes.CongratsScreen}>
            <MobileWebCongratsScreenWeb />
          </Route>
          <Redirect to={routes.CongratsScreen} />
        </Switch>
      ) : (
        <Layout layoutRoutes={layoutRoutes}>
          <Switch>
            {layoutRoutes.map((route) => {
              if (!route) {
                return null;
              }
              const {
                component: LayoutView,
                componentProps = {},
                path,
                exact,
              } = route;

              return (
                <Route
                  key={
                    Array.isArray(route.path)
                      ? route.path.join(",")
                      : route.path
                  }
                  path={path}
                  exact={exact}
                >
                  <LayoutView {...componentProps} />
                </Route>
              );
            })}
            <Redirect to={routes.Messenger} />
          </Switch>
        </Layout>
      ),
      loading: null,
    }),
    [isMobileWeb, layoutRoutes],
  );

  const out = shouldElevationToSSO
    ? navigationSwitchRouter["sso-elevation"]
    : navigationSwitchRouter[navigationSwitch];

  return (
    <>
      <Toastr position="top-center" preventDuplicates closeOnToastrClick />
      <Router history={history}>
        <Switch>
          <Route path={routes.VerifyInvitation}>
            <VerifyInvitationScreenWeb />
          </Route>
          <Route path={routes.SharepointSuccess} exact>
            <SharepointSuccess />
          </Route>
          <Route path={routes.SharepointFailure} exact>
            <SharepointFailure />
          </Route>
          {out}
        </Switch>
        {/* this needs to be a child of `Router` in case something in a Portal uses `useNavigationService` */}
        <PortalHost />
      </Router>
    </>
  );
};

export { AppRouter };
