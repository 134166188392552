import { put, select, takeLatest } from "typed-redux-saga/macro";

import { FilterStateActions } from "@kraaft/shared/core/modules/filter/filterActions";
import { selectFilterState } from "@kraaft/shared/core/modules/filter/filterSelectors";
import { Visibility } from "@kraaft/shared/core/modules/filter/filterState";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { OfflineSchemaStateActions } from "@kraaft/shared/core/modules/schema/schema.offline";
import {
  selectRoomSchema,
  selectRoomSchemaLabelsColumn,
  selectRoomSchemaStatusColumn,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { getSelectColumnOption } from "@kraaft/shared/core/modules/schema/schema.utils";
import { UserStateActions } from "@kraaft/shared/core/modules/user/userActions";
import {
  selectAllPoolUsers,
  selectCurrentUserAtLeastAdminOrSuperadmin,
  selectCurrentUserIsSuperadmin,
  selectCurrentUserPools,
  waitForCurrentUserPools,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { getEmptyArray } from "@kraaft/shared/core/utils/utils";

export function* reconciliateFiltersSaga() {
  yield* takeLatest(
    [
      PoolStateActions.setPoolLocation,
      OfflineSchemaStateActions.set,
      UserStateActions.loggedUserReceived,
    ],
    handleReconciliateFilters,
  );
}

function* reconciliateVisibility(oldVisibility: Visibility) {
  const canFilterAsAdmin = yield* select(
    selectCurrentUserAtLeastAdminOrSuperadmin,
  );
  const canFilterAsSuperadmin = yield* select(selectCurrentUserIsSuperadmin);

  let newVisibility = oldVisibility;

  if (newVisibility === "superadmin" && !canFilterAsSuperadmin) {
    newVisibility = "all";
  }
  if (newVisibility === "all" && !canFilterAsAdmin) {
    newVisibility = "member";
  }

  return newVisibility;
}

function* reconciliateStatusId(
  poolId: string,
  oldStatusId: string | undefined,
) {
  const statusColumn = yield* select(selectRoomSchemaStatusColumn(poolId));

  if (
    oldStatusId !== undefined &&
    statusColumn !== undefined &&
    getSelectColumnOption(statusColumn, oldStatusId) !== undefined
  ) {
    return oldStatusId;
  }

  return undefined;
}

function* reconciliateLabelIds(poolId: string, oldLabelIds: string[]) {
  const labelsColumn = yield* select(selectRoomSchemaLabelsColumn(poolId));

  if (labelsColumn === undefined || oldLabelIds.length === 0) {
    return getEmptyArray<string>();
  }

  const newLabelIds = Object.keys(labelsColumn.options).filter((labelId) =>
    oldLabelIds.includes(labelId),
  );

  return newLabelIds;
}

function* reconciliateResponsibleId(
  poolId: string,
  oldResponsibleId: string | undefined,
) {
  const poolUsers = yield* select(selectAllPoolUsers(poolId));

  if (
    oldResponsibleId !== undefined &&
    poolUsers[oldResponsibleId] !== undefined
  ) {
    return oldResponsibleId;
  }

  return undefined;
}

function* handleReconciliateFilters() {
  yield* waitForCurrentUserPools();
  const pools = yield* select(selectCurrentUserPools);
  const filters = yield* select(selectFilterState);
  const currentPoolId = yield* select(selectCurrentPoolId);
  const roomSchema = yield* select(selectRoomSchema(currentPoolId));
  const isUserSuperadmin = yield* select(selectCurrentUserIsSuperadmin);

  if (!roomSchema || pools === undefined) {
    return;
  }

  for (const [poolId, roomFilters] of Object.entries(filters)) {
    // State is persisted => a `_persist` key is added so we ignore it
    if (poolId === "_persist") {
      continue;
    }
    const belongsToPool = Boolean(pools[poolId]);

    if (!belongsToPool && !isUserSuperadmin) {
      yield* put(FilterStateActions.resetFilters({ poolId }));
      continue;
    }

    if (poolId !== currentPoolId) {
      continue;
    }
    const newVisibility = yield* reconciliateVisibility(roomFilters.visibility);
    const newStatusId = yield* reconciliateStatusId(
      poolId,
      roomFilters.statusId,
    );
    const newLabelIds = yield* reconciliateLabelIds(
      poolId,
      roomFilters.labelIds,
    );
    const newResponsibleId = yield* reconciliateResponsibleId(
      poolId,
      roomFilters.responsibleId,
    );

    yield* put(
      FilterStateActions.setRoomReconciliatedFilters({
        poolId,
        visibility: newVisibility,
        statusId: newStatusId,
        labelIds: newLabelIds,
        responsibleId: newResponsibleId,
      }),
    );
  }
}
