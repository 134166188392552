import { DeleteOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Api } from "@kraaft/shared/core/services/api";

export const deleteSchemaLibraryTagOperation =
  DeleteOneOperationBuilder.create<SchemaLibraryTag>()
    .payload<{ language: LibrarySchemaLanguage }>()
    .mutate(async ({ id, language }) => {
      await Api.deleteSchemaLibraryTag({ id, language });
    });
