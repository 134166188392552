import { createContext } from "react";
import { noop } from "ts-essentials";

import { MessageWithTextCapability } from "@kraaft/shared/core/modules/message/core/message.helper";

export interface MessageTextEditorContextType {
  selectedMessage: MessageWithTextCapability | undefined;
  setSelectedMessage(message: MessageWithTextCapability | undefined): void;
}

export const MessageTextEditorContext =
  createContext<MessageTextEditorContextType>({
    selectedMessage: undefined,
    setSelectedMessage: noop,
  });
