import {
  MessageHelper,
  MessageWithTextCapability,
} from "@kraaft/shared/core/modules/message/core/message.helper";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

export function getEditMessageMenuLabel(
  t: TFunction,
  message: MessageWithTextCapability,
) {
  if (MessageHelper.isAudio(message)) {
    return t("editTranscriptionAction");
  }
  if (MessageHelper.isImage(message) || MessageHelper.isVideo(message)) {
    return message.attachment.caption
      ? t("messageMenu.editCaption")
      : t("messageMenu.addCaption");
  }
  return t("editMessageAction");
}
