import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";
import clsx from "clsx";

import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { useMemberManagement } from "@kraaft/web/src/views/settings/manageMembers/manageMembers.context";

import { makeDialogStyle } from "./removeMemberDialog.styles";

type RemoveMemberDialogProps = {
  data: { username?: string; userId: string; grade?: UserPoolRole };
  open: boolean;
  onClose: () => void;
};
const RemoveMemberDialog = ({
  open,
  onClose,
  data,
}: RemoveMemberDialogProps) => {
  const { removeMember } = useMemberManagement();
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const handleRemoveMember = async () => {
    removeMember(data.userId);
    onClose();
  };

  const isMemberExternal = data.grade === UserPoolRole.EXTERNAL;

  const renderContent = () => (
    <div className={classes.dialogContentContainer}>
      <DialogContentText classes={{ root: clsx(classes.text, classes.spacer) }}>
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence1"
            : "removeMemberSentence1",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
      <DialogContentText
        classes={{ root: clsx(classes.text, classes.noMargin) }}
      >
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence2"
            : "removeMemberSentence2",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
      <DialogContentText
        classes={{ root: clsx(classes.text, classes.noMargin) }}
      >
        {t(
          isMemberExternal
            ? "removeMemberExternalSentence3"
            : "removeMemberSentence3",
          {
            member: data.username,
          },
        )}
      </DialogContentText>
    </div>
  );

  const dialog: KDialogConfig = {
    title: t(
      isMemberExternal ? "removeMemberExternalTitle" : "removeMemberTitle",
      {
        member: data.username,
      },
    ),
    content: renderContent(),
    actions: [
      {
        accessibilityLabel: t("remove"),
        text: t("remove"),
        onPress: handleRemoveMember,
        autoFocus: true,
        destructive: true,
      },
      {
        accessibilityLabel: t("cancel"),
        text: t("cancel"),
        onPress: onClose,
      },
    ],
  };

  return <KDialog open={open} onClose={onClose} {...dialog} />;
};

export { RemoveMemberDialog };
