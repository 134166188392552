import { EditOneOperationBuilder } from "@kraaft/offline";
import {
  KSchema,
  KSchemaColumnDefinition,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { optimisticSchemaEditColumnDefinition } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";

export const editColumnDefinitionOperation =
  EditOneOperationBuilder.create<KSchema>()
    .payload<{ columnKey: string; definition: KSchemaColumnDefinition }>()
    .expected((aggregate, payload) => {
      optimisticSchemaEditColumnDefinition(aggregate, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editSchemaColumn({
        schemaId: payload.id,
        columnKey: payload.columnKey,
        column: {
          definition: payload.definition,
        },
      });
      return { updatedAt };
    });
