import { PropsWithChildren, useState } from "react";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";

interface AnswerNpsSurveyFormContext {
  note: number | null;
  setNote: (note: number) => void;
  isSubmitNoteDisabled: boolean;
  target: string | undefined;
  setTarget: (target: string) => void;
  isSubmitTargetDisabled: boolean;
  benefit: string | undefined;
  setBenefit: (benefit: string) => void;
  isSubmitBenefitDisabled: boolean;
  missingFunctionality: string | undefined;
  setMissingFunctionality: (missingFunctionality: string) => void;
  isSubmitMissingFunctionalityDisabled: boolean;
}

export const AnswerNpsSurveyFormContext =
  createMeshContext<AnswerNpsSurveyFormContext>();

export const AnswerNpsSurveyFormContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [note, setNote] = useState<number | null>(null);
  const [target, setTarget] = useState("");
  const [benefit, setBenefit] = useState("");
  const [missingFunctionality, setMissingFunctionality] = useState("");

  const isSubmitNoteDisabled = !note;
  const isSubmitTargetDisabled = target.length === 0;
  const isSubmitBenefitDisabled = benefit.length === 0;
  const isSubmitMissingFunctionalityDisabled =
    missingFunctionality.length === 0;

  const contextValue = useMeshContextSetup<AnswerNpsSurveyFormContext>({
    note,
    setNote,
    isSubmitNoteDisabled,
    target,
    setTarget,
    isSubmitTargetDisabled,
    benefit,
    setBenefit,
    isSubmitBenefitDisabled,
    missingFunctionality,
    setMissingFunctionality,
    isSubmitMissingFunctionalityDisabled,
  });

  return (
    <AnswerNpsSurveyFormContext.Provider value={contextValue}>
      {children}
    </AnswerNpsSurveyFormContext.Provider>
  );
};
