import { AttachmentBase } from "../folder/attachmentTypes";
import { Actor } from "./actor";
import { and, getRoomPermissions, granted } from "./guards.tools";

export const ModularFolderGuards = {
  create: and(granted("Room.talk"), granted("Record.create")),
  delete: and(granted("Room.edit"), granted("Record.delete")),
  editAttachmentGeolocation: (
    actor: Actor,
    poolId: string,
    attachment: AttachmentBase,
  ) => {
    if (actor.claims.support) {
      return [true] as const;
    }

    if (actor.userId === attachment.senderUserId) {
      return [true] as const;
    }

    const perms = getRoomPermissions(actor, poolId);
    if (perms?.correct) {
      return [true] as const;
    }

    return [false, "unauthorized"] as const;
  },
};
