import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { type DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { assert } from "ts-essentials";

import { useCombinedRefs, useMeshContext } from "@kraaft/helper-hooks";
import type { Size } from "@kraaft/shared/core/types";
import { ColorStyle, Spacing } from "@kraaft/ui";

import { HideVertical } from "../../hideVertical/hideVertical";
import { Icon } from "../../icon";
import { OrderableListContext } from "../orderableList.context";
import {
  ORDERABLE_LIST_DRAG_ITEM_TYPE,
  type OrderableListDragItem,
  type OrderableListRow,
  type OrderedListRow,
  type TargetType,
} from "../orderableList.types";
import { getPlacementFromTargetAndDraggedSourcePosition } from "./draggableRow.utils";

const DEFAULT_PLACEHOLDER_SIZE: Size = {
  height: 50,
  width: 100,
};

export type DraggableRowProps<T extends OrderableListRow> = {
  item: OrderedListRow<T>;
  Renderer: React.ComponentType<T>;
  containerClassName?: string;
  withHandle?: boolean;
  targetType: TargetType;
  isDraggingOverList: boolean;
};

const DraggableRow_ = <T extends OrderableListRow>({
  item,
  Renderer,
  containerClassName,
  withHandle,
  targetType,
  isDraggingOverList,
}: DraggableRowProps<T>) => {
  const classes = useStyles();

  const {
    sourceType,
    onDragStart,
    identifier,
    setCurrentlyHoveredRow,
    disabled,
  } = useMeshContext(OrderableListContext);
  const rowReference = useRef<HTMLDivElement>(null);

  const [{ isDragging, isDraggingStarted }, drag, preview] = useDrag({
    type: sourceType,
    item: (): OrderableListDragItem<T> => {
      onDragStart?.();
      if (rowReference.current) {
        return {
          type: ORDERABLE_LIST_DRAG_ITEM_TYPE,
          sourceKey: item.key,
          sourceIndex: item.computedIndex,
          listIdentifier: identifier,
          placeholderSize: {
            height: rowReference.current.clientHeight,
            width: rowReference.current.clientWidth,
          },
          data: item.data,
        };
      }
      return {
        type: ORDERABLE_LIST_DRAG_ITEM_TYPE,
        sourceKey: item.key,
        sourceIndex: item.computedIndex,
        listIdentifier: identifier,
        placeholderSize: DEFAULT_PLACEHOLDER_SIZE,
        data: item.data,
      };
    },
    isDragging: (monitor: DragSourceMonitor<OrderableListDragItem<T>>) =>
      monitor.getItem().sourceKey === item.key,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      isDraggingStarted: // triggered when we are effectively moving the element (usually one frame after)
        monitor.isDragging() && monitor.getTargetIds().length > 0,
    }),
    canDrag: !disabled,
  });

  const [, drop] = useDrop({
    accept: targetType,
    hover: (_dragItem: OrderableListDragItem<T>, monitor) => {
      assert(rowReference.current !== null, "rowReference.current is null");
      const targetBoundingRect = rowReference.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      const placement = getPlacementFromTargetAndDraggedSourcePosition(
        targetBoundingRect,
        clientOffset,
      );

      setCurrentlyHoveredRow({
        index: item.computedIndex,
        key: item.key,
        placement,
      });
    },
  });

  useLayoutEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const conbinedRefs = useCombinedRefs(
    rowReference,
    drop,
    !withHandle ? drag : undefined,
  );

  const content = useMemo(
    () => React.createElement(Renderer, item.data),
    [Renderer, item.data],
  );

  return (
    <HideVertical hidden={isDraggingStarted && !isDraggingOverList}>
      <div
        ref={conbinedRefs}
        className={clsx(
          classes.container,
          isDragging && classes.containerHidden,
          containerClassName,
        )}
      >
        {withHandle && (
          <div ref={drag} className={classes.handleIconContainer}>
            <Icon name="dots-six" color={ColorStyle.SEPARATOR} />
          </div>
        )}
        {content}
      </div>
    </HideVertical>
  );
};

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
  },
  containerHidden: {
    opacity: 0,
    zIndex: 0,
  },

  handleIconContainer: {
    paddingRight: Spacing.S8,
    cursor: "grab",
  },
});

export const DraggableRow = React.memo(DraggableRow_) as typeof DraggableRow_;
