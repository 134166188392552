import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { RoomType } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors/directSelectors";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";

export class RoomOrRoomCardSelectors {
  private static selectRoomOrRoomCard = memoize((roomId: string) => {
    return createSelector(
      selectRoom(roomId),
      RoomCardSelectors.select(roomId),
      (room, roomCard) => [room, roomCard] as const,
    );
  });

  private static selectUserRoomOrRoomCard = memoize((roomId: string) => {
    return createSelector(
      selectRoomUserHistory(roomId),
      RoomCardSelectors.select(roomId),
      (userRoom, roomCard) => [userRoom, roomCard] as const,
    );
  });

  static selectNotificationFilter = memoize((roomId: string) =>
    createSelector(
      this.selectUserRoomOrRoomCard(roomId),
      ([userRoom, roomCard]) => {
        if (userRoom) {
          return userRoom.notificationFilter ?? "inherit";
        }
        return roomCard && roomCard.type === "member"
          ? roomCard.notificationSource
          : "inherit";
      },
    ),
  );

  static selectIsCurrentUserMember = memoize((roomId: string) =>
    createSelector(
      this.selectUserRoomOrRoomCard(roomId),
      ([userRoom, roomCard]) =>
        Boolean(userRoom || roomCard?.type === "member"),
    ),
  );

  static selectIsEveryone = memoize((roomId: string) =>
    createSelector(this.selectRoomOrRoomCard(roomId), ([room, roomCard]) =>
      Boolean(room?.type === RoomType.EVERYONE || roomCard?.isEveryoneRoom),
    ),
  );

  static selectTitle = memoize((roomId: string) =>
    createSelector(this.selectRoomOrRoomCard(roomId), ([room, roomCard]) => {
      if (room) {
        return (
          getExistingRoomRecordField(room.record.properties, "title") ?? ""
        );
      }
      return roomCard?.title;
    }),
  );

  static selectEmoji = memoize((roomId: string) =>
    createSelector(this.selectRoomOrRoomCard(roomId), ([room, roomCard]) => {
      return room?.emoji ?? roomCard?.emoji;
    }),
  );
}
