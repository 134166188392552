import { useCallback } from "react";

import { useEffectRef } from "../useEffectRef";

/**
 * Function to listen for a keydown on an element that is focused
 * Returns a ref to pass to the element that has to be focused
 *
 * const ref = useFocusedKeydown("Enter", () => console.log("Enter has been pressed"))
 *
 * return <div ref={ref} />
 */
export function useFocusedKeydown<T>(key: string, callback: () => void) {
  return useEffectRef(
    useCallback(
      (node: T) => {
        if (!(node instanceof HTMLElement)) {
          return;
        }
        // TODO this should be prop on react-native-web 0.19
        node.setAttribute("tabindex", "0");

        function handler(event: KeyboardEvent) {
          if (event.key === key) {
            event.preventDefault();
            callback();
          }
        }

        node.addEventListener("keydown", handler);
        return () => {
          node.removeEventListener("keydown", handler);
        };
      },
      [callback, key],
    ),
  );
}
