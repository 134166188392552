import { isNative } from "@kraaft/helper-functions";
import { useAnswerNpsSurveyFlow } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyFlow/useAnswerNpsSurveyFlow";
import { AnswerNpsSurveyFormContextProvider } from "@kraaft/shared/core/modules/surveys/components/answerNpsSurveyForm/answerNpsSurveyForm.context";
import { Button, Sheet, Spacing } from "@kraaft/ui";
import { BottomSheetDefinition } from "@kraaft/ui/src/layout/sheet/bottomSheet/bottomSheet.definition/bottomSheet.definition.types";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import { Transition } from "@kraaft/shared/components/transition";
import { useEffect, useState } from "react";
import { AnchoredSheetDefinition } from "@kraaft/ui/src/layout/sheet/anchoredSheet/anchoredSheet.definition/anchoredSheet.definition.types";

export const AnswerNpsSurveyFormSheet = Sheet({
  native: "bottom",
  web: "anchored",
})
  .create(
    ({ Paper, Content, Header, Footer, GrowingContent }) =>
      ({ onClose, setPreventClose }) => {
        return (
          <AnswerNpsSurveyFormContextProvider>
            <AnswerNpsSurveyFormSheetContent
              Paper={Paper}
              Content={Content}
              Header={Header}
              Footer={Footer}
              GrowingContent={GrowingContent}
              onClose={onClose}
              setPreventClose={setPreventClose}
            />
          </AnswerNpsSurveyFormContextProvider>
        );
      },
  )
  .withDefaults({ withoutBackdrop: true });

interface AnswerNpsSurveyFormSheetContentProps
  extends Omit<AnchoredSheetDefinition | BottomSheetDefinition, "Host"> {
  onClose: (params?: { bypassPreventClose: boolean }) => void;
  setPreventClose: (value: boolean) => void;
}

export const AnswerNpsSurveyFormSheetContent = ({
  Paper,
  Content,
  Header,
  Footer,
  onClose,
  setPreventClose,
}: AnswerNpsSurveyFormSheetContentProps) => {
  const { t } = useTranslation();
  const [sheetHeight, setSheetHeight] = useState<number>(0);
  const {
    stepId,
    stepElements: { stepContent, nextButton },
  } = useAnswerNpsSurveyFlow({ closeHost: onClose, sheetHeight });

  const isThankingScreen = stepId === "thanking";

  interface LayoutEvent {
    nativeEvent: {
      layout: {
        height: number;
      };
    };
  }

  const handleLayout = (event: LayoutEvent) => {
    if (stepId !== "thanking") {
      const { height } = event.nativeEvent.layout;
      setSheetHeight(height);
    }
  };

  useEffect(() => {
    setPreventClose(true);
    const timer = setTimeout(() => {
      setPreventClose(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [setPreventClose]);

  return (
    <View onLayout={handleLayout}>
      <Paper
        noPadding={isThankingScreen}
        noSafeAreaBottom={isThankingScreen && isNative()}
      >
        {!isThankingScreen && (
          <Header
            onClose={() => onClose({ bypassPreventClose: true })}
            withCloseButton
          >
            {t("nps.form.title")}
          </Header>
        )}

        <Content noPadding={isThankingScreen}>
          <Transition identifier={stepId}>{stepContent}</Transition>
        </Content>

        {!isThankingScreen && (
          <Footer>
            {nextButton && (
              <Button
                text={nextButton.text}
                accessibilityLabel={nextButton.accessibilityLabel}
                disabled={nextButton.disabled}
                onPress={nextButton.onPress}
                style={styles.button}
                tooltip=""
              />
            )}
          </Footer>
        )}
      </Paper>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: Platform.select({ native: 1 }),
    marginBottom: Platform.select({ native: Spacing.S8 }),
  },
});
