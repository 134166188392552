import { SchemaLibraryTagOfflineFeature } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagOfflineFeature";
import { offlineReduxBundle } from "@kraaft/shared/core/store/offline";

export const {
  Actions: OfflineSchemaLibraryTagActions,
  Selectors: OfflineSchemaLibraryTagSelectors,
  StateActions: OfflineSchemaLibraryTagStateActions,
} = offlineReduxBundle.create(
  SchemaLibraryTagOfflineFeature,
  {
    selectRawAggregate: (state: any) =>
      state.schemaLibraryTag.schemaLibraryTags,
  },
  () => ({}),
);
