import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { KDialog } from "@kraaft/shared/components/kDialog";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";
import { ResolvePromise } from "@kraaft/shared/core/typeUtils";
import { Button, Color, ColorStyle, Spacing } from "@kraaft/ui";
import { useFileInput } from "@kraaft/web/src/core/utils/useFileInput";

const TEMPLATE_URL = "/models/kraaft_user_import_template.xlsx";

interface ImportUserDialogProps {
  poolId: string;
  open: boolean;
  onClose: () => void;
}

const ImportUserDialog = ({ poolId, open, onClose }: ImportUserDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<
    ResolvePromise<typeof SuperadminApi.importUsers> | undefined
  >(undefined);

  const importFiles = async (files: ModernFile<LocalPath>[]) => {
    const file = files[0];
    if (file) {
      const excelBase64 = await fileHelper.convertToBase64(file.path);
      if (!excelBase64) {
        dispatch(showError({ title: t("error") }));
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      try {
        const { warnings } = await SuperadminApi.importUsers({
          poolId,
          excelBase64,
        });

        setResult({ warnings });
      } catch (error) {
        setResult({ warnings: [error.message] });
      }
      setIsLoading(false);
    }
  };

  const { getInputProps, open: openFilePicker } = useFileInput({
    onAccepted: importFiles,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    multiple: false,
    maxSize: 100000000, // 100 MBytes
  });

  const onClickImport = () => {
    setResult(undefined);
    openFilePicker();
  };

  useEffect(() => {
    if (open) {
      setResult(undefined);
    }
  }, [open]);

  const title = t("superadminImportUsers");
  const wasImportSuccessful = !result?.warnings;

  const resultMessage =
    result &&
    (wasImportSuccessful
      ? t("superadminImportUsersDone")
      : [t("superadminImportUsersWarnings"), ...(result.warnings || [])].join(
          "\n\n",
        ));

  const content = (
    <>
      <input {...getInputProps()} />
      <Button
        onPress={onClickImport}
        accessibilityLabel={t("superadminImportUsersButton")}
        text={t("superadminImportUsersButton")}
        loading={isLoading}
      />
      <a
        href={TEMPLATE_URL}
        target="_blank"
        rel="noreferrer"
        className={classes.download}
      >
        <Button
          accessibilityLabel={t("superadminImportUsersTemplate")}
          text={t("superadminImportUsersTemplate")}
          variant="TERTIARY"
        />
      </a>
      <Typography
        className={clsx(
          classes.result,
          !result?.warnings ? classes.success : classes.warning,
        )}
      >
        {resultMessage}
      </Typography>
    </>
  );

  return (
    <KDialog open={open} onClose={onClose} title={title} content={content} />
  );
};

const useStyles = makeStyles({
  result: { whiteSpace: "pre-wrap", marginTop: 11 },
  success: { color: Color.BLUE_AZURE },
  warning: { color: ColorStyle.ACTION_DESCTRUCTIVE, flex: 1 },
  download: {
    textDecoration: "unset",
    marginLeft: Spacing.S16,
  },
});

export { ImportUserDialog };
