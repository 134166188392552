import { CreateOperationBuilder } from "@kraaft/offline";
import { optimisticallyCreateIncrementTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import {
  ModularRecordProperties,
  ModularRecordPropertiesWithTitle,
} from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";

function splitPropertiesForNetwork(allProperties: ModularRecordProperties[]) {
  let propertiesCount = 0;

  const propertiesChunks: Array<ModularRecordProperties[]> = [];
  let currentPropertiesBatch: ModularRecordProperties[] = [];

  function flush() {
    if (currentPropertiesBatch.length === 0) {
      return;
    }
    /**
     * Here we unshift because we don't want to mess up the ordering
     * the last ones must be imported first so the first ones will appear first in the end
     */
    propertiesChunks.unshift(currentPropertiesBatch);
    currentPropertiesBatch = [];
    propertiesCount = 0;
  }

  for (const record of allProperties) {
    propertiesCount += Object.keys(record).length;
    if (propertiesCount > 100) {
      flush();
    }
    currentPropertiesBatch.push(record);
  }
  flush();

  return propertiesChunks;
}

function generateDeterministicId(id: string, index: number) {
  if (index > 99) {
    return id;
  }
  return `${id.slice(0, -2)}${index.toString().padStart(2, "0")}`;
}

export const createOperation = CreateOperationBuilder.create<ModularFolder>()
  .payload<{
    roomId: string;
    schemaId: string;
    properties: Array<ModularRecordProperties>;
  }>({
    count(payload) {
      return payload.properties.length;
    },
  })
  .dependsOn<{
    getCurrentUserId(): string;
    getSchema(id: string): KSchema | undefined;
  }>()
  .augment((payload, { getCurrentUserId, getSchema }) => {
    const schema = getSchema(payload.schemaId);
    return {
      userId: getCurrentUserId(),
      schema: schema?.collection === "folder" ? schema : undefined,
      poolId: schema?.poolId,
    };
  })
  .expected((payload) => {
    return payload.ids.map((id, index) => {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const properties = payload.properties[index]!;

      const finalProperties: ModularRecordPropertiesWithTitle = Object.assign(
        {
          [KSchemaRemarkableColumns.TITLE]: {
            columnType: KColumnType.shortText,
            value: ModularRecordUtils.getPropertyField(
              properties,
              KSchemaRemarkableColumns.TITLE,
              KColumnType.shortText,
              payload.schema
                ? optimisticallyCreateIncrementTitle(payload.schema)
                : "",
            ),
          } as const,
        },
        properties,
      );

      if (payload.userId && payload.schema) {
        ModularRecordUtils.fillAutomatedUserValues(
          payload.schema,
          finalProperties,
          payload.userId,
        );
      }

      const modularFolder: ModularFolder = {
        id,
        poolId: payload.poolId ?? "",
        roomId: payload.roomId,
        schemaId: payload.schemaId,
        index: -1,
        geolocation: undefined,
        incrementalId: -1,
        properties: finalProperties,
        updatedAt: new Date(),
        pendingOptimisticOperations: 1,
      };

      return modularFolder;
    });
  })
  .mutate(async (payload, task) => {
    /**
     * This is known as a possible improvement
     * Here we should do multiple requests and save the progress, so that if we retry we only do
     * not done requests. For now, the workaround is to do multiple network requests anyways without saving the progress
     */

    const chunks = splitPropertiesForNetwork(payload.properties);
    const ids: string[] = [];

    for (const [index, chunk] of chunks.entries()) {
      try {
        ids.push(
          ...(await Api.addModularFolders({
            requestId: generateDeterministicId(task.id, index),
            roomId: payload.roomId,
            schemaId: payload.schemaId,
            folders: chunk.map(KSchemaConversion.toRawProperties),
          })),
        );
      } catch (e) {
        if (!HttpError.isRequestAlreadyMade(e)) {
          throw e;
        }
      }
    }
    return ids;
  });
