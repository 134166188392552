import { DragOperation } from "@dnd-kit/abstract";
import { pointerIntersection } from "@dnd-kit/collision";
import { useDroppable as useKitDroppable } from "@dnd-kit/react";

import { DndContract } from "./dnd.types";

export function useDroppable<
  OwnContract extends DndContract,
  AcceptsContract extends DndContract,
>(
  params: {
    id: string;
    data: OwnContract;
    accepts: Array<AcceptsContract["type"]> | ((data: any) => boolean);
    onDrop?: (data: AcceptsContract, operation: DragOperation) => void;
  } & Omit<Parameters<typeof useKitDroppable>["0"], "id" | "accept" | "data">,
) {
  const accepts = params.accepts;

  return useKitDroppable({
    id: params.id,
    accept: Array.isArray(accepts)
      ? accepts
      : (draggable) => accepts(draggable.data as any),
    data: {
      onDrop: params.onDrop,
      ...params.data,
    },
    collisionDetector: params.collisionDetector ?? pointerIntersection,
  });
}
