import { useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { FlatListProps, Platform, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { StrictOmit } from "ts-essentials";

import { useMeshContext } from "@kraaft/helper-hooks";
import { BidirectionalLoader } from "@kraaft/shared/components/bidirectionalMessageList/bidirectionalLoader";
import { useBidirectionalMessageProps } from "@kraaft/shared/components/bidirectionalMessageList/bidirectionalMessageList.utils";
import { RenderMessage } from "@kraaft/shared/components/bidirectionalMessageList/renderMessage/renderMessage";
import { useLastReadMessageReady } from "@kraaft/shared/components/bidirectionalMessageList/useLastReadMessageReady";
import { useScrollToBottomButton } from "@kraaft/shared/components/bidirectionalMessageList/useScrollToBottomButton";
import { useUnreadBanner } from "@kraaft/shared/components/bidirectionalMessageList/useUnreadBanner";
import { NotReadLine } from "@kraaft/shared/components/conversation/notReadLine";
import { MessageListContext } from "@kraaft/shared/components/replyPreview/replyHooks";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { selectMessagesReceived } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { selectReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageSelectors";
import { MaybeMessageSelection } from "@kraaft/shared/core/modules/message/messageState";
import {
  getIdFromMessage,
  getKeyFromMessage,
} from "@kraaft/shared/core/modules/message/messageUtils";
import { nullId } from "@kraaft/shared/core/utils";
import {
  useCallbackRealtime,
  useEffectRealtime,
} from "@kraaft/shared/core/utils/hooks";
import { PlatformSelect } from "@kraaft/shared/core/utils/platformSelect/platformSelect";
import { executeAfterStatePropagation } from "@kraaft/shared/core/utils/promiseUtils";
import { BidirectionalList } from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList";
import {
  BidirectionalListHandle,
  ScrollAnchor,
  ScrollPosition,
} from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList.props";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";

export type OnScrollInfo = {
  scroll: number;
  contentHeight: number;
  scrollHeight: number;
};

export interface BidirectionalMessageListProps
  extends PlatformSelect<{
    native: StrictOmit<
      FlatListProps<AnyMessage>,
      "data" | "onScroll" | "renderItem"
    >;
    web: object;
  }> {
  roomId: string;
  messageSelection: MaybeMessageSelection;
  enableSelection: boolean;
  openMessageActionSheet?: (message: AnyMessage) => void;
  onScroll?: (info: OnScrollInfo) => void;
  sizerWidth: number;
  lastReadMessageId: string | undefined;
  visible: boolean;
}

const BidirectionalMessageList_ = ({
  roomId,
  enableSelection,
  messageSelection,
  openMessageActionSheet,
  onScroll: externOnScroll,
  sizerWidth,
  lastReadMessageId,
  visible,
  ...other
}: BidirectionalMessageListProps) => {
  const { persisted, sent } = useSelector(selectMessagesReceived(roomId));
  const messageRepliedId = useSelector(selectReplyingSourceMessage(roomId));

  const handle = useRef<BidirectionalListHandle>(null);

  const scrollToDataId = useCallbackRealtime(
    async (
      [_lastReadMessageId],
      messageId: string,
      position: ScrollPosition,
      shouldAnimate: boolean,
      isAlreadyDisplayed: boolean,
      scrollAnchor?: ScrollAnchor,
    ) => {
      const isLastRead = messageId === _lastReadMessageId;
      return handle.current?.scrollToElement(
        messageId,
        position,
        shouldAnimate,
        isAlreadyDisplayed,
        scrollAnchor ?? (isLastRead ? "start" : "center"),
      );
    },
    [],
    [lastReadMessageId],
  );

  const {
    messagesToDisplay,
    earlierEdgeComponent,
    laterEdgeComponent,
    onScroll,
    startFromId,
    firstChunkIsInView,
    lastChunkIsInView,
  } = useBidirectionalMessageProps(lastReadMessageId, roomId, scrollToDataId);

  useEffectRealtime(
    ([_lastChunkIsInView]) => {
      if (!_lastChunkIsInView || !handle.current?.isNearBottomOfList()) {
        return;
      }
      executeAfterStatePropagation(handle.current?.scrollToBottomOfList);
    },
    [persisted],
    [lastChunkIsInView],
  );

  useEffectRealtime(
    ([_startFromId, _lastChunkIsInView]) => {
      if (sent === 0) {
        return;
      }
      if (_lastChunkIsInView) {
        executeAfterStatePropagation(handle.current?.scrollToBottomOfList);
      } else {
        _startFromId(undefined).catch(console.error);
      }
    },
    [sent],
    [startFromId, lastChunkIsInView],
  );

  const { messageListHandle } = useMeshContext(MessageListContext);

  useImperativeHandle(messageListHandle, () => ({
    scrollToMessage: (id: string, forceAnimation = true) =>
      startFromId(id, forceAnimation),
    scrollToBottom: () => startFromId(undefined),
  }));

  const { messageIdToDisplayBannerAfter } = useUnreadBanner(
    visible,
    messagesToDisplay,
    lastReadMessageId,
    (index, id) => scrollToDataId(id, index, true, true, "start"),
  );

  const renderItem = useCallbackRealtime(
    (
      [_firstChunkIsInView, _messagesToDisplay],
      message: AnyMessage,
      index: number,
    ) => {
      const selected =
        messageSelection.selectionType !== undefined &&
        messageSelection.selection?.[message.id] === true;

      const isUnread = message.id === messageIdToDisplayBannerAfter.current;

      const firstMessageNotRead =
        _firstChunkIsInView &&
        index === _messagesToDisplay.length - 1 &&
        lastReadMessageId === nullId;

      return (
        <>
          {Platform.select({
            web: firstMessageNotRead && <NotReadLine />,
            native: isUnread && <NotReadLine />,
          })}
          <RenderMessage
            selected={selected}
            enableSelection={enableSelection}
            message={message}
            messageRepliedId={messageRepliedId}
            openMessageActionSheet={openMessageActionSheet}
            roomId={roomId}
            width={sizerWidth}
          />
          {Platform.select({
            web: isUnread && <NotReadLine />,
            native: firstMessageNotRead && <NotReadLine />,
          })}
        </>
      );
    },
    [
      messageSelection.selectionType,
      messageSelection.selection,
      messageIdToDisplayBannerAfter,
      lastReadMessageId,
      enableSelection,
      messageRepliedId,
      openMessageActionSheet,
      roomId,
      sizerWidth,
    ],
    [firstChunkIsInView, messagesToDisplay],
  );

  const handleScrollToBottom = useCallback(() => {
    startFromId(undefined).catch(console.error);
  }, [startFromId]);

  const { element: scrollToBottomButton, checkIfButtonHasToShow } =
    useScrollToBottomButton({
      roomId,
      onPress: handleScrollToBottom,
      isLastChunkDisplayed: lastChunkIsInView,
      isNearBottomOfList: handle.current?.isNearBottomOfList,
    });

  const moreThanOneMessageToDisplay = messagesToDisplay.length > 0;
  useEffect(() => {
    if (moreThanOneMessageToDisplay) {
      checkIfButtonHasToShow();
    }
  }, [messagesToDisplay, checkIfButtonHasToShow, moreThanOneMessageToDisplay]);

  const internOnScroll = useCallback(
    (infos: OnScrollInfo, userTriggered: boolean) => {
      if (userTriggered) {
        onScroll(infos).catch(console.error);
        externOnScroll?.(infos);
      }
      checkIfButtonHasToShow();
    },
    [checkIfButtonHasToShow, onScroll, externOnScroll],
  );

  return (
    <>
      <BidirectionalList
        {...other}
        handle={handle}
        items={messagesToDisplay}
        renderItem={renderItem}
        getRenderKeyFromItem={getKeyFromMessage}
        getIdFromItem={getIdFromMessage}
        onScroll={internOnScroll}
        earlierEdgeComponent={earlierEdgeComponent}
        laterEdgeComponent={laterEdgeComponent}
        keyboardShouldPersistTaps="handled"
      />
      {scrollToBottomButton}
    </>
  );
};

export const BidirectionalMessageList = withDependencyWrapper(
  BidirectionalMessageList_,
  ({ roomId, visible }) => {
    const { ready, lastReadMessageId } = useLastReadMessageReady(
      roomId,
      visible,
    );

    if (!ready) {
      return undefined;
    }

    return { lastReadMessageId };
  },
  {
    Fallback: () => (
      <View style={styles.fallbackContainer}>
        <BidirectionalLoader nativeID="bottom-messages-loader" />
      </View>
    ),
  },
);

const styles = StyleSheet.create({
  fallbackContainer: {
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
