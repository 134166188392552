import { StaticRole } from "./actor";

function toGrants<const P extends string[]>(perms: P) {
  return perms.reduce((acc, perm) => {
    acc[perm] = true;
    return acc;
  }, {} as any) as { [I in P[number]]: true };
}

export const defaultRolesGrants: RolesGrants = {
  own: {
    Record: toGrants(["create", "view", "edit", "delete"]),
    Room: toGrants([
      "create",
      "view",
      "edit",
      "delete",
      "talk",
      "moderate",
      "correct",
      "archive",
      "unarchive",
    ]),
    Schema: toGrants(["create", "view", "edit", "delete"]),
    Members: toGrants(["invite", "view", "eject", "roles"]),
  },
  adm: {
    Record: toGrants(["create", "view", "edit", "delete"]),
    Room: toGrants([
      "create",
      "view",
      "edit",
      "delete",
      "talk",
      "correct",
      "archive",
      "unarchive",
    ]),
    Schema: toGrants(["create", "view", "edit", "delete"]),
    Members: toGrants(["invite", "view", "eject", "roles"]),
  },
  room: {
    Schema: {},
    Record: {},
    Room: toGrants(["create", "edit"]),
    Members: {},
  },
  std: {
    Schema: {},
    Record: toGrants(["create", "edit"]),
    Room: toGrants(["talk"]),
    Members: toGrants(["invite", "view"]),
  },
  ext: {
    Schema: {},
    Record: toGrants(["create", "edit"]),
    Room: toGrants(["talk"]),
    Members: {},
  },
};

type RoomGrants = {
  create?: boolean;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
  talk?: boolean;
  moderate?: boolean;
  correct?: boolean;
  archive?: boolean;
  unarchive?: boolean;
};

type SchemaGrants = {
  create?: boolean;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
};

type RecordGrants = {
  create?: boolean;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
};

type MemberGrants = {
  invite?: boolean;
  view?: boolean;
  eject?: boolean;
  roles?: boolean;
};

export type AllGrants = {
  Room: RoomGrants;
  Schema: SchemaGrants;
  Record: RecordGrants;
  Members: MemberGrants;
};

export type RolesGrants = {
  [R in StaticRole]: AllGrants;
};
