import { useMemo } from "react";
import lodash from "lodash";

import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { isKraaftEmployeeEmail } from "@kraaft/shared/core/utils/userUtils";

import { useStyles } from "./manageMembers.styles";

export type ManageMembersInfoProps = {
  members: {
    email?: string;
    role?: UserPoolRole;
    pricing?: { computed: string };
  }[];
};

export const ManageMembersInfo = ({ members }: ManageMembersInfoProps) => {
  const classes = useStyles();

  const totalMembers = useMemo(() => {
    return members.filter(
      (member) =>
        member.email === undefined || !isKraaftEmployeeEmail(member.email),
    );
  }, [members]);

  const [guests, internals] = useMemo(() => {
    return lodash.partition(totalMembers, (member) => {
      return member.role === UserPoolRole.EXTERNAL;
    });
  }, [totalMembers]);

  const [internalsPaying, internalsFree] = useMemo(() => {
    return lodash.partition(internals, (member) => {
      return member.pricing?.computed === "chargeable";
    });
  }, [internals]);

  return (
    <div className={classes.table}>
      <div className={classes.cell}>
        <div>
          <Trans i18nKey="manageMembersInfo.total" />
        </div>
        <div>{totalMembers.length}</div>
      </div>
      <div className={classes.cell}>
        <div>
          <Trans i18nKey="manageMembersInfo.internalsPaying" />
        </div>
        <div>{internalsPaying.length}</div>
      </div>
      <div className={classes.cell}>
        <div>
          <Trans i18nKey="manageMembersInfo.internalsFree" />
        </div>
        <div>{internalsFree.length}</div>
      </div>
      <div className={classes.cell}>
        <div>
          <Trans i18nKey="manageMembersInfo.guests" />
        </div>
        <div>{guests.length}</div>
      </div>
    </div>
  );
};
