import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ExpandableSection } from "@kraaft/shared/components/expandableSection";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { selectPoolFolderSchemas } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { ScreenParams } from "@kraaft/shared/core/services/navigation/navigationParams";
import { FilteredListItem } from "@kraaft/web/src/components/dropdown/filteredList";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import { useHandleChangeSingleSelectHelper } from "@kraaft/web/src/components/dropdown/kDropdownUtils";
import { NavigationMenu } from "@kraaft/web/src/components/menu";
import { NavigationMenuItemProps } from "@kraaft/web/src/components/menu/navigationMenu";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";
import { templateRoute } from "@kraaft/web/src/views/app/appRouter/routeUtils";

import { useStyles } from "./database.styles";

interface ExpandableRoomViewsProps {
  poolId: string | undefined;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const ExpandableRoomViews = (props: ExpandableRoomViewsProps) => {
  const classes = useStyles();
  const { expanded, setExpanded, poolId } = props;
  const { t } = useTranslation();
  const navigationService = useNavigationService();
  const rooms = useSelector(
    RoomSelectors.selectAllOfPoolAlphaSorted(poolId ?? ""),
  );
  const schemas = useSelector(selectPoolFolderSchemas(poolId));
  const params =
    useParams<
      ScreenParams<
        | "Database"
        | "ModularFoldersTable"
        | "ModularFoldersTableForRoom"
        | "ConversationTable"
      >
    >();
  const selectedRoom = params.roomId;
  const selectedRoomArray = useMemo(
    () => (params.roomId ? [params.roomId] : []),
    [params],
  );

  const setSelectedRoom = useCallback(
    (newValue: string) => {
      const schemaId = params.schemaId ?? schemas[0]?.id ?? "";
      navigationService.navigate("ModularFoldersTableForRoom", {
        roomId: newValue,
        schemaId,
      });
    },
    [navigationService, params.schemaId, schemas],
  );

  const handleSelectedRoomFromArrayChange = useHandleChangeSingleSelectHelper(
    (newValue: string | undefined) => {
      if (!newValue) {
        return;
      }
      setSelectedRoom(newValue);
    },
    [setSelectedRoom],
  );

  const roomItems = useMemo<FilteredListItem[]>(
    () =>
      Object.values(rooms ?? {}).map((details) => ({
        label: getExistingRoomRecordField(
          details.record.properties,
          "title",
          "",
        ),
        value: details.id,
        onSelect: () => setSelectedRoom(details.id),
      })),
    [rooms, setSelectedRoom],
  );

  const roomViewItems = useMemo<NavigationMenuItemProps[]>(
    () =>
      selectedRoom
        ? [
            ...schemas.map<NavigationMenuItemProps>((schema) => ({
              id: schema.id,
              label: schema.name,
              icon: KSchemaUtils.getSchemaIconName(schema.icon),
              matchRoute: templateRoute(routes.ModularFoldersTableForRoom, {
                schemaId: schema.id,
                roomId: selectedRoom,
              }),
              onPress: () =>
                navigationService.navigate("ModularFoldersTableForRoom", {
                  schemaId: schema.id,
                  roomId: selectedRoom,
                }),
            })),
          ]
        : [],
    [navigationService, schemas, selectedRoom],
  );

  return (
    <ExpandableSection
      containerId="database-room-views"
      title={t("roomViews")}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <div className={classes.dropdown}>
        <KDropdown
          placeholder={t("selectOrSearch")}
          items={roomItems}
          onSelectionChange={handleSelectedRoomFromArrayChange}
          selectedItemIds={selectedRoomArray}
          withSearchBar
          variant="dark"
        />
      </div>
      {roomViewItems.length > 0 && (
        <div className={classes.conversationNavMenu}>
          <NavigationMenu
            items={roomViewItems}
            currentRoute={navigationService.getLocation()}
          />
        </div>
      )}
    </ExpandableSection>
  );
};

export { ExpandableRoomViews };
