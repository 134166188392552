import { createSelector } from "reselect";

import { PoolAddonsInjectedState } from "../poolAddons.state";

export class PoolAddonsSelectors {
  static select = (state: PoolAddonsInjectedState) =>
    state.poolAddons.poolAddons;

  static selectPlanningEnabled = createSelector(
    PoolAddonsSelectors.select,
    ({ planning }) => planning,
  );
}
