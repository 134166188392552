import { getStorage, ref, uploadBytes } from "firebase/storage";
import mime from "mime";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import {
  BlobResolveError,
  FileUploader,
} from "@kraaft/shared/core/modules/file/fileUploader/fileUploader";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { firebaseApp } from "@kraaft/shared/core/services/firebase/modularApp/app";
import { FirebaseSDK } from "@kraaft/shared/core/services/firebase/sdk/common";
import { kfetch } from "@kraaft/shared/core/utils/kfetch";

import { FileAllocator } from "../fileAllocator/fileAllocator";

async function uploadFile(uploadUrl: string, file: File) {
  const response = await kfetch(uploadUrl, {
    method: "PUT",
    mode: "cors",
    body: file,
  });

  if (!response.ok) {
    let message: string | undefined;
    let details: unknown;
    const text = await response.text();
    if (text) {
      try {
        ({
          error: { message, details },
        } = JSON.parse(text));
      } catch (e) {}
    }

    if (!message) {
      message = text ?? response.statusText;
    }

    const error = new HttpError(response.status, message, undefined, details);
    console.warn("HttpError:", error, "details=", details);
    throw error;
  }
}

export class WebFirebaseFileUpload extends FileUploader<ModernFile<LocalPath>> {
  private async uploadThroughUploadUrl(
    uploadUrl: string,
    data: Blob,
    filename: string,
  ) {
    await uploadFile(uploadUrl, new File([data], filename));
  }

  private async uploadThroughStoragePath(
    storagePath: string,
    data: Blob,
    filename: string,
  ) {
    const contentType = mime.getType(filename) ?? "application/octet-stream";

    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, storagePath);

    try {
      await uploadBytes(storageRef, data, { contentType });
    } catch (error) {
      throw FirebaseSDK.interpretError(error);
    }
  }

  async upload(params: {
    file: ModernFile<LocalPath>;
    storagePath: string;
    uploadUrl: string;
  }) {
    const { file, uploadUrl, storagePath } = params;

    let data: Blob;

    try {
      data = await FileAllocator.resolveLocalPathToBlob(file.path);
    } catch (e) {
      throw new BlobResolveError();
    }

    if (getEnvironment().FIREBASE.EMULATOR.ENABLED) {
      await this.uploadThroughStoragePath(storagePath, data, file.filename);
    } else {
      await this.uploadThroughUploadUrl(uploadUrl, data, file.filename);
    }
  }

  async uploadToUserSpace(file: ModernFile<LocalPath>, userId: string) {
    const path = this.generateUserSpaceStoragePath(userId, file.filename);

    let data: Blob;

    try {
      data = await FileAllocator.resolveLocalPathToBlob(file.path);
    } catch (e) {
      throw new BlobResolveError();
    }

    await this.uploadThroughStoragePath(path, data, file.filename);
    return path;
  }
}
