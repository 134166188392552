import { Text, View } from "react-native";

import { RoomAvatar } from "@kraaft/shared/components/roomCard/roomAvatar";
import {
  getRoomCardType,
  isRoomPrivate,
  useHomeDateFormat,
} from "@kraaft/shared/components/roomCard/roomCardUtils";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { Icon } from "@kraaft/ui";

import { styles } from "./simpleRoomCard.styles";

interface Props {
  roomCard: AnyRoomCard;
  withNavigationIndicator?: boolean;
}

const SimpleRoomCard = ({ roomCard, withNavigationIndicator }: Props) => {
  const isPrivate = isRoomPrivate(roomCard);
  const showExternalIndicator = Guard.useInPool(
    roomCard.poolId,
    "Room.seeExternalIndicator",
  );
  const roomCardType = getRoomCardType(roomCard, showExternalIndicator);
  const dateTime = useHomeDateFormat(
    roomCard.lastMessageInfo?.createdAt ?? roomCard.roomCreatedAt,
  );

  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.avatarContainer}>
          <RoomAvatar
            roomCardType={roomCardType}
            withPrivateIndicator={isPrivate}
            size="medium"
            withNavigationIndicator={withNavigationIndicator}
          />
        </View>
        <Text style={styles.title} numberOfLines={1} ellipsizeMode="tail">
          {roomCard.title}
        </Text>
        {roomCard.pinned && (
          <Icon name="pin-02" size="SMALL" color="FONT_LOW_EMPHASIS" />
        )}
      </View>
      <View style={styles.dateContainer}>
        <Text style={styles.date} numberOfLines={1}>
          {dateTime}
        </Text>
      </View>
    </View>
  );
};

export { SimpleRoomCard };
