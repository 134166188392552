import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { CompactActionGroup } from "@kraaft/shared/components/compactActionGroup/compactActionGroup";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { EditMembersPanel } from "@kraaft/shared/components/conversationDetails/editMembersPanel";
import { MembersPanel } from "@kraaft/shared/components/conversationDetails/membersPanel";
import { useRoomMembers } from "@kraaft/shared/components/conversationDetails/membersSection/useRoomMembers";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { InviteRoomMemberFlow } from "@kraaft/shared/components/memberList/inviteRoomMemberFlow";
import { Members } from "@kraaft/shared/components/members/members";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { Room, RoomType } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useBooleanState } from "@kraaft/shared/core/utils/useBooleanState";
import { Button, Spacing } from "@kraaft/ui";

const FIRST_MEMBERS_DISPLAY_COUNT = 10;

type MembersSectionProps = {
  room: Room;
  portalHostname?: string;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  onPanelClose: () => void;
};

const MembersSection = ({
  room,
  portalHostname,
  openedPanel,
  setOpenedPanel,
  onPanelClose,
}: MembersSectionProps) => {
  const { t } = useTranslation();

  const [previousOpenedPanel, setPreviousOpenedPanel] = useState<
    ConversationDetailsPanel | undefined
  >();

  const [isInviteRoomMemberOpen, openInviteRoomMember, closeInviteRoomMember] =
    useBooleanState();

  const members = useRoomMembers({ roomId: room.id });

  const firstMembers = useMemo(
    () => members.slice(0, FIRST_MEMBERS_DISPLAY_COUNT),
    [members],
  );

  const canInviteMembers = Guard.use("Room.inviteMembers");
  const canEditMembers = Guard.use("Room.editMembers");

  const openEditMembers = useCallback(
    (source: "room_info_tab" | "members_header") => {
      trackEvent({
        eventName: "View Room Edit Members",
        room_id: room.id,
        room_name: getExistingRoomRecordField(
          room.record.properties,
          "title",
          "",
        ),
        source,
      });

      setPreviousOpenedPanel(openedPanel);
      setOpenedPanel(ConversationDetailsPanel.editMembers);
    },
    [openedPanel, setOpenedPanel, setPreviousOpenedPanel, room],
  );

  const handleEditMembersPress = useCallback(
    () => openEditMembers("room_info_tab"),
    [openEditMembers],
  );

  const handleMembersHeaderEditMembersPress = useCallback(
    () => openEditMembers("members_header"),
    [openEditMembers],
  );

  const handleMembersPress = useCallback(() => {
    trackEvent({
      eventName: "View Room Members",
      room_id: room.id,
      room_name: getExistingRoomRecordField(
        room.record.properties,
        "title",
        "",
      ),
      source: "room_info_tab",
    });

    setOpenedPanel(ConversationDetailsPanel.members);
  }, [setOpenedPanel, room]);

  const handleMembersPanelClose = useCallback(() => {
    onPanelClose();
  }, [onPanelClose]);

  const handleEditMembersPanelClose = useCallback(() => {
    if (!previousOpenedPanel) {
      return onPanelClose();
    }

    setOpenedPanel(previousOpenedPanel);
    setPreviousOpenedPanel(undefined);
  }, [
    setOpenedPanel,
    previousOpenedPanel,
    setPreviousOpenedPanel,
    onPanelClose,
  ]);

  const isEveryoneRoom = room.type === RoomType.EVERYONE;
  const hasMembers = members.length > 0;

  return (
    <>
      <DetailsSection>
        <SectionHeader
          titleElementId="conversation-infos-members-title"
          title={`${t("members")} ( ${members.length} )`}
        />

        <View style={styles.sectionContent}>
          {!isEveryoneRoom && (canEditMembers || canInviteMembers) ? (
            <CompactActionGroup>
              {canEditMembers ? (
                <Button
                  testID="ide2e-open-select-room-members"
                  variant="QUATERNARY"
                  size="SMALL"
                  alignContent="left"
                  accessibilityLabel={t("menuMembers")}
                  text={t("menuMembers")}
                  icon="edit-02"
                  onPress={handleEditMembersPress}
                />
              ) : null}
              {canInviteMembers ? (
                <Button
                  variant="QUATERNARY"
                  size="SMALL"
                  alignContent="left"
                  accessibilityLabel={t("inviteNewMember")}
                  text={t("inviteNewMember")}
                  icon="user-plus-01"
                  onPress={openInviteRoomMember}
                />
              ) : null}
            </CompactActionGroup>
          ) : null}

          {hasMembers && (
            <>
              <Members items={firstMembers} isScrollDisabled />
              {members.length > FIRST_MEMBERS_DISPLAY_COUNT && (
                <Button
                  onPress={handleMembersPress}
                  variant="QUATERNARY"
                  size="SMALL"
                  alignContent="left"
                  accessibilityLabel={t("seeAll")}
                  text={t("seeAll")}
                />
              )}
            </>
          )}
        </View>
      </DetailsSection>

      <MembersPanel
        roomId={room.id}
        portalHostname={portalHostname}
        isEditEnabled={!isEveryoneRoom && canEditMembers}
        isVisible={[openedPanel, previousOpenedPanel].includes(
          ConversationDetailsPanel.members,
        )}
        onClose={handleMembersPanelClose}
        onEdit={handleMembersHeaderEditMembersPress}
      />

      {!isEveryoneRoom && (
        <>
          <EditMembersPanel
            room={room}
            portalHostname={portalHostname}
            isVisible={openedPanel === ConversationDetailsPanel.editMembers}
            onClose={handleEditMembersPanelClose}
          />
          <InviteRoomMemberFlow
            open={isInviteRoomMemberOpen}
            onClose={closeInviteRoomMember}
            roomId={room.id}
            source="room"
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  sectionContent: {
    gap: Spacing.S8,
  },
});

export { MembersSection };
