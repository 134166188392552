import { fromPairs, keyBy, toInteger } from "lodash";
import { Dictionary, SafeDictionary } from "ts-essentials";

import { compactMap } from "@kraaft/helper-functions";
import { StorageImagePreview } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import {
  MessageReaction,
  MessageReactions,
} from "@kraaft/shared/core/modules/message/core/user.message";
import {
  DocumentData,
  QueryDocumentSnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";

import { AnyMessage, Messages } from "../../modules/message/core/any.message";
import * as Types from "./firestoreTypes";
import { normalizeAttachment } from "./normalizeAttachment";
import { parseDate } from "./parseDate";
import { prepareDownloadUrl } from "./prepareDownloadUrl";

function normalizeReaction(reaction: Types.MessageReaction): MessageReaction {
  return {
    emoji: reaction.emoji,
    reactedAt: parseDate(reaction.reactedAt),
  };
}

function normalizeReactions(
  reactions: Types.FirestoreUserMessage["reactions"],
): MessageReactions {
  if (!reactions) {
    return {};
  }
  return fromPairs(
    Object.entries(reactions).map(([key, value]) => [
      key,
      normalizeReaction(value),
    ]),
  );
}

function normalizeImagePreview(
  firestorePreview: Types.FirestoreStorageImagePreview,
): StorageImagePreview {
  return {
    size: {
      width: toInteger(firestorePreview.size?.width),
      height: toInteger(firestorePreview.size?.height),
    },
    downloadUrl: prepareDownloadUrl(firestorePreview.file.downloadUrl),
    filename: firestorePreview.file.filename,
  };
}

function normalizePreviews(
  previews: SafeDictionary<
    Types.FirestoreStorageImagePreview,
    "original" | "medium"
  >,
) {
  const images: Dictionary<StorageImagePreview> = {};

  for (const preview of Object.values(previews)) {
    if (preview !== undefined && preview.format !== "small") {
      images[preview.format] = normalizeImagePreview(preview);
    }
  }

  return images;
}

// eslint-disable-next-line complexity
export function normalizeMessage(
  id: string,
  data: Types.AnyFirestoreMessage,
  userId: string | undefined,
) {
  let message: AnyMessage | undefined;

  const createdAt = parseDate(data.createdAt);
  const baseMessage = {
    id: id,
    roomId: data.roomId,
    senderId: data.senderId,
    createdAt: createdAt,
    updatedAt: parseDate(data.updatedAt),
  };

  if (data.type === "log") {
    switch (data.event.type) {
      case "folderCompleted":
      case "folderUnchecked":
      case "folderCreated":
      case "internalMemberJoinedPool":
      case "workflowMessage":
        message = {
          ...baseMessage,
          type: "log",
          event: data.event,
          deletedBy: data.deletedBy,
          deletedAt: data.deletedAt && parseDate(data.deletedAt),
        };
        break;
    }
  } else {
    const baseUserMessage = {
      ...baseMessage,
      optimisticId: data.optimisticId,
      answerTo: data.answerTo,
      forwarded: data.forwarded,
      deletedBy: data.deletedBy,
      deletedAt: data.deletedAt && parseDate(data.deletedAt),
      modularObjects: data.modularObjects,
      sendingStatus: "persisted" as const,
      isReply: !(data.senderId === userId),
      reactions: normalizeReactions(data.reactions),
    };

    if (data.type === "image") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "image",
        attachment: normalizeAttachment(attachment),
      } as Messages.Image;
    } else if (data.type === "audio") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "audio",
        attachment: normalizeAttachment(attachment),
      } as Messages.Audio;
    } else if (data.type === "video") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "video",
        attachment: normalizeAttachment(attachment),
      } as Messages.Video;
    } else if (data.type === "document") {
      const { attachment } = data;

      message = {
        ...baseUserMessage,
        type: "document",
        attachment: normalizeAttachment(attachment),
      } as Messages.Document;
    } else if (data.type === "text") {
      message = {
        ...baseUserMessage,
        type: "text",
        text: data.text,
        textModifiedAt: data.textModifiedAt && parseDate(data.textModifiedAt),
      };
    } else if (data.type === "geolocation") {
      message = {
        ...baseUserMessage,
        type: "geolocation",
        geolocation: data.geolocation,
        thumbnail: normalizeImagePreview(data.thumbnail),
      };
    }
  }

  // ignore unknown messages

  return message;
}

export function normalizeMessages(
  docs: QueryDocumentSnapshot<DocumentData>[],
  userId: string | undefined,
): Record<string, AnyMessage> {
  const messages: AnyMessage[] = compactMap(docs, (doc) => {
    const message = normalizeMessage(
      doc.id,
      doc.data() as Types.AnyFirestoreMessage,
      userId,
    );
    return message;
  });

  return keyBy(messages, (message) => message.id);
}
