import { EditOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const setTagsOperation = EditOneOperationBuilder.create<LibrarySchema>()
  .payload<{ tagIds: Array<string> }>()
  .expected((aggregate, payload) => {
    aggregate.tagIds = payload.tagIds;
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.setLibrarySchemaTags({
      librarySchemaId: payload.id,
      tagIds: payload.tagIds,
    });

    return { updatedAt };
  });
