import { memo } from "react";
import { useSelector } from "react-redux";

import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { OfflineSchemaSelectors } from "@kraaft/shared/core/modules/schema/schema.offline";
import { EditPoolSchema } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchema";
import { EditPoolSchemaMetadata } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchemaMetadata";
import { EditPoolSchemaReportTemplates } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/editPoolSchemaReportTemplates";
import { SchemaBuilderHeader } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderHeader";
import { PoolSchemaBuilderTabs } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderTabs";

import { useCountAffectedBySchemaId } from "../hooks/useCountAffectedBySchemaId";

import { useStyles } from "./poolSchemaBuilder.styles";

interface PoolSchemaBuilderProps {
  schema: KSchema;
  tab: PoolSchemaBuilderTabs;
  hidePreview?: boolean;
}

const PoolSchemaBuilder_ = ({
  schema,
  tab,
  hidePreview,
}: PoolSchemaBuilderProps) => {
  const styles = useStyles();
  const editedSchema = useSelector(OfflineSchemaSelectors.select(schema.id));

  const recordsCount = useCountAffectedBySchemaId(
    schema.poolId,
    schema.id,
    schema.collection,
  );

  if (!editedSchema || !schema) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SchemaBuilderHeader schema={schema} />
      <div className={styles.content}>
        {tab === PoolSchemaBuilderTabs.schema && (
          <EditPoolSchema
            hidePreview={hidePreview}
            schema={editedSchema}
            recordsCount={recordsCount}
          />
        )}
        {tab === PoolSchemaBuilderTabs.reports && (
          <EditPoolSchemaReportTemplates poolSchema={schema} />
        )}
        {tab === PoolSchemaBuilderTabs.info && (
          <EditPoolSchemaMetadata schema={editedSchema} />
        )}
      </div>
    </div>
  );
};

export const PoolSchemaBuilder = memo(
  PoolSchemaBuilder_,
) as typeof PoolSchemaBuilder_;
