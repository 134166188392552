/**
 * Ensures a function can only be called once
 * This helps through hot reloads or weird mobile states
 *
 * @example
 * const logOnce = guardFromExecutingMoreThanOnce(() => console.log("hello"));
 *
 * logOnce(); <-- logs
 * logOnce(); <-- does not log
 */
export function guardFromExecutingMoreThanOnce<T>(fn: () => T) {
  let executed = false;
  return () => {
    if (executed) {
      return;
    }
    executed = true;
    fn();
  };
}
