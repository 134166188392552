import { call, put } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { setLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* importRooms(
  action: ReturnType<typeof roomActions.importRooms>,
) {
  const { payload, meta } = action;

  try {
    yield* call(Api.importRoomFromRecords, {
      poolId: payload.poolId,
      rooms: payload.rooms.map((room) => ({
        record: KSchemaConversion.toRawProperties(room.record.properties),
        members: room.memberIds,
      })),
    });
    yield* put(setLoader({ ...meta.loader, status: LoaderStatus.SUCCESS }));
    yield* put(showSuccess({ title: i18n.t("importRoomsSuccess") }));
  } catch (e) {
    yield* put(
      setLoader({ ...meta.loader, status: LoaderStatus.FAILURE, error: e }),
    );
    yield* put(
      showError({ title: i18n.t("importRoomsFailure", { error: e.message }) }),
    );
    console.error(e);
  }
}
