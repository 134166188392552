import { cloneDeep } from "lodash";

import { CLONE_DEEP_VERBOSE } from "@kraaft/shared/constants/global";

export function traceCloneDeep<T>(objectType: string, toClone: T): T {
  if (CLONE_DEEP_VERBOSE) {
    console.log(`traceCloneDeep: ${objectType}`);
  }
  return cloneDeep(toClone);
}
