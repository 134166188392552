import { DeclareOfflineFeature } from "@kraaft/offline";

import { taskManagerUtils } from "../../store/offline/taskManagerUtils.provider";
import { assignRole } from "./operations/assignRole.operation";
import { unassignRole } from "./operations/unassignRole.operation";
import { PoolMemberPreview } from "./poolMember.types";

export const PoolMemberOfflineFeature =
  DeclareOfflineFeature<PoolMemberPreview>("PoolMember")(
    {
      assignRole,
      unassignRole,
    },
    taskManagerUtils.create("PoolMember"),
  );
