import { spawn } from "typed-redux-saga/macro";

import { appSagas } from "@kraaft/shared/core/modules/app";
import { carouselSagas } from "@kraaft/shared/core/modules/carousel/carousel.sagas";
import { datadogSagas } from "@kraaft/shared/core/modules/datadog/datadog.sagas";
import { directorySagas } from "@kraaft/shared/core/modules/directory";
import { dummySaga } from "@kraaft/shared/core/modules/dummy/dummy.saga";
import { featureFlagSagas } from "@kraaft/shared/core/modules/featureFlags";
import { fileBucketSagas } from "@kraaft/shared/core/modules/fileBucket";
import { filterSagas } from "@kraaft/shared/core/modules/filter";
import { intercomSaga } from "@kraaft/shared/core/modules/intercom/redux/intercom.saga";
import { mapOverlaySagas } from "@kraaft/shared/core/modules/mapOverlay";
import { mediaSagas } from "@kraaft/shared/core/modules/media";
import { memorySagas } from "@kraaft/shared/core/modules/memory";
import { messageSagas } from "@kraaft/shared/core/modules/message";
import { messageDataSagas } from "@kraaft/shared/core/modules/message/messageData/messageData.sagas";
import { offlineMessageSagas } from "@kraaft/shared/core/modules/message/offline";
import { miniMediaSagas } from "@kraaft/shared/core/modules/miniMedia";
import { modularFolderSagas } from "@kraaft/shared/core/modules/modularFolder";
import { modularFolderHistorySagas } from "@kraaft/shared/core/modules/modularFolderHistory/modularFolderHistory.sagas";
import { networkSaga } from "@kraaft/shared/core/modules/network/redux/network.saga";
import { notificationsSaga } from "@kraaft/shared/core/modules/notifications/notifications.sagas";
import { permissionRequestStrategySagas } from "@kraaft/shared/core/modules/permissionRequestStrategy/permissionRequestStrategy.sagas";
import { poolSagas } from "@kraaft/shared/core/modules/pool";
import { poolMemberSagas } from "@kraaft/shared/core/modules/poolMember";
import { reportTemplateSagas } from "@kraaft/shared/core/modules/reportTemplate";
import { roomSagas } from "@kraaft/shared/core/modules/room";
import { roomCardSaga } from "@kraaft/shared/core/modules/roomCard/roomCard.saga";
import { roomSchemaVisibilitySagas } from "@kraaft/shared/core/modules/roomSchemaVisibility";
import { schemaSagas } from "@kraaft/shared/core/modules/schema";
import { schemaTemplateSaga } from "@kraaft/shared/core/modules/schemaTemplate";
import { userSagas } from "@kraaft/shared/core/modules/user";
import { userContactInfoSaga } from "@kraaft/shared/core/modules/userContactInfos";
import { userPoolSagas } from "@kraaft/shared/core/modules/userPool";
import { offlineBundleSagas } from "@kraaft/shared/core/store/offline/offlineBundle.saga";
import { npsSurveySagas } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.sagas";

export function* commonSagas() {
  yield* spawn(appSagas);
  yield* spawn(roomSagas);
  yield* spawn(userSagas);
  yield* spawn(poolMemberSagas);
  yield* spawn(reportTemplateSagas);
  yield* spawn(poolSagas);
  yield* spawn(messageSagas);
  yield* spawn(offlineMessageSagas);
  yield* spawn(memorySagas);
  yield* spawn(schemaSagas);
  yield* spawn(modularFolderSagas);
  yield* spawn(schemaTemplateSaga);
  yield* spawn(directorySagas);
  yield* spawn(miniMediaSagas);
  yield* spawn(mediaSagas);
  yield* spawn(filterSagas);
  yield* spawn(userPoolSagas);
  yield* spawn(roomSchemaVisibilitySagas);
  yield* spawn(fileBucketSagas);
  yield* spawn(featureFlagSagas);
  yield* spawn(messageDataSagas);
  yield* spawn(modularFolderHistorySagas);
  yield* spawn(mapOverlaySagas);
  yield* spawn(notificationsSaga);
  yield* spawn(userContactInfoSaga);
  yield* spawn(datadogSagas);
  yield* spawn(networkSaga);
  yield* spawn(intercomSaga);
  yield* spawn(dummySaga);
  yield* spawn(permissionRequestStrategySagas);
  yield* spawn(offlineBundleSagas);
  yield* spawn(carouselSagas);
  yield* spawn(roomCardSaga);
  yield* spawn(npsSurveySagas);
}
