import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useInfoMenuItems(
  roomId: string,
  message: AnyUserMessage,
  onPress: () => void,
) {
  const { t } = useTranslation();

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (MessageHelper.isPersisted(message) && !MessageHelper.isReply(message)) {
      items.push({
        label: t("infos"),
        onPress: () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "information",
          });

          onPress();
        },
        icon: "info-circle",
      });
    }
    return items;
  }, [message, onPress, roomId, t]);
}
