import { createSelector } from "@reduxjs/toolkit";
import { memoize, pickBy } from "lodash";
import { Dictionary } from "ts-essentials";

import { Guards } from "@kraaft/shared/core/modules/auth/guards";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  getUserPoolRole,
  isAtLeastPoolAdmin,
  isAtLeastPoolStandard,
  isPoolOwner,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

import { selectActor } from "../auth/actor.selector";

export const selectCurrentUserId = ({ user }: RootState) => user.userAuth?.uid;

export const selectCurrentUserAuth = ({ user }: RootState) => user.userAuth;

export const selectAuthState = ({ user }: RootState) => user.authState;

export const selectCurrentUser = ({ user }: RootState) => user.currentUser;

export const selectOnboardingFetched = ({ user }: RootState) =>
  user.onboarding?.fetched;

export const selectOnboardingState = ({ user }: RootState) =>
  user.onboarding?.state;

export const selectMayShowHomeEmptyState = ({ user }: RootState) =>
  user.mayShowHomeEmptyState;

export const selectCurrentUserIsSuperadmin = ({ user }: RootState) =>
  user.currentUser?.superRole === "administrator";

export const selectUsers = ({ user }: RootState) => user.users;

const selectPoolUsers = ({ user }: RootState) => user.poolUsers;

export const selectBlockedUserIds = ({ user }: RootState) =>
  user.blockedUserIds;

const emptyDict: Dictionary<User> = {};

/**
 * for external users, only return the list of internal users + actor
 * for internal users, return the list of internal + external users
 */
export const selectAllPoolUsers = memoize(
  (poolId: string | undefined, ignoreDemoUsers = false) =>
    createSelector(
      selectPoolUsers,
      selectActor,
      (users, actor): Dictionary<User> => {
        if (!poolId || !actor) {
          return emptyDict;
        }

        const poolUsers = users[poolId];

        const [canListExternals] = Guards.PoolMembers.listExternalPoolMembers(
          actor,
          poolId,
        );

        const filteredPoolUsers = pickBy(poolUsers, (poolUser, userId) => {
          if (poolUser.isClonedFromDemo && ignoreDemoUsers) {
            return false;
          }

          if (userId === actor.userId) {
            return true;
          }

          if (
            !canListExternals &&
            poolUser.pools[poolId]?.role === UserPoolRole.EXTERNAL
          ) {
            return false;
          }

          return true;
        });

        return filteredPoolUsers;
      },
    ),
  lodashKeyResolver,
);

export const selectInternalPoolUsers = memoize(
  (poolId: string | undefined, ignoreDemoUsers = false) =>
    createSelector(selectAllPoolUsers(poolId, ignoreDemoUsers), (poolUsers) => {
      if (!poolId) {
        return emptyDict;
      }

      const internalPoolUsers = pickBy(poolUsers, (poolUser) =>
        isAtLeastPoolStandard(poolUser.pools[poolId]?.role),
      );

      return internalPoolUsers;
    }),
  lodashKeyResolver,
);

export function selectUser(userId: string | undefined) {
  return ({ user }: RootState) =>
    userId !== undefined ? user.users[userId] : undefined;
}

export function selectUsername(userId: string | undefined) {
  return ({ user }: RootState): string =>
    getUsername(userId !== undefined ? user.users[userId] : undefined);
}

export const selectCurrentUserPools = ({ user }: RootState) =>
  user.currentUser?.pools;

export function* waitForCurrentUserPools() {
  yield waitFor((state: RootState) => {
    const currentUser = selectCurrentUser(state);

    const userPools = currentUser?.pools;
    if (userPools === undefined) {
      return false;
    }

    return Object.keys(userPools).length > 0;
  });
}

export const selectUserRole = memoize(
  (userId: string | undefined, poolId: string | undefined) =>
    createSelector(selectUser(userId), (user): UserPoolRole => {
      return getUserPoolRole(user, poolId);
    }),
  lodashKeyResolver,
);

export const selectCurrentUserRole = memoize((poolId: string | undefined) =>
  createSelector(
    selectCurrentUser,
    (currentUser): UserPoolRole => getUserPoolRole(currentUser, poolId),
  ),
);

// Select Role-based permissions

const selectCurrentContextAtLeastStandard = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return isAtLeastPoolStandard(getUserPoolRole(currentUser, currentPoolId));
  },
);

export const selectCurrentContextAtLeastAdmin = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return isAtLeastPoolAdmin(getUserPoolRole(currentUser, currentPoolId));
  },
);

const selectCurrentContextOwner = createSelector(
  selectCurrentUser,
  selectCurrentPoolId,
  (currentUser, currentPoolId): boolean => {
    return getUserPoolRole(currentUser, currentPoolId) === UserPoolRole.OWNER;
  },
);

export const selectCurrentContextAtLeastStandardOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextAtLeastStandard,
  (isSuperadmin, isAtLeastStandard): boolean => {
    return isSuperadmin || isAtLeastStandard;
  },
);

export const selectCurrentContextAtLeastAdminOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextAtLeastAdmin,
  (isSuperadmin, isAtLeastAdmin): boolean => {
    return isSuperadmin || isAtLeastAdmin;
  },
);

export const selectCurrentContextOwnerOrSuperadmin = createSelector(
  selectCurrentUserIsSuperadmin,
  selectCurrentContextOwner,
  (isSuperadmin, isOwner): boolean => {
    return isSuperadmin || isOwner;
  },
);

// Current User : using current user and given pool

export const selectCurrentUserAtLeastStandard = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isAtLeastPoolStandard(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAtLeastAdmin = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isAtLeastPoolAdmin(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAccountOwner = memoize(
  (poolId: string | undefined) =>
    createSelector(selectCurrentUser, (currentUser): boolean => {
      return isPoolOwner(getUserPoolRole(currentUser, poolId));
    }),
);

export const selectCurrentUserAtLeastStandardOrSuperadmin = memoize(
  (poolId: string | undefined) =>
    createSelector(
      selectCurrentUserIsSuperadmin,
      selectCurrentUser,
      (isSuperadmin, currentUser): boolean => {
        const isAtLeastStandard = isAtLeastPoolStandard(
          getUserPoolRole(currentUser, poolId),
        );
        return isSuperadmin || isAtLeastStandard;
      },
    ),
);

export const selectCurrentUserAtLeastAdminOrSuperadmin = memoize(
  (poolId: string | undefined) =>
    createSelector(
      selectCurrentUserIsSuperadmin,
      selectCurrentUser,
      (isSuperadmin, currentUser): boolean => {
        const isAtLeastAdmin = isAtLeastPoolAdmin(
          getUserPoolRole(currentUser, poolId),
        );
        return isSuperadmin || isAtLeastAdmin;
      },
    ),
);
