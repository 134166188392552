import { useCallback, useEffect, useState } from "react";

import { Listeners } from "@kraaft/helper-functions";
import { KeyValueStorage } from "@kraaft/shared/core/modules/storage/storage";

import { registeredLogger } from "../logger";
import { Task } from "./task";
import { TaskManager } from "./taskManager";
import { TaskStore } from "./taskStore";
import { TaskExecutionStopReason, TaskFailStrategy } from "./taskStore.types";

export class TaskManagerUtils {
  constructor(
    private readonly storage: KeyValueStorage,
    private readonly handleTaskError: (error: Error) => TaskFailStrategy,
  ) {}

  static logger = registeredLogger.current.createSubLogger([
    TaskManagerUtils.name,
  ]);

  public onTaskAppended = new Listeners<() => void>();

  private readonly taskManagers: Array<TaskManager> = [];

  private getNamespaceStorageKey(namespace: string) {
    return `offline-namespace-${namespace}`;
  }

  getCreatedTaskManagers() {
    return this.taskManagers;
  }

  executeAll() {
    this.taskManagers.map((taskManager) =>
      taskManager.execute().catch(console.error),
    );
  }

  create(name: string) {
    const namespaceStorageKey = this.getNamespaceStorageKey(name);
    const storeForName = new TaskStore(this.storage, namespaceStorageKey);

    const taskManager = new TaskManager(
      name,
      storeForName,
      this.handleTaskError,
    );

    taskManager.onTaskAdded.register(() => this.onTaskAppended.trigger());

    this.taskManagers.push(taskManager);

    return taskManager;
  }
}

interface TaskManagerDump {
  namespaces: Record<
    string,
    { tasks: Task[]; lastStopReason: TaskExecutionStopReason }
  >;
}

export function useDumpNamespacedTaskManager(
  taskManagerUtils: TaskManagerUtils,
) {
  const [dump, setDump] = useState<TaskManagerDump>({ namespaces: {} });

  const update = useCallback(async () => {
    const newDump: TaskManagerDump = { namespaces: {} };
    for (const taskManager of taskManagerUtils.getCreatedTaskManagers()) {
      const tasks = await taskManager.getQueue();
      const lastStopReason = await taskManager.getLastStopReason();
      newDump.namespaces[taskManager.name] = { tasks, lastStopReason };
    }
    setDump(newDump);
  }, [taskManagerUtils]);

  useEffect(() => {
    const subscriptions: Array<() => void> = [];

    for (const taskManager of taskManagerUtils.getCreatedTaskManagers()) {
      subscriptions.push(taskManager.taskStore.onUpdate.register(update));
    }

    return () => {
      for (const subscription of subscriptions) {
        subscription();
      }
    };
  }, [taskManagerUtils, update]);

  return dump;
}
