import { assert } from "ts-essentials";

import { EditOneOperationBuilder } from "@kraaft/offline";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Api } from "@kraaft/shared/core/services/api";

export const setHighlightedCheckboxOperation =
  EditOneOperationBuilder.create<KSchema>()
    .payload<{ columnKey: string | undefined }>()
    .expected((aggregate, payload) => {
      assert(aggregate.collection === "folder");
      aggregate.highlightedCheckbox = payload.columnKey;
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.setPoolSchemaHighlightedCheckbox({
        schemaId: payload.id,
        columnKey: payload.columnKey,
      });
      return { updatedAt };
    });
