import { EditOperationBuilder } from "@kraaft/offline";
import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";

export const autonumberTitlesOperation =
  EditOperationBuilder.create<ModularFolder>()
    .payload<{
      poolId: string;
      schemaId: string;
      prefix: string;
    }>({
      gatherIds: () => [],
      replaceId: () => {},
    })
    .expected((datas, payload) => {
      for (const modularFolder of Object.values(datas)) {
        if (
          modularFolder.poolId !== payload.poolId ||
          modularFolder.schemaId !== payload.schemaId
        ) {
          continue;
        }
        modularFolder.properties.title.value = `${payload.prefix}${modularFolder.incrementalId}`;
        incrementPendingOperations(modularFolder);
      }
      return datas;
    })
    .mutate((payload, task) =>
      Api.autoRenameModularFolders({
        requestId: task.id,
        poolId: payload.poolId,
        schemaId: payload.schemaId,
        prefix: payload.prefix,
      }),
    )
    .acknowledgeOn((modularFolders, payload, result) => {
      return Object.values(modularFolders).some(
        (modularFolder) =>
          modularFolder.poolId === payload.poolId &&
          modularFolder.schemaId === payload.schemaId &&
          modularFolder.updatedAt.getTime() >= result.updatedAt.getTime(),
      );
    });
