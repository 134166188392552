import type { VirtualElement } from "@floating-ui/react";
import { useEffect } from "react";

import { isNative } from "@kraaft/helper-functions";

export function makeAnchorFromEvent(
  element: HTMLElement | null,
  event: React.MouseEvent | MouseEvent,
): VirtualElement {
  if (!element) {
    return {
      getBoundingClientRect() {
        return {
          bottom: 0,
          height: 0,
          left: 0,
          right: 0,
          top: 0,
          width: 0,
          x: 0,
          y: 0,
        };
      },
    };
  }
  return {
    contextElement: element,
    getBoundingClientRect() {
      return {
        width: 0,
        height: 0,
        x: event.clientX,
        y: event.clientY,
        left: event.clientX,
        right: event.clientX,
        top: event.clientY,
        bottom: event.clientY,
      };
    },
  };
}

export function addContextMenuListener(
  ref: React.RefObject<HTMLElement | unknown>,
  callback: (virtualElement: VirtualElement) => void,
) {
  if (isNative() || !ref?.current) {
    return;
  }

  const abortController = new AbortController();

  const currentRef = ref.current;
  if (currentRef instanceof HTMLElement) {
    currentRef.addEventListener(
      "contextmenu",
      (event) => {
        if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
          // Do nothing, allow default context menu
          return;
        }
        event.preventDefault();
        event.stopPropagation();

        const virtualElement = makeAnchorFromEvent(currentRef, event);
        callback(virtualElement);
      },
      {
        signal: abortController.signal,
      },
    );
  }

  return () => {
    abortController.abort();
  };
}

export function useContextMenuListener(
  ref: React.RefObject<HTMLElement | unknown> | null,
  callback: (virtualElement: VirtualElement) => void,
) {
  useEffect(() => {
    // Here we're using defensive programming because we want to protect from implementation
    // that uses `useRef(null)` which returns any.
    if (isNative() || !ref?.current) {
      return;
    }
    if (!(ref.current instanceof HTMLElement)) {
      console.warn(
        "You cannot use contextMenu of this element, it must be an instance of HTMLElement, found ",
        ref?.current,
      );
      return;
    }

    return addContextMenuListener(ref, callback);
  }, [callback, ref]);
}
