import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import deburr from "lodash/fp/deburr";
import { StrictOmit } from "ts-essentials";

import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Spacing, Text, TextInput, TextInputProps } from "@kraaft/ui";

function prepareValue(value: string) {
  return deburr(value.replaceAll(/\s+/g, " ").trim());
}

interface Props extends StrictOmit<TextInputProps, "onChangeText"> {
  expectedValue: string;
  onValueMatches: (value: boolean) => void;
}

const DeleteConfirmationInput = ({
  onValueMatches,
  expectedValue,
  ...props
}: Props) => {
  const [text, setText] = useState("");

  const { t } = useTranslation();

  const expectedValueWithFallback = expectedValue.trim()
    ? expectedValue
    : t("deleteOption.emptyExpected");

  const onChangeText = useCallback(
    (value: string) => {
      setText(value);
      return onValueMatches(
        prepareValue(expectedValueWithFallback) === prepareValue(value),
      );
    },
    [expectedValueWithFallback, onValueMatches],
  );

  return (
    <View style={styles.container}>
      <Text size="BODY">
        <Trans
          i18nKey="deleteInstruction"
          values={{
            expectedValue: expectedValueWithFallback,
          }}
          components={{
            Bold: <Text weight="bold" displayRepeatingWhitespaces />,
          }}
        />
      </Text>
      <TextInput
        {...props}
        onChangeText={onChangeText}
        value={text}
        placeholder={expectedValueWithFallback}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Spacing.S12,
  },
});

export { DeleteConfirmationInput };
