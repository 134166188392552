import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { SectionSpacer } from "@kraaft/shared/components/conversationDetails/sectionSpacer";
import { HideWhenKeyboard } from "@kraaft/shared/components/hideWhenKeyboard";
import { KeyboardAndHeaderAwareScrollView } from "@kraaft/shared/components/keyboardAware";
import { MapButtonContainer } from "@kraaft/shared/components/mapButton/container";
import { ModularDetailsEditorContext } from "@kraaft/shared/components/modular/details/editors/types";
import { useModularDetails } from "@kraaft/shared/components/modular/details/useModularDetails";
import { useModularDetailsAttachmentContext } from "@kraaft/shared/components/modular/details/useModularDetailsAttachmentContext";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { useIsInModularFolderPreview } from "@kraaft/shared/components/modularFolders/modularFolderContext";
import { AutoSaveInfoText } from "@kraaft/shared/components/modularFolders/modularFolderDetails/autoSaveInfoText";
import { DoesNotExist } from "@kraaft/shared/components/modularFolders/modularFolderDetails/doesNotExist";
import { ModularFolderDetailsFooter } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/modularFolderDetailsFooter";
import { NavigateToModularFolderHistory } from "@kraaft/shared/components/modularFolders/modularFolderDetails/navigateToModularFolderHistory";
import { useModularFolderStatus } from "@kraaft/shared/components/modularFolders/modularFolderDetails/useModularFolderStatus";
import { useModularFolderUpdate } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { PromotionCard } from "@kraaft/shared/components/promotionCard";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  selectModularFolderProperties,
  selectModularFolderTitle,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { SchemaLockLookup } from "@kraaft/shared/core/modules/schema/lockInfo.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumnValue,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useDefaultModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useDefaultModularColumnContext";
import {
  selectSchemaHasGeolocationColumn,
  selectSchemaLockLookup,
  selectSchemaName,
  selectSchemaOrderedColumns,
  selectSchemaRootSection,
} from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { MapContext } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { Preloader, Spacing } from "@kraaft/ui";

import { styles } from "./modularFolderDetails.styles";

interface Props {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  portalHostname?: string;
}

const ModularFolderDetails_ = ({
  roomId,
  schemaId,
  modularFolderId,
  portalHostname,
}: Props) => {
  const {
    fetched,
    schemaNotFound,
    isDeleted,
    considerNoConnection,
    schemaExists,
    modularFolderExists,
  } = useModularFolderStatus(roomId, schemaId, modularFolderId);

  if (
    schemaNotFound ||
    (!fetched && (isDeleted || considerNoConnection)) ||
    (fetched && !modularFolderExists)
  ) {
    return <DoesNotExist roomId={roomId} />;
  }

  if (!modularFolderExists || !schemaExists) {
    return <Preloader absoluteFill transparent />;
  }

  return (
    <ModularDisplayRequirementsProvider>
      <ModularDisplayExtendedRequirementsProvider
        recordType="modularFolder"
        noCheckboxConfirmation={false}
      >
        <ModularFolderDetailsContent
          roomId={roomId}
          schemaId={schemaId}
          modularFolderId={modularFolderId}
          portalHostname={portalHostname}
        />
      </ModularDisplayExtendedRequirementsProvider>
    </ModularDisplayRequirementsProvider>
  );
};

interface ModularFolderDetailsContentProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
  portalHostname?: string;
}

const ModularFolderDetailsContent_ = ({
  roomId,
  modularFolderId,
  schemaId,
  portalHostname,
  lockLookup,
  rootSection,
}: ModularFolderDetailsContentProps & {
  lockLookup: SchemaLockLookup;
  rootSection: KSchemaSection;
}) => {
  const dispatch = useDispatch();

  const schemaName = useSelector(selectSchemaName(schemaId));
  const schemaHasGeolocationColumn = useSelector(
    selectSchemaHasGeolocationColumn(schemaId),
  );
  const modularFolderTitle = useSelector(
    selectModularFolderTitle(modularFolderId),
  );

  const isRoomExternal = useSelector(RoomSelectors.selectHasExternals(roomId));

  const updateModularFolder = useModularFolderUpdate(modularFolderId);

  useTrackPage("ModularFolderDetails", { identifier: modularFolderId });

  const updateRecord = useCallback(
    (update: ModularTableValueUpdate) => {
      updateModularFolder([
        [
          update.column.key,
          {
            columnType: update.column.type,
            value: update.value,
          } as KSchemaColumnValue,
        ],
      ]);
    },
    [updateModularFolder],
  );

  const updateRecordSignature = useCallback(
    (key: string, file: ModernFile<LocalPath> | undefined) => {
      if (file) {
        dispatch(
          OfflineModularFolderActions.addSignature({
            id: modularFolderId,
            columnKey: key,
            file,
          }),
        );
      } else {
        updateModularFolder([
          [
            key,
            {
              columnType: KColumnType.signature,
              value: undefined,
            },
          ],
        ]);
      }
    },
    [dispatch, modularFolderId, updateModularFolder],
  );

  const {
    attachmentContext,
    renderAttachImageToFolderModal,
    renderAttachDocumentToFolderModal,
  } = useModularDetailsAttachmentContext(roomId, schemaId, modularFolderId);

  const getModularFolderTracking = useCallbackRealtime(
    ([_schemaId, _schemaName, _modularFolderTitle, _isRoomExternal]) => {
      return {
        schemaId: _schemaId,
        schemaName: _schemaName ?? "",
        folderTitle: _modularFolderTitle,
        isRoomExternal: _isRoomExternal,
      };
    },
    [],
    [schemaId, schemaName, modularFolderTitle, isRoomExternal],
  );

  const editorContext: ModularDetailsEditorContext = useMemo(
    () => ({
      portalHostname,
      roomId,
      getModularFolderTracking,
    }),
    [getModularFolderTracking, portalHostname, roomId],
  );

  const hasMapButton = schemaHasGeolocationColumn;

  const mapContext = useMemo<MapContext>(
    () => ({
      location: "ModularFolderDetails",
      folderId: modularFolderId,
      roomId,
      schemaId,
    }),
    [modularFolderId, roomId, schemaId],
  );

  const schemaOrderedColumns = useSelector(
    selectSchemaOrderedColumns(schemaId),
  );
  const defaultColumnsContext = useDefaultModularColumnContext(
    lockLookup,
    schemaOrderedColumns,
  );

  const modularFolderProperties = useSelector(
    selectModularFolderProperties(modularFolderId),
  );

  const {
    ModularDetailsContextProvider,
    modularDetailsElements,
    scrollViewProps,
    topElement,
  } = useModularDetails({
    id: modularFolderId,
    rootSection,
    testId: "ide2e-modularFolder",
    recordProperties: modularFolderProperties ?? {},
    updateRecord,
    updateRecordSignature,
    editorContext,
    columnsContext: defaultColumnsContext,
    attachmentContext,
    offsetIndices: hasMapButton ? 2 : 1,
  });

  const isInPreview = useIsInModularFolderPreview();

  const scrollView = useMemo(
    () => (
      <KeyboardAndHeaderAwareScrollView
        style={styles.scrollViewContainer}
        extraScrollHeight={Spacing.S8}
        contentContainerStyle={styles.scrollViewContentContainer}
        enableResetScrollToCoords={false}
        {...scrollViewProps}
      >
        {topElement}
        {hasMapButton && (
          <View style={styles.mapButton}>
            <MapButtonContainer
              type="modularFoldersDetails"
              context={mapContext}
            />
          </View>
        )}

        {modularDetailsElements}

        <SectionSpacer />

        <View style={styles.autoSaveContainer}>
          <AutoSaveInfoText />
        </View>

        {!isInPreview && (
          <NavigateToModularFolderHistory
            roomId={roomId}
            schemaId={schemaId}
            modularFolderId={modularFolderId}
          />
        )}

        <PromotionCard
          variant="reports"
          source="modular_folder_details"
          withBorderRadius
          containerStyle={styles.promotionCardContainer}
        />
      </KeyboardAndHeaderAwareScrollView>
    ),
    [
      hasMapButton,
      isInPreview,
      mapContext,
      modularDetailsElements,
      modularFolderId,
      roomId,
      schemaId,
      scrollViewProps,
      topElement,
    ],
  );

  return (
    <>
      <View style={styles.scrollViewWrapper}>
        <ModularDetailsContextProvider>
          {scrollView}
        </ModularDetailsContextProvider>
      </View>
      {renderAttachImageToFolderModal}
      {renderAttachDocumentToFolderModal}
      <HideWhenKeyboard>
        <ModularFolderDetailsFooter
          schemaId={schemaId}
          modularFolderId={modularFolderId}
          portalHostname={portalHostname}
        />
      </HideWhenKeyboard>
    </>
  );
};

const ModularFolderDetailsContent = withDependencyWrapper(
  ModularFolderDetailsContent_,
  ({ schemaId }) => {
    const rootSection = useSelector(selectSchemaRootSection(schemaId));
    const lockLookup = useSelector(selectSchemaLockLookup(schemaId));

    if (!rootSection || !lockLookup) {
      return undefined;
    }

    return { rootSection, lockLookup };
  },
);

export const ModularFolderDetails = React.memo(
  ModularFolderDetails_,
) as typeof ModularFolderDetails_;
