import {
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  AppStateActions,
  updateInviteState,
} from "@kraaft/shared/core/modules/app/appActions";
import { selectInviteState } from "@kraaft/shared/core/modules/app/appSelector";
import {
  InviteState,
  NavigationSwitchValue,
} from "@kraaft/shared/core/modules/app/appState";
import { appSagas as platformSagas } from "@kraaft/shared/core/modules/app/platformSagas";
import { LoginSlice } from "@kraaft/shared/core/modules/login/login.slice";
import { MemoryStateActions } from "@kraaft/shared/core/modules/memory/memoryActions";
import { PoolStateActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  UserActions,
  UserStateActions,
} from "@kraaft/shared/core/modules/user/userActions";
import {
  selectAuthState,
  selectCurrentUser,
  selectCurrentUserAtLeastStandard,
  selectCurrentUserAuth,
  selectCurrentUserId,
  selectOnboardingState,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { analytics } from "@kraaft/shared/core/services/analytics/analytics";
import { Api } from "@kraaft/shared/core/services/api";
import { firebaseLanguageService } from "@kraaft/shared/core/services/firebase/firebaseLanguage";
import { i18n } from "@kraaft/shared/core/services/i18next/i18next";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function* appSagas() {
  yield* takeEvery(
    [
      PoolStateActions.setPools,
      UserActions.userConnectedToFirebase,
      UserActions.userDisconnectedFromFirebase,
      UserStateActions.loggedUserReceived,
      UserStateActions.setCurrentUserOnboardingState,
      UserStateActions.updateCurrentUserOnboardingState,
      updateInviteState,
      PoolStateActions.setPoolLocation,
      MemoryStateActions.setSSOElevationSkipped,
      UserActions.refreshAuth,
    ],
    updateNavigationSwitchSaga,
  );

  yield* takeEvery(
    UserActions.userDisconnectedFromFirebase,
    resetAnalyticsSaga,
  );
  yield* takeEvery(updateInviteState, initialVerifyInvitationLinkSaga); // Will only run once. As we always trigger the verification when navigating to /invites/*, this is okay!
  yield* takeEvery(UserActions.disconnectUserCommand, resetInviteStateSaga);
  yield* takeEvery(UserActions.disconnectUserCommand, trackLogOut);
  yield* takeEvery(UserActions.userConnectedToFirebase, userConnectedSaga);
  yield* takeLatest(LoginSlice.actions.tryLoginHint, lastLoginHintSaga);

  yield* spawn(platformSagas);

  yield firebaseLanguageService.setupAndSubscribe();
}

function* resetAnalyticsSaga() {
  yield* call(() => analytics.resetTrackContext());
}

// eslint-disable-next-line complexity
function* getNavigationSwitch(): Generator<
  unknown,
  NavigationSwitchValue | undefined
> {
  const inviteState = yield* select(selectInviteState);
  const authState = yield* select(selectAuthState);

  if (inviteState.status === "invalid") {
    return "invalid-invitation";
  }

  switch (authState) {
    case "loading":
      return "loading";
    case "signedOut":
      return "signed-out";
    case "signedIn": {
      const currentUser = yield* select(selectCurrentUser);
      if (currentUser === undefined) {
        // wait in current step for currentUser
        return undefined;
      }

      const onboardingState = yield* select(selectOnboardingState);
      if (onboardingState === undefined) {
        // wait in current step for onboardingStep
        return undefined;
      }

      if (
        currentUser.username === undefined ||
        currentUser.username.length === 0
      ) {
        return "need-username";
      }

      if (inviteState.status === "valid") {
        // waiting in current step for the invitation status to be joined
        return undefined;
      }
      if (inviteState.status === "joined") {
        return "invite-congrats";
      }

      const isWaitingPools = currentUser.pools === undefined;
      if (isWaitingPools) {
        return "loading";
      }

      const currentPool = yield* select(selectCurrentPool);
      const userHasPools = Boolean(
        currentUser.pools && Object.keys(currentUser.pools).length > 0,
      );

      if (!userHasPools) {
        return "signed-in-no-workspace";
      }

      if (!currentPool) {
        // wait in current step for currentPoolId
        return undefined;
      }

      if (onboardingState?.shouldShowPoolOnboarding) {
        if (!currentPool.companyInfos.industry) {
          return "onboarding-need-company-industry";
        }
      }

      const isAtLeastStandard = yield* select(
        selectCurrentUserAtLeastStandard(currentPool.id),
      );

      if (!currentUser.job && isAtLeastStandard) {
        return "onboarding-need-job";
      }

      if (onboardingState?.shouldShowPoolOnboarding) {
        if (!currentPool.companyInfos.size) {
          return "onboarding-need-company-size";
        }
      }

      const userAuth = yield* select(selectCurrentUserAuth);

      if (currentPool.creatorId === userAuth?.uid && !userAuth?.email) {
        return "onboarding-need-email";
      }

      if (onboardingState?.shouldShowPoolOnboarding) {
        if (!currentPool.acquisitionInfos.source) {
          return "onboarding-need-acquisition-source";
        }
      }

      if (onboardingState?.shouldShowPoolOnboarding) {
        return "onboarding-invite-to-pool";
      }

      return "ok";
    }
  }
}

function* updateNavigationSwitchSaga() {
  const navigationSwitch = yield* getNavigationSwitch();

  if (navigationSwitch) {
    yield* put(AppStateActions.setNavigationSwitch(navigationSwitch));
  }
}

function* initialVerifyInvitationLinkSaga(
  action: ReturnType<typeof updateInviteState>,
) {
  const { status, key } = action.payload;

  if (status !== "pending_check" || !key) {
    return;
  }

  try {
    const data = yield* call(Api.checkInviteLink, { key });

    yield waitFor((state: RootState) => state.user.authState !== "loading");

    if (data.status === "valid") {
      yield* put(UserActions.disconnectUserCommand());
      if (data.type === "pool") {
        yield* put(
          updateInviteState({
            status: data.status,
            type: data.type,
            poolId: data.poolId,
            poolName: data.poolName,
            key,
            identityProvider: data.identityProvider,
          }),
        );
      } else if (data.type === "room") {
        const inviteState: InviteState = {
          status: data.status,
          type: data.type,
          poolId: data.poolId,
          roomId: data.roomId,
          roomName: data.roomName,
          key,
        };
        yield* put(updateInviteState(inviteState));
      }
    } else {
      yield* put(updateInviteState({ status: "invalid" }));
    }
  } catch (e) {
    console.log("error validating invite", e);
    yield* put(showError({ title: i18n.t("serverError") }));
    yield* put(updateInviteState({ status: "unknown" }));
  }
}

function* resetInviteStateSaga() {
  yield* put(updateInviteState({ status: "unknown" }));
}

function* userConnectedSaga() {
  const inviteState = yield* select(selectInviteState);
  const { status, key } = inviteState;
  if (status === "valid" && key) {
    yield waitFor((state: RootState) => selectCurrentUser(state)?.username);

    const currentUserId = yield* select(selectCurrentUserId);
    if (currentUserId) {
      try {
        yield* call(Api.joinInviteLink, { key, userId: currentUserId });
        yield* put(updateInviteState({ ...inviteState, status: "joined" }));
      } catch (e) {
        console.error("error in joinInviteLink", e);
        yield* put(
          showError({ title: i18n.t("internalError"), message: e.message }),
        );
        yield* put(updateInviteState({ status: "unknown" }));
      }
    }
  }
}

function* trackLogOut() {
  yield* call(() => trackEvent({ eventName: "User Sign Out Success" }));
}

function* lastLoginHintSaga({
  payload,
}: ReturnType<(typeof LoginSlice)["actions"]["tryLoginHint"]>) {
  yield* take(UserActions.userConnectedToFirebase);
  yield* put(LoginSlice.actions.saveLastCredentialHint(payload));
}
