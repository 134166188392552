import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { TFunction } from "@kraaft/shared/core/services/i18next";
import { AppDispatch } from "@kraaft/shared/core/store";
import { ImageProxyBuilder } from "@kraaft/ui";

export function useImageDownload(message: AnyUserMessage | null) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useCallback(async () => {
    if (message === null || !MessageHelper.isImage(message)) {
      // No error raised since message is not meant to be downloaded
      return;
    }
    await downloadImage(message.attachment, dispatch, t);
  }, [dispatch, message, t]);
}

export async function downloadImage(
  attachment: ImageAttachment,
  dispatch: AppDispatch,
  t: TFunction,
) {
  const { downloadUrl: originalDownloadUrl, filename } = attachment.original;

  if (originalDownloadUrl) {
    const downloadUrl = fileAllocator.parseRemotePath(
      ImageProxyBuilder.fromUrl(originalDownloadUrl)
        .setGeolocation(attachment.geolocation?.coords)
        .buildUrl(),
    );

    try {
      await fileSaver.download(downloadUrl, filename || "image");
    } catch (e) {
      console.error(e);
      dispatch(showError({ title: t("couldNotDownloadImage") }));
    }
  }
}
