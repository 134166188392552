/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "fast-deep-equal";

import { Checkbox } from "@kraaft/shared/components/checkbox";
import { useIsInConversationPreview } from "@kraaft/shared/components/conversation/conversationContext";
import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { MenuMessageContainer } from "@kraaft/shared/components/message/menuMessageContainer";
import { MessageAttentionSeeker } from "@kraaft/shared/components/message/messageAttentionSeeker";
import { MessageAudio } from "@kraaft/shared/components/message/messageAudio";
import { MessageDeleted } from "@kraaft/shared/components/message/messageDeleted";
import { MessageDocument } from "@kraaft/shared/components/message/messageDocument";
import { MessageGeolocation } from "@kraaft/shared/components/message/messageGeolocation";
import { MessageImage } from "@kraaft/shared/components/message/messageImage";
import { MessageLog } from "@kraaft/shared/components/message/messageLog";
import { MessageSender } from "@kraaft/shared/components/message/messageSender/messageSender";
import { MessageText } from "@kraaft/shared/components/message/messageText";
import { MessageVideo } from "@kraaft/shared/components/message/messageVideo";
import { ReplyModuleIcon } from "@kraaft/shared/components/message/moduleIcon";
import { ReactionsContainer } from "@kraaft/shared/components/message/reactions/reactionsContainer";
import { RetryButton } from "@kraaft/shared/components/message/retryButton/retryButton";
import { SendingIndicator } from "@kraaft/shared/components/message/sendingIndicator";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { toggleSelectMessages } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import {
  Module,
  selectIsLastCurrentUserSentMessage,
  selectMessageModules,
  selectMessageSelectionType,
} from "@kraaft/shared/core/modules/message/messageSelectors";
import { retrySendingOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { retrySendingMessage } from "@kraaft/shared/core/modules/message/send/sendMessageActions";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { IconSize, Text } from "@kraaft/ui";

import { MessageReceiptStatus } from "./messageReceiptStatus";
import * as utils from "./messageUtils";

import { styles } from "./message.styles";

export type MessageProps = {
  message: AnyMessage;
  roomId: string;
  sizerWidth: number;
  enableSelect?: boolean;
  selected?: boolean;
  messageRepliedId?: AnyMessage | null;
  onPress?: () => void;
  onLongPress?: (message: AnyMessage) => void;
};

const Message_ = ({
  message,
  messageRepliedId,
  roomId,
  enableSelect,
  selected,
  sizerWidth,
  onLongPress,
}: MessageProps) => {
  const { t } = useTranslation();

  const navigationService = useNavigationService();
  const isInConversationPreview = useIsInConversationPreview();

  const dispatch = useDispatch();

  const poolId = useSelector(selectCurrentPoolId);

  const isLastCurrentUserSentMessage = useSelector(
    selectIsLastCurrentUserSentMessage(roomId, message.id),
  );

  const messageModules = useSelector(selectMessageModules(message));

  const selectionType = useSelector(
    selectMessageSelectionType(roomId, "conversation"),
  );

  const answerId =
    MessageHelper.isUserMessage(message) && message.answerTo !== undefined
      ? message.answerTo
      : "";
  const answeredMessage = useSelector(
    selectMessageInRoom(roomId, answerId ?? ""),
  );
  const answeredUserMessage =
    answeredMessage !== undefined &&
    MessageHelper.isUserMessage(answeredMessage)
      ? answeredMessage
      : undefined;

  const isReply = "isReply" in message && message.isReply === true;
  const messageContentStyle = isReply
    ? styles.messageContentReply
    : styles.messageContentDefault;

  const footerStyle = useMemo(
    () => [isReply ? styles.footerReply : styles.footerSelf],
    [isReply],
  );

  const bodyStyle = useMemo(
    () => [
      styles.messageBody,
      isReply ? styles.messageBodyReply : styles.messageBodyDefault,
    ],
    [isReply],
  );

  const handleRetrySend = useCallback(() => {
    dispatch(retrySendingMessage({ roomId: roomId, optimisticId: message.id }));
    dispatch(retrySendingOfflineMessage(message.id));
  }, [dispatch, message.id, roomId]);

  const handleMessageSelect = useCallback(() => {
    dispatch(
      toggleSelectMessages({
        roomId,
        selectionType: selectionType,
        selectionSource: "conversation",
        messageIds: [message.id],
        selectState: !selected,
      }),
    );
  }, [dispatch, message.id, selectionType, roomId, selected]);

  const renderMessageSelector = useCallback(() => {
    const canMessageBeSelected = utils.canMessageBeSelected(
      message,
      selectionType,
    );

    if (!canMessageBeSelected) {
      return null;
    }

    return (
      <View style={styles.selectButtonContainer}>
        <Checkbox
          checked={selected}
          onPress={handleMessageSelect}
          size={IconSize.LARGE}
        />
      </View>
    );
  }, [handleMessageSelect, message, selectionType, selected]);

  const navigateToModularFolder = useCallback(
    ({ schemaId, moduleId }: Module) => {
      if (moduleId !== undefined) {
        return navigationService.navigate("ModularFolderDetails", {
          roomId,
          schemaId,
          folderId: moduleId,
        });
      }
    },
    [navigationService, roomId],
  );

  const renderModules = useCallback(() => {
    if (isInConversationPreview || messageModules.length === 0) {
      return;
    }

    return (
      <View style={styles.modulesContainer}>
        {messageModules.map((module) => {
          if (!module) {
            return;
          }
          const canNavigate = module.moduleId !== undefined;

          return (
            <TouchableOpacity
              accessibilityLabel={t("openFolder")}
              key={module.schemaId}
              disabled={!canNavigate}
              onPress={() => navigateToModularFolder(module)}
            >
              <ReplyModuleIcon
                type="right"
                isReply={isReply}
                iconName={module.icon}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, [
    t,
    isInConversationPreview,
    isReply,
    messageModules,
    navigateToModularFolder,
  ]);

  const renderMessageContent = useCallback(
    (hoverableProps: HoverableRenderProps, style?: ViewStyle) => {
      const { isHovered, hoverProps } = hoverableProps;

      const canMenuBeShown =
        utils.isMessageSelectable(message) &&
        !enableSelect &&
        !isInConversationPreview;

      if (MessageHelper.isLog(message)) {
        return (
          <View style={styles.messageContentContainer}>
            <MessageLog roomId={roomId} message={message} />
          </View>
        );
      }

      const isSending = message.sendingStatus === "sending";
      const needRetry = message.sendingStatus === "error";

      const showReceiptMention =
        message.deletedAt === undefined &&
        isLastCurrentUserSentMessage &&
        !isSending &&
        !needRetry;

      return (
        <MessageAttentionSeeker messageId={message.id}>
          <View {...hoverProps} style={[styles.messageContentContainer, style]}>
            {isReply && <MessageSender poolId={poolId} message={message} />}
            <View style={messageContentStyle}>
              {isReply && messageRepliedId?.id === message.id && (
                <ReplyModuleIcon
                  isReply={isReply}
                  type="reply"
                  iconName="corner-down-left"
                />
              )}
              <View style={bodyStyle}>
                <MenuMessageContainer
                  roomId={roomId}
                  message={message}
                  showMenu={canMenuBeShown && isHovered}
                  isReply={isReply}
                >
                  {renderModules()}
                  {MessageHelper.isDeleted(message) ? (
                    <MessageDeleted message={message} />
                  ) : MessageHelper.isAudio(message) ? (
                    <MessageAudio
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      sizerWidth={sizerWidth}
                      onLongPress={onLongPress}
                    />
                  ) : MessageHelper.isDocument(message) ? (
                    <MessageDocument
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      sizerWidth={sizerWidth}
                      onLongPress={onLongPress}
                    />
                  ) : MessageHelper.isImage(message) ? (
                    <MessageImage
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      sizerWidth={sizerWidth}
                      onLongPress={onLongPress}
                    />
                  ) : MessageHelper.isVideo(message) ? (
                    <MessageVideo
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      onLongPress={onLongPress}
                      sizerWidth={sizerWidth}
                    />
                  ) : MessageHelper.isGeolocation(message) ? (
                    <MessageGeolocation
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      sizerWidth={sizerWidth}
                      onLongPress={onLongPress}
                    />
                  ) : (
                    <MessageText
                      roomId={roomId}
                      message={message}
                      answer={answeredUserMessage}
                      sizerWidth={sizerWidth}
                      onLongPress={onLongPress}
                    />
                  )}
                </MenuMessageContainer>
                <ReactionsContainer roomId={roomId} message={message} />

                <View style={footerStyle}>
                  {showReceiptMention && (
                    <MessageReceiptStatus roomId={roomId} message={message} />
                  )}

                  {isSending ? (
                    <SendingIndicator nativeId="message-sending-indicator" />
                  ) : needRetry ? (
                    <RetryButton handleRetry={handleRetrySend} />
                  ) : (
                    <Text style={styles.date}>
                      {utils.formatDate(message.createdAt)}
                    </Text>
                  )}
                </View>
                {!isReply && messageRepliedId?.id === message.id && (
                  <ReplyModuleIcon
                    isReply={isReply}
                    type="reply"
                    iconName="corner-down-left"
                  />
                )}
              </View>
            </View>
          </View>
        </MessageAttentionSeeker>
      );
    },
    [
      message,
      enableSelect,
      isInConversationPreview,
      isLastCurrentUserSentMessage,
      isReply,
      poolId,
      messageContentStyle,
      messageRepliedId?.id,
      bodyStyle,
      roomId,
      renderModules,
      answeredUserMessage,
      sizerWidth,
      onLongPress,
      footerStyle,
      handleRetrySend,
    ],
  );

  // Apply Conditional style based on the current state
  // [ ] Message Hovered
  // [ ] Message Selected
  // [ ] Message Not Selectable

  const getMessageSelectionContainerStyle = useCallback(
    (isHovered: boolean, canMessageBeSelected: boolean) => {
      return canMessageBeSelected
        ? [selected ? styles.selected : isHovered ? styles.hovered : null]
        : {};
    },
    [selected],
  );

  const renderMessageSelection = useCallback(
    (hoverableProps: HoverableRenderProps) => {
      const { hoverProps, isHovered } = hoverableProps;

      const canMessageBeSelected = utils.canMessageBeSelected(
        message,
        selectionType,
      );

      return (
        <TouchableWithoutFeedback
          accessibilityLabel={selected ? t("unselect") : t("select")}
          disabled={!canMessageBeSelected}
          onPress={handleMessageSelect}
        >
          <View
            {...hoverProps}
            style={[
              styles.messageSelectionContainer,
              getMessageSelectionContainerStyle(
                isHovered,
                canMessageBeSelected,
              ),
            ]}
            pointerEvents="box-only"
          >
            {enableSelect === true && renderMessageSelector()}
            <View style={styles.messageSelectionContentContainer}>
              {renderMessageContent(
                hoverableProps,
                styles.messageSelectionContent,
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      );
    },
    [
      enableSelect,
      getMessageSelectionContainerStyle,
      handleMessageSelect,
      message,
      renderMessageContent,
      renderMessageSelector,
      selectionType,
      selected,
      t,
    ],
  );

  const renderHoverableContent = useCallback(
    (hoverableProps: HoverableRenderProps) => {
      if (enableSelect) {
        return renderMessageSelection(hoverableProps);
      }
      return renderMessageContent(hoverableProps);
    },
    [enableSelect, renderMessageContent, renderMessageSelection],
  );

  return <Hoverable>{renderHoverableContent}</Hoverable>;
};

export const Message = memo(Message_, isEqual) as typeof Message_;
