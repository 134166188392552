import { EditOneOperationBuilder } from "@kraaft/offline";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { optimisticSchemaRenameColumn } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";

export const renameColumnOperation = EditOneOperationBuilder.create<KSchema>()
  .payload<{ columnKey: string; name: string }>()
  .expected((aggregate, payload) => {
    optimisticSchemaRenameColumn(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editSchemaColumn({
      schemaId: payload.id,
      columnKey: payload.columnKey,
      column: {
        name: payload.name,
      },
    });
    return { updatedAt };
  });
