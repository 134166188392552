import { chunk } from "lodash";

import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import {
  AnsweringUserMessage,
  MessageHelper,
} from "@kraaft/shared/core/modules/message/core/message.helper";
import { MessageQueries } from "@kraaft/shared/core/modules/message/message.queries";
import { selectMessageDataForRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { getStore, RootState } from "@kraaft/shared/core/store";
import { trackSinceAppStarted } from "@kraaft/shared/core/utils/datadog/useTrackAppSinceAppStarted";

export async function fetchAnsweredMessages(
  userId: string,
  roomId: string,
  messages: Record<string, AnyUserMessage>,
  fromCache: boolean,
) {
  const state = getStore().getState() as RootState;
  if (!state) {
    return {};
  }

  const storedMessages = selectMessageDataForRoom(roomId)(state);
  const allAnsweredIds = Object.values(messages)
    .filter(
      (message): message is AnsweringUserMessage =>
        MessageHelper.isAnswering(message) && !storedMessages[message.answerTo],
    )
    .map((message) => message.answerTo);

  if (allAnsweredIds.length === 0) {
    trackSinceAppStarted("performances_first_message_fetch");
    return {};
  }
  const missingMessagesPromises = chunk(allAnsweredIds, 10).map((chk) =>
    MessageQueries.getUnknownMessages(chk, roomId, userId, fromCache),
  );
  const missingMessages = await Promise.all(missingMessagesPromises);
  const mergedMessages: Record<string, AnyMessage> = {};
  for (const message of missingMessages) {
    Object.assign(mergedMessages, message);
  }
  trackSinceAppStarted("performances_first_message_fetch");
  return mergedMessages;
}
