/**
 *
 * On low-end Android devices `shouldPolyfillRelativeTimeFormat` is extremely slow.
 * <i>(7 seconds on my device)</i>
 * This is because the <b>@formatJs</b> implementation does not only check if the Intl API exist.
 * It also resolves the local to find the best available alternative.
 * [source](https://github.com/formatjs/formatjs/blob/2a35a9b54de259f40fe56d8081d18d226b53bc54/packages/intl-relativetimeformat/should-polyfill.ts#L28)
 *
 *
 * We're supposed to use it this
 * ```typescript
 *  const unsupportedLocale = shouldPolyfill(locale)
 *  if (!unsupportedLocale) {
 *     return
 *  }
 *  await import('@formatjs/intl-relativetimeformat/polyfill-force')
 *  await import(`@formatjs/intl-relativetimeformat/locale-data/${unsupportedLocale}`)
 * ```
 * [source](https://formatjs.github.io/docs/polyfills/intl-relativetimeformat)
 *
 * But that's not how we use it at <b>Kraaft</b> since we have a restrained list of possible local.
 * So the resolve part is useless for and can be bypassed.
 */
export function shouldPolyfillRelativeTimeFormat(locale = "en"): boolean {
  return (
    !("RelativeTimeFormat" in Intl) ||
    !supportedLocalesOf(locale) ||
    !hasResolvedOptionsNumberingSystem(locale)
  );
}

function supportedLocalesOf(locale?: string | string[]) {
  if (!locale) {
    return true;
  }
  const locales = Array.isArray(locale) ? locale : [locale];
  return (
    Intl.RelativeTimeFormat.supportedLocalesOf(locales).length ===
    locales.length
  );
}

function hasResolvedOptionsNumberingSystem(locale?: string | string[]) {
  try {
    return (
      "numberingSystem" in
      new Intl.RelativeTimeFormat(locale || "en", {
        numeric: "auto",
      }).resolvedOptions()
    );
  } catch (_) {
    return false;
  }
}
