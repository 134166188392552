import { useRef } from "react";
import withScrolling, { createVerticalStrength } from "react-dnd-scrolling";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { ColorValue, Icon, Text } from "@kraaft/ui";
import { MAX_AUTHORIZED_ELEMENT_COUNT } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/editSchema.const";
import { ElementsList } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsList";
import {
  SchemaBuilderContext,
  useSchemaBuilderRootSection,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementsEditor.styles";

interface ElementCreationBase {
  placement: HoverState;
  inSection?: string;
}

export interface SectionCreation extends ElementCreationBase {
  type: "section";
  section: KSchemaSection;
}

export interface ColumnCreation extends ElementCreationBase {
  type: "column";
  column: KSchemaColumn;
}

export type ElementCreation = SectionCreation | ColumnCreation;

interface ColumnsEditorProps {
  availableColumnTypes: KColumnType[];
  columnsContext: ModularColumnsContext;
}

const linearVerticalStrength = createVerticalStrength(200);
const Scrolling = withScrolling("div");

function getCounterRatioColor(columnCount: number): ColorValue {
  if (columnCount >= MAX_AUTHORIZED_ELEMENT_COUNT) {
    return "WHITE";
  }

  if (columnCount >= 250) {
    return "RED_TOMATO";
  }

  if (columnCount >= 150) {
    return "ORANGE_BASIC";
  }

  return "GREEN_LAGOON";
}

function getCounterTitleColor(
  hasReachedQuestionThreshold: boolean,
): ColorValue {
  return hasReachedQuestionThreshold ? "WHITE" : "FONT_HIGH_EMPHASIS";
}

const ElementsEditor = ({
  availableColumnTypes,
  columnsContext,
}: ColumnsEditorProps) => {
  const styles = useStyles();
  const rootSection = useSchemaBuilderRootSection();

  const openElementsCountWarningSheet = useMeshContext(
    SchemaBuilderContext,
    (context) => context.openElementsCountWarningSheet,
  );

  const { t } = useTranslation();

  const columnListRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<HTMLDivElement>(null);

  const columnCount = KSchemaUtils.getVisibleColumnCount(rootSection);
  const hasReachedQuestionThreshold =
    columnCount >= MAX_AUTHORIZED_ELEMENT_COUNT;

  return (
    <>
      <Scrolling
        verticalStrength={linearVerticalStrength}
        className={styles.root}
      >
        <ElementsList
          columnListRef={columnListRef}
          availableColumnTypes={availableColumnTypes}
          columnsContext={columnsContext}
        />
      </Scrolling>

      <div
        ref={counterRef}
        onClick={openElementsCountWarningSheet}
        className={clsx({
          [styles.counter]: true,
          [styles.counterWithThresholdWarning]: hasReachedQuestionThreshold,
        })}
      >
        <Icon
          name="hash-02"
          size="MINI"
          color={getCounterTitleColor(hasReachedQuestionThreshold)}
        />
        <Text
          size="MEDIUM"
          color={getCounterTitleColor(hasReachedQuestionThreshold)}
        >
          {t("formBuilder.schema.questionCounter")}
        </Text>
        <Text
          size="MEDIUM"
          weight="bold"
          color={getCounterRatioColor(columnCount)}
        >
          {columnCount}/{MAX_AUTHORIZED_ELEMENT_COUNT}
        </Text>
      </div>
    </>
  );
};

export { ElementsEditor };
