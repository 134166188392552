import {
  FetchNetworkError,
  NetworkError,
} from "@kraaft/shared/core/services/firebase/networkError";

export class HttpError extends Error {
  constructor(
    public readonly status: number,
    message: string,
    public readonly errorCode: string | undefined,
    public readonly details: unknown,
  ) {
    super(message);
  }

  static isHttpErrorWithCode(error: Error, code: string): boolean {
    return error instanceof HttpError && error.errorCode === code;
  }

  static isRequestAlreadyMade(error: Error) {
    if (error instanceof HttpError) {
      if (error.errorCode === "DUPLICATE_REQUEST") {
        return true;
      }
    }
    return false;
  }

  static isNetworkError(error: Error): error is NetworkError {
    return error instanceof NetworkError;
  }

  static isFetchNetworkError(error: Error): error is FetchNetworkError {
    return error instanceof FetchNetworkError;
  }
}
