import { put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import {
  MessageActions,
  MessageStateActions,
} from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { getMessageInputPartitions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* editMessageTextSaga() {
  yield* takeEvery(MessageActions.editMessageTextRequest, editMessageText);
}

function* editMessageText(
  action: ReturnType<typeof MessageActions.editMessageTextRequest>,
) {
  const { payload } = action;
  const { roomId, messageId, text, messageType } = payload;

  const originMessage = yield* select(selectMessageInRoom(roomId, messageId));
  const originText =
    originMessage !== undefined &&
    MessageHelper.isUserMessage(originMessage) &&
    MessageHelper.hasTextCapability(originMessage)
      ? getMessageInputPartitions(originMessage)
      : undefined;

  try {
    yield* put(MessageStateActions.editMessageText(payload));

    if (messageType === "text") {
      yield Api.editMessageText({
        roomId,
        messageId,
        text,
      });
    } else if (messageType === "audio") {
      yield Api.editMessageTranscription({
        roomId,
        messageId,
        transcription: text,
      });
    } else {
      yield Api.editMessageCaption({
        roomId,
        messageId,
        caption: text,
      });
    }
  } catch (err) {
    console.log("Error in editMessage", err);
    yield* put(
      showError({
        title: i18n.t("internalError"),
        message: err.message,
      }),
    );

    // rollback
    if (originText !== undefined) {
      yield* put(
        MessageStateActions.editMessageText({
          ...payload,
          text: originText,
        }),
      );
    }
  }
}
