import { httpsCallable } from "@kraaft/shared/core/services/firebase/firebaseUtils";

export const NpsSurveyApi = {
  createNpsSurveyResponse: async (payload: {
    note: number;
  }): Promise<{ responseId: string }> => {
    const data = (await httpsCallable("createNpsSurveyResponse")(payload)).data;

    return { responseId: data.responseId };
  },
  updateNpsSurveyResponse: async (payload: {
    responseId: string;
    target?: string;
    benefit?: string;
    missingFunctionality?: string;
  }): Promise<void> => {
    await httpsCallable("updateNpsSurveyResponse")(payload);
  },
  getShouldDisplayNpsSurveyForm: async (): Promise<{
    shouldDisplayNpsSurveyForm: boolean;
  }> => {
    const data = (await httpsCallable("shouldDisplayNpsSurveyForm")()).data;

    return { shouldDisplayNpsSurveyForm: data.showForm };
  },
};
