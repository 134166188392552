import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

import {
  RoomSelectors,
  selectRoomMembersCount,
} from "@kraaft/shared/core/modules/room/roomSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { ColorStyle, Icon } from "@kraaft/ui";

import { styles } from "./scrollToBottomButton.styles";

interface Props {
  roomId: string;
  onPress: () => void;
}

const ScrollToBottomButton = ({ roomId, onPress }: Props) => {
  const { t } = useTranslation();

  const isRoomShared = useSelector(RoomSelectors.selectHasExternals(roomId));
  const memberCount = useSelector(selectRoomMembersCount(roomId));

  const handleButtonPress = () => {
    onPress();
    trackEvent({
      eventName: "Click On Go Down To Last Message",
      room_id: roomId,
      is_shared: isRoomShared,
      member_count: memberCount,
    });
  };

  return (
    <TouchableOpacity
      accessibilityLabel={t("seeLastMessage")}
      onPress={handleButtonPress}
      style={styles.scrollToBottomButtonContainer}
    >
      <View style={styles.scrollToBottomButton}>
        <Icon name="chevron-down" color={ColorStyle.ACTION_CTA} size={26} />
      </View>
    </TouchableOpacity>
  );
};

export { ScrollToBottomButton };
