import { EditOneOperationBuilder } from "@kraaft/offline";
import { Api } from "@kraaft/shared/core/services/api";

import { StaticRole } from "../../auth/actor";
import {
  PoolMemberId,
  PoolMemberIdHelper,
  PoolMemberPreview,
} from "../poolMember.types";
import { roleIdsToGrade } from "../poolMemberUtils";

export const assignRole = EditOneOperationBuilder.create<PoolMemberPreview>()
  .payload<{ value: StaticRole }>()
  .expected((aggregate, payload) => {
    if (payload.value === "room") {
      aggregate.roles = aggregate.roles ?? [];
      aggregate.roles.push(payload.value);
    } else if (payload.value === "std") {
      aggregate.roles = [payload.value, "room"];
    } else {
      aggregate.roles = [payload.value];
    }
    aggregate.grade = roleIdsToGrade(aggregate.roles);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { id, value } = payload;
    const { poolId, userId } = PoolMemberIdHelper.split(id as PoolMemberId);
    return Api.assignRole({
      poolId,
      memberId: userId,
      roleId: value,
    });
  });
