import { memoize } from "lodash";

import {
  Capabilities,
  GuardOf,
  GuardParamsOf,
} from "@kraaft/shared/core/modules/auth/guards";
import { getGuard } from "@kraaft/shared/core/modules/auth/hooks";

import { Actor } from "./actor";
import { AllGrants, defaultRolesGrants } from "./roles";
import { access, Path } from "./types";

const keys = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;

const getPermissions = (actor: Actor, poolId: string) => {
  if (actor.claims.support) {
    return defaultRolesGrants.own;
  }

  if (!actor.claims.pools[poolId]) {
    return {
      Room: {},
      Members: {},
      Record: {},
      Schema: {},
    };
  }

  return getRolesPermissions(actor.claims.pools[poolId].roles);
};

const getRolesPermissions = memoize(
  (roles: string[]) => {
    const grants = roles
      .map(
        (role) => defaultRolesGrants[role as keyof typeof defaultRolesGrants],
      )
      .filter((g) => !!g);

    const merged = grants.reduce(
      (acc, g) => {
        for (const section of keys(g)) {
          for (const key of keys(g[section])) {
            if (g[section][key]) {
              acc[section][key] = g[section][key];
            }
          }
        }
        return acc;
      },
      {
        Room: {},
        Members: {},
        Record: {},
        Schema: {},
      } as AllGrants,
    );

    return merged;
  },
  (roles) => `${[...roles].sort().join("|")}`,
);

export const getRoomPermissions = (actor: Actor, poolId: string) => {
  const grants = getPermissions(actor, poolId);
  return grants.Room;
};

export function granted<P extends Path<AllGrants>>(specifier: P): Guard {
  return (actor: Actor, poolId: string) => {
    const grants = getPermissions(actor, poolId);
    const hasGrant = access(grants, specifier);
    return hasGrant ? ([true] as const) : ([false, "unauthorized"] as const);
  };
}

export const and = <Args extends any[]>(
  ...condition: Array<(...args: Args) => GuardOutput>
) => {
  return (...args: Args) => {
    const hasGrant = condition.every((c) => c(...args)[0]);
    return hasGrant ? ([true] as const) : ([false, "unauthorized"] as const);
  };
};

export type GuardOutput = readonly [true] | readonly [false, reason: string];

export type Guard<Args extends any[] = any[]> = (
  actor: Actor,
  poolId: string,
  ...args: Args
) => GuardOutput;

export type GuardParams<G extends Guard> = G extends Guard<infer Args>
  ? Args
  : [];

export function checkGuard<C extends Capabilities>(
  cap: C,
  actor: Actor | undefined,
  poolId: string | undefined,
  ...args: GuardParamsOf<C>
) {
  const guard = getGuard(cap);
  if (!actor || !poolId) {
    return [false, "unauthorized"] as const;
  }

  return guard(actor, poolId, ...args) as ReturnType<GuardOf<C>>;
}
