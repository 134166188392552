import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { setReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useAnswerMenuItems(roomId: string, message: AnyUserMessage) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMemo(() => {
    const items: ActionSheetItem[] = [];
    if (MessageHelper.isPersisted(message)) {
      items.push({
        label: t("answer"),
        onPress: () => {
          trackEvent({
            eventName: "Reply To Message",
            room_id: roomId,
            message_type: message.type,
            source: "action_sheet",
          });

          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "answer",
          });

          dispatch(setReplyingSourceMessage(roomId, message.id));
        },
        icon: "corner-down-left",
      });
    }
    return items;
  }, [message, t, roomId, dispatch]);
}
