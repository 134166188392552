import { keyBy } from "lodash";

import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageQueries } from "@kraaft/shared/core/modules/message/message.queries";
import { buildMessageList } from "@kraaft/shared/core/modules/message/messageUtils";
import {
  DocumentData,
  QueryDocumentSnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";

export class MessageLoader {
  static PAGE_SIZE = 30;
  static FETCH_MORE_IF_CHUNK_SIZE_LESS_THAN = 15;

  static async fetchBefore(
    roomId: string,
    referenceDoc: QueryDocumentSnapshot<DocumentData>,
    pageSize: number,
    currentUserId: string,
  ) {
    let cursorDoc: QueryDocumentSnapshot<DocumentData> | undefined =
      referenceDoc;
    const messages: Record<string, AnyMessage> = {};
    const snapshots: Record<string, QueryDocumentSnapshot<DocumentData>> = {};
    let end = false;

    while (
      cursorDoc &&
      buildMessageList(messages).length <
        MessageLoader.FETCH_MORE_IF_CHUNK_SIZE_LESS_THAN
    ) {
      const { allDocs: newDocs, messages: newMessages } =
        await MessageQueries.fetchMessagesBefore(
          roomId,
          cursorDoc,
          pageSize,
          currentUserId,
        );
      cursorDoc = newDocs[newDocs.length - 1];
      Object.assign(
        snapshots,
        keyBy(newDocs, (doc) => doc.id),
      );
      Object.assign(
        messages,
        keyBy(newMessages, (message) => message.id),
      );
      if (newDocs.length < pageSize) {
        end = true;
        break;
      }
    }
    return {
      messages,
      snapshots,
      end,
    };
  }

  static async fetchAfter(
    roomId: string,
    referenceDoc: QueryDocumentSnapshot<DocumentData>,
    pageSize: number,
    currentUserId: string,
  ) {
    let cursorDoc: QueryDocumentSnapshot<DocumentData> | undefined =
      referenceDoc;
    const messages: Record<string, AnyMessage> = {};
    const snapshots: Record<string, QueryDocumentSnapshot<DocumentData>> = {};
    let end = false;

    while (
      cursorDoc &&
      buildMessageList(messages).length <
        MessageLoader.FETCH_MORE_IF_CHUNK_SIZE_LESS_THAN
    ) {
      const { allDocs: newDocs, messages: newMessages } =
        await MessageQueries.fetchMessageAfter(
          roomId,
          cursorDoc,
          pageSize,
          currentUserId,
        );
      cursorDoc = newDocs[newDocs.length - 1];
      Object.assign(
        snapshots,
        keyBy(newDocs, (doc) => doc.id),
      );
      Object.assign(
        messages,
        keyBy(newMessages, (message) => message.id),
      );
      if (newDocs.length < pageSize) {
        end = true;
        break;
      }
    }
    return {
      messages,
      snapshots,
      end,
    };
  }

  static async fetchRoomFirstMessages(
    roomId: string,
    pageSize: number,
    currentUserId: string,
  ) {
    const { allDocs, messages } = await MessageQueries.fetchRoomFirstMessages(
      roomId,
      pageSize,
      currentUserId,
    );
    let end = allDocs.length !== pageSize;

    const lastDoc = allDocs[allDocs.length - 1];
    const grouped = buildMessageList(messages);

    if (
      lastDoc &&
      grouped.length < MessageLoader.FETCH_MORE_IF_CHUNK_SIZE_LESS_THAN
    ) {
      const {
        messages: newMessages,
        snapshots: newSnapshots,
        end: afterEnd,
      } = await MessageLoader.fetchAfter(
        roomId,
        lastDoc,
        pageSize - grouped.length,
        currentUserId,
      );
      end = afterEnd;
      allDocs.push(...Object.values(newSnapshots));
      Object.assign(messages, newMessages);
    }

    return {
      messages,
      snapshots: keyBy(allDocs, (doc) => doc.id),
      end,
    };
  }

  static async fetchMessagesAround(
    roomId: string,
    id: string,
    pageSize: number,
    currentUserId: string,
  ) {
    const item = await MessageQueries.fetchMessage(id, currentUserId);

    if (!item || !item.message) {
      return undefined;
    }

    const beforePromise = MessageLoader.fetchBefore(
      roomId,
      item.snapshot,
      pageSize,
      currentUserId,
    );
    const afterPromise = MessageLoader.fetchAfter(
      roomId,
      item.snapshot,
      pageSize,
      currentUserId,
    );
    const [before, after] = await Promise.all([beforePromise, afterPromise]);

    return {
      before: before.messages,
      beforeEnd: before.end,
      beforeSnapshots: before.snapshots,

      after: after.messages,
      afterEnd: after.end,
      afterSnapshots: after.snapshots,

      doc: item.snapshot,
      item: item.message,
    };
  }

  static async fetchRoomLastMessages(
    roomId: string,
    pageSize: number,
    currentUserId: string,
  ) {
    const { allDocs, messages } = await MessageQueries.fetchRoomLastMessages(
      roomId,
      pageSize,
      currentUserId,
    );
    let end = allDocs.length !== pageSize;

    const firstDoc = allDocs[allDocs.length - 1];
    const grouped = buildMessageList(messages);

    if (
      firstDoc &&
      grouped.length < MessageLoader.FETCH_MORE_IF_CHUNK_SIZE_LESS_THAN
    ) {
      const {
        messages: newMessages,
        snapshots: newSnapshots,
        end: beforeEnd,
      } = await MessageLoader.fetchBefore(
        roomId,
        firstDoc,
        pageSize - grouped.length,
        currentUserId,
      );
      end = beforeEnd;
      allDocs.push(...Object.values(newSnapshots));
      Object.assign(messages, newMessages);
    }

    return {
      messages,
      snapshots: keyBy(allDocs, (doc) => doc.id),
      end,
    };
  }
}
