import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Dictionary } from "ts-essentials";

import { MessageTypes } from "@kraaft/shared/core/modules/message";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { ReceiptStatus } from "@kraaft/shared/core/modules/message/messageState";
import { RoomMember } from "@kraaft/shared/core/modules/room/roomState";
import { selectRoomMembersAndReadStatus } from "@kraaft/shared/core/modules/room/selectors";
import {
  selectCurrentUserId,
  selectUsers,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

const NAME_LIMIT = 2;

function computedReceiptText(
  t: TFunction,
  receiptStatus: MessageTypes.ReceiptStatus,
  message: AnyUserMessage,
  readers: RoomMember[],
  allUsers: Dictionary<User>,
) {
  switch (receiptStatus) {
    case ReceiptStatus.ALL:
      return t("readByAll");

    case ReceiptStatus.NONE:
      return message.sendingStatus === "sending" ? t("sending") : t("sent");

    default: {
      const otherReaders = readers.filter(
        ({ userId }) => userId !== message.senderId,
      );
      const readersNames = otherReaders.map(({ userId }) =>
        getUsername(allUsers[userId]),
      );
      const maxReaders = readersNames.slice(0, NAME_LIMIT);
      const moreReaders = otherReaders.length - NAME_LIMIT;
      return `${t("readBy")} ${maxReaders.join(", ")} ${
        moreReaders > 0 ? ` +${moreReaders}` : ""
      }`;
    }
  }
}

export function useMessageReceiptStatus({
  roomId,
  message,
}: {
  roomId: string;
  message: AnyUserMessage;
}) {
  const { t } = useTranslation();

  const currentUserId = useSelector(selectCurrentUserId);
  const roomMembers = useSelector(selectRoomMembersAndReadStatus(roomId));
  const users = useSelector(selectUsers);

  const messageCreatedAt = message.createdAt.getTime();

  const { receipt, receiptText } = useMemo(() => {
    const readers = roomMembers.filter(
      (user) =>
        user.lastReadMessageCreatedAt !== undefined &&
        user.lastReadMessageCreatedAt.getTime() >= messageCreatedAt &&
        user.userId !== currentUserId,
    );

    const receipt_ =
      readers.length === 0
        ? ReceiptStatus.NONE
        : readers.length < roomMembers.length - 1
          ? ReceiptStatus.SOME
          : ReceiptStatus.ALL;

    const receiptText_ = computedReceiptText(
      t,
      receipt_,
      message,
      readers,
      users,
    );

    return { receipt: receipt_, receiptText: receiptText_ };
  }, [currentUserId, message, messageCreatedAt, roomMembers, t, users]);

  return { receipt, receiptText };
}
