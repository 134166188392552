import { createEntityAdapter } from "@reduxjs/toolkit";

import { RoomUserHistory } from "../roomState";

export const roomUserHistoryAdapter = createEntityAdapter<
  RoomUserHistory,
  string
>({
  selectId: (history) => history.id,
});
