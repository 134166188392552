import { EditOneOperationBuilder } from "@kraaft/offline";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { fileUpload } from "@kraaft/shared/core/modules/file/fileUploader";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";
import { getStore } from "@kraaft/shared/core/store";

import { setLoader } from "../../../loaders/loaderActions";
import { LoaderStatus } from "../../../loaders/loaderTypes";
import { generateAddReportTemplateLoaderId } from "../../../reportTemplate/reportTemplate.actions.utils";

export const addReportTemplateOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{
      name: string;
      librarySchemaId: string;
      file: ModernFile<LocalPath>;
      forceAdd: boolean;
    }>()
    .creates(() => 1)
    .expected((aggregate, payload) => {
      aggregate.reportTemplates ??= [];
      aggregate.reportTemplates.push({
        id: payload.allocatedIds[0] ?? "temp",
        enabled: false,
        variant: "custom",
        name: payload.name,
        index: aggregate.reportTemplates.length,
        updatedAt: new Date(),
        format: payload.file.filename.endsWith(".docx") ? "docx" : "xlsx",
        file: {
          filename: payload.name,
          downloadUrl: payload.file.path,
        },
      });
      return aggregate;
    })
    .mutate(async (payload) => {
      const { librarySchemaId, name, file, forceAdd } = payload;

      // this is not ideal but it'll do the trick until we have a way to manage loader withing the offline package
      getStore().dispatch(
        setLoader({
          id: generateAddReportTemplateLoaderId(librarySchemaId),
          status: LoaderStatus.LOADING,
        }),
      );

      const storagePathPayload = {
        librarySchemaId,
        filename: file.filename,
      };
      const uploadPath =
        await Api.generateLibrarySchemaReportTemplateUploadPath(
          storagePathPayload,
        );

      await fileUpload.upload({
        file,
        storagePath: uploadPath.storagePath,
        uploadUrl: uploadPath.uploadUrl,
      });

      const { updatedAt, id } = await Api.addLibrarySchemaReportTemplate({
        librarySchemaId,
        name,
        storagePath: uploadPath.storagePath,
        forceAdd,
      });

      getStore().dispatch(
        setLoader({
          id: generateAddReportTemplateLoaderId(librarySchemaId),
          status: LoaderStatus.SUCCESS,
        }),
      );

      return { updatedAt, ids: [id] };
    });
