import { EditOneOperationBuilder } from "@kraaft/offline";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  KSchema,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { optimisticSchemaAddSection } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";

export const addSectionOperation = EditOneOperationBuilder.create<KSchema>()
  .payload<{ section: KSchemaSection; placement: HoverState; index: number }>()
  .expected((aggregate, payload) => {
    optimisticSchemaAddSection(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    try {
      const { updatedAt } = await Api.addSchemaSection({
        schemaId: payload.id,
        index: payload.index,
        name: payload.section.name,
        parentSectionKey: KSchemaUtils.rootSectionKey,
      });
      return { updatedAt };
    } catch (e) {
      if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
        getStore().dispatch(
          showError({
            title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
          }),
        );
      }
      throw e;
    }
  });
