import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { HeaderIconButton } from "@kraaft/shared/components/headerIconButton";
import { NotFullSynchronizedModularFolderSheet } from "@kraaft/shared/core/modules/modularFolder/hooks/notFullSynchronizedModularFolderSheet";
import { selectModularFolderPendingOperations } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ColorStyle } from "@kraaft/ui";

import { useModularFolderContextMenu } from "./useModularFolderContextMenu";

export interface ModularFolderDetailsMenuProps {
  roomId: string;
  schemaId: string;
  modularFolderId: string;
}

const ModularFolderDetailsMenu = (props: ModularFolderDetailsMenuProps) => {
  const { roomId, schemaId, modularFolderId } = props;
  const { t } = useTranslation();

  const menuButtonRef = useRef(null);

  const { element: contextMenuSheet, open: openContextMenuSheet } =
    useModularFolderContextMenu({
      schemaId,
      roomId,
      source: "information_panel",
    });

  const pendingOperationCount = useSelector(
    selectModularFolderPendingOperations(modularFolderId),
  );

  const { open: openNotFullSyncrhonized, element: notFullSynchronizedElement } =
    NotFullSynchronizedModularFolderSheet.use({
      modularFolderId,
      modularFolderPendingOperations: pendingOperationCount,
      titleSynchronized: t("offline.modularFolder.synchronized"),
      descriptionSynchronized: t("offline.modularFolder.synchronizedContent"),
      titleNotSynchronized: t("offline.modularFolder.notSynchronized", {
        count: pendingOperationCount,
      }),
      descriptionNotSynchronized: t("offline.modularFolder.pendingOperation", {
        count: pendingOperationCount,
      }),
      cta: null,
    });

  return (
    <>
      {pendingOperationCount > 0 ? (
        <HeaderIconButton
          accessibilityLabel={t("menu")}
          icon="clock"
          contentColor={ColorStyle.FONT_LOW_EMPHASIS}
          onPress={openNotFullSyncrhonized}
        />
      ) : null}
      <HeaderIconButton
        accessibilityLabel={t("menu")}
        ref={menuButtonRef}
        icon="dots-vertical"
        onPress={() =>
          openContextMenuSheet({ modularFolderId, anchor: menuButtonRef })
        }
      />
      {notFullSynchronizedElement}
      {contextMenuSheet}
    </>
  );
};

export { ModularFolderDetailsMenu };
