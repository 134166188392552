import { Context, type PropsWithChildren, useContext, useEffect } from "react";

import { type MeshContext } from "@kraaft/helper-hooks";

import { DEFAULT_PORTAL_HOSTNAME } from "../portalConstants";
import { PortalManagementContext } from "../portalContext";
import { usePortalKey } from "./usePortalKey";
import { wrapElementInContextProviders } from "./wrapElementInContextProviders";

export interface PortalProps {
  hostname?: string;
  forwardContext?: Array<Context<any>>;
  forwardMeshContext?: Array<MeshContext<any>>;
}

export const Portal = ({
  children,
  hostname = DEFAULT_PORTAL_HOSTNAME,
  forwardContext,
  forwardMeshContext,
}: PropsWithChildren<PortalProps>) => {
  const { addElement, removeElement } = useContext(PortalManagementContext);
  const key = usePortalKey();

  const contextWrapped = wrapElementInContextProviders(
    children,
    forwardContext,
  );
  const meshContextWrapped = wrapElementInContextProviders(
    contextWrapped,
    forwardMeshContext,
  );

  useEffect(() => {
    addElement(meshContextWrapped, key, hostname);
  }, [addElement, hostname, key, meshContextWrapped]);

  useEffect(
    () => () => removeElement(key, hostname),
    [removeElement, hostname, key],
  );

  return null;
};
