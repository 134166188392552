import { createAction } from "@reduxjs/toolkit";

import { LoaderStatus } from "@kraaft/shared/core/modules/loaders/loaderTypes";
import {
  createLoaderMeta,
  getOfflineMessageLoadingId,
} from "@kraaft/shared/core/modules/loaders/loaderUtils";
import { OfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";

export const logOfflineMessageEvent = createAction<{ log: string }>(
  "@offlineMessage/LOG_OFFLINE_MESSAGE_EVENT",
);

export const addOfflineMessage = createAction(
  "@offlineMessage/ADD_OFFLINE_MESSAGE",
  (payload: OfflineMessage) => ({
    payload,
    meta: createLoaderMeta({
      id: getOfflineMessageLoadingId(payload.optimisticId),
      status: LoaderStatus.LOADING,
    }),
  }),
);

export const errorSendingOfflineMessage = createAction<string>(
  "@offlineMessage/ERROR_SENDING_OFFLINE_MESSAGE",
);

export const retrySendingOfflineMessage = createAction<string>(
  "@offlineMessage/RETRY_SENDING_OFFLINE_MESSAGE",
);

export const offlineMessageSendSuccess = createAction(
  "@offlineMessage/OFFLINE_MESSAGE_SEND_SUCCESS",
  (payload: OfflineMessage) => ({
    payload,
    meta: createLoaderMeta({
      id: getOfflineMessageLoadingId(payload.optimisticId),
      status: LoaderStatus.SUCCESS,
    }),
  }),
);

export const offlineMessageSendFailure = createAction(
  "@offlineMessage/OFFLINE_MESSAGE_SEND_FAILURE",
  (payload: OfflineMessage, error: Error) => ({
    payload,
    meta: createLoaderMeta({
      id: getOfflineMessageLoadingId(payload.optimisticId),
      status: LoaderStatus.FAILURE,
      error,
    }),
  }),
);

export const incrementSentCount = createAction(
  "@offlineMessage/INCREMENT_SENT_COUNT",
);

export const stopBackgroundServiceRequest = createAction(
  "@offlineMessage/STOP_BACKGROUND_SERVICE",
);

export const OfflineMessageStateActions = {
  deleteOfflineMessage: createAction<string>(
    "@offlineMessage/state/DELETE_OFFLINE_MESSAGE",
  ),
};

export const OfflineMessageActions = {
  deleteOfflineMessage: createAction<string>(
    "@offlineMessage/DELETE_OFFLINE_MESSAGE",
  ),
};
