import { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { ColorValue, Radius } from "../constants";
import { getColorFromColorValue } from "../utils";

interface CalloutProps {
  backgroundColor: ColorValue;
  borderColor: ColorValue;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}

export const Callout = ({
  backgroundColor,
  borderColor,
  style,
  children,
}: CalloutProps) => {
  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: getColorFromColorValue(backgroundColor),
          borderColor: getColorFromColorValue(borderColor),
        },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    borderRadius: Radius.MEDIUM,
    borderWidth: 1,
  },
});
