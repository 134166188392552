import moment from "moment";

import { orderBy, uniq } from "lodash";
import { call, put, select } from "typed-redux-saga/macro";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { groupSimilarLogs } from "@kraaft/shared/core/modules/message/messageGrouping";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { RootState } from "@kraaft/shared/core/store";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

import { selectUsers } from "../user/userSelectors";
import { AnyMessage } from "./core/any.message";
import { AnyUserMessage } from "./core/any.user.message";
import {
  MessageHelper,
  MessageWithTextCapability,
} from "./core/message.helper";

const EDIT_OR_DELETE_LIMIT_DAYS = 1;

const ONE_MB = 1024 * 1024 * 1024;
const fileSizeLimit = 50 * ONE_MB;
const fileSizeLimitMb = fileSizeLimit / ONE_MB;

interface CheckFileSize {
  isBelowMaxSize: boolean;
  message?: string;
}

export function checkFileSize(file: ModernFile<LocalPath>): CheckFileSize {
  if (file.size && file.size > fileSizeLimit) {
    const message =
      file.contentType === "video"
        ? i18n.t("fileServiceVideoTooBigBody", {
            size: fileSizeLimitMb,
          })
        : i18n.t("fileServiceFileTooBigBody", {
            filename: file.filename,
            size: fileSizeLimitMb,
          });

    return { isBelowMaxSize: false, message };
  }

  return { isBelowMaxSize: true };
}

export function* findAndFetchUnknownUsers(userIds: string[]) {
  yield* call(waitFor, ({ user }: RootState) => !user.isLoadingPoolUsers);
  const users = yield* select(selectUsers);
  const invalidIds = ["", "0", "superadmin"];
  const unknownUserIds = uniq(userIds).filter(
    (id) => users[id] === undefined && !invalidIds.includes(id),
  );
  for (const userId of unknownUserIds) {
    yield* put(UserActions.loadUser(userId));
  }
}

// Conditionals

export function isMessageAgeOkToBeEdited(message: MessageWithTextCapability) {
  if (
    moment().isAfter(
      moment(message.createdAt).add(EDIT_OR_DELETE_LIMIT_DAYS, "days"),
    )
  ) {
    return false;
  }

  return true;
}

export const canShowRemoveMessage = (
  message: AnyMessage,
  isSuperadmin: boolean,
  isAccountOwner: boolean,
) => {
  if (!MessageHelper.isUserMessage(message)) {
    return false;
  }

  return !MessageHelper.isReply(message) || isSuperadmin || isAccountOwner;
};

export function openMessageEditingAgeLimitationAlert() {
  AlertDialog.alert(
    i18n.t("messageCannotBeEditedAlertTitle"),
    i18n.t("messageCannotBeEditedAlertContent"),
    [
      {
        text: i18n.t("cancel"),
        style: "cancel",
      },
    ],
  );
}

export function canRemoveMessage(
  message: AnyMessage,
  isSuperadmin: boolean,
  isAccountOwner: boolean,
) {
  if (!canShowRemoveMessage(message, isSuperadmin, isAccountOwner)) {
    return false;
  }

  const can =
    isSuperadmin ||
    isAccountOwner ||
    !moment().isAfter(
      moment(message.createdAt).add(EDIT_OR_DELETE_LIMIT_DAYS, "days"),
    );

  if (!can) {
    AlertDialog.alert(
      i18n.t("messageCannotBeRemovedAlertTitle"),
      i18n.t("messageCannotBeRemovedAlertContent"),
      [
        {
          text: i18n.t("cancel"),
          style: "cancel",
        },
      ],
    );
  }

  return can;
}

export function isMessageAwaitingSend(message: AnyMessage) {
  return (
    MessageHelper.isUserMessage(message) &&
    ["sending", "error"].includes(message.sendingStatus)
  );
}

export const orderMessages = <T extends AnyMessage>(
  messages: T[],
  reverseOrder = false,
) => {
  return orderBy(
    messages,
    [
      (message) => (isMessageAwaitingSend(message) ? 0 : 1),
      (message) => message.createdAt,
      (message) => message.id,
    ],
    reverseOrder ? ["desc", "asc", "asc"] : ["asc", "desc", "desc"],
  );
};

export function messageHasReactions(msg: AnyUserMessage) {
  return Object.keys(msg.reactions).length > 0;
}

export function getMessageInputPartitions(message: MessageWithTextCapability) {
  switch (message.type) {
    case "text":
      return message.text;
    case "image":
    case "video":
      return message.attachment.caption;
    case "audio":
      return message.attachment.transcription;
  }
}

export function setMessageInputPartitions(
  message: MessageWithTextCapability,
  text: InputPartition[],
) {
  switch (message.type) {
    case "text":
      message.text = text;
      break;
    case "image":
    case "video":
      message.attachment.caption = text;
      break;
    case "audio":
      message.attachment.transcription = text;
      break;
  }
}

export function getIdFromMessage(message: AnyMessage) {
  return message.id;
}

export function getKeyFromMessage(message: AnyMessage) {
  return MessageHelper.isUserMessage(message)
    ? message.optimisticId || message.id
    : message.id;
}

export function sortMessagesFn(a: AnyMessage, b: AnyMessage) {
  const timeDiff = a.createdAt.getTime() - b.createdAt.getTime();
  if (timeDiff !== 0) {
    return timeDiff;
  }
  return a.id < b.id ? -1 : a === b ? 0 : 1;
}

export function sortMessages(messages: AnyMessage[], reversed?: boolean) {
  return messages.sort((a, b) => sortMessagesFn(a, b) * (reversed ? -1 : 1));
}

export function removeDeletedLogMessages(messages: AnyMessage[]) {
  return messages.filter(
    (message) => message.type !== "log" || !MessageHelper.isDeleted(message),
  );
}

export function buildMessageList(messages: Record<string, AnyMessage>) {
  return groupSimilarLogs(
    sortMessages(removeDeletedLogMessages(Object.values(messages))),
  );
}
