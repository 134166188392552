import { Pressable, StyleSheet, View } from "react-native";

import { ColorStyle } from "../../../constants/color";
import { PixelSize } from "../../../constants/pixelSize";
import { Spacing } from "../../../constants/spacing";
import { Text } from "../../../display/text";
import type { AnchoredSheetDefinition } from "./anchoredSheet.definition/anchoredSheet.definition.types";
import { AnchoredSheetHeaderBackButton } from "./anchoredSheet.headerBackButton";
import { Icon } from "../../../display/icon";

export const AnchoredSheetHeader: AnchoredSheetDefinition["Header"] = ({
  children,
  withBackBehavior,
  onClose,
  withCloseButton,
}) => {
  return (
    <View style={styles.container}>
      {withBackBehavior && <AnchoredSheetHeaderBackButton onPress={onClose} />}
      <Text size="BODY" color="FONT_HIGH_EMPHASIS" weight="medium">
        {children}
      </Text>
      {withCloseButton && (
        <Pressable onPress={onClose} accessibilityLabel="Close dialog">
          <Icon name="x-close" color={ColorStyle.ACTION_CTA} />
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: Spacing.S16,
    height: PixelSize.S32,
    gap: Spacing.S8,
  },

  separator: {
    height: StyleSheet.hairlineWidth,
    flexGrow: 1,
    backgroundColor: ColorStyle.SEPARATOR,
  },

  close: {
    flexGrow: 1,
    flexShrink: 1,
  },

  closeButton: {
    alignSelf: "flex-end",
  },
});
