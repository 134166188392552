import { useSelector } from "react-redux";

import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectCurrentContextAtLeastStandardOrSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";

export function useContextShowExternalBanner(
  roomId: string | undefined,
): boolean {
  const isAtLeastStandardOrSuperadmin = useSelector(
    selectCurrentContextAtLeastStandardOrSuperadmin,
  );
  const isExternal = useSelector(RoomSelectors.selectHasExternals(roomId));
  return isExternal && isAtLeastStandardOrSuperadmin;
}
