import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { DialogActionButtons } from "@kraaft/shared/components/dialogActionButtons/dialogActionButtons";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { saveEditSchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { selectEditedSchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateSelectors";
import { editTaskTemplate } from "@kraaft/shared/core/modules/schemaTemplate/taskTemplate/taskTemplateActions";
import { selectEditedTaskTemplateTitles } from "@kraaft/shared/core/modules/schemaTemplate/taskTemplate/taskTemplateSelectors";
import { prepareForOrderableList } from "@kraaft/web/src/components/orderableList/orderableList.utils";
import {
  OrderableOptionRenderer,
  OrderableOptionRendererProps,
} from "@kraaft/web/src/components/sortableListWithAddAndDelete/orderableOptionRenderer/orderableOptionRenderer";
import { SortableListWithAddAndDelete } from "@kraaft/web/src/components/sortableListWithAddAndDelete/sortableListWithAddAndDelete";

import { styles } from "./editChecklistTemplate.styles";

interface EditChecklistTemplateProps {
  onClose: () => void;
}

export const EditChecklistTemplate = ({
  onClose,
}: EditChecklistTemplateProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taskNames = useSelector(selectEditedTaskTemplateTitles);
  const schema = useSelector(selectEditedSchemaTemplate);

  const onTaskTemplateNameChanged = useCallback(
    (newName: string) => {
      dispatch(editTaskTemplate({ name: newName }));
    },
    [dispatch],
  );

  const onSave = useCallback(() => {
    dispatch(saveEditSchemaTemplate());
    onClose();
  }, [onClose, dispatch]);

  const canSave = useMemo(
    () =>
      schema?.name &&
      schema.name.length > 0 &&
      taskNames.length > 0 &&
      taskNames.some((task) => task.length > 0),
    [schema, taskNames],
  );

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onNameInputKeyPress = useCallback(
    (ev: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (ev.nativeEvent.key === "Enter") {
        if (taskNames.length === 0) {
          dispatch(editTaskTemplate({ taskNames: [""] }));
        }
        setTimeout(
          () => document.getElementById("orderable-option-0")?.focus(),
          0,
        );
      }
    },
    [taskNames, dispatch],
  );

  const options = useMemo(
    () =>
      prepareForOrderableList(taskNames, (taskName, index) => ({
        key: index.toString(),
        index,
        data: {
          id: index.toString(),
          text: taskName,
        },
      })),
    [taskNames],
  );

  const handleChange = useCallback(
    (newOptions: typeof options) => {
      dispatch(
        editTaskTemplate({
          taskNames: Object.values(newOptions)
            .sort((a, b) => a.index - b.index)
            .map((o) => o.data.text || ""),
        }),
      );
    },
    [dispatch],
  );

  const handleCreate = useCallback(
    (id: string, index: number) => ({
      id,
      text: t("formBuilder.schema.columnSpecific.select.newOption", {
        index,
      }),
    }),
    [t],
  );

  const renderRow = useCallback((props: OrderableOptionRendererProps) => {
    return (
      <OrderableOptionRenderer
        option={props.option}
        onDelete={props.onDelete}
        onPressEnter={props.onPressEnter}
        onChange={props.onChange}
      />
    );
  }, []);

  return (
    <View style={styles.dialogContentContainer}>
      <KInput
        autoFocus
        style={styles.nameOfTheList}
        onChangeText={onTaskTemplateNameChanged}
        value={schema?.name}
        onKeyPress={onNameInputKeyPress}
        showEditIcon
        label={t("nameOfTheList")}
        testID="ide2e-task-template-title-input"
      />
      <SortableListWithAddAndDelete
        id="task-template-sortable-list"
        addButtonText={t("addTask")}
        options={options}
        onChange={handleChange}
        renderRow={renderRow}
        onCreate={handleCreate}
      />
      <DialogActionButtons
        primaryButton={{
          onPress: onSave,
          accessibilityLabel: t("save"),
          text: t("save"),
          disabled: !canSave,
        }}
        secondaryButton={{
          onPress: onCancel,
          accessibilityLabel: t("cancel"),
          text: t("cancel"),
        }}
      />
    </View>
  );
};
