import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RoomSelectors } from "@kraaft/shared/core/modules/room/selectors";

import { useArchive } from "./roomArchive.hooks";

function useIsArchived(roomId: string) {
  const isArchivedForUser = useSelector(
    RoomSelectors.selectArchivedForUser(roomId),
  );
  const isArchivedForAll = useSelector(
    RoomSelectors.selectArchivedForAll(roomId),
  );

  return useMemo(
    () => ({
      isArchived: Boolean(isArchivedForUser || isArchivedForAll),
      isArchivedForAll: Boolean(isArchivedForAll),
    }),
    [isArchivedForAll, isArchivedForUser],
  );
}

export function useArchiveRoom(roomId: string) {
  const isCurrentUserMemberOfRoom = useSelector(
    RoomSelectors.selectIsCurrentUserMember(roomId),
  );

  const { isArchived, isArchivedForAll } = useIsArchived(roomId);

  return useArchive({
    isArchivedForAll,
    isArchived,
    source: "room_info_tab",
    roomId,
    isCurrentUserMemberOfRoom,
  });
}
