import { ErrorReporter } from "@kraaft/shared/core/modules/errorReporter/errorReporter";
import { crashlytics } from "@kraaft/web/src/core/services/firebase/sdk";

export class WebErrorReporter implements ErrorReporter {
  setUserId(userId: string): void {
    crashlytics().setUserId(userId).catch(console.error);
  }

  reportError(error: Error) {
    crashlytics().recordError(error);
  }
}
