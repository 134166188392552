import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";

import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";

// Timeout returns types are different between React-Native/Browser and Node.
type Timeout = ReturnType<typeof setTimeout>;

export function useDebounceFunction<T extends unknown[]>(
  fn: (...params: T) => void,
  delay = 500,
) {
  const timeout = useRef<Timeout>();

  return useCallback(
    (...params: T) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => fn(...params), delay);
    },
    [delay, fn],
  );
}

export function useDebounce<T>(value: T, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler: Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Function used execute optimistic operations on a value
 * It takes a debounceMs parameter that allows a callback to be executed if no operations
 * are done within debounceMs
 *
 * Example:
 * You are editing a text but dont want to call the onChange prop every time you update the value
 * The onChange prop gets called after debounceMs milliseconds
 *
 * const { text, onChange } = props;
 *
 * const { update, value } = useDebounceOptimistic(text, 1000, onChange);
 *
 * return <TextInput value={value} onChangeText={(newText) => update(oldText => newText)} />
 */
export function useDebounceOptimistic<T>(
  item: T,
  debounceMs: number,
  onDebounced: (optimistic: T) => void,
  options?: { keepUpdatesOnDebounce?: boolean },
): {
  value: T;
  update: (updater: (old: T) => T) => void;
} {
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const [updates, setUpdates] = useState<Array<(old: T) => T>>([]);

  const optimisticValue = useMemo(() => {
    let v = cloneDeep(item);
    for (const update of updates) {
      v = update(v);
    }
    return v;
  }, [item, updates]);

  const handleDebounced = useCallbackRealtime(
    ([_optimisticValue, _onDebounced]) => {
      _onDebounced(_optimisticValue);
      if (!options?.keepUpdatesOnDebounce) {
        setUpdates([]);
      }
    },
    [options?.keepUpdatesOnDebounce],
    [optimisticValue, onDebounced],
  );

  return {
    update: useCallback(
      (updater: (old: T) => T) => {
        clearTimeout(timeout.current);
        setUpdates((oldUpdates) => [...oldUpdates, updater]);
        timeout.current = setTimeout(handleDebounced, debounceMs);
      },
      [debounceMs, handleDebounced],
    ),
    value: optimisticValue,
  };
}
