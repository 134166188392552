import { cloneDeepWith, forOwn, isDate, isObject, isString } from "lodash";

export class SimpleStorageSerializer {
  private static dateCode = "@@date@@";

  static forStorage(runtime: any) {
    return cloneDeepWith(runtime, (value) => {
      if (isDate(value)) {
        return `${SimpleStorageSerializer.dateCode}${value.toISOString()}`;
      }
    });
  }

  static fromStorage(runtime: any) {
    forOwn(runtime, (value, key, parent) => {
      if (
        isString(value) &&
        value.startsWith(SimpleStorageSerializer.dateCode)
      ) {
        const [_, dateString] = value.split(SimpleStorageSerializer.dateCode);
        if (!dateString) {
          return;
        }
        parent[key] = new Date(dateString);
      } else if (isObject(value)) {
        SimpleStorageSerializer.fromStorage(value);
      }
    });
    return runtime;
  }
}
