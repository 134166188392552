/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text, TextProps, View } from "react-native";
import { useSelector } from "react-redux";

import { PartitionTextView } from "@kraaft/shared/components/textInputWithMentions/partitionTextView";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import {
  selectCurrentUserId,
  selectUsername,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { formatAddress } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, Icon } from "@kraaft/ui";

import { styles } from "./replyPreview.style";

interface InformationPreviewProps {
  message: AnyUserMessage;
  isReply: boolean;
  isPreview: boolean;
}

const InformationPreview = (props: InformationPreviewProps): JSX.Element => {
  const { message, isReply, isPreview } = props;
  const author = useSelector(selectUsername(message.senderId));
  const currentUserId = useSelector(selectCurrentUserId);
  const { t } = useTranslation();

  const iconColor = isReply ? ColorStyle.FONT_HIGH_EMPHASIS : Color.WHITE;
  const messageStyle = useMemo(
    () =>
      isReply
        ? {
            color: ColorStyle.FONT_LOW_EMPHASIS,
            flexShrink: 1,
            flexGrow: 1,
          }
        : {
            color: Color.WHITE,
            flexShrink: 1,
            flexGrow: 1,
          },
    [isReply],
  );

  const messageNameStyle = isReply
    ? { color: ColorStyle.FONT_HIGH_EMPHASIS }
    : { color: Color.WHITE };
  const borderStyle = isReply
    ? { borderColor: Color.BLACK }
    : { borderColor: Color.WHITE };
  const userAnsweredTo: string =
    currentUserId !== message.senderId
      ? `${isPreview ? `${t("answerTo")} ` : ""}${author}`
      : t("you");

  const textProps = useMemo<TextProps>(
    () => ({ style: messageStyle, numberOfLines: 2, ellipsizeMode: "tail" }),
    [messageStyle],
  );

  const audioTextProps = useMemo<TextProps>(
    () => ({ style: messageStyle, ellipsizeMode: "tail" }),
    [messageStyle],
  );

  return (
    <View style={[styles.infoContainer, borderStyle]}>
      <View style={styles.previewHeader}>
        <Text
          numberOfLines={2}
          style={[styles.messageSenderName, messageNameStyle]}
        >
          {userAnsweredTo}
        </Text>
      </View>
      <View style={styles.messagePreview}>
        {MessageHelper.isDeleted(message) ? (
          <Text style={messageStyle}>{t("removedReplyMessage")}</Text>
        ) : (
          <>
            {MessageHelper.isText(message) && (
              <Text {...textProps}>
                <PartitionTextView
                  partitions={message.text}
                  dark={isReply}
                  textStyle={textProps.style}
                />
              </Text>
            )}
            {MessageHelper.isGeolocation(message) && (
              <Text {...textProps}>
                <TextProcessor textStyle={textProps.style}>
                  {formatAddress(message.geolocation)}
                </TextProcessor>
              </Text>
            )}
            {MessageHelper.isImage(message) && (
              <Text {...textProps}>
                {message.attachment.caption ? (
                  <PartitionTextView
                    partitions={message.attachment.caption}
                    dark={isReply}
                    textStyle={textProps.style}
                  />
                ) : (
                  <Text style={textProps.style}>{t("photo")}</Text>
                )}
              </Text>
            )}
            {MessageHelper.isDocument(message) && (
              <Text {...textProps}>
                <TextProcessor textStyle={textProps.style}>
                  {message.attachment.original.filename}
                </TextProcessor>
              </Text>
            )}
            {MessageHelper.isVideo(message) && (
              <Text {...textProps}>
                <TextProcessor textStyle={textProps.style}>
                  {isPreview
                    ? message.attachment.original.filename
                    : t("video")}
                </TextProcessor>
              </Text>
            )}
            {MessageHelper.isAudio(message) && (
              <View style={styles.audioTranscriptionContainer}>
                <Icon
                  name="microphone-01"
                  size="MEDIUM"
                  style={styles.icon}
                  color={iconColor}
                />
                <Text
                  style={messageStyle}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  <Text style={[styles.messageTitlePreview, messageStyle]}>
                    {message.attachment.transcription
                      ? t("transcription")
                      : t("vocalMessage")}
                  </Text>

                  <Text {...audioTextProps}>
                    {message.attachment.transcription ? (
                      <PartitionTextView
                        partitions={message.attachment.transcription}
                        dark={isReply}
                      />
                    ) : null}
                  </Text>
                </Text>
              </View>
            )}
          </>
        )}
      </View>
    </View>
  );
};

export { InformationPreview };
