import { lazy, Suspense } from "react";

const PlanningEditor = lazy(() =>
  import(
    "@kraaft/shared/core/modules/planning/screens/planningEditor/planningEditor"
  ).then((module) => ({
    default: module.PlanningEditor,
  })),
);

export const PlanningScreen = () => {
  return (
    <Suspense fallback="Loading">
      <PlanningEditor />
    </Suspense>
  );
};
