import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import {
  BannerType,
  RoomBanner,
} from "@kraaft/shared/components/conversation/header/roomBanner";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { useSchemaModularFolderCount } from "@kraaft/shared/core/modules/modularFolder/hooks/useSchemaModularFolderCount";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { conditionalEntry } from "@kraaft/shared/core/utils";

import { useStyles } from "./autoNumberDialog.styles";

interface AutoNumberDialogProps {
  schemaId: string;
  prefix: string;
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const AutoNumberDialog = ({
  schemaId,
  prefix,
  open,
  onSave,
  onCancel,
  onClose,
}: AutoNumberDialogProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const poolId = useSelector(selectCurrentPoolId);

  const overwriteOptions = [
    { label: t("overwriteFolderTitlesDialogKeep"), identifier: "keep" },
    {
      label: t("overwriteFolderTitlesDialogOverwrite"),
      identifier: "overwrite",
    },
  ];

  const [overwrite, setOverwrite] = useState(false);

  useEffect(() => {
    if (open) {
      setOverwrite(false);
    }
  }, [open]);

  const count = useSchemaModularFolderCount(schemaId);

  const handleValidate = useCallback(() => {
    if (overwrite && poolId) {
      dispatch(
        OfflineModularFolderActions.autonumberTitles({
          poolId,
          schemaId,
          prefix,
        }),
      );
    }
    onSave();
    onClose();
  }, [dispatch, onClose, onSave, overwrite, poolId, prefix, schemaId]);

  const handleChangeOverwriteOption = (overwriteOption: string[]) => {
    if (overwriteOption[0] === "keep") {
      setOverwrite(false);
    } else if (overwriteOption[0] === "overwrite") {
      setOverwrite(true);
    }
  };

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const actionsOverwrite = [
    {
      accessibilityLabel: t("validate"),
      text: t("validate"),
      onPress: handleValidate,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: handleCancel,
    },
  ] as FooterActions;

  const contentOverwrite = (
    <div className={styles.root}>
      <KSelectionList
        items={overwriteOptions}
        selected={[overwrite ? "overwrite" : "keep"]}
        setSelected={handleChangeOverwriteOption}
      />
      {conditionalEntry(
        <div>
          <RoomBanner
            bannerType={BannerType.WARNING}
            floating
            bannerText={`${count} ${t(
              "overwriteFolderTitlesDialogBannerCountText",
            )}`}
          />
        </div>,
        overwrite,
      )}
    </div>
  );

  return (
    <KDialog
      size={KDialogSize.SMALL}
      title={t("overwriteFolderTitlesDialogTitle")}
      open={open}
      onClose={onClose}
      content={contentOverwrite}
      actions={actionsOverwrite}
    />
  );
};
