import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { ToggleWithLabel } from "@kraaft/shared/components/toggle/toggleWithLabel";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { PoolMemberPreview } from "@kraaft/shared/core/modules/poolMember/poolMember.types";
import { Icon } from "@kraaft/ui";
import {
  getEmailForPayload,
  getPhoneNumberForPayload,
  InputString,
} from "@kraaft/web/src/views/settings/settingsUtils";
import {
  useConfirmEditHandler,
  Warnings,
} from "@kraaft/web/src/views/settings/useConfirmEditHandler";

import { useStyles } from "./dialog.styles";

interface Props {
  poolId: string;
  member: PoolMemberPreview;
  open: boolean;
  onClose: () => void;
}
const EditUserAuthDialog = ({ poolId, member, open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState<InputString>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<InputString>(undefined);
  const [firstName, setFirstName] = useState<InputString>(undefined);
  const [lastName, setLastName] = useState<InputString>(undefined);

  const [force, setForce] = useState(false);
  const [warnings, setWarnings] = useState<Warnings>({});
  const [isLoading, setIsLoading] = useState(false);

  const isFirstNameValid = firstName === undefined || firstName.length > 0;
  const isLastNameValid = lastName === undefined || lastName.length > 0;

  const resetValues = useCallback(() => {
    setFirstName(undefined);
    setLastName(undefined);
    setEmail(undefined);
    setPhoneNumber(undefined);
  }, []);

  useEffect(() => {
    resetValues();
  }, [member, resetValues]);

  const title = t("editUserAuth");

  const content = (
    <div className={classes.dialogContentContainer}>
      <div className={classes.item}>
        <KInput
          label={t("lastStepLabelFirstName")}
          defaultValue={member.firstname}
          value={firstName}
          onChangeText={setFirstName}
          error={!isFirstNameValid}
          disableAutocomplete
        />
      </div>
      <div className={classes.item}>
        <KInput
          label={t("lastStepLabelLastName")}
          defaultValue={member.lastname}
          value={lastName}
          onChangeText={setLastName}
          error={!isLastNameValid}
          disableAutocomplete
        />
      </div>
      <div className={classes.item}>
        <KInput
          label={t("email")}
          defaultValue={member.email}
          value={email}
          onChangeText={setEmail}
          error={Boolean(warnings.emailConflictText)}
          helperText={warnings.emailConflictText}
          disableAutocomplete
        />
      </div>
      <div className={classes.item}>
        <KInput
          label={t("phone")}
          defaultValue={member.phone}
          value={phoneNumber}
          onChangeText={setPhoneNumber}
          error={Boolean(warnings.phoneNumberConflictText)}
          helperText={warnings.phoneNumberConflictText}
          disableAutocomplete
        />
      </div>
      <div className={clsx(classes.item, classes.helperTextContainer)}>
        <Icon name="info-circle" size="XLARGE" color="BLUE_AZURE" />
        <div className={classes.helperTextWrapper}>
          <span className={classes.helperTextTitle}>
            {t("editUserAuthHelpTextTitle")}
          </span>
          <span className={classes.helperText}>
            {t("editUserAuthHelpText")}
          </span>
        </div>
      </div>

      <div className={classes.item}>
        <ToggleWithLabel
          label={t("forceEdition")}
          value={force}
          setValue={setForce}
        />
      </div>
    </div>
  );

  const handleConfirm = useConfirmEditHandler(poolId, onClose);

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      const newWarnings = await handleConfirm({
        userId: member.userId,
        email: getEmailForPayload(email),
        phoneNumber: getPhoneNumberForPayload(phoneNumber),
        lastName,
        firstName,
        force,
      });

      if (newWarnings) {
        setWarnings(newWarnings);
      }
    } catch (error) {
      dispatch(showError({ title: t("internalError") }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setWarnings({});
  }, [email, phoneNumber]);

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirm"),
      text: t("confirm"),
      onPress: onConfirm,
      loading: isLoading,
      disabled: !(isFirstNameValid && isLastNameValid),
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      actions={actions}
    />
  );
};

export { EditUserAuthDialog };
