import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";

import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { Button, Sheet, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { ElementOptionsCondition } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementOptionsCondition";
import { ElementOptionsDescription } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementOptionsDescription";

interface ElementOptionsSheetProps {
  element: KSchemaElement;
}

type ElementOptionsRoute = "description" | "condition";

export const ElementOptionsSheet = Sheet({
  web: "popup",
})
  .create<ElementOptionsSheetProps>(
    ({ Content, Paper, Header }) =>
      ({ element, onClose }) => {
        const { t } = useTranslation();
        const [route, setRoute] = useState<ElementOptionsRoute>("description");

        const content = useMemo(() => {
          if (route === "description") {
            return <ElementOptionsDescription element={element} />;
          }
          if (route === "condition") {
            return <ElementOptionsCondition element={element} />;
          }
          return null;
        }, [element, route]);

        return (
          <Paper>
            <Header onClose={onClose}>
              <Text>{t("options")}</Text>
            </Header>
            <Content>
              <Box row gap="S16">
                <Box gap="S8" minWidth={125} items="flex-start">
                  <Button
                    style={styles.button}
                    onPress={() => setRoute("description")}
                    text={t("schemaColumnOptions.nav.description")}
                    variant="TERTIARY"
                    selected={route === "description"}
                    size="SMALL"
                  />
                  {element.key !== KSchemaRemarkableColumns.TITLE && (
                    <Button
                      style={styles.button}
                      onPress={() => setRoute("condition")}
                      text={t("schemaColumnOptions.nav.conditions")}
                      variant="TERTIARY"
                      selected={route === "condition"}
                      size="SMALL"
                    />
                  )}
                </Box>
                <Box grow>{content}</Box>
              </Box>
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "LARGE" });

const styles = StyleSheet.create({
  button: {
    width: "100%",
    justifyContent: "flex-start",
  },
});
