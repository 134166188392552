import fromPairs from "lodash/fromPairs";
import { Dictionary } from "ts-essentials";

import { RoomCardProps } from "@kraaft/shared/components/roomCard";
import { ModularCardPills } from "@kraaft/shared/components/roomCard/modularCardPills";
import {
  RoomModularRecord,
  RoomType,
} from "@kraaft/shared/core/modules/room/roomState";
import { ColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KRoomSchema,
  KSchemaColumn,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { nullId } from "@kraaft/shared/core/utils/utils";

const baseDate = new Date();

// eslint-disable-next-line complexity
export const createMockValue = (
  column: KSchemaColumn,
  poolUsers: Dictionary<User, string>,
): ColumnValue | undefined => {
  switch (column.type) {
    case KColumnType.number:
      return {
        columnType: KColumnType.number,
        value: 1000,
      };
    case KColumnType.automatedAutoIncrement:
    case KColumnType.shortText:
      return {
        columnType: column.type,
        value: i18n.t("mockRoomName"),
      };
    case KColumnType.longText:
      return {
        columnType: KColumnType.longText,
        value: i18n.t("mockRoomDescription"),
      };
    case KColumnType.automatedCreatedBy:
    case KColumnType.user: {
      const user = Object.keys(poolUsers)[0] ?? "";

      return {
        columnType: column.type,
        value: [user],
      };
    }
    case KColumnType.selectSingle:
    case KColumnType.selectMultiple: {
      const select = Object.keys(column.options)[0] ?? "";

      return {
        columnType: column.type,
        value: [select],
      };
    }
    case KColumnType.geolocation:
      return {
        columnType: KColumnType.geolocation,
        value: {
          address: {
            description: "23 Rue Saint-Jacques, Paris, France",
            googlePlaceId: "ChIJ3_8RzuBx5kcRhkFWOQ2HDA8",
          },
          coords: {
            latitude: 48.851502,
            longitude: 2.3461207,
          },
        },
      };
    case KColumnType.automatedCreatedAt:
    case KColumnType.date:
      return {
        columnType: column.type,
        value: new Date(),
      };
    case KColumnType.currency:
      return {
        columnType: KColumnType.currency,
        value: 195,
      };
    default:
      return;
  }
};

export const mockRoomRecord: (
  roomSchema: KRoomSchema | null,
  poolUsers: Dictionary<User, string>,
) => RoomModularRecord = (roomSchema, poolUsers) => ({
  type: RoomType.DEFAULT,
  schemaId: roomSchema?.id as string,
  id: "mockId",
  properties: roomSchema
    ? {
        ...(fromPairs(
          KSchemaUtils.orderedColumns(roomSchema.rootSection).map((column) => {
            return [column.key, createMockValue(column, poolUsers)];
          }),
        ) as RoomModularRecord["properties"]),
      }
    : ({
        title: {
          columnType: KColumnType.shortText,
          value: i18n.t("mockRoomName"),
        },
      } as RoomModularRecord["properties"]),
});

export const mockCardProps = (
  lastMessage: string,
  roomSchema: KRoomSchema,
  roomProperties: RoomModularRecord["properties"],
  poolId: string,
  poolUsers: Dictionary<User, string>,
  pillColumns: string[],
  currencyCode: string,
  unread?: boolean,
): RoomCardProps => {
  const pills = (
    <ModularCardPills
      pillColumns={pillColumns}
      recordProperties={roomProperties}
      schemaId={roomSchema.id}
      extraContext={{ poolMembers: poolUsers, currencyCode }}
    />
  );

  return {
    roomCard: {
      id: "room-id-1@user-id-1",
      type: "member",
      roomId: "room-id-1",
      title: i18n.t("mockRoomName"),
      visibility: "pool",
      roomCreatedBy: Object.keys(poolUsers)[0] ?? nullId,
      roomCreatedAt: baseDate,
      unread: unread ?? false,
      notificationSource: "inherit",
      poolId,
      isArchivedForAll: false,
      lastEventAt: baseDate,
      properties: roomProperties,
      responsibleId: roomProperties.responsible?.value?.[0] ?? nullId,
      labelIds: roomProperties.labels?.value ?? [],
      statusId: roomProperties.status?.value?.[0] ?? nullId,
      lastMessageInfo: {
        type: "text",
        id: "message-id-1",
        senderId: "user-id-1",
        createdAt: baseDate,
        text: lastMessage,
      },
      isEveryoneRoom: false,
      emoji: "💬",
      isArchivedForUser: false,
      archived: false,
      pinned: false,
      pinnedAt: undefined,
      userId: "user-id-1",
      hasExternalMembers: false,
    },

    description: lastMessage,

    pills,
    isActive: false,
  };
};
