/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { memoize } from "lodash";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { lodashKeyResolver } from "@kraaft/shared/core/utils/utils";

import { useActor } from "./actor.hook";
import { Capabilities, GuardOf, GuardParamsOf, Guards } from "./guards";
import { Guard } from "./guards.tools";
import { access } from "./types";

type Cap = Capabilities;

export const getGuard = memoize(
  <C extends Capabilities>(cap: C) => access(Guards, cap) as Guard,
);

const getHook = memoize(<C extends Capabilities>(cap: C) => {
  const capability = getGuard(cap);
  return (...args: GuardParamsOf<C>) => {
    const actor = useActor();
    const poolId = useSelector(selectCurrentPoolId);

    if (!actor || !poolId) {
      return [false, "unauthorized"] as const;
    }
    return capability(actor, poolId, ...args) as ReturnType<GuardOf<C>>;
  };
});

const getHookInPool = memoize(
  <C extends Capabilities>(poolId: string | undefined, cap: C) => {
    const capability = getGuard(cap);
    return (...args: GuardParamsOf<C>) => {
      const actor = useActor();

      if (!actor || !poolId) {
        return [false, "unauthorized"] as const;
      }
      return capability(actor, poolId, ...args) as ReturnType<GuardOf<C>>;
    };
  },
  lodashKeyResolver,
);

/**
 * TODO: Add a way to define solvers for the hooks
 * so we can solve the arguments *before* calling the guard
 * and pass them to the guard which must stay pure
 *
 * @now Guard.use("Message.delete", message)
 * @then Guard.use("Message.delete", messageId) | Guard.use("Message.delete", message)
 */

export class GuardHooks {
  static use<C extends Cap, P extends GuardParamsOf<C>>(cap: C, ...args: P) {
    const useGuard = getHook(cap);
    return useGuard(...args)[0];
  }

  static useInPool<C extends Cap, P extends GuardParamsOf<C>>(
    poolId: string | undefined,
    cap: C,
    ...args: P
  ) {
    const useGuardInPool = getHookInPool(poolId, cap);
    return useGuardInPool(...args)[0];
  }

  static useGuard<C extends Capabilities>(cap: C) {
    const guard = getGuard(cap);
    const actor = useActor();
    const poolId = useSelector(selectCurrentPoolId);

    return useCallback(
      (...args: GuardParamsOf<C>) => {
        if (!actor || !poolId) {
          return [false, "unauthorized"] as const;
        }
        return guard(actor, poolId, ...args) as ReturnType<GuardOf<C>>;
      },
      [guard, actor, poolId],
    );
  }
}
