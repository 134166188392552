import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import * as roomActions from "@kraaft/shared/core/modules/room/roomActions";
import { RoomOrRoomCardSelectors } from "@kraaft/shared/core/modules/room/selectors/roomOrRoomCardSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* changeRoomEmojiSaga({
  payload: { roomId, emoji },
}: ReturnType<typeof roomActions.RoomActions.changeRoomEmoji>) {
  const currentRoomEmoji = yield* select(
    RoomOrRoomCardSelectors.selectEmoji(roomId),
  );

  if (currentRoomEmoji === undefined) {
    return;
  }

  try {
    yield* put(roomActions.RoomStateActions.setRoomEmoji({ roomId, emoji }));
    yield* call(Api.setRoomEmoji, { roomId, emoji });
  } catch (error) {
    yield* put(
      roomActions.RoomStateActions.setRoomEmoji({
        roomId,
        emoji: currentRoomEmoji,
      }),
    );
    yield* put(showError({ title: i18n.t("cannotChangeRoomEmoji") }));
  }
}
