import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectGridLibrarySchemasForLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.selectors";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";

import { Guard } from "../auth";
import { selectCurrentUserIsSuperadmin } from "../user/userSelectors";
import { LibrarySchemaActions } from "./librarySchema.actions";

/** this hook subscribes to library schemas for the current pool and returns true if it has at least one */
export function useHasLibrarySchemas() {
  const dispatch = useDispatch();

  const pool = useSelector(selectCurrentPool);
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const canListPoolSchema = Guard.use("Schema.listSchemas");

  const hasLibrarySchemas =
    useSelector(selectGridLibrarySchemasForLanguage(pool?.poolLanguage))
      .length > 0;

  useEffect(() => {
    if (canListPoolSchema) {
      const payload = {
        companyId: pool?.companyId,
        superadmin: isSuperadmin,
      };
      dispatch(LibrarySchemaActions.subscribe(payload));

      return () => {
        dispatch(LibrarySchemaActions.unsubscribe(payload));
      };
    }
  }, [isSuperadmin, canListPoolSchema, dispatch, pool?.companyId]);

  return canListPoolSchema && hasLibrarySchemas;
}
