import { EditOneOperationBuilder } from "@kraaft/offline";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";

export const editConditionOperation = EditOneOperationBuilder.create<KSchema>()
  .payload<{ key: string; condition: CompositeCondition | undefined }>()
  .expected((aggregate, payload) => {
    const element = KSchemaUtils.findElement(
      aggregate.rootSection,
      payload.key,
    );
    if (!element) {
      return aggregate;
    }
    element.condition = payload.condition;
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editElementCondition({
      schemaId: payload.id,
      key: payload.key,
      condition: payload.condition,
    });
    return { updatedAt };
  });
