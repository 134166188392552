import { guardFromExecutingMoreThanOnce } from "@kraaft/helper-functions";
import { injectReducer, injectSaga } from "@kraaft/shared/core/store";

import { poolAddonsReducer } from "./poolAddons.reducer";
import { POOL_ADDONS_REDUCER_NAME } from "./poolAddons.reducerName";
import { poolAddonsSaga } from "./poolAddons.saga";

guardFromExecutingMoreThanOnce(() => {
  injectReducer(POOL_ADDONS_REDUCER_NAME, poolAddonsReducer);
  injectSaga(poolAddonsSaga);
})();
