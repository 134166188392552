import { useDispatch } from "react-redux";

import { uuid } from "@kraaft/helper-functions";
import type {
  DirectoryDropHelpers,
  UseDirectoryDropProps,
} from "@kraaft/shared/components/directory/useDirectoryDrop/types";
import { DroppableItem } from "@kraaft/shared/components/droppable";
import { BrowserPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { fillDirectoryWithMessage } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { uploadFilesRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";

const useDirectoryDrop = (
  props: UseDirectoryDropProps,
): DirectoryDropHelpers => {
  const { directory, onLoading } = props;
  const dispatch = useDispatch();

  const { loaderId } = useLoader({ onLoading });

  const onDrop = (item: DroppableItem) => {
    dispatch(
      fillDirectoryWithMessage({
        directoryId: directory.id,
        roomId: directory.roomId,
        loaderId,
        messageId: item.messageId,
      }),
    );
  };

  const onDropFiles = (files: Array<ModernFile<BrowserPath>>) => {
    const context: UploadAttachmentContext = {
      type: "directory",
      roomId: directory.roomId,
      directoryId: directory.id,
      loaderId,
      source: "file",
    };

    return dispatch(
      uploadFilesRequest({
        context,
        files: files.map((file) => ({ ...file, optimisticId: uuid() })),
      }),
    );
  };

  return {
    onDrop,
    onDropFiles,
  };
};

export { useDirectoryDrop };
