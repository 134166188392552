import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { type TFunction } from "@kraaft/shared/core/services/i18next";

export function getRoleTranslation(t: TFunction, role: UserPoolRole) {
  return t(role);
}

export function roleIdsToGrade(roleIds?: string[]) {
  if (!roleIds) {
    return UserPoolRole.EXTERNAL;
  }
  if (roleIds.includes("own")) {
    return UserPoolRole.OWNER;
  }
  if (roleIds.includes("adm")) {
    return UserPoolRole.ADMIN;
  }
  if (roleIds.includes("std")) {
    return UserPoolRole.STANDARD;
  }
  return UserPoolRole.EXTERNAL;
}
