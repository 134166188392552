import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
} from "react-native";

import { useVoidOnPress } from "@kraaft/helper-hooks";
import { EmojiSelectorSheet } from "@kraaft/shared/components/avatarEmojiPicker/emojiSelector.sheet";
import { RoomAvatar } from "@kraaft/shared/components/roomCard/roomAvatar";
import { RoomCardType } from "@kraaft/shared/components/roomCard/roomCardUtils";
import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";

export interface AvatarEmojiPickerHandle {
  openEmojiSelector: () => void;
}

interface AvatarEmojiPickerProps {
  emoji: string;
  setEmoji: (emoji: string) => void;
  roomCardType: RoomCardType;
  isPrivate: boolean;
  recentEmojiSpace: RecentEmojiSpace;
}

export const AvatarEmojiPicker = React.forwardRef<
  AvatarEmojiPickerHandle,
  AvatarEmojiPickerProps
>(
  (
    { emoji, setEmoji, roomCardType, isPrivate, recentEmojiSpace },
    externalRef,
  ) => {
    const { t } = useTranslation();

    const buttonRef = useRef(null);

    const { element, open } = EmojiSelectorSheet.use({
      anchor: buttonRef,
      placement: "bottom-start",
      onEmojiSelected: setEmoji,
      recentEmojiSpace,
      abovePopup: true,
    });

    const voidOpen = useVoidOnPress(open);

    const handle = useMemo(() => ({ openEmojiSelector: voidOpen }), [voidOpen]);

    useImperativeHandle(externalRef, () => handle);

    const getContainerStyle = useCallback(
      ({ pressed }: PressableStateCallbackType) => [
        pressed && styles.containerPressed,
      ],
      [],
    );

    return (
      <>
        <Pressable
          ref={buttonRef}
          accessibilityLabel={t("editRoomEmoji")}
          onPress={voidOpen}
          style={getContainerStyle}
        >
          <RoomAvatar
            withPrivateIndicator={isPrivate}
            size="large"
            roomCardType={roomCardType}
            emoji={emoji}
          />
        </Pressable>
        {element}
      </>
    );
  },
);

const styles = StyleSheet.create({
  containerPressed: {
    opacity: 0.75,
    transform: [{ scale: 0.95 }],
  },
});
