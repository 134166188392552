import React from "react";
import clsx from "clsx";

import { UnknownObject } from "@kraaft/shared/core/types";

import { DraggableRow, DraggableRowProps } from "../draggableRow";
import { AdditionalTypes, TransitionDirection } from "../orderableList.types";

import { useStyles } from "./animatedDraggableRow.styles";

type AnimatedDraggableRowProps<
  T extends UnknownObject,
  K extends string = string,
> = AdditionalTypes<K> &
  DraggableRowProps<T> & {
    isDragging: boolean;
    direction: TransitionDirection | undefined;
    draggedRowHeight: number | undefined;
  };

const AnimatedDraggableRow_ = <
  T extends UnknownObject,
  K extends string = string,
>(
  props: AnimatedDraggableRowProps<T, K>,
) => {
  const {
    item,
    listIdentifier,
    renderItem,
    containerClassName,
    withHandle,
    onDragBegin,
    onDragEnd,
    onHovered,
    isDragging,
    direction,
    draggedRowHeight,
    additionalTypes,
    disabled,
  } = props;

  const classes = useStyles({ draggedRowHeight });

  return (
    <div
      className={clsx(
        isDragging && {
          [classes.withTransition]: true,
          [classes.withTransitionUp]: direction === "up",
          [classes.withTransitionDown]: direction === "down",
        },
      )}
    >
      <DraggableRow<T, K>
        item={item}
        listIdentifier={listIdentifier}
        renderItem={renderItem}
        containerClassName={containerClassName}
        withHandle={withHandle}
        onDragBegin={onDragBegin}
        onDragEnd={onDragEnd}
        onHovered={onHovered}
        additionalTypes={additionalTypes}
        disabled={disabled}
      />
    </div>
  );
};

const AnimatedDraggableRow = React.memo(
  AnimatedDraggableRow_,
) as typeof AnimatedDraggableRow_;

export { AnimatedDraggableRow };
