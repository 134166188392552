import { StyleSheet } from "react-native";

import { Color, ColorStyle, Spacing } from "@kraaft/ui";

export const styles = StyleSheet.create({
  topButtons: {
    backgroundColor: Color.WHITE,
    padding: Spacing.S12,
    flexDirection: "row",
    gap: Spacing.S8,
  },
  topButton: {
    flex: 1,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: Color.WHITE,
  },
  separatorLine: {
    position: "absolute",
    backgroundColor: ColorStyle.SEPARATOR,
    top: 0,
    bottom: 0,
    right: 0,
    left: Spacing.S72,
  },
  pinnedSeparator: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    width: "100%",
    height: Spacing.S8,
  },
  loadingFooter: {
    height: 50,
  },
  textFooter: {
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
    textAlign: "center",
  },
});
