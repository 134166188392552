import { produce } from "immer";

import { registeredLogger, registeredTracking } from "../logger";
import {
  BaseAggregate,
  CreationOperation,
  CustomOperation,
  OptimisticOperation,
  UserDeclaredOperations,
} from "./optimistic.types";

export const OptimisticBuilder = {
  logger: registeredLogger.current.createSubLogger(["OptimisticBuilder"]),

  safelyCallExpectedForCreation(
    featureName: string,
    operationName: string,
    operation: CreationOperation<any, any, any, any>,
    payload: any,
  ) {
    try {
      return operation.expected({ ...payload, ...payload.augmented });
    } catch (e) {
      this.logger.warn(
        `Failed building optimistic with augmented payload for operation ${operationName}`,
      );
      registeredTracking.current({
        feature: featureName,
        level: "warn",
        log: "Failed building optimistic with augmented payload (creation)",
        data: {
          operationName,
        },
      });
      return operation.expected({ ...payload });
    }
  },

  safelyCallExpectedForCustom<Aggregate>(
    featureName: string,
    operationName: string,
    operation: CustomOperation<any, any, any, any, any>,
    datas: Record<string, Aggregate>,
    payload: any,
  ) {
    try {
      return operation.expected(datas, { ...payload, ...payload.augmented });
    } catch (e) {
      this.logger.warn(
        `Failed building optimistic with augmented payload for operation ${operationName}`,
      );
      registeredTracking.current({
        feature: featureName,
        level: "warn",
        log: "Failed building optimistic with augmented payload (custom)",
        data: {
          operationName,
        },
      });
      return operation.expected(datas, { ...payload });
    }
  },

  build<Aggregate extends BaseAggregate>(
    name: string,
    declaredOperations: UserDeclaredOperations,
    allOperations: OptimisticOperation[],
    allAggregates: Record<string, Aggregate>,
  ) {
    const createdAggregates: Record<string, Aggregate> = {};

    const cloned = new Set<string>();

    const finalState = produce(allAggregates, (proxy) => {
      for (const operation of allOperations) {
        const declaredOperation = declaredOperations[operation.task.name];
        if (!declaredOperation) {
          this.logger.log(
            `Cannot find declared operation for task name ${operation.task.name}`,
          );
          registeredTracking.current({
            feature: name,
            level: "warn",
            log: "Cannot find declared operation",
            data: {
              operationName: operation.task.name,
            },
          });
          continue;
        }
        if (declaredOperation.type === "creations") {
          const created = this.safelyCallExpectedForCreation(
            name,
            operation.task.name,
            declaredOperation,
            operation.task.payload,
          );
          for (let i = 0; i < created.length; i += 1) {
            const createdAggregate = created[i];
            const id = operation.task.payload.ids[i];

            proxy[id] = createdAggregate;
            createdAggregates[id] = createdAggregate;
            cloned.add(id);
          }
        } else if (declaredOperation.type === "custom") {
          this.safelyCallExpectedForCustom(
            name,
            operation.task.name,
            declaredOperation,
            proxy,
            operation.task.payload,
          );
        }
      }
    });

    return { state: finalState, created: createdAggregates };
  },
};
