import { memoize } from "lodash";
import { createSelector } from "reselect";

import { OfflinePoolMemberSelectors } from "./poolMember.offline";

export const selectPoolMembers = memoize((poolId: string) =>
  createSelector(OfflinePoolMemberSelectors.selectAllArray, (poolMembers) => {
    return poolMembers.filter((poolMember) => poolMember.poolId === poolId);
  }),
);
