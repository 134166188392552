import { db } from "../../services/firebase/modularApp";
import { doc, onSnapshotDocument } from "../../services/firebase/modularQuery";
import { PoolAddons } from "./poolAddons.state";

interface FirestorePoolAddons {
  planning: boolean;
}

class PoolAddonsSerializer {
  static deserialize(firestorePoolAddons: FirestorePoolAddons): PoolAddons {
    return {
      planning: firestorePoolAddons.planning,
    };
  }
}

export class PoolAddonsQueries {
  static subscribe(
    poolId: string,
    callback: (poolAddons: PoolAddons | undefined) => void,
  ) {
    return onSnapshotDocument(
      "PoolAddonsQueries.subscribe",
      doc(db, "pool-addons-1n", poolId),
      (snapshot) => {
        const data = snapshot.data();
        if (!data) {
          callback(undefined);
          return;
        }
        callback(PoolAddonsSerializer.deserialize(data as FirestorePoolAddons));
      },
    );
  }
}
