import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compact } from "lodash";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { conditionalEntry, isDev } from "@kraaft/shared/core/utils";
import { copyTextToClipboard } from "@kraaft/shared/core/utils/clipboard";

const emptyArray: ActionSheetItem[] = [];

export function useDevMenuItems(
  roomId: string,
  message: AnyMessage,
): ActionSheetItem[] {
  const { t } = useTranslation();

  const copyPath = useCallback(() => {
    copyTextToClipboard(`/messages-projection-2/${message.id}`);
  }, [message.id]);

  const copyStoragePath = useCallback(() => {
    copyTextToClipboard(`rooms/${roomId}/messages/${message.id}`);
  }, [message.id, roomId]);

  const menuItems = useMemo<ActionSheetItem[]>(
    () =>
      compact([
        {
          label: t("copyMessagePath"),
          onPress: copyPath,
          icon: "copy-06",
        },
        conditionalEntry(
          {
            label: t("copyMessageStoragePath"),
            onPress: copyStoragePath,
            icon: "copy-06",
          },
          MessageHelper.hasAttachment(message),
        ),
      ]),
    [copyPath, copyStoragePath, message, t],
  );

  return isDev ? menuItems : emptyArray;
}
