import { useEffect } from "react";

export function useKeyPressed(
  key: string,
  onKeyPressed: (event: any) => void,
  preventDefault = false,
) {
  useEffect(() => {
    const handlePress = (ev: KeyboardEvent) => {
      if (preventDefault) {
        ev.preventDefault();
      }
      if (ev.key === key) {
        onKeyPressed(ev);
      }
    };
    document.addEventListener("keydown", handlePress);
    return () => document.removeEventListener("keydown", handlePress);
  }, [key, onKeyPressed, preventDefault]);
}
