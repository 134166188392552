import { LimitedTextInput, Spacing, Text } from "@kraaft/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

const TEXT_SUGGESTION_MAX_LENGTH = 250;
const TEXT_SUGGESTION_MAX_LENGTH_THRESHOLD = 200;

export const TextSuggestionInput = ({
  onChange,
  title,
  subtitle,
}: {
  onChange: (text: string) => void;
  title?: string;
  subtitle?: string;
}) => {
  const { t } = useTranslation();
  const [suggestionText, setSuggestionText] = useState("");

  useEffect(() => {
    // Here, we need to change the mesh context value manually after using the internal state because of a rerender issue when controlling input with mesh context values
    onChange(suggestionText);
  }, [suggestionText, onChange]);

  const styles = createStyles();

  return (
    <View style={styles.container}>
      <Text
        size="BODY"
        weight="bold"
        style={{ marginBottom: !subtitle ? Spacing.S8 : Spacing.S12 }}
      >
        {title}
      </Text>
      {subtitle && (
        <Text size="MEDIUM" style={styles.stepSubtitle}>
          {subtitle}
        </Text>
      )}
      <LimitedTextInput
        accessibilityLabel={t("nps.form.myAnswer")}
        placeholder={t("nps.form.myAnswer")}
        value={suggestionText}
        onChangeText={setSuggestionText}
        onBlur={() => onChange(suggestionText)}
        multiline
        autoFocus
        maxLength={TEXT_SUGGESTION_MAX_LENGTH}
        maxLengthLabelThreshold={TEXT_SUGGESTION_MAX_LENGTH_THRESHOLD}
      />
    </View>
  );
};

export const createStyles = () =>
  StyleSheet.create({
    container: { minWidth: Platform.select({ web: 450 }) },
    stepSubtitle: {
      marginBottom: Spacing.S12,
    },
  });
