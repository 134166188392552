import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ActionCreator } from "redux";
import { eventChannel } from "redux-saga";
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "typed-redux-saga/macro";

import { nanoid } from "@kraaft/helper-functions";

import { Logger, registeredTracking } from "../logger";
import {
  BaseAggregate,
  DependenciesFromDeclaredOperations,
  OptimisticOperation,
  UserDeclaredOperations,
} from "../optimistic/optimistic.types";
import { OptimisticHelper } from "../optimistic/optimisticHelper";
import { traceCloneDeep } from "../optimistic/traceCloneDeep";
import { Task } from "../taskStore/task";
import { TaskExecutor, TaskProcessor } from "../taskStore/taskStore.types";
import { CreateReduxBundleActions } from "./reduxBundle.actions";
import {
  FeatureOptimisticState,
  IdCorrespondance,
  MutateResults,
  OfflineReduxBundle,
} from "./reduxBundle.init";
import {
  ReduxBundleHelper,
  ReduxErrorAction,
  ReduxSaga,
  ReduxSelector,
  UserProvidedSelectors,
} from "./reduxBundle.types";

interface OnTaskSucceededPayload {
  task: Task;
  result: any;
}

interface OnTaskFailedPayload {
  task: Task;
  error: any;
}

interface OnTaskAddedPayload {
  task: Task;
}

interface OnTaskDelayedPayload {
  task: Task;
}

interface OnTaskSkippedPayload {
  task: Task;
  error: any;
}

export function CreateReduxBundleSaga<Aggregate extends BaseAggregate>(
  name: string,
  taskProcessor: TaskProcessor & TaskExecutor,
  declaredOperations: UserDeclaredOperations,
  bundleActions: ReturnType<typeof CreateReduxBundleActions<Aggregate>>,
  userProvidedSelectors: UserProvidedSelectors<Aggregate>,
  errorAction: ReduxErrorAction,
  resetAction: ActionCreator<any>,
  getDependencies: () => DependenciesFromDeclaredOperations<UserDeclaredOperations>,
  selectMutateResults: ReduxSelector<MutateResults>,
  selectOptimisticFeature: ReduxSelector<FeatureOptimisticState>,
  selectIdCorrespondance: ReduxSelector<IdCorrespondance>,
  actions: OfflineReduxBundle["actions"],
  logger: Logger,
) {
  const actionPrefix = ReduxBundleHelper.getActionPrefix(name);

  const { stateActions } = bundleActions;

  const saga: ReduxSaga = function* () {
    let lastSubscription: Record<string, Aggregate> | undefined;

    function* flushPendingSubscription(
      stateOperations: OptimisticOperation[],
      source: string,
    ) {
      if (!lastSubscription) {
        return;
      }
      const mutateResults = yield* select(selectMutateResults);

      const payload = lastSubscription;
      lastSubscription = undefined;

      const toDelete: number[] = [];

      for (let i = 0; i < stateOperations.length; i += 1) {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        const operation = stateOperations[i]!;
        const operationResult = mutateResults[operation.task.id];

        if (operationResult === undefined) {
          continue;
        }

        const declaredOperation = declaredOperations[operation.task.name];

        if (!declaredOperation) {
          toDelete.push(i);
          logger.error(
            `Unknown operation ${operation.task.name} while flusing subscription`,
          );
          continue;
        }
        if (
          OptimisticHelper.shouldAcknowledgeOptimisticOperation(
            name,
            payload,
            declaredOperation,
            operation,
            operationResult,
          )
        ) {
          toDelete.push(i);
        }
      }
      logger.log(
        `Flushing subscription (${source}), removing ${toDelete.length} optimistic operations`,
      );
      yield* put(
        stateActions.set({
          aggregates: payload,
          feature: name,
          operationIndexes: toDelete,
        }),
      );
    }

    taskProcessor.execute().catch(console.error);

    const onTaskAddedChannel = eventChannel<OnTaskAddedPayload>((emit) =>
      taskProcessor.onTaskAdded.register((task) => emit({ task })),
    );

    const onTaskSucceededChannel = eventChannel<OnTaskSucceededPayload>(
      (emit) =>
        taskProcessor.onTaskSucceeded.register((task, result) => {
          emit({ task, result });
        }),
    );

    const onTaskFailedChannel = eventChannel<OnTaskFailedPayload>((emit) =>
      taskProcessor.onTaskFailed.register((task, error) =>
        emit({
          task,
          error,
        }),
      ),
    );

    const onTaskDelayedChannel = eventChannel<OnTaskDelayedPayload>((emit) =>
      taskProcessor.onTaskDelayed.register((task) => emit({ task })),
    );

    const onTaskSkippedChannel = eventChannel<OnTaskSkippedPayload>((emit) =>
      taskProcessor.onTaskSkipped.register((task, error) =>
        emit({ task, error }),
      ),
    );

    yield* takeEvery(
      onTaskAddedChannel,
      function* ({ task }: OnTaskAddedPayload) {
        yield* put(
          actions.addOperation({
            feature: name,
            operation: {
              task,
            },
          }),
        );
      },
    );

    yield* takeEvery(
      onTaskSucceededChannel,
      function* ({ task, result }: OnTaskSucceededPayload) {
        const declaredOperation = declaredOperations[task.name];
        if (!declaredOperation) {
          logger.error("Could not find operation from succeeded task");
          return;
        }
        if (
          declaredOperation.type === "creations" &&
          OptimisticHelper.isValidCreationResult(name, task.name, result)
        ) {
          yield* put(
            actions.replaceOperationTargetId({
              feature: name,
              ids: task.payload.ids,
              by: result,
            }),
          );
        }
        yield* put(
          actions.setOperationMutateResult({
            feature: name,
            id: task.id,
            // This ensures result is set even if backend sends empty responses
            result: result === undefined ? true : result,
          }),
        );
        const { operations: stateOperations } = yield* select(
          selectOptimisticFeature,
        );
        yield* call(
          flushPendingSubscription,
          stateOperations,
          "task_succeeded",
        );

        const currentState = yield* select(
          userProvidedSelectors.selectRawAggregate,
        );

        if (
          OptimisticHelper.shouldAcknowledgeOptimisticOperation(
            name,
            currentState,
            declaredOperation,
            { task },
            result,
          )
        ) {
          const operationIndex = stateOperations.findIndex(
            (op) => op.task.id === task.id,
          );
          if (operationIndex < 0) {
            return;
          }
          logger.log("Task is already acknowledged in current state, flushing");
          yield* put(
            stateActions.set({
              aggregates: currentState,
              ...{ feature: name, operationIndexes: [operationIndex] },
            }),
          );
        }
      },
    );

    yield* takeEvery(
      onTaskFailedChannel,
      function* ({ task, error }: OnTaskFailedPayload) {
        yield* put(
          actions.removeDependentOperations({
            feature: name,
            dependencies: [...task.dependencies],
          }),
        );
        yield* put(errorAction({ feature: name, task, error }));
        const { operations: stateOperations } = yield* select(
          selectOptimisticFeature,
        );
        yield* call(flushPendingSubscription, stateOperations, "task_failed");
      },
    );

    yield* takeEvery(onTaskDelayedChannel, function* (delayedTask) {
      const { operations: stateOperations } = yield* select(
        selectOptimisticFeature,
      );
      yield* call(flushPendingSubscription, stateOperations, "task_delayed");
    });

    yield* takeEvery(
      onTaskSkippedChannel,
      function* ({ task: skippedTask, error }) {
        let { operations: stateOperations } = yield* select(
          selectOptimisticFeature,
        );
        const taskToDeleteIndex = stateOperations.findIndex(
          ({ task }) => task.id === skippedTask.id,
        );
        if (taskToDeleteIndex >= 0) {
          yield* put(
            actions.deleteOperations({
              feature: name,
              operationIndexes: [taskToDeleteIndex],
            }),
          );
        }
        ({ operations: stateOperations } = yield* select(
          selectOptimisticFeature,
        ));
        yield* put(errorAction({ feature: name, task: skippedTask, error }));
        yield* call(flushPendingSubscription, stateOperations, "task_skipped");
      },
    );

    yield* takeLatest(
      stateActions.receive,
      function* ({ payload }: ReturnType<typeof stateActions.receive>) {
        lastSubscription = payload;
        const { operations: stateOperations } = yield* select(
          selectOptimisticFeature,
        );
        const mutateResults = yield* select(selectMutateResults);
        const requestCountToResolve = stateOperations.reduce(
          (acc, operation) =>
            acc + (mutateResults[operation.task.id] === undefined ? 1 : 0),
          0,
        );
        if (requestCountToResolve > 0) {
          logger.log(
            `Not all API requests fulfilled (${requestCountToResolve}) caching payload...`,
          );
          registeredTracking.current({
            feature: name,
            level: "info",
            log: "Delaying incoming subscription",
            data: {
              requestCountToResolve,
            },
          });
        } else {
          yield* call(flushPendingSubscription, stateOperations, "snapshot");
        }
      },
    );

    yield* takeEvery(
      ({ type }: ReturnType<ActionCreatorWithPayload<any, any>>) =>
        type.startsWith(actionPrefix),
      function* ({ type, payload }) {
        const operationName = ReduxBundleHelper.getOperationNameFromActionType(
          name,
          type,
        );
        const declaredOperation = declaredOperations[operationName];

        if (!declaredOperation) {
          logger.error(
            `Could not find declared operation from action type for task name ${operationName}`,
          );
          registeredTracking.current({
            feature: name,
            level: "error",
            log: "Could not find declared operation from action",
            data: {
              operationName,
            },
          });
          return;
        }

        logger.log(
          `Creating operation ${operationName} (type: ${declaredOperation.type})`,
        );

        const mutablePayload = traceCloneDeep("Operation payload", payload);
        const idDependencies = OptimisticHelper.getTargetIdsOfActionPayload(
          declaredOperation,
          mutablePayload,
        );

        const idCorrespondance = yield* select(selectIdCorrespondance);
        for (const dependency of idDependencies) {
          const correspondance = idCorrespondance[dependency];
          if (correspondance) {
            declaredOperation.replaceId(
              mutablePayload,
              dependency,
              correspondance,
            );
          }
        }

        // We need to cloneDeep since some augmented payload come directly from redux
        // which is readonly
        mutablePayload.augmented = traceCloneDeep(
          "augment",
          declaredOperation.augment?.(mutablePayload, getDependencies()),
        );

        const task: Task = {
          id: payload.taskId,
          dependencies: idDependencies,
          name: operationName,
          payload: mutablePayload,
        };

        taskProcessor.enqueue(task).catch(console.error);
      },
    );

    yield* takeEvery(resetAction, function* () {
      yield* call(() => taskProcessor.reset());
    });

    const storedTasks = yield* call(() => taskProcessor.getQueue());
    yield* put(
      actions.seedOperations({
        feature: name,
        operations: storedTasks.map((task) => ({
          mutateResult: undefined,
          task,
        })),
      }),
    );
  };

  return saga;
}
