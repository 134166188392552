import { ReactNode, useCallback, useImperativeHandle, useRef } from "react";
import isEqual from "fast-deep-equal";

import { isIE, uuid } from "@kraaft/shared/core/utils";
import { Button } from "@kraaft/ui";
import { OrderableList } from "@kraaft/web/src/components/orderableList";
import { OrderedListRows } from "@kraaft/web/src/components/orderableList/orderableList.types";

import { useStyles } from "./sortable.styles";

export type EditSource = "drag" | "edit" | "remove" | "add";

interface OptionWithId {
  id: string;
}

export interface SortableListWithAddAndDeleteProps<
  Option extends OptionWithId,
> {
  id?: string;
  options: OrderedListRows<Option> | undefined;
  onChange: (newOptions: OrderedListRows<Option>) => void;
  noBackground?: boolean;
  addButtonText: string;
  onDeleteConfirmation?: (value: Option) => Promise<boolean>;
  onCreate: (id: string, index: number) => Option;
  renderRow: (props: {
    option: Option;
    onDelete: (value: Option) => void;
    onChange: (id: string, value: Option) => void;
    onPressEnter: (id: string) => void;
  }) => ReactNode;
}

export interface SortableListWithAddAndDeleteHandle {
  scrollToEnd(): void;
  focusInput(): void;
}

const SortableListWithAddAndDelete = <Option extends OptionWithId>({
  id,
  fwdRef,
  options: originalOptions,
  onChange,
  onCreate,
  noBackground,
  addButtonText,
  onDeleteConfirmation,
  renderRow,
}: SortableListWithAddAndDeleteProps<Option> & {
  fwdRef?: React.Ref<SortableListWithAddAndDeleteHandle>;
}): JSX.Element => {
  const refOptions = useRef(originalOptions ?? {});
  refOptions.current = originalOptions ?? {};
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const listRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ noBackground: !!noBackground });

  useImperativeHandle(fwdRef, () => ({
    scrollToEnd: () => {
      if (listRef.current) {
        if (isIE()) {
          listRef.current.scrollTop = listRef.current.scrollHeight;
        } else {
          listRef.current.scrollTo({
            top: listRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    },
    focusInput: () => {},
  }));

  const deleteOption = useCallback(
    async (deletedOption: Option) => {
      if (onDeleteConfirmation) {
        const confirmDeletion = await onDeleteConfirmation(deletedOption);
        if (!confirmDeletion) {
          return;
        }
      }
      const elementId = deletedOption.id;
      const newOptions = { ...refOptions.current };
      const element = newOptions[elementId];
      if (!element) {
        return;
      }
      delete newOptions[elementId];
      for (const option of Object.values(newOptions)) {
        if (option.index > element.index) {
          option.index -= 1;
        }
      }
      onChangeRef.current(newOptions);
    },
    [onDeleteConfirmation],
  );

  const focusElement = useCallback((elementId?: string) => {
    let newElementId = elementId;
    setTimeout(() => {
      if (!newElementId) {
        const length = Object.keys(refOptions.current).length;
        newElementId = Object.values(refOptions.current).find(
          (v) => v.index === length - 1,
        )?.data.id;
      }
      if (!newElementId) {
        return;
      }
      const input = document.getElementById(
        `orderable-option-${newElementId}`,
      ) as HTMLInputElement | undefined;
      if (!input) {
        return;
      }
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }, 0);
  }, []);

  const onAdd = useCallback(() => {
    const optionsLength = Object.keys(refOptions.current).length;
    const newId = uuid();

    const newOptions = { ...refOptions.current };
    newOptions[newId] = {
      key: newId,
      index: optionsLength,
      data: onCreate(newId, optionsLength),
    };
    focusElement();
    onChangeRef.current(newOptions);
  }, [focusElement, onCreate]);

  const onSubmitElement = useCallback(
    (elementId: string) => {
      const optionsLength = Object.keys(refOptions.current).length;
      const element = refOptions.current[elementId];
      if (!element) {
        return;
      }
      if (element.index === optionsLength - 1) {
        onAdd();
      } else {
        const nextElement = Object.values(refOptions.current).find(
          (v) => v.index === element.index + 1,
        );
        if (!nextElement) {
          return;
        }
        focusElement(nextElement.data.id);
      }
    },
    [focusElement, onAdd, refOptions],
  );

  const commitIfNeeded = useCallback((elementId: string, value: Option) => {
    const storedElement = refOptions.current[elementId];
    if (!storedElement || isEqual(storedElement.data, value)) {
      return;
    }
    const newOptions = { ...refOptions.current };
    storedElement.data = value;
    onChangeRef.current(newOptions);
  }, []);

  const renderOrderableListRow = useCallback(
    (option: Option) => {
      return renderRow({
        option,
        onDelete: deleteOption,
        onChange: commitIfNeeded,
        onPressEnter: onSubmitElement,
      });
    },
    [commitIfNeeded, deleteOption, onSubmitElement, renderRow],
  );

  return (
    <div className={classes.inputOptionContainer} id={id}>
      <div className={classes.sortableDiv} ref={listRef}>
        <OrderableList
          customScrollThreshold={60}
          rows={refOptions.current}
          updateRows={onChange}
          renderRow={renderOrderableListRow}
          rowContainerClassName={classes.rowContainer}
          withHandle
        />
      </div>
      <Button
        icon="plus"
        variant="QUATERNARY"
        size="SMALL"
        accessibilityLabel={addButtonText}
        text={addButtonText}
        onPress={onAdd}
      />
    </div>
  );
};

export { SortableListWithAddAndDelete };
