import React, { useCallback, useEffect, useMemo } from "react";
import { Insets, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { DetailsAdvancedSections } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections";
import { InfosLoader } from "@kraaft/shared/components/conversationDetails/infos/infos.loader";
import { MembersSection } from "@kraaft/shared/components/conversationDetails/membersSection";
import { QuickAccessSection } from "@kraaft/shared/components/conversationDetails/quickAccessSection";
import { RoomReportSection } from "@kraaft/shared/components/conversationDetails/roomReportSection";
import { SectionSpacer } from "@kraaft/shared/components/conversationDetails/sectionSpacer";
import { useModularRoomDetails } from "@kraaft/shared/components/conversationDetails/useModularRoomDetails";
import { KeyboardAwareScrollView } from "@kraaft/shared/components/keyboardAware";
import {
  subscribeToRoomUsers,
  unsubscribeFromRoomUsers,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";
import { useConversationHeaderHeight } from "@kraaft/shared/core/utils/useConversationHeaderHeight";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import { ColorStyle, Spacing } from "@kraaft/ui";

const SCROLL_INSETS: Insets = { right: -0.1 };

export interface InfosProps {
  roomId: string;
  schemaId: string;
  portalHostname?: string;
  style?: StyleProp<ViewStyle>;
  goToUrl: (url: string) => void;
  openedPanel: ConversationDetailsPanel | undefined;
  setOpenedPanel: (
    panelIdentifier: ConversationDetailsPanel | undefined,
  ) => void;
  isStickyPanel?: boolean;
}

const InfosConnected_ = ({
  roomId,
  portalHostname,
  style,
  goToUrl,
  openedPanel,
  setOpenedPanel,
  isStickyPanel,
  room,
  schemaId,
  rootSection,
}: InfosProps & {
  room: Room;
  rootSection: KSchemaSection;
}) => {
  const navigationService = useNavigationService();

  const dispatch = useDispatch();

  const headerHeight = useConversationHeaderHeight();

  useEffect(() => {
    dispatch(subscribeToRoomUsers({ roomId }));

    return () => {
      dispatch(unsubscribeFromRoomUsers({ roomId }));
    };
  }, [dispatch, roomId]);

  const closePanel = useCallback(() => {
    setOpenedPanel(undefined);
    if (isStickyPanel) {
      navigationService.navigate("Conversation", { roomId });
    }
  }, [isStickyPanel, navigationService, roomId, setOpenedPanel]);

  const {
    ModularDetailsContextProvider,
    modularDetailsElements,
    scrollViewProps,
    topElement,
  } = useModularRoomDetails({
    id: "ide2e-conversation-detail-drawer",
    schemaId,
    room,
    portalHostname,
    openedPanel,
    isStickyPanel,
    onPanelClose: closePanel,
    offsetIndices: 1,
    rootSection,
  });

  const scrollView = useMemo(
    () => (
      <KeyboardAwareScrollView
        style={[styles.scrollViewContainer, style]}
        additionalHeight={headerHeight}
        extraScrollHeight={Spacing.S8}
        scrollIndicatorInsets={SCROLL_INSETS}
        contentContainerStyle={styles.scrollViewContentContainer}
        enableResetScrollToCoords={false}
        {...scrollViewProps}
      >
        <>
          {isNative() && (
            <>
              <QuickAccessSection room={room} />
              <SectionSpacer />
            </>
          )}
          {topElement}
          {modularDetailsElements}

          {!isNative() && (
            <>
              <MembersSection
                room={room}
                portalHostname={portalHostname}
                openedPanel={openedPanel}
                setOpenedPanel={setOpenedPanel}
                onPanelClose={closePanel}
              />
              <SectionSpacer />
            </>
          )}

          <DetailsAdvancedSections
            room={room}
            portalHostname={portalHostname}
            goToUrl={goToUrl}
            openedPanel={openedPanel}
            setOpenedPanel={setOpenedPanel}
            onPanelClose={closePanel}
          />
        </>
      </KeyboardAwareScrollView>
    ),
    [
      style,
      headerHeight,
      scrollViewProps,
      topElement,
      modularDetailsElements,
      room,
      portalHostname,
      openedPanel,
      setOpenedPanel,
      closePanel,
      goToUrl,
    ],
  );

  return (
    <ModularDisplayRequirementsProvider>
      <ModularDisplayExtendedRequirementsProvider
        recordType="room"
        noCheckboxConfirmation={false}
      >
        <ModularDetailsContextProvider>
          {scrollView}
        </ModularDetailsContextProvider>
        <RoomReportSection
          roomId={roomId}
          openedPanel={openedPanel}
          setOpenedPanel={setOpenedPanel}
          portalHostname={portalHostname}
        />
      </ModularDisplayExtendedRequirementsProvider>
    </ModularDisplayRequirementsProvider>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexGrow: 1,
    flexBasis: 0,
  },
  scrollViewContentContainer: {
    paddingBottom: Spacing.S32,
  },
});

const InfosConnected = React.memo(
  withDependencyWrapper(
    InfosConnected_,
    ({ roomId }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const room = useSelector(selectRoom(roomId));
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const roomSchema = useSelector(selectRoomSchema(room?.poolId));

      if (room === undefined || roomSchema === undefined) {
        return undefined;
      }

      return { room, rootSection: roomSchema.rootSection };
    },
    { Fallback: InfosLoader },
  ),
);

const Infos = ({
  goToUrl,
  openedPanel,
  roomId,
  schemaId,
  setOpenedPanel,
  isStickyPanel,
  portalHostname,
  style,
}: InfosProps) => {
  return (
    <ModularDisplayRequirementsProvider>
      <ModularDisplayExtendedRequirementsProvider
        recordType="room"
        noCheckboxConfirmation={false}
      >
        <InfosConnected
          goToUrl={goToUrl}
          openedPanel={openedPanel}
          roomId={roomId}
          schemaId={schemaId}
          setOpenedPanel={setOpenedPanel}
          isStickyPanel={isStickyPanel}
          portalHostname={portalHostname}
          style={style}
        />
      </ModularDisplayExtendedRequirementsProvider>
    </ModularDisplayRequirementsProvider>
  );
};

export { Infos };
