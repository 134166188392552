import { call, put, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* moveDirectorySaga() {
  yield* takeEvery(DirectoryActions.move, moveDirectory);
}

function* moveDirectory({
  payload: {
    roomId,
    directoryId,
    newParentDirectoryId,
    afterSiblingDirectoryId,
  },
}: ReturnType<(typeof DirectoryActions)["move"]>) {
  try {
    yield* call(Api.moveDirectory, {
      roomId,
      directoryId,
      newParentId: newParentDirectoryId,
      afterSiblingId: afterSiblingDirectoryId,
    });
  } catch (e) {
    yield* put(showError({ title: i18n.t("directory.cannotMove") }));
  }
}
