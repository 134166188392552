import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, Text, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useOpenItinerary } from "@kraaft/shared/components/geolocationOpener/useItinerary";
import { UseOpenItineraryParams } from "@kraaft/shared/components/geolocationOpener/useItinerary.types";
import { LocationFallback } from "@kraaft/shared/components/placeSelection/footerPlaceLocation/locationFallback";
import { NoLocationReason } from "@kraaft/shared/components/placeSelection/footerPlaceLocation/noLocationReason";
import { styles } from "@kraaft/shared/components/placeSelection/placeSelection.styles";
import { GeoLocation } from "@kraaft/shared/core/types";
import { shareGoogleMapsGeolocation } from "@kraaft/shared/core/utils/coordinates";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Button } from "@kraaft/ui";

export type FooterPlaceLocationProps = Pick<
  UseOpenItineraryParams,
  "context"
> & {
  location: GeoLocation | undefined;
  noLocationReason: NoLocationReason;
  isDeletable: boolean;
  isEditable: boolean;
  isEditing: boolean;
  onStartEdition: () => void;
  submitEditionText: string;
  onSubmitEdition: () => void;
  onDelete: () => void;
};

const FooterPlaceLocation = ({
  location,
  context,
  isDeletable,
  isEditable,
  isEditing,
  submitEditionText,
  onSubmitEdition,
  onStartEdition,
  onDelete,
  noLocationReason,
}: FooterPlaceLocationProps) => {
  const { t } = useTranslation();
  const {
    ItineraryActionSheet: AvailableMapAppsActionSheet,
    openItinerary: openAvailableMapAppsActionSheet,
  } = useOpenItinerary({
    coords: location?.coords,
    context,
  });

  const roomId = "roomId" in context ? context.roomId : "";

  const shareGeolocation = useCallback(async () => {
    if (!location) {
      return;
    }
    trackEvent({
      eventName: "Share Geolocation",
      room_id: roomId,
    });

    await shareGoogleMapsGeolocation(location);
  }, [location, roomId]);

  return (
    <>
      <View style={styles.footerContainer}>
        <View style={styles.addressContainer}>
          <Text style={styles.addressLabel} numberOfLines={2}>
            <LocationFallback
              location={location}
              noLocationReason={noLocationReason}
            />
          </Text>
          {!isEditing && isEditable && (
            <Button
              accessibilityLabel={t("edit")}
              icon="edit-02"
              variant="TERTIARY"
              onPress={onStartEdition}
            />
          )}
          {isEditing && isDeletable && (
            <Button
              accessibilityLabel={t("del")}
              icon="trash-03"
              variant="TERTIARY"
              onPress={onDelete}
              destructive
            />
          )}
        </View>
        <View style={styles.buttonContainer} pointerEvents="box-none">
          {isEditing ? (
            <Button
              disabled={!location}
              onPress={onSubmitEdition}
              accessibilityLabel={submitEditionText}
              text={submitEditionText}
            />
          ) : (
            <View style={styles.footerButtonsContainer}>
              <Button
                icon="navigation-pointer-01"
                onPress={openAvailableMapAppsActionSheet}
                accessibilityLabel={t("itinerary")}
                text={t("itinerary")}
              />
              {isNative() && location ? (
                <Button
                  icon="share-02"
                  onPress={shareGeolocation}
                  accessibilityLabel={t("share")}
                  text={t("share")}
                  variant="SECONDARY"
                />
              ) : undefined}
            </View>
          )}
        </View>
        <SafeAreaView />
      </View>
      {AvailableMapAppsActionSheet}
    </>
  );
};

export { FooterPlaceLocation };
