import { memo, useCallback, useMemo } from "react";
import { View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { DocumentLoader } from "@kraaft/shared/components/documentLoader";
import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { getMaxWidthOfMessage } from "@kraaft/shared/components/message/messageSize";
import {
  isReplyOrForward,
  MessageRendererProps,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Icon, IconName, Text } from "@kraaft/ui";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageDocument.styles";

type Props = MessageRendererProps<Messages.Document> & {
  sizerWidth: number;
};

const MessageDocument_ = (props: Props) => {
  const { roomId, message, answer, onLongPress, sizerWidth } = props;
  const { isReply, attachment } = message;

  const iconName: IconName = isNative() ? "file-04" : "download-01";

  const isMessageReplyOrForward = isReplyOrForward(message);

  const { textContainerStyle, textStyle, messageStyle } = useMessageStyles({
    isReply,
    hasReactions: messageHasReactions(message),
    isFontSizeLargeBody: true,
    sizerWidth,
  });

  const rootStyle = useMemo(
    () => [baseStyles.messageBox, messageStyle],
    [messageStyle],
  );

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  const maxHeaderWidth = getMaxWidthOfMessage(isNative(), sizerWidth);

  return (
    <MessageWithHeader
      roomId={roomId}
      message={message}
      answerToMessage={answer}
      onLongPressPreview={handleLongPress}
      width={maxHeaderWidth}
    >
      <DocumentLoader
        downloadUrl={attachment.original.downloadUrl}
        filename={attachment.original.filename}
        onLongPress={handleLongPress}
        type="view"
        trackingSource="conversation"
      >
        <DraggableMessage
          messageId={message.id}
          messageType={DraggableMessageType.MessageDocument}
        >
          <View
            style={[
              rootStyle,
              textContainerStyle,
              isMessageReplyOrForward && styles.textContainerWithinBubble,
              styles.documentContainer,
            ]}
          >
            <View style={styles.iconContainer}>
              <DocumentLoader
                downloadUrl={attachment.original.downloadUrl}
                filename={attachment.original.filename}
                onLongPress={handleLongPress}
                type="download"
                trackingSource="conversation"
              >
                <Icon
                  id="ide2e-download-message-document"
                  name={iconName}
                  size="MEDIUM"
                  style={styles.icon}
                />
              </DocumentLoader>
            </View>
            <Text style={[textStyle, styles.text]}>
              <TextProcessor textStyle={textStyle}>
                {attachment.original.filename}
              </TextProcessor>
            </Text>
          </View>
        </DraggableMessage>
      </DocumentLoader>
    </MessageWithHeader>
  );
};

export const MessageDocument = memo(MessageDocument_);
