import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import { Sheet, useCarouselOpenClose } from "@kraaft/ui";

import { MiniMediaCarouselContent } from "../../miniMedia/components/miniMediaCarousel/miniMediaCarouselContent";
import {
  selectDirectoryCarousel,
  selectDirectoryCarouselData,
} from "../directorySelectors";

interface DirectoryCarouselProps {
  roomId: string;
}

const DirectoryCarouselSheet = Sheet({
  default: "overlay",
}).create<DirectoryCarouselProps>(({ Content }) => ({ roomId, onClose }) => {
  const data = useSelector(selectDirectoryCarouselData);

  if (!data) {
    return null;
  }

  return (
    <Content>
      <MiniMediaCarouselContent
        initialIndex={data.initialIndex}
        medias={data.medias}
        onClose={onClose}
        roomId={roomId}
      />
    </Content>
  );
});

export const DirectoryCarousel = () => {
  const dispatch = useDispatch();
  const directoryCarousel = useSelector(selectDirectoryCarousel);
  const { open, onClose } = useCarouselOpenClose(directoryCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = DirectoryCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId: directoryCarousel.show ? directoryCarousel.roomId : undefined,
  });

  if (!directoryCarousel?.show) {
    return null;
  }

  return element;
};
