import { StrictOmit } from "ts-essentials";

import { FetchNetworkError } from "@kraaft/shared/core/services/firebase/networkError";

type RequestInitWithRecordHeaders = StrictOmit<RequestInit, "headers"> & {
  headers?: Record<string, string> | undefined;
};

/**
 * Wrapper around fetch to throw Kraaft NetworkError and
 * automatically compute NetworkError.elapsedDurationMs property
 */
export async function kfetch(url: string, info: RequestInitWithRecordHeaders) {
  const start = Date.now();

  try {
    return await fetch(url, info);
  } catch (e) {
    throw new FetchNetworkError({
      url,
      message: e.message,
      cause: e,
      elapsedDurationMs: Date.now() - start,
    });
  }
}
