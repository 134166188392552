import { ReactNode, useEffect, useRef } from "react";
import {
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  View,
} from "react-native";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";

import { LottieManager } from "@kraaft/shared/components/lottieManager";
import { LottieRef } from "@kraaft/shared/components/lottieManager/lottieManager.props";
import { HapticService } from "@kraaft/shared/core/services/haptic";
import { taskManagerUtils } from "@kraaft/shared/core/store/offline/taskManagerUtils.provider";
import { Sheet, Spacing, Text } from "@kraaft/ui";

import lottie from "./assets/animation.json";
import blueblob from "./assets/blue_blob.png";
import greenblob from "./assets/green_blob.png";

export const NotFullSynchronizedModularFolderSheet = Sheet({
  native: "bottom",
  web: "popup",
})
  .create<{
    modularFolderPendingOperations: number;
    titleSynchronized: string;
    descriptionSynchronized: string;
    titleNotSynchronized: string;
    descriptionNotSynchronized: string;
    cta: ReactNode;
  }>(
    ({ Paper, Content }) =>
      ({
        modularFolderPendingOperations,
        titleSynchronized,
        titleNotSynchronized,
        descriptionSynchronized,
        descriptionNotSynchronized,
        cta,
      }) => {
        const lottieRef = useRef<LottieRef>(null);

        useEffect(() => {
          taskManagerUtils.executeAll();
        });

        const pendingOperationCount = modularFolderPendingOperations;
        const hasPendingOperations = pendingOperationCount > 0;

        useEffect(() => {
          if (!hasPendingOperations) {
            lottieRef.current?.play();
            const timeout = setTimeout(() => {
              HapticService.renderFeedback("info-light");
            }, 450);
            return () => clearTimeout(timeout);
          }
        }, [hasPendingOperations]);

        return (
          <Paper withHandle>
            <Content>
              <View style={styles.centeredContainer}>
                <View style={styles.animationContainer}>
                  {hasPendingOperations && (
                    <Animated.View exiting={FadeOut} style={styles.animation}>
                      <ImageBackground
                        source={blueblob as ImageSourcePropType}
                        style={styles.blob}
                      />
                    </Animated.View>
                  )}
                  {!hasPendingOperations && (
                    <Animated.View entering={FadeIn} style={styles.animation}>
                      <ImageBackground
                        source={greenblob as ImageSourcePropType}
                        style={styles.blob}
                      />
                    </Animated.View>
                  )}
                  <LottieManager
                    style={styles.lottie}
                    lottieRef={lottieRef}
                    source={lottie}
                    loop={false}
                    autoPlay={false}
                  />
                </View>
                <View style={styles.textContainer}>
                  <Text
                    weight="bold"
                    size="TITLE"
                    color="FONT_HIGH_EMPHASIS"
                    style={styles.text}
                  >
                    {hasPendingOperations
                      ? titleNotSynchronized
                      : titleSynchronized}
                  </Text>
                  <Text
                    size="BODY"
                    color="FONT_HIGH_EMPHASIS"
                    style={styles.text}
                  >
                    {hasPendingOperations
                      ? descriptionNotSynchronized
                      : descriptionSynchronized}
                  </Text>
                </View>
              </View>
              {cta}
            </Content>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "SMALL" });

const styles = StyleSheet.create({
  centeredContainer: {
    marginVertical: Spacing.S16,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  animationContainer: {
    position: "relative",
    height: 100,
    width: 100,
  },
  animation: {
    ...StyleSheet.absoluteFillObject,
  },
  lottie: {
    ...StyleSheet.absoluteFillObject,
    padding: 20,
  },
  blob: {
    flexGrow: 1,
    width: "100%",
    aspectRatio: 1,
  },
  textContainer: {
    marginTop: Spacing.S16,
    gap: Spacing.S8,
  },
  text: {
    textAlign: "center",
  },
});
