import { useSelector } from "react-redux";

import { LogText } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/logText";
import { InternalMemberJoinedPoolEvent } from "@kraaft/shared/core/modules/message/core/log.message";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { selectUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { ColorStyle } from "@kraaft/ui";

interface InternalMemberJoinedPoolProps {
  event: InternalMemberJoinedPoolEvent;
}

export const InternalMemberJoinedPool = ({
  event,
}: InternalMemberJoinedPoolProps) => {
  const user = useSelector(selectUser(event.userId));
  const pool = useSelector(selectOnePool(event.poolId));

  return (
    <LogText
      color={ColorStyle.FONT_HIGH_EMPHASIS}
      i18nKey="logInternalMemberJoinedPool"
      userId={event.userId}
      values={{
        name: pool?.name,
        username: getUsername(user),
      }}
      userColor={user?.usernameColor ?? ColorStyle.FONT_HIGH_EMPHASIS}
    />
  );
};
