import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useArchive } from "@kraaft/shared/core/modules/room/hooks/roomArchive.hooks";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { ArchiveSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";

function useIsArchived(roomId: string) {
  const isArchivedForUser = useSelector(
    RoomCardSelectors.selectArchivedForUser(roomId),
  );
  const isArchivedForAll = useSelector(
    RoomCardSelectors.selectArchivedForAll(roomId),
  );

  return useMemo(
    () => ({
      isArchived: Boolean(isArchivedForUser || isArchivedForAll),
      isArchivedForAll: Boolean(isArchivedForAll),
    }),
    [isArchivedForAll, isArchivedForUser],
  );
}

export function useArchiveRoomCard(
  roomId: string,
  source: Exclude<ArchiveSource, "room_info_tab">,
) {
  const isCurrentUserMemberOfRoom = useSelector(
    RoomCardSelectors.selectIsCurrentUserMember(roomId),
  );

  const { isArchived, isArchivedForAll } = useIsArchived(roomId);

  return useArchive({
    isArchivedForAll,
    isArchived,
    roomId,
    source,
    isCurrentUserMemberOfRoom,
  });
}
