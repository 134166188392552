import { useCallback, useMemo, useState } from "react";
import { LayoutChangeEvent, StyleSheet, View, ViewStyle } from "react-native";

import { ForwardedBy } from "@kraaft/shared/components/message/forward/forwardedBy";
import {
  isReplyOrForward,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { ReplyPreview } from "@kraaft/shared/components/replyPreview";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Color, Spacing } from "@kraaft/ui";

interface Props {
  children: React.ReactNode;
  roomId: string;
  message: AnyUserMessage;
  answerToMessage?: AnyUserMessage;
  onLongPressPreview?: () => void;
  withBubbleStyle?: boolean;
  removeMesageBackground?: boolean;
  width?: number;
  fitContent?: boolean;
}

const MessageWithHeader = (props: Props) => {
  const {
    children,
    message,
    answerToMessage,
    onLongPressPreview,
    withBubbleStyle = true,
    removeMesageBackground = false,
    width: specifiedWidth,
    fitContent,
  } = props;
  const [contentWidth, setContentWidth] = useState<number | undefined>(
    undefined,
  );

  const width = specifiedWidth ?? contentWidth;

  const { isReply } = message;

  const hasReactions = messageHasReactions(message);
  const { messageBackgroundColor, messageStyle } = useMessageStyles({
    isReply,
    hasReactions,
  });

  const isMessageReplyOrForward = isReplyOrForward(message);

  const messageBackground = useMemo<ViewStyle>(
    () => ({
      borderTopLeftRadius: isReply ? 2 : 16,
      borderTopRightRadius: isReply ? 16 : 2,
      borderRadius: 0,
      flexDirection: "column",
      overflow: "hidden",
    }),
    [isReply],
  );

  const onHeaderLayout = useCallback((event: LayoutChangeEvent) => {
    setContentWidth(event.nativeEvent.layout.width);
  }, []);

  const additionalHeaderContainerStyle = useMemo(
    () => ({
      backgroundColor: messageBackgroundColor as string,
      maxWidth: width,
    }),
    [width, messageBackgroundColor],
  );

  return (
    <View
      style={[
        (withBubbleStyle || isMessageReplyOrForward) && messageStyle,
        removeMesageBackground && styles.removeBackgroundColor,
      ]}
    >
      {isMessageReplyOrForward ? (
        <View style={messageBackground}>
          <View
            style={[styles.headerContainer, additionalHeaderContainerStyle]}
          >
            <>
              {MessageHelper.isForwarded(message) ? (
                <ForwardedBy message={message} isReply={isReply} />
              ) : null}
              {answerToMessage ? (
                <ReplyPreview
                  onLongPress={onLongPressPreview}
                  message={answerToMessage}
                  isReply={isReply}
                  style={styles.replyPreview}
                />
              ) : null}
            </>
          </View>

          {fitContent ? (
            <View onLayout={onHeaderLayout} style={styles.childrenContainer}>
              {children}
            </View>
          ) : (
            children
          )}
        </View>
      ) : (
        children
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexShrink: 1,
  },
  childrenContainer: {
    alignSelf: "center",
  },
  removeBackgroundColor: {
    backgroundColor: Color.TRANSPARENT,
  },
  replyPreview: {
    margin: Spacing.S8,
    marginBottom: Spacing.NONE,
  },
});

export { MessageWithHeader };
