import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { Toggle } from "@kraaft/shared/components/toggle";
import { AiDirectoryApi } from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.api";
import {
  AiCategory,
  useAiDirectorySettings,
} from "@kraaft/shared/core/modules/aiDirectory/aiDirectory.hook";
import { AddCategorySheet } from "@kraaft/shared/core/modules/aiDirectory/components/aiDirectorySection/addCategory.sheet";
import { RenameCategorySheet } from "@kraaft/shared/core/modules/aiDirectory/components/aiDirectorySection/renameCategory.sheet";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectCurrentContextAtLeastAdmin,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { ActionSheet, Button, Text } from "@kraaft/ui";
import { Box } from "@kraaft/web/src/components/box";
import { KTable, KTableConfig } from "@kraaft/web/src/components/kTable";
import { IntegrationSection } from "@kraaft/web/src/views/settings/manageIntegrations/integrationSection";

import { useStyles } from "./aiDirectorySection.styles";

export const AiDirectorySection = () => {
  const poolId = useSelector(selectCurrentPool)?.id;
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);
  const isAdmin = useSelector(selectCurrentContextAtLeastAdmin);

  if (poolId && (isSuperAdmin || isAdmin)) {
    return <AiDirectory poolId={poolId} />;
  }

  return null;
};

interface AiDirectoryProps {
  poolId: string;
}

const AiDirectory = ({ poolId }: AiDirectoryProps) => {
  const config = useAiDirectorySettings(poolId);
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  const { t } = useTranslation();
  const classes = useStyles();

  const [isEnabled, setIsEnabled] = useState(config?.enabled || false);

  useEffect(() => {
    setIsEnabled(config?.enabled || false);
  }, [config]);

  const toggleEnable = async (enabled: boolean) => {
    if (poolId) {
      setIsEnabled(enabled);
      try {
        if (enabled) {
          await AiDirectoryApi.enableAiDirectory({ poolId });
        } else {
          await AiDirectoryApi.disableAiDirectory({ poolId });
        }
      } catch (e) {
        console.log("Error toggling ai directories settings", e);
        setIsEnabled(config?.enabled || false);
      }
    }
  };

  const reset = async () => {
    newConfirmationAlert({
      title: t("aiDirectory.resetConfirmation"),
      onConfirm: () => AiDirectoryApi.resetAiDirectory({ poolId }),
    });
  };

  const isSectionVisible = isSuperAdmin || config?.enabled;

  if (!isSectionVisible) {
    return null;
  }

  return (
    <IntegrationSection
      actions={[]}
      details={
        <div className={classes.sectionContainer}>
          <div className={classes.description}>
            <Text color="FONT_LOW_EMPHASIS">
              {t("aiDirectory.description")}
            </Text>
          </div>

          <div className={classes.toggleContainer}>
            <Toggle value={isEnabled} setValue={toggleEnable} />
            {isSuperAdmin && (
              <Button
                variant="SECONDARY"
                destructive
                text={t("aiDirectory.reset")}
                icon="trash-01"
                onPress={reset}
              />
            )}
          </div>

          {isEnabled && (
            <AiCategoryTable
              poolId={poolId}
              categories={config?.categories ?? []}
            />
          )}
        </div>
      }
      title={t("aiDirectory.title")}
    />
  );
};

interface AiCategoryTableProps {
  poolId: string;
  categories: AiCategory[];
}

export const AiCategoryTable = ({
  poolId,
  categories,
}: AiCategoryTableProps) => {
  const { t } = useTranslation();

  const { open: openAddCategorySheet, element: addCategorySheet } =
    AddCategorySheet.use({
      poolId,
    });
  const { open: openRenameCategorySheet, element: renameCategorySheet } =
    RenameCategorySheet.use({
      poolId,
    });

  const { open: openEditCategorySheet, element: editCategorySheet } =
    EditActionSheet.use({
      poolId,
      onRename: (category: AiCategory) => openRenameCategorySheet({ category }),
    });

  const tableConfig = useMemo<KTableConfig<AiCategory>>(
    () => ({
      columns: [
        {
          id: "name",
          label: t("aiDirectory.categoryName"),
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (category) => <Text>✨ {category.name}</Text>,
        },
        {
          id: "actions",
          width: 1,
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (category) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const anchor = useRef(null);
            return (
              <Button
                ref={anchor}
                variant="TERTIARY"
                size="SMALL"
                icon="dots-horizontal"
                onPress={() => openEditCategorySheet({ category, anchor })}
              />
            );
          },
        },
      ],
    }),
    [openEditCategorySheet, t],
  );

  return (
    <Box grow>
      <KTable
        config={tableConfig}
        data={categories}
        extractKey={(item) => item.id}
      />
      <Box mt="S16" row>
        <Button
          icon="plus"
          variant="SECONDARY"
          size="SMALL"
          text={t("aiDirectory.addCategory")}
          onPress={openAddCategorySheet}
        />
      </Box>
      {editCategorySheet}
      {addCategorySheet}
      {renameCategorySheet}
    </Box>
  );
};

const EditActionSheet = ActionSheet().create<{
  poolId: string;
  category: AiCategory;
  onRename: (category: AiCategory) => void;
}>(
  ({ ActionSheetContent, ActionSheetItem }) =>
    ({ onClose, onRename, category, poolId }) => {
      const { t } = useTranslation();
      const dispatch = useDispatch();
      const [isDeleting, setIsDeleting] = useState(false);

      const handleRename = () => {
        onRename(category);
        onClose();
      };

      const handleDelete = async () => {
        setIsDeleting(true);
        try {
          await AiDirectoryApi.deleteAiCategory({
            categoryId: category.id,
            poolId,
          });
          onClose();
        } catch (e) {
          setIsDeleting(false);
          dispatch(showError({ title: t("errorServer"), message: e.message }));
        }
      };

      return (
        <ActionSheetContent>
          <ActionSheetItem
            onClick={handleRename}
            label={t("aiDirectory.renameCategory")}
          />
          <ActionSheetItem
            onClick={handleDelete}
            destructive
            label={t("aiDirectory.deleteCategory")}
            loading={isDeleting}
          />
        </ActionSheetContent>
      );
    },
);
