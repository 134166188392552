import { assert } from "ts-essentials";
import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import { MemoryStateActions } from "@kraaft/shared/core/modules/memory/memoryActions";
import { selectHasDeniedSetRoomLocation } from "@kraaft/shared/core/modules/memory/memorySelectors";
import { addOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageActions";
import { GeolocationOfflineMessage } from "@kraaft/shared/core/modules/message/offline/offlineMessageState";
import { selectRoom } from "@kraaft/shared/core/modules/room/selectors";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { GeoLocation } from "@kraaft/shared/core/types";
import { runSagaAsPromise } from "@kraaft/shared/core/utils/saga.utils";

import * as actions from "./sendMessageActions";

export function* sendGeolocationMessageSagas() {
  yield* takeEvery(actions.sendGeolocationMessage, sendGeolocationMessageSaga);
}

function* setRoomLocationAndShowToast(
  roomId: string,
  geolocation: GeoLocation,
) {
  yield* call(Api.setRoomLocationFromMessage, { roomId, geolocation });

  yield* put(showSuccess({ title: i18n.t("successfulyUpdatedAddress") }));
}

function* deniedSetRoomLocation(roomId: string) {
  yield* put(MemoryStateActions.deniedSetRoomLocation({ roomId }));
}

function* sendGeolocationMessageSaga({
  payload: { roomId, geolocation, optimisticId, answerTo },
}: ReturnType<typeof actions.sendGeolocationMessage>) {
  const senderId = yield* select(selectCurrentUserId);
  assert(senderId);

  const offlineMessage: GeolocationOfflineMessage = {
    senderId,
    roomId,
    optimisticId,
    writtenAt: new Date().getTime(),
    type: "geolocation",
    geolocation,
    answerTo,
  };

  yield* put(addOfflineMessage(offlineMessage));

  const hasDeniedSetRoomLocation = yield* select(
    selectHasDeniedSetRoomLocation(roomId),
  );

  if (hasDeniedSetRoomLocation) {
    return;
  }

  const room = yield* select(selectRoom(roomId));
  if (room === undefined) {
    return;
  }
  const roomSchema = yield* select(selectRoomSchema(room.poolId));
  if (roomSchema === undefined) {
    return;
  }

  const firstGeolocationColumn = KSchemaUtils.getFirstColumnOfType(
    roomSchema,
    KColumnType.geolocation,
  );

  if (
    firstGeolocationColumn &&
    room.record.properties[firstGeolocationColumn.key]?.value === undefined
  ) {
    AlertDialog.alert(
      i18n.t("setRoomLocationFromMessageAlert.title"),
      i18n.t("setRoomLocationFromMessageAlert.explanation"),
      [
        {
          text: i18n.t("setRoomLocationFromMessageAlert.yes"),
          onPress: async () => {
            await runSagaAsPromise(
              setRoomLocationAndShowToast,
              roomId,
              geolocation,
            );
          },
          style: "default",
        },
        {
          text: i18n.t("setRoomLocationFromMessageAlert.no"),
          onPress: async () => {
            await runSagaAsPromise(deniedSetRoomLocation, roomId);
          },
          style: "cancel",
        },
      ],
    );
  }
}
