import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import {
  FolderCheckedEvent,
  FolderCreatedEvent,
  FolderUncheckedEvent,
} from "@kraaft/shared/core/modules/message/core/log.message";
import { selectSchemaIcon } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Color, FontSize, getFontIcon, Text } from "@kraaft/ui";

export const PrependWithSchemaIcon = ({
  event,
}: {
  event: FolderCreatedEvent | FolderCheckedEvent | FolderUncheckedEvent;
}) => {
  const iconName = useSelector(selectSchemaIcon(event.schemaId));

  return iconName ? (
    <Text style={styles.icon}>
      {getFontIcon(KSchemaUtils.getSchemaIconName(iconName))}{" "}
    </Text>
  ) : null;
};

const styles = StyleSheet.create({
  icon: {
    fontFamily: "icomoon",
    fontSize: FontSize.MEDIUM,
    color: Color.WHITE,
    letterSpacing: -2,
  },
});
