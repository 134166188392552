import { EditOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const editOperation = EditOneOperationBuilder.create<LibrarySchema>()
  .payload<{ update: { enabled?: boolean } }>()
  .expected((aggregate, payload) => {
    if (payload.update.enabled !== undefined) {
      aggregate.enabled = payload.update.enabled;
    }

    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.editLibrarySchema({
      librarySchemaId: payload.id,
      update: payload.update,
    });

    return { updatedAt };
  });
