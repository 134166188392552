import { useCallback, useContext } from "react";

import { MessageTextEditorContext } from "@kraaft/shared/components/messageTextEditor/messageTextEditorContext";
import { MessageWithTextCapability } from "@kraaft/shared/core/modules/message/core/message.helper";

export function useEditedMessage() {
  const { selectedMessage } = useContext(MessageTextEditorContext);
  return selectedMessage;
}

export function useStartEditingMessageText(): (
  message: MessageWithTextCapability,
) => void {
  return useContext(MessageTextEditorContext).setSelectedMessage;
}

export function useStopEditingMessageText() {
  const { setSelectedMessage } = useContext(MessageTextEditorContext);
  return useCallback(() => setSelectedMessage(undefined), [setSelectedMessage]);
}
