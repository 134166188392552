import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { AskPoolCompanySizeForm } from "@kraaft/shared/components/onboarding/askPoolCompanySize/askPoolCompanySizeForm";
import { usePoolCompanySize } from "@kraaft/shared/components/onboarding/askPoolCompanySize/usePoolCompanySize";
import { appsFlyer } from "@kraaft/shared/core/services/appsflyer";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { PoolCompanySize } from "./poolCompanySize";

import { styles } from "./askPoolCompanySize.styles";

const AskPoolCompanySize = () => {
  const { t } = useTranslation();

  const { setPoolCompanySize, isLoading, poolId } = usePoolCompanySize();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Company Size",
  });

  const [selected, setSelected] = useState<PoolCompanySize | undefined>();

  const next = useCallback(() => {
    if (!selected) {
      return;
    }

    setPoolCompanySize(selected).catch(console.error);

    trackEvent({
      eventName: "Onboarding Selected Company Size",
      companySize: selected,
    });

    if (selected === "5-20") {
      appsFlyer.logEvent("onboarding_selected_company_size_5_20", { poolId });
    } else if (selected === ">20") {
      appsFlyer.logEvent("onboarding_selected_company_size_20_x", { poolId });
    }
  }, [poolId, selected, setPoolCompanySize]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.title}>
        {t("poolCompanySize.whatIsYourCompanySize")}
      </Text>
      <AskPoolCompanySizeForm selected={selected} setSelected={setSelected} />
      <View style={styles.spacer} />
      <Button
        loading={isLoading}
        disabled={!selected}
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskPoolCompanySize };
