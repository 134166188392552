import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularDisplayExtendedRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayExtendedRequirements";
import { ModularDisplayRequirementsProvider } from "@kraaft/shared/core/modules/schema/useModularDisplayRequirements";
import { PoolSchemaBuilderContextProvider } from "@kraaft/web/src/components/schemaBuilder/poolSchemaBuilder/poolSchemaBuilderContextProvider";
import { EditSchema } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/editSchema";
import { ElementsThresholdSheet } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsThreshold.sheet";

interface EditPoolSchemaProps {
  schema: KSchema;
  recordsCount: number | undefined;
  hidePreview?: boolean;
}

const EditPoolSchema = ({
  schema,
  recordsCount,
  hidePreview,
}: EditPoolSchemaProps) => {
  const { open: openElementsCountWarningSheet } =
    ElementsThresholdSheet.useOrphan({ schemaCollection: schema.collection });

  return (
    <PoolSchemaBuilderContextProvider
      schema={schema}
      recordsCount={recordsCount}
      hidePreview={hidePreview}
      openElementsCountWarningSheet={openElementsCountWarningSheet}
    >
      <ModularDisplayRequirementsProvider>
        <ModularDisplayExtendedRequirementsProvider
          recordType="formPreview"
          noCheckboxConfirmation={true}
        >
          <EditSchema />
        </ModularDisplayExtendedRequirementsProvider>
      </ModularDisplayRequirementsProvider>
    </PoolSchemaBuilderContextProvider>
  );
};
export { EditPoolSchema };
