import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import {
  DirectoryActions,
  DirectoryStateActions,
} from "@kraaft/shared/core/modules/directory/directoryActions";
import {
  selectChildDirectoryCount,
  selectDirectory,
} from "@kraaft/shared/core/modules/directory/directorySelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* newDirectorySaga() {
  yield* takeEvery(DirectoryActions.add, newDirectory);
}

function* newDirectory({
  payload: { name, roomId, parentId },
  meta,
}: ReturnType<(typeof DirectoryActions)["add"]>) {
  const index = yield* select(selectChildDirectoryCount(roomId, parentId));

  const parent = yield* select(selectDirectory(parentId));

  const optimisticId = name;
  const optimistic: Directory = {
    id: optimisticId,
    roomId,
    name,
    parentId,
    index,
    files: [],
    depth: parent?.depth ?? 0 + 1,
    deepestChildDepth: 0,
    optimistic: true,
  };

  yield* put(DirectoryStateActions.add({ directory: optimistic }));

  try {
    yield* call(Api.addDirectory, { name: name.trim(), roomId, parentId });
    yield* put(DirectoryActions.addSuccess(meta));
  } catch (e) {
    console.log("error in newDirectorySaga: ", e);
    yield* put(DirectoryStateActions.delete({ directoryId: optimisticId }));
    yield* put(DirectoryActions.addFailure(e, meta));

    if (
      HttpError.isHttpErrorWithCode(e, "DirectoryTreeDepthMustNotExceedLimit")
    ) {
      yield* put(showError({ title: i18n.t("directory.errorDepthExceeded") }));
    } else {
      yield* put(showError({ title: i18n.t("directory.cannotAdd") }));
    }
  }
}
