import { memo, useCallback, useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { useIsInConversationPreview } from "@kraaft/shared/components/conversation/conversationContext";
import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { MessageCaption } from "@kraaft/shared/components/message/messageCaption";
import { MediaOpener } from "@kraaft/shared/components/message/messageImage/mediaOpener";
import { MessageRendererProps } from "@kraaft/shared/components/message/messageUtils";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { ColorStyle, Image } from "@kraaft/ui";

import { MessageIcon } from "./messageIcon";
import { getResizedDimensions } from "./messageImageUtils";

import { styles as baseStyles } from "../message.styles";
import { styles } from "./messageImage.styles";

interface Props extends MessageRendererProps<Messages.Image> {
  sizerWidth: number;
}

const MessageImage_ = (props: Props) => {
  const { roomId, message, sizerWidth, answer, onLongPress } = props;
  const { isReply, attachment } = message;

  const isInConversationPreview = useIsInConversationPreview();

  const imageUrl = attachment.original.downloadUrl;

  const imageRect = useMemo(() => {
    return getResizedDimensions(attachment.size, sizerWidth, isNative());
  }, [attachment.size, sizerWidth]);

  const contentWidth = imageRect.width;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      baseStyles.messageBox,
      isReply ? styles.containerReply : styles.containerDefault,
      { opacity: contentWidth > 0 ? 1 : 0 },
    ],
    [contentWidth, isReply],
  );

  const imageStyle = useMemo(
    () => ({
      ...imageRect,
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    }),
    [imageRect],
  );

  const messageContent = useMemo(
    () => (
      <Image
        preferredSize="small"
        style={imageStyle}
        source={imageUrl}
        geolocation={message.attachment.geolocation?.coords}
        {...(!isNative() ? { draggable: true } : {})}
        contentFit="cover"
        testID={`message-image-img-container-${message.attachment.original.filename}`}
      />
    ),
    [
      imageStyle,
      imageUrl,
      message.attachment.geolocation?.coords,
      message.attachment.original.filename,
    ],
  );

  const messageWrapped = useMemo(
    () =>
      isInConversationPreview ? (
        messageContent
      ) : (
        <MessageIcon message={message} roomId={roomId}>
          {messageContent}
        </MessageIcon>
      ),
    [isInConversationPreview, message, messageContent, roomId],
  );

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  return (
    <View style={containerStyle}>
      <MessageWithHeader
        roomId={roomId}
        message={message}
        answerToMessage={answer}
        onLongPressPreview={handleLongPress}
        width={contentWidth}
        removeMesageBackground={true}
      >
        <MediaOpener onLongPress={handleLongPress} messageId={message.id}>
          <DraggableMessage
            id="image-message"
            messageId={message.id}
            messageType={DraggableMessageType.MessageImage}
            style={styles.draggable}
          >
            {messageWrapped}
          </DraggableMessage>
        </MediaOpener>
        <MessageCaption message={message} width={contentWidth} />
      </MessageWithHeader>
    </View>
  );
};

export const MessageImage = memo(MessageImage_);
