import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { MarkRequired } from "ts-essentials";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectCurrentContextAtLeastAdminOrSuperadmin,
  selectCurrentContextOwnerOrSuperadmin,
  selectCurrentUser,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { ScreenKeyWithoutParams } from "@kraaft/shared/core/services/navigation/navigationParams";
import { conditionalArray } from "@kraaft/shared/core/utils";
import { IconName } from "@kraaft/ui";
import { NavigationMenu } from "@kraaft/web/src/components/menu";
import { NavigationMenuItemProps } from "@kraaft/web/src/components/menu/navigationMenu";
import { Sidebar } from "@kraaft/web/src/components/sidebar";
import { routes } from "@kraaft/web/src/views/app/appRouter/routes";
import { RoomSchemaBuilder } from "@kraaft/web/src/views/roomSchemaBuilder";
import { BackOffice } from "@kraaft/web/src/views/settings/backOffice";
import { LibrarySchemaList } from "@kraaft/web/src/views/settings/librarySchemaList";
import { LibrarySchemaBuilderView } from "@kraaft/web/src/views/settings/librarySchemaList/librarySchemaBuilderView";
import { ManageAnalytics } from "@kraaft/web/src/views/settings/manageAnalytics";
import { ManageBilling } from "@kraaft/web/src/views/settings/manageBilling/manageBilling";
import { ManageDefaultDirectories } from "@kraaft/web/src/views/settings/manageDefaultDirectories";
import { IdentityProviderPage } from "@kraaft/web/src/views/settings/manageIdentityProviders/identityProviderPage";
import { ManageIntegrations } from "@kraaft/web/src/views/settings/manageIntegrations";
import { ManageMapOverlays } from "@kraaft/web/src/views/settings/manageMapOverlays";
import { ManageMembers } from "@kraaft/web/src/views/settings/manageMembers";
import { ManageNotifications } from "@kraaft/web/src/views/settings/manageNotifications";
import { ManagePool } from "@kraaft/web/src/views/settings/managePool";
import { ManageTaskTemplates } from "@kraaft/web/src/views/settings/manageTaskTemplates";
import { MyProfile } from "@kraaft/web/src/views/settings/myProfile";

import {
  makeResponsiveStyle,
  nativeStyles,
  useSettingsStyles,
} from "./settings.styles";

const ensureSettingsLayoutRoute = <T,>(layoutRoute: SettingsLayoutRoute<T>) =>
  layoutRoute;

interface SettingsMenuItem {
  label: string;
  icon: IconName;
  routeName: ScreenKeyWithoutParams;
  matchRoute?: string[];
}

interface SettingsLayoutRoute<T = Record<string, never>> {
  containerId?: string;
  path: string;
  component: React.ComponentType<T>;
  componentProps?: T;
  exact?: boolean;
  menuItem?: SettingsMenuItem;
}

const Settings = () => {
  const { t } = useTranslation();
  const classes = {
    ...useSettingsStyles(),
    ...makeResponsiveStyle(),
  };

  const navigationService = useNavigationService();
  const currentRoute = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const currentPoolId = useSelector(selectCurrentPoolId);
  const isAtLeastPoolAdminOrSuperadmin = useSelector(
    selectCurrentContextAtLeastAdminOrSuperadmin,
  );
  const isPoolOwnerOrSuperadmin = useSelector(
    selectCurrentContextOwnerOrSuperadmin,
  );
  const isSuperAdmin = useSelector(selectCurrentUserIsSuperadmin);

  const layoutRoutes = useMemo(
    () => [
      {
        containerId: "settings-nav-my-profile",
        path: routes.Profile,
        component: MyProfile,
        menuItem: {
          label: t("menuMyProfile"),
          icon: "user-circle",
          routeName: "Profile",
        },
      },
      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-pool",
            path: routes.ManagePool,
            component: ManagePool,
            menuItem: {
              label: t("settingsPool"),
              icon: "keyboard-02",
              routeName: "ManagePool",
            },
          },
        ],
        isPoolOwnerOrSuperadmin,
      ),

      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-members",
            path: routes.ManageMembers,
            component: ManageMembers,
            menuItem: {
              label: t("settingsMembers"),
              icon: "users-01",
              routeName: "ManageMembers",
            },
          },
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),
      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-billing",
            path: routes.Billing,
            component: ManageBilling,
            menuItem: {
              label: t("settingsBilling"),
              icon: "currency-dollar",
              routeName: "Billing",
            },
          },
        ],
        isPoolOwnerOrSuperadmin,
      ),
      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-room-form-builder",
            path: routes.RoomSchemaBuilder,
            component: RoomSchemaBuilder,
            menuItem: {
              label: t("formBuilder.nav.roomSchema"),
              icon: "message-circle-01",
              routeName: "RoomSchemaBuilder",
            },
          },
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),

      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-default-directories",
            path: routes.ManageDefaultDirectories,
            component: ManageDefaultDirectories,
            menuItem: {
              label: t("settingsDefaultDirectories"),
              icon: "folder",
              routeName: "ManageDefaultDirectories",
            },
          },
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),

      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-task-templates",
            path: routes.ManageTaskTemplates,
            component: ManageTaskTemplates,
            menuItem: {
              label: t("settingsTasksLists"),
              icon: "check-done-01",
              routeName: "ManageTaskTemplates",
            },
          },
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),

      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-integrations",
            path: routes.Integrations,
            component: ManageIntegrations,
            menuItem: {
              label: t("settingsIntegrations"),
              icon: "layers-three-01",
              routeName: "Integrations",
            },
          },
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),

      ...conditionalArray(
        [
          ensureSettingsLayoutRoute({
            containerId: "settings-nav-map-overlays",
            path: routes.ManageMapOverlays,
            component: ManageMapOverlays,
            componentProps: { poolId: currentPoolId },
            menuItem: {
              label: t("importMapOverlay.settingsButtonLabel"),
              icon: "map-02",
              routeName: "ManageMapOverlays",
            },
          }),
        ],
        Boolean(isAtLeastPoolAdminOrSuperadmin),
      ),

      ensureSettingsLayoutRoute({
        containerId: "settings-nav-notifications",
        path: routes.ManageNotifications,
        component: ManageNotifications,
        componentProps: { poolId: currentPoolId },
        menuItem: {
          label: t("settingsNotifications"),
          icon: "bell-04",
          routeName: "ManageNotifications",
        },
      }),

      ...conditionalArray(
        [
          ensureSettingsLayoutRoute({
            containerId: "settings-nav-analytics",
            path: routes.Analytics,
            component: ManageAnalytics,
            componentProps: { poolId: currentPoolId },
            menuItem: {
              label: t("settingsAnalytics"),
              icon: "bar-chart-01",
              routeName: "Analytics",
            },
          }),
        ],
        isAtLeastPoolAdminOrSuperadmin,
      ),

      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-nav-schema-library",
            path: routes.SchemaLibraryList,
            component: LibrarySchemaList,
            menuItem: {
              label: t("settingsSchemaLibrary"),
              icon: "form-builder",
              routeName: "SchemaLibraryList",
              matchRoute: [routes.LibrarySchemaBuilder],
            },
            exact: true,
          },
          {
            containerId: "settings-nav-library-schema",
            path: routes.LibrarySchemaBuilder,
            component: LibrarySchemaBuilderView,
          },
        ],
        isUserSuperadmin(currentUser),
      ),
      ...conditionalArray<SettingsLayoutRoute>(
        [
          {
            containerId: "settings-sso-superadmin",
            path: routes.IdentityProviders,
            component: IdentityProviderPage,
            menuItem: {
              label: t("settingsIdentityProviders"),
              icon: "log-in-01",
              routeName: "IdentityProviders",
            },
          },
          {
            containerId: "settings-nav-superadmin",
            path: routes.Superadmin,
            component: BackOffice,
            menuItem: {
              label: t("settingsSuperadmin"),
              icon: "eye",
              routeName: "Superadmin",
            },
          },
        ],
        isUserSuperadmin(currentUser),
      ),
    ],
    [
      currentPoolId,
      currentUser,
      isAtLeastPoolAdminOrSuperadmin,
      isPoolOwnerOrSuperadmin,
      t,
    ],
  );

  const items = useMemo(() => {
    const routesWithMenuItem = layoutRoutes.filter(
      (layoutRoute) => layoutRoute.menuItem !== undefined,
    ) as MarkRequired<SettingsLayoutRoute, "menuItem">[];

    return routesWithMenuItem.map<NavigationMenuItemProps>((layoutRoute) => {
      return {
        id: layoutRoute.path,
        containerId: layoutRoute.containerId,
        label: layoutRoute.menuItem.label,
        icon: layoutRoute.menuItem.icon,
        matchRoute: [
          layoutRoute.path,
          ...(layoutRoute.menuItem.matchRoute ?? []),
        ],
        onPress: () => navigationService.navigateToPathname(layoutRoute.path),
      };
    });
  }, [layoutRoutes, navigationService]);

  const defaultRoute = isSuperAdmin
    ? navigationService.generateRoute(routes.ManageMembers)
    : navigationService.generateRoute(routes.Profile);

  const renderRoute = useCallback(<T extends SettingsLayoutRoute>(item: T) => {
    const SettingsPage = item.component;
    const componentProps = item.componentProps;

    return (
      <Route path={item.path} key={item.path} exact={item.exact}>
        <SettingsPage {...componentProps} />
      </Route>
    );
  }, []);

  return (
    <div className={classes.page}>
      <Sidebar minimizable className={classes.navBar}>
        <NavigationMenu
          menuStyle={nativeStyles.menu}
          items={items}
          currentRoute={currentRoute.pathname}
        />
      </Sidebar>

      <div className={classes.settings}>
        <Switch>
          {/* layoutRoutes contains routes with and without props, so we need to cast it */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(layoutRoutes as any).map(renderRoute)}
          <Redirect to={defaultRoute.route} />
        </Switch>
      </div>
    </div>
  );
};

export { Settings };
