import {
  ActionCreatorWithPayload,
  ActionCreatorWithPreparedPayload,
  PayloadAction,
  Reducer as RReducer,
} from "@reduxjs/toolkit";
import { Selector, SelectorArray, SelectorResultArray } from "reselect";

import {
  BaseAggregate,
  UserDeclaredOperations,
} from "../optimistic/optimistic.types";
import { Task } from "../taskStore/task";

export type ReduxActionsFromDeclaredOperations<
  M extends UserDeclaredOperations,
> = {
  [k in keyof M]: M[k] extends { param: infer Param; input: infer Input }
    ? ActionCreatorWithPreparedPayload<
        [Param],
        Param & Input & { taskId: string }
      > & {
        // TODO implement this
        delayed: ActionCreatorWithPayload<Param & { cancelableId: string }>;
        dismiss: ActionCreatorWithPayload<{ cancelableId: string }>;
        confirm: ActionCreatorWithPayload<{ cancelableId: string }>;
      }
    : never;
};

export type ReduxSelectorsFromDeclaredOperations<Aggregate> = {
  selectDetails: Selector<
    any,
    { state: Record<string, Aggregate>; created: Record<string, Aggregate> }
  >;
  select(id: string): Selector<any, Aggregate | undefined>;
  selectAll: Selector<any, Record<string, Aggregate>>;
  selectAllArray: Selector<any, Aggregate[]>;
  selectTaskStatus(id: string | undefined): Selector<any, boolean>;
  createSelector<Selectors extends SelectorArray, Result>(
    selectorsArray: Selectors,
    combiner: (
      ...args: SelectorResultArray<
        [
          Selector<
            any,
            {
              state: Record<string, Aggregate>;
              created: Record<string, Aggregate>;
            },
            any
          >,
          ...Selectors,
        ]
      >
    ) => Result,
  ): Selector<any, Result>;
};

export type ReduxStateActions<Aggregate> = {
  set: ActionCreatorWithPayload<{
    feature: string;
    operationIndexes: number[];
    aggregates: Record<string, Aggregate>;
  }>;
  receive: ActionCreatorWithPayload<Record<string, Aggregate>>;
};

export interface UserProvidedSelectors<Aggregate extends BaseAggregate> {
  /**
   * Selector that selects all the pristine aggregates. To ensure stability in the optimistic
   * this data should never be altered, except by the returned Actions.set action.
   */
  selectRawAggregate: Selector<any, Record<string, Aggregate>>;
}

export type ReduxSelector<T> = (state: any) => T;

export type ReduxState = any;

export type ReduxReducer = RReducer<any, any>;

export type ReduxSaga = () => Generator<any, any, any>;

export type ReduxExtraGenerator<Aggregate extends BaseAggregate, T> = (
  getState: () => ReduxState,
  selectAggregate: (id: string) => (state: ReduxState) => Aggregate | undefined,
  selectAll: (state: ReduxState) => Record<string, Aggregate>,
) => T;

export type ReduxErrorAction = ActionCreatorWithPayload<{
  feature: string;
  task: Task;
  error: any;
}>;

export class ReduxBundleHelper {
  static getActionPrefix(aggregateName: string) {
    return `${aggregateName}/operations/`;
  }

  static getOperationNameFromActionType(aggregateName: string, type: string) {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    return type.split(ReduxBundleHelper.getActionPrefix(aggregateName))[1]!;
  }
}
