import { DeleteOneOperationBuilder } from "@kraaft/offline";
import { Api } from "@kraaft/shared/core/services/api";

import { KSchema } from "../modularTypes/kSchema";

export const deleteOperation =
  DeleteOneOperationBuilder.create<KSchema>().mutate(async (payload, task) => {
    await Api.deleteSchema({
      requestId: task.id,
      schemaId: payload.id,
    });
  });
