import { makeStyles } from "@mui/styles";

import { ColorStyle, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingLeft: Spacing.S12,
    paddingRight: Spacing.S8,
    borderRadius: Radius.SMALL,
  },
  infos: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    paddingTop: Spacing.S12,
    paddingBottom: Spacing.S12,
    rowGap: Spacing.S4,
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    marginLeft: Spacing.S8,
    justifyContent: "end",
  },
  editorContainer: {
    paddingBottom: Spacing.S12,
  },
  input: {
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: Spacing.S4,
    width: "100%",
  },
  changeWarning: {
    gridColumn: 2,
  },
});
