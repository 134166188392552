import { call, put, takeEvery } from "typed-redux-saga/macro";

import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { setDefaultDirectoryTreeForPool } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminReducers";

export function* setDefaultTreeForPoolSaga() {
  yield* takeEvery(
    DirectoryActions.setDefaultTreeForPool,
    setDefaultTreeForPool,
  );
}

function* setDefaultTreeForPool({
  payload,
}: ReturnType<(typeof DirectoryActions)["setDefaultTreeForPool"]>) {
  try {
    yield* put(setDefaultDirectoryTreeForPool(payload));
    yield* call(Api.setDefaultDirectoryTree, payload);
    yield* put(showSuccess({ title: i18n.t("directory.successfullySaved") }));
  } catch (e) {
    yield* put(showError({ title: i18n.t("directory.errorSaving") }));
  }
}
