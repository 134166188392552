import { t } from "i18next";
import { call, put, select } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import { clearLoader } from "@kraaft/shared/core/modules/loaders/loaderActions";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { fetchUnknownMessages } from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import {
  MiniMediaActions,
  MiniMediaStateActions,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.actions";
import { onlyMiniMediaOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.utils";
import { waitFor } from "@kraaft/shared/core/utils/sagas";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

import { DirectoryStateActions } from "../../directory/directoryActions";

export function* openMiniMediaSaga({
  payload,
  meta,
}: ReturnType<typeof MiniMediaActions.openMiniMedia>) {
  yield* put(
    fetchUnknownMessages({
      roomId: payload.roomId,
      messageIds: [payload.messageId],
    }),
  );
  const message: AnyMessage = yield waitFor(
    selectMessageInRoom(payload.roomId, payload.messageId),
  );

  switch (message.type) {
    case "image":
    case "video": {
      let mediaIds = [payload.messageId];
      if (payload.directoryId) {
        const directory = yield* select(selectDirectory(payload.directoryId));

        if (!directory) {
          return;
        }
        mediaIds = onlyMiniMediaOfType(directory.files, ["image", "video"]).map(
          (media) => media.id,
        );

        yield* put(
          DirectoryStateActions.openCarousel({
            roomId: payload.roomId,
            directoryId: payload.directoryId,
            messageId: payload.messageId,
          }),
        );
        break;
      }

      yield* put(
        MiniMediaStateActions.openMiniMediaCarousel({
          roomId: payload.roomId,
          messageId: payload.messageId,
          mediaIds,
        }),
      );
      break;
    }
    case "document": {
      const path = yield* call(() =>
        fileAllocator.temporaryIfNeeded(
          message.attachment.original.downloadUrl,
        ),
      );

      try {
        yield* call(() => fileSaver.viewDocument(path));
        trackEvent({
          eventName: "Open Document",
          source: payload.trackingSource,
          document_name: message.attachment.original.filename,
        });
      } catch (e) {
        yield* put(showError({ title: t("couldNotOpenDocument") }));
      }
      break;
    }
    default:
      break;
  }
  yield* put(clearLoader(meta.loader.id));
}
