import { useCallback, useRef } from "react";
/**
 * Used to register an effect based on an incoming ref
 * Will call your function once the ref is acquired and call the
 * return function when the ref stops existing
 *
 * const ref = useEffectRef((node) => {
 *   node.addEventListener(fn);
 *   return () => node.removeEventListener(fn);
 * })
 *
 * return <div ref={ref} />
 */

// biome-ignore lint/suspicious/noConfusingVoidType: void is a totally fine return type from function
export function useEffectRef<T>(fn: (node: T) => (() => void) | void) {
  const unsubscribe = useRef<() => void>();

  const handleRef = useCallback(
    (ref: T | null) => {
      if (ref === null) {
        unsubscribe.current?.();
        unsubscribe.current = undefined;
        return;
      }
      const unsub = fn(ref);
      if (unsub) {
        unsubscribe.current = unsub;
      }
    },
    [fn],
  );

  return handleRef;
}
