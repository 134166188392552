import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import type { GestureResponderEvent, ReturnKeyTypeOptions } from "react-native";

import { RoundButton } from "@kraaft/shared/components/roundButton";
import { TextInputWithMentions } from "@kraaft/shared/components/textInputWithMentions/textInputWithMentions";
import type { TextInputWithMentionsHandle } from "@kraaft/shared/components/textInputWithMentions/textInputWithMentions";
import type { Mention } from "@kraaft/shared/core/framework/mentionnableText/mention";
import type { MentionAwareText } from "@kraaft/shared/core/framework/mentionnableText/mentionAwareText";
import { Icon } from "@kraaft/ui";
import type { IconName } from "@kraaft/ui";

import { styles } from "./textMessageInput.styles";
import { isNative } from "@kraaft/helper-functions";
import { AllEmojisSheet } from "@kraaft/shared/components/message/emojiPicker/allEmojisSheet";
import { BaseEmojisWidth } from "@kraaft/shared/components/message/emojiPicker/baseEmojis";
import { RoundSelectButton } from "@kraaft/shared/components/roundSelectButton";

export interface TextMessageInputProps {
  isEditingMessage: boolean;
  isLoading?: boolean;
  placeholder?: string;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  onPublish: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  large?: boolean;
  isSubmitDisabled?: boolean;
  mentionAwareText: MentionAwareText;
  availableMentions: Mention[];
}

export interface TextMessageInputHandle {
  focus(): void;
  blur(): void;
}

const TextMessageInput = forwardRef<
  TextMessageInputHandle,
  TextMessageInputProps
>(
  (
    {
      large,
      isEditingMessage,
      placeholder,
      returnKeyType = "done",
      onPublish,
      onBlur,
      onFocus,
      isLoading,
      isSubmitDisabled,
      mentionAwareText,
      availableMentions,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const buttonIcon: IconName = isEditingMessage ? "check" : "send-03-fill";

    const inputRef = useRef<TextInputWithMentionsHandle>(null);
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    }));

    const handleSubmit = useCallback(() => {
      if (!isSubmitDisabled) {
        onPublish();
      }
    }, [isSubmitDisabled, onPublish]);

    const { element: emojiPickerSheet, open: openEmojiSheet } =
      AllEmojisSheet.use({
        onEmojiSelected: (emoji) => mentionAwareText.insertAtCursor(emoji),
        offsetSpacing: "S12",
        placement: "top-end",
        height: 300,
        width: BaseEmojisWidth,
      });

    const handleOpenEmojiSheet = useCallback(
      (event: GestureResponderEvent) => {
        openEmojiSheet({ anchor: { current: event.currentTarget } });
      },
      [openEmojiSheet],
    );

    return (
      <View style={styles.container}>
        <TextInputWithMentions
          ref={inputRef}
          nativeID="room-text-message-input"
          mentionAwareText={mentionAwareText}
          availableMentions={availableMentions}
          onSubmit={handleSubmit}
          containerStyle={[
            styles.textInputContainer,
            large ? styles.largeTextInputContainer : undefined,
          ]}
          placeholder={placeholder || t("messageInputPlaceholder")}
          returnKeyType={returnKeyType}
          editable={!isLoading}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <View style={styles.buttonContainer}>
          {!isNative() && (
            <View style={{ marginLeft: 10 }}>
              <RoundSelectButton
                accessibilityLabel="Select an emoji"
                onPress={handleOpenEmojiSheet}
                size="small"
              >
                <Icon color="ACTION_CTA" size="MEDIUM" name="face-wink-plus" />
              </RoundSelectButton>
              {emojiPickerSheet}
            </View>
          )}
          <RoundButton
            accessibilityLabel={t("send")}
            large={large}
            onPress={handleSubmit}
            disabled={isSubmitDisabled}
            loading={isLoading}
            icon={buttonIcon}
          />
        </View>
      </View>
    );
  },
);

export { TextMessageInput };
