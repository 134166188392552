/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import moment from "moment";

import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import URLSearchParams from "@ungap/url-search-params";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { getAppEngineApiBaseUrl } from "@kraaft/shared/constants/environment/environment.utils";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { selectCurrentPool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { auth } from "@kraaft/shared/core/services/firebase/sdk";
import { usePolling } from "@kraaft/shared/core/utils/usePolling";
import { Button, ColorStyle, FontSize, PixelSize, Spacing } from "@kraaft/ui";
import { KTable, KTableConfig } from "@kraaft/web/src/components/kTable";
import { selectPoolAdmin } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminSelectors";
import { usePoolAdminSubscription } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminUtils";

import { IntegrationSection } from "../integrationSection";
import { SharePointPoolSyncDialog } from "./sharePointPoolSyncDialog";

type AccountTypeData = {
  owner: string;
  status: "valid" | "invalid";
  synchronizedAt: Date;
};

const SharePointSection = () => {
  const { t } = useTranslation();
  const pool = useSelector(selectCurrentPool);
  const poolId = pool?.id;

  usePoolAdminSubscription(poolId);
  const poolAdmin = useSelector(selectPoolAdmin(poolId));
  const [loading, setLoading] = useState(false);

  const account: AccountTypeData | null = usePolling({
    endpoint: "getPoolMicrosoftStorageData",
    arguments: [{ poolId: poolId || "" }],
    delay: poolId ? 10000 : 0,
  });

  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const openDialog = useCallback(() => {
    setShowDialog(true);
  }, []);
  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const config: KTableConfig<AccountTypeData> = useMemo(() => {
    return {
      enableHover: true,
      columns: [
        {
          id: "owner",
          label: t("sharePointAccount"),
          component: (elt: AccountTypeData) => (
            <Typography>{elt.owner}</Typography>
          ),
        },
        {
          id: "status",
          label: t("sharePointToken"),
          component: (elt: AccountTypeData) => (
            <Typography>{t(elt.status)}</Typography>
          ),
        },
        {
          id: "synchronizedAt",
          label: t("sharePointSync"),
          component: (elt: AccountTypeData) => (
            <Typography>
              {[
                moment(elt.synchronizedAt).format("LT"),
                moment(elt.synchronizedAt).format("L"),
              ].join(" ")}
            </Typography>
          ),
        },
        {
          id: "poolFolder",
          label: t("sharePointPoolFolder"),
          component: () => (
            <Button
              accessibilityLabel={
                poolAdmin?.microsoftStoragePoolSync ? t("modify") : t("define")
              }
              text={
                poolAdmin?.microsoftStoragePoolSync ? t("modify") : t("define")
              }
              variant="SECONDARY"
              size="SMALL"
              icon="edit-02"
              onPress={openDialog}
            />
          ),
        },
      ],
      style: {
        columns: {
          color: ColorStyle.FONT_HIGH_EMPHASIS,
          fontSize: FontSize.MEDIUM,
          paddingLeft: Spacing.S16,
        },
        rows: {
          height: PixelSize.S40,
        },
      },
    };
  }, [openDialog, poolAdmin?.microsoftStoragePoolSync, t]);

  const isSynchronized =
    pool?.external?.microsoftStorage?.isSynchronized ||
    account?.status === "invalid" ||
    false;
  const data = account ? [account] : [];
  const connect = useCallback(async () => {
    const searchParams = new URLSearchParams({
      authorization: (await auth().currentUser?.getIdToken()) ?? "",
      poolId: poolId ?? "",
    });

    // for local env, we need to use localhost instead of 127.0.0.1
    const baseUrl = getAppEngineApiBaseUrl().replace(
      /^http:\/\/127\.0\.0\.1:/,
      "http://localhost:",
    );

    window
      .open(
        `${baseUrl}/external/p/connectMicrosoftStorage?${searchParams.toString()}`,
        "_blank",
      )
      ?.focus();
  }, [poolId]);

  const disconnect = useCallback(async () => {
    const handleConfirm = async () => {
      if (!poolId) {
        return;
      }

      setLoading(true);
      try {
        await Api.disconnectMicrosoftStorageFromPool({ poolId });
      } catch (e) {
        dispatch(showError({ title: t("internalError"), message: e.message }));
      } finally {
        setLoading(false);
      }
    };

    newConfirmationAlert({
      title: t("disconnectMicrosoftStorageTitle"),
      message: t("disconnectMicrosoftStorageContent"),
      confirmText: t("confirmDisconnect"),
      onConfirm: handleConfirm,
    });
  }, [t, dispatch, poolId]);

  const actions: ActionSheetItem[] = useMemo(
    () =>
      !isSynchronized
        ? [
            {
              label: t("connect"),
              onPress: connect,
            },
          ]
        : [
            {
              label: t("reconnect"),
              onPress: connect,
            },
            {
              label: t("unconnect"),
              onPress: disconnect,
              style: "destructive",
            },
          ],
    [isSynchronized, t, connect, disconnect],
  );
  const isLoading = loading || (isSynchronized && !account);

  return (
    <>
      <IntegrationSection
        actions={actions}
        details={
          isSynchronized ? (
            <KTable
              data={isLoading ? [] : data}
              extractKey={
                isLoading
                  ? (_) => "loading"
                  : (acct: AccountTypeData) => acct.owner
              }
              config={config}
              loading={isLoading}
            />
          ) : null
        }
        title="SharePoint"
      />
      {poolId && (
        <SharePointPoolSyncDialog
          open={showDialog}
          onClose={closeDialog}
          poolId={poolId}
        />
      )}
    </>
  );
};

export { SharePointSection };
