import { useCallback, useEffect, useState } from "react";

export const useCarouselOpenClose = (isCarouselStateOpen = false) => {
  const [shouldClose, setShouldClose] = useState(false);

  useEffect(() => {
    if (!isCarouselStateOpen) {
      setShouldClose(false);
    }
  }, [isCarouselStateOpen]);

  const handleClose = useCallback(() => {
    setShouldClose(true);
  }, []);

  const open = isCarouselStateOpen && !shouldClose;

  return { open, onClose: handleClose };
};
