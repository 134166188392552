import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { opacify } from "@kraaft/helper-functions";
import { DeleteConfirmationInput } from "@kraaft/shared/components/input/deleteConfirmationInput";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import {
  Button,
  Color,
  ColorStyle,
  FontSize,
  Preloader,
  Radius,
  Sheet,
  Spacing,
  Text,
} from "@kraaft/ui";

interface DeleteElementSheetProps {
  collection: "room" | "folder";
  elementKind: "column" | "section";
  elementName: string;
  onDelete: () => void;
  recordsCount: number | undefined;
}

export const DeleteElementSheet = Sheet({
  web: "popup",
})
  .create<DeleteElementSheetProps>(
    ({ Content, Paper, Header, Footer }) =>
      ({
        onClose,
        elementName,
        elementKind,
        onDelete,
        collection,
        recordsCount,
      }) => {
        const { t } = useTranslation();
        const [canDelete, setCanDelete] = useState(false);

        return (
          <Paper>
            <Header onClose={onClose}>{t("deleteColumn.title")}</Header>
            <Content>
              <View style={styles.container}>
                <DeleteConfirmationInput
                  expectedValue={elementName}
                  onValueMatches={setCanDelete}
                  autoFocus
                />
                <View style={styles.warning}>
                  <Text size="BODY">
                    <Trans
                      i18nKey={
                        elementKind === "section"
                          ? `deleteColumn.warningSection.${collection}`
                          : `deleteColumn.warningColumn.${collection}`
                      }
                      values={{ count: recordsCount }}
                      components={{
                        Loading:
                          recordsCount !== undefined ? (
                            <Text />
                          ) : (
                            <Preloader
                              size={FontSize.BODY}
                              color={ColorStyle.FONT_HIGH_EMPHASIS}
                            />
                          ),
                      }}
                    />
                  </Text>
                </View>
              </View>
            </Content>
            <Footer>
              <Button
                accessibilityLabel={t("cancel")}
                text={t("cancel")}
                onPress={onClose}
                variant="QUATERNARY"
              />
              <Button
                onPress={onDelete}
                text={t("deleteColumn.delete")}
                accessibilityLabel={t("deleteColumn.delete")}
                disabled={!canDelete}
                destructive
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "MEDIUM" });

const styles = StyleSheet.create({
  container: {
    paddingTop: Spacing.S12,
    gap: Spacing.S12,
  },
  warning: {
    padding: Spacing.S12,
    backgroundColor: opacify(Color.ORANGE_BASIC, 0.15),
    borderColor: Color.ORANGE_BASIC,
    borderWidth: 1,
    borderRadius: Radius.SMALL,
  },
});
