import { createReducer } from "@reduxjs/toolkit";

import { UserActions } from "../../user/userActions";
import { PoolAddonsState } from "../poolAddons.state";
import { PoolAddonsActions } from "./poolAddons.actions";

const initialState: PoolAddonsState = {
  poolAddons: {
    planning: false,
  },
};

export const poolAddonsReducer = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);
  addCase(PoolAddonsActions.reset, () => initialState);

  addCase(PoolAddonsActions.set, (state, { payload }) => {
    state.poolAddons = payload.poolAddons ?? initialState.poolAddons;
  });
});
