import { PoolMemberOfflineFeature } from "@kraaft/shared/core/modules/poolMember/poolMember.offlineFeature";

import { offlineReduxBundle } from "../../store/offline";

export const {
  Actions: OfflinePoolMemberActions,
  Selectors: OfflinePoolMemberSelectors,
  StateActions: OfflinePoolMemberStateActions,
} = offlineReduxBundle.create(
  PoolMemberOfflineFeature,
  {
    selectRawAggregate: (state: any) => state.poolMember.poolMembers,
  },
  () => ({}),
);
