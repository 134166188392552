// eslint-disable-next-line no-restricted-imports
import { Image } from "react-native";

import { LocalPath } from "@kraaft/shared/core/modules/file/file";
import {
  FileAllocator,
  fileAllocator,
} from "@kraaft/shared/core/modules/file/fileAllocator";
import {
  ImageHelper,
  ImageQuality,
} from "@kraaft/shared/core/modules/file/imageHelper/imageHelper";
import { Size } from "@kraaft/shared/core/types";

export class WebImageHelper extends ImageHelper {
  async size(path: LocalPath): Promise<Size> {
    const size = await new Promise<Size>((resolve) => {
      const success = (width: number, height: number) =>
        resolve({ width, height });
      Image.getSize(path, success, () => resolve({ width: 0, height: 0 }));
    });
    return size;
  }

  async resize(path: LocalPath) {
    const data = await FileAllocator.resolveLocalPathToBlob(path);
    const resizedPath = await fileAllocator.fromBlob(data);
    return resizedPath;
  }

  async getQuality(path: LocalPath): Promise<ImageQuality> {
    const dimensions = await this.size(path);
    return this.getQualityFromDimension(dimensions);
  }
}
