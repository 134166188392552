import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import {
  canMessageBeAttached,
  isMessageAttached,
} from "@kraaft/shared/components/message/messageUtils";
import { unflagMessage } from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { toggleSelectMessages } from "@kraaft/shared/core/modules/message/messageActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useAttachMenuItems(roomId: string, message: AnyUserMessage) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMemo(() => {
    const classifyMenuItems: ActionSheetItem[] = [];
    const unclassifyMenuItems: ActionSheetItem[] = [];
    if (!roomId) {
      return { classifyMenuItems, unclassifyMenuItems };
    }

    if (canMessageBeAttached(message)) {
      classifyMenuItems.push({
        label: t("attachTo"),
        icon: "flag-03",
        onPress: () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "classify",
          });

          dispatch(
            toggleSelectMessages({
              selectionType: "attach",
              selectionSource: "conversation",
              roomId,
              messageIds: [message.id],
              selectState: true,
            }),
          );
        },
      });
      if (isMessageAttached(message)) {
        unclassifyMenuItems.push({
          label: t("detachAllRecords"),
          icon: "flag-off",
          onPress: () => {
            trackEvent({
              eventName: "Click Message Action Sheet Option",
              room_id: roomId,
              action: "unclassify",
            });

            dispatch(
              unflagMessage({
                roomId,
                messageId: message.id,
              }),
            );
          },
        });
      }
    }
    return {
      classifyMenuItems,
      unclassifyMenuItems,
    };
  }, [roomId, message, t, dispatch]);
}
