import { makeStyles } from "@mui/styles";

import { Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: Spacing.S16,
    boxSizing: "border-box",
    minHeight: "100%",
  },
});
