import { createReducer } from "@reduxjs/toolkit";
import compact from "lodash/compact";

import { applyRecordUpdate } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { RoomDataState } from "@kraaft/shared/core/modules/room/data/roomDataState";
import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import * as actions from "../roomActions";
import { roomAdapter } from "./roomAdapter";
import { roomUserHistoryAdapter } from "./roomUserHistoryAdapter";

const initialState: RoomDataState = {
  roomEntities: roomAdapter.getInitialState(),
  roomUserHistoryEntities: roomUserHistoryAdapter.getInitialState(),
  pages: {},
};

function getOrCreatePage(state: RoomDataState, filterId: string) {
  let page = state.pages[filterId];
  if (!page) {
    page = {
      isLoading: false,
      hasError: false,
      results: { ids: [], hasMore: true, cursor: undefined },
    };
    state.pages[filterId] = page;
  }
  return page;
}

export const roomDataReducers = createReducer(initialState, (builder) => {
  const { addCase } = builder;

  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(actions.updateRooms, (state, { payload }) => {
    roomAdapter.setMany(state.roomEntities, payload);
  });

  addCase(actions.firstRoomsLoaded, (state, { payload }) => {
    roomAdapter.setMany(
      state.roomEntities,
      compact(payload.data.map((item) => item.room)),
    );
    roomUserHistoryAdapter.setMany(
      state.roomUserHistoryEntities,
      compact(payload.data.map((item) => item.userHistory)),
    );

    state.pages[payload.filterId] = {
      isLoading: false,
      hasError: false,
      results: payload.pageResults,
    };
  });

  addCase(actions.updateRoomsLoading, (state, { payload }) => {
    const page = getOrCreatePage(state, payload.filterId);

    page.isLoading = payload.isLoading;
    page.hasError = payload.hasError;
  });

  addCase(actions.cleanAllRooms, () => initialState);

  addCase(actions.RoomArchiveStateActions.setArchived, (state, { payload }) => {
    const { roomId } = payload;
    roomUserHistoryAdapter.updateOne(state.roomUserHistoryEntities, {
      id: roomId,
      changes: { isArchived: true },
    });
  });

  addCase(
    actions.RoomArchiveStateActions.setUnarchived,
    (state, { payload }) => {
      const { roomId } = payload;
      roomUserHistoryAdapter.updateOne(state.roomUserHistoryEntities, {
        id: roomId,
        changes: { isArchived: false },
      });
    },
  );

  addCase(actions.setRoom, (state, { payload }) => {
    const { room, userHistory } = payload;

    roomAdapter.setOne(state.roomEntities, room);

    if (userHistory) {
      roomUserHistoryAdapter.setOne(state.roomUserHistoryEntities, userHistory);
    } else {
      roomUserHistoryAdapter.removeOne(state.roomUserHistoryEntities, room.id);
    }
  });

  addCase(actions.updateRoomRecord, (state, { payload }) => {
    const { roomId, update } = payload;

    const room = state.roomEntities.entities[roomId];
    if (room) {
      applyRecordUpdate(room.record.properties, update);
    }
  });

  addCase(actions.markRoomUnread, (state, { payload }) => {
    const { roomId } = payload;

    roomUserHistoryAdapter.updateOne(state.roomUserHistoryEntities, {
      id: roomId,
      changes: { isMarkedUnread: true },
    });
  });

  addCase(actions.markRoomUnreadFailure, (state, { payload }) => {
    const { roomId, oldValue } = payload;

    roomUserHistoryAdapter.updateOne(state.roomUserHistoryEntities, {
      id: roomId,
      changes: { isMarkedUnread: oldValue },
    });
  });

  addCase(actions.updateRoomRecordFailure, (state, { payload }) => {
    const { roomId, rollback } = payload;
    const room = state.roomEntities.entities[roomId];
    if (room) {
      applyRecordUpdate(room.record.properties, rollback);
    }
  });

  addCase(actions.updateRoomVisibility, (state, { payload }) => {
    const { roomId, visibility } = payload;
    const room = state.roomEntities.entities[roomId];
    if (room) {
      room.visibility = visibility;
    }
  });

  addCase(actions.updateRoomVisibilityFailure, (state, { payload }) => {
    const { roomId, visibility } = payload;
    const room = state.roomEntities.entities[roomId];
    if (room) {
      room.visibility = visibility;
    }
  });

  addCase(
    actions.RoomStateActions.setNotificationFilter,
    (state, { payload }) => {
      const entry = state.roomUserHistoryEntities.entities[payload.roomId];
      if (!entry) {
        return;
      }
      entry.notificationFilter = payload.notificationFilter;
    },
  );

  addCase(actions.RoomStateActions.updateRoomMembers, (state, { payload }) => {
    const { roomId, addedUserIds, removedUserIds } = payload;

    const room = state.roomEntities.entities[roomId];
    if (room) {
      if (addedUserIds !== undefined) {
        for (const userId of addedUserIds) {
          room.members[userId] = {};
        }
      }
      if (removedUserIds !== undefined) {
        for (const userId of removedUserIds) {
          delete room.members[userId];
        }
      }
    }
  });

  addCase(actions.RoomStateActions.roomDeleted, (state, { payload }) => {
    roomAdapter.removeOne(state.roomEntities, payload.roomId);
  });

  addCase(actions.RoomStateActions.roomJoined, (state, { payload }) => {
    const room = roomAdapter
      .getSelectors()
      .selectById(state.roomEntities, payload.roomId);

    roomUserHistoryAdapter.setOne(state.roomUserHistoryEntities, {
      id: payload.roomId,
      isArchived: room?.isArchivedForAll ?? false,
      lastEventAt: new Date(),
      lastReadMessageId: undefined,
    });
  });

  addCase(actions.RoomStateActions.roomLeft, (state, { payload }) => {
    roomUserHistoryAdapter.removeOne(
      state.roomUserHistoryEntities,
      payload.roomId,
    );
  });

  addCase(actions.RoomStateActions.setRoomEmoji, (state, { payload }) => {
    const { roomId, emoji } = payload;
    roomAdapter.updateOne(state.roomEntities, {
      id: roomId,
      changes: { emoji },
    });
  });
});
