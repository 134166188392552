import { httpsCallable } from "../../services/firebase/firebaseUtils";

export class PoolAddonsAPI {
  static async togglePlanning(poolId: string, value: boolean) {
    if (value) {
      await httpsCallable("enablePlanningFeature")({ poolId });
    } else {
      await httpsCallable("disablePlanningFeature")({ poolId });
    }
  }
}
