import { createSlice } from "@reduxjs/toolkit";

import { Actor } from "./actor";

const actorSlice = createSlice({
  name: "actor",
  initialState: { value: undefined } as { value: Actor | undefined },
  reducers: {
    setActor: (state, action: { payload: Actor | undefined }) => {
      state.value = action.payload;
    },
  },
  selectors: {
    selectActor: (state) => state.value,
  },
});

export const { setActor } = actorSlice.actions;
export const actorReducer = actorSlice.reducer;
