import { useCallback } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { ReplyPreview } from "@kraaft/shared/components/replyPreview/replyPreview";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { unsetReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { selectReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageSelectors";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

interface SelectingReplyPreviewProps {
  roomId: string;
  style?: StyleProp<ViewStyle>;
}

export const SelectingReplyPreview = ({
  roomId,
  style,
}: SelectingReplyPreviewProps) => {
  const dispatch = useDispatch();

  const onReplyingClose = useCallback(() => {
    trackEvent({
      eventName: "Cancel Reply To Message",
      room_id: roomId,
    });
    dispatch(unsetReplyingSourceMessage(roomId));
  }, [dispatch, roomId]);

  const messageReplied = useSelector(selectReplyingSourceMessage(roomId));

  if (messageReplied === null || !MessageHelper.isUserMessage(messageReplied)) {
    return null;
  }

  return (
    <ReplyPreview
      isPreview
      message={messageReplied}
      onClose={onReplyingClose}
      style={style}
    />
  );
};
