import React from "react";
import { makeStyles } from "@mui/styles";

import { ColorStyle } from "@kraaft/ui";

export const WebOnlyUnderlineHover = ({
  children,
  color,
}: React.PropsWithChildren<{ color?: string }>) => {
  const classes = useStyles();
  return (
    <span
      className={classes.container}
      style={{
        textDecorationColor: color || ColorStyle.FONT_LOW_EMPHASIS,
      }}
    >
      {children}
    </span>
  );
};

export const useStyles = makeStyles({
  container: {
    display: "flex",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});
