import { Dictionary } from "ts-essentials";

import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { errorReporting } from "@kraaft/shared/core/services/errorReporting";
import { db } from "@kraaft/shared/core/services/firebase/modularApp";
import {
  collection,
  doc,
  DocumentData,
  documentId,
  DocumentSnapshot,
  documentSnapshotExist,
  getDoc,
  getDocs,
  getDocsFromCache,
  limit,
  onSnapshotQuery,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "@kraaft/shared/core/services/firebase/modularQuery";
import { AnyFirestoreMessage } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import {
  normalizeMessage,
  normalizeMessages,
} from "@kraaft/shared/core/services/firestore/normalizeMessage";

export class AssignationDetailsSerializer {}

export const MessageQueries = {
  subscribeToMessages(
    userId: string | undefined,
    roomId: string,
    updateFrom: Date | undefined,
    callback: (
      messages: ReturnType<typeof normalizeMessages>,
      docs: QueryDocumentSnapshot<DocumentData>[],
      firstPayload: boolean,
    ) => void,
  ) {
    const isFirstPayload = { first: true };

    return onSnapshotQuery(
      "subscribeToMessages",
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("updatedAt", "desc"),
        ...(updateFrom ? [where("updatedAt", ">", updateFrom)] : []),
      ),
      (snapshot) => {
        if (snapshot.size > 0) {
          const messages = normalizeMessages(snapshot.docs, userId);

          callback(messages, snapshot.docs, isFirstPayload.first);
        }
        isFirstPayload.first = false;
      },
    );
  },

  async fetchRoomLastMessage(roomId: string, userId: string) {
    const { docs } = await getDocs(
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [last] = docs;
    if (!last) {
      return undefined;
    }
    return normalizeMessage(
      last.id,
      last.data() as AnyFirestoreMessage,
      userId,
    );
  },

  async fetchRoomLastMessages(
    roomId: string,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await getDocs(
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("createdAt", "desc"),
        limit(pageSize),
      ),
    );

    return {
      allDocs: docs,
      messages: normalizeMessages(docs, userId),
    };
  },

  async fetchRoomFirstMessages(
    roomId: string,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await getDocs(
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("createdAt", "asc"),
        limit(pageSize),
      ),
    );

    return {
      allDocs: docs,
      messages: normalizeMessages(docs, userId),
    };
  },

  async fetchMessage(messageId: string, userId: string) {
    try {
      const docSnap = await getDoc(
        doc(db, "messages-projection-2n", messageId),
      );

      if (!documentSnapshotExist(docSnap) || !docSnap.data()) {
        return undefined;
      }
      const item = normalizeMessage(docSnap.id, docSnap.data() as any, userId);
      return {
        message: item,
        snapshot: docSnap,
      };
    } catch (e) {
      console.error("fetchMessage error:", e);
      return undefined;
    }
  },

  async fetchMessagesBefore(
    roomId: string,
    beforeDoc: DocumentSnapshot,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await getDocs(
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("createdAt", "desc"),
        startAfter(beforeDoc),
        limit(pageSize),
      ),
    );

    return {
      allDocs: docs,
      messages: normalizeMessages(docs, userId),
    };
  },

  async fetchMessageAfter(
    roomId: string,
    afterDoc: DocumentSnapshot,
    pageSize: number,
    userId: string,
  ) {
    const { docs } = await getDocs(
      query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        orderBy("createdAt", "asc"),
        startAfter(afterDoc),
        limit(pageSize),
      ),
    );

    return {
      allDocs: docs,
      messages: normalizeMessages(docs, userId),
    };
  },

  async getUnknownMessages(
    messageIds: string[],
    roomId: string,
    userId: string | undefined,
    fromCache: boolean,
  ): Promise<Dictionary<AnyMessage, string>> {
    try {
      const queryRef = query(
        collection(db, "messages-projection-2n"),
        where("roomId", "==", roomId),
        where(documentId(), "in", messageIds),
      );

      const { docs } = fromCache
        ? await getDocsFromCache(queryRef)
        : await getDocs(queryRef);

      return normalizeMessages(docs, userId);
    } catch (e) {
      errorReporting.reportError(
        e,
        `getUnknownMessages messageIds==${messageIds}`,
      );
      return {};
    }
  },
};
