import { StyleSheet, View } from "react-native";

import { ColorStyle, Spacing } from "../../constants";
import { Text } from "../../display";

interface ActionSheetSeparatorProps {
  label: string;
}

export const ActionSheetSeparator = ({ label }: ActionSheetSeparatorProps) => {
  return (
    <View style={styles.root}>
      <View style={styles.separator} />
      <Text color="FONT_LOW_EMPHASIS" size="MEDIUM">
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: Spacing.S16,
    marginVertical: Spacing.S4,
  },
  separator: {
    flexGrow: 1,
    height: 1,
    backgroundColor: ColorStyle.SEPARATOR,
    marginBottom: Spacing.S12,
  },
});
