import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KDialogConfig } from "@kraaft/shared/components/kDialog/kDialogProps";
import { KSelectionList } from "@kraaft/shared/components/kSelectionList";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import {
  attachToFolderFromSelection,
  createFolderFromSelection,
} from "@kraaft/shared/core/modules/message/attach/attachMessageActions";
import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectFolderSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { PortalHost } from "@kraaft/ui";

import { CurrentStepProps } from "../../../multiStepDialogTypes";

import { useStyles } from "../chooseStep.styles";

const PORTAL_HOSTNAME = "chooseFolderDialog";

export interface ChooseModularFolderStepParams {
  roomId: string;
  schemaId: string | undefined;
  onSuccess?: () => void;
}

type ChooseFolderStepProps = CurrentStepProps<ChooseModularFolderStepParams>;

const ChooseModularFolderStep = (props: ChooseFolderStepProps) => {
  const { roomId, setStep, schemaId, onSuccess } = props;

  const dispatch = useDispatch();
  const isRoomExternal = useSelector(RoomSelectors.selectHasExternals(roomId));
  const { t } = useTranslation();
  const classes = useStyles();

  const schema = useSelector(selectFolderSchema(schemaId));

  const { folders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId,
      schemaId: schemaId,
    }),
  );
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");

  const { loaderId, loading } = useLoader({
    prefix: "fillDirectoryFromPhotoGallery",
  });

  const goToPreviousStep = useCallback(() => {
    setStep({
      index: 0,
    });
  }, [setStep]);

  const handleSelect = useCallback(
    ([newSelectedFolderId]: string[]) => {
      if (newSelectedFolderId) {
        setSelectedFolderId(newSelectedFolderId);
      }
    },
    [setSelectedFolderId],
  );

  const handleAttachToExisting = useCallback(
    ({ folderId, folderTitle }: { folderId: string; folderTitle: string }) => {
      if (schema) {
        dispatch(
          attachToFolderFromSelection({
            roomId,
            schemaId: schema.id,
            folderId,
            loaderId,
            folderTitle,
            isRoomExternal,
          }),
        );
      }
      onSuccess?.();
    },
    [dispatch, isRoomExternal, loaderId, onSuccess, roomId, schema],
  );

  const handleAttachToNew = useCallback(() => {
    if (schema) {
      trackEvent({
        eventName: "Create Object Record",
        room_id: roomId,
        schema_id: schema.id,
        is_shared: isRoomExternal,
        schema_name: schema.name,
        record_title: null,
        from: "messages",
      });

      dispatch(
        createFolderFromSelection({
          roomId,
          schemaId: schema.id,
          loaderId,
        }),
      );
      onSuccess?.();
    }
  }, [dispatch, isRoomExternal, loaderId, onSuccess, roomId, schema]);

  const title = t("attachTo");

  const folderItems = useMemo<KSelectableListItem[]>(
    () =>
      folders.map((folder) => {
        const schemaIcon = schema?.icon;

        return {
          label: folder.properties.title.value,
          icon: schemaIcon
            ? KSchemaUtils.getSchemaIconName(schema.icon)
            : "attachment-01",
          identifier: folder.id,
        };
      }),
    [folders, schema],
  );

  const content = (
    <div className={classes.dialogContentContainer}>
      <KSelectionList
        items={folderItems}
        leftIcon="tag-01"
        selected={[selectedFolderId]}
        setSelected={handleSelect}
        noPadding
      />
    </div>
  );

  const leftActions: KDialogConfig["leftActions"] = [
    {
      accessibilityLabel: t("previous"),
      text: t("previous"),
      variant: "SECONDARY",
      onPress: goToPreviousStep,
    },
  ];

  const actions: KDialogConfig["actions"] = [
    {
      accessibilityLabel: t("attachTo"),
      text: t("attachTo"),
      onPress: () => {
        const folderTitle = folders.find(
          (folder) => folder.id === selectedFolderId,
        )?.properties.title.value;
        if (folderTitle) {
          handleAttachToExisting({ folderId: selectedFolderId, folderTitle });
        }
      },
      variant: "PRIMARY",
      loading,
      disabled: selectedFolderId === "",
    },
    {
      icon: "plus",
      accessibilityLabel: t("createNewObject"),
      text: t("createNewObject"),
      onPress: () => {
        handleAttachToNew();
      },
    },
  ];

  return (
    <>
      <KDialogContent
        title={title}
        content={content}
        leftActions={leftActions}
        actions={actions}
        fullHeight
      />
      <PortalHost hostname={PORTAL_HOSTNAME} />
    </>
  );
};

export { ChooseModularFolderStep };
