import { EditOneOperationBuilder } from "@kraaft/offline";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import {
  getModularFolderAttachmentValue,
  incrementPendingOperations,
} from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";

export const addAttachmentFromMessageOperation =
  EditOneOperationBuilder.create<ModularFolder>()
    .payload<{
      roomId: string;
      columnKey: string | undefined;
      messageId: string;
    }>()
    .dependsOn<{
      getMessage(roomId: string, id: string): AnyMessage | undefined;
    }>()
    .augment((payload, { getMessage }) => {
      const message = getMessage(payload.roomId, payload.messageId);
      if (!message || !MessageHelper.hasAttachment(message)) {
        return;
      }
      return { attachment: message.attachment };
    })
    .expected((modularFolder, payload) => {
      incrementPendingOperations(modularFolder);
      if (!payload.attachment) {
        return;
      }
      const propertyValue = getModularFolderAttachmentValue(
        modularFolder,
        payload.columnKey,
      );
      propertyValue?.push(payload.attachment);
    })
    .mutate((payload) =>
      Api.addModularFolderAttachmentFromMessage({
        columnKey: payload.columnKey,
        folderId: payload.id,
        messageId: payload.messageId,
        roomId: payload.roomId,
      }),
    );
