import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { useEmojiSelectorWidth } from "@kraaft/shared/components/avatarEmojiPicker/useEmojiSelectorWidth";
import { RecentAwareEmojiSelector } from "@kraaft/shared/components/emojiSelector/recentAwareEmojiSelector";
import { RecentEmojiSpace } from "@kraaft/shared/core/modules/emoji/emoji.state";
import { selectCurrentPoolLanguage } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { Sheet, useAnimationContext } from "@kraaft/ui";

type EmojiSelectorContentProps = {
  onClose: () => void;
  onEmojiSelected: (emoji: string) => void;
  recentEmojiSpace: RecentEmojiSpace;
};

export const EmojiSelectorSheet = Sheet({
  native: "bottom",
  web: "anchored",
}).create<EmojiSelectorContentProps>(
  ({ Paper, Header, GrowingContent }) =>
    ({ onClose, onEmojiSelected, recentEmojiSpace }) => {
      const { t } = useTranslation();

      const emojiSelectorWidth = useEmojiSelectorWidth();

      const { animationState } = useAnimationContext();

      const isAnimating =
        animationState !== "undetermined" && animationState !== "entered";
      const poolLanguage = useSelector(selectCurrentPoolLanguage);

      const handleEmojiSelected = useCallback(
        async (emoji: string) => {
          onEmojiSelected(emoji);
          onClose();
        },
        [onClose, onEmojiSelected],
      );

      return (
        <Paper noPadding noGap noSafeAreaBottom width={400}>
          {isNative() && <Header onClose={onClose}>{t("pickAnEmoji")}</Header>}
          <GrowingContent height={400} noPadding>
            <RecentAwareEmojiSelector
              width={emojiSelectorWidth}
              poolLanguage={poolLanguage}
              onEmojiSelected={handleEmojiSelected}
              isAnimating={isAnimating}
              recentEmojiSpace={recentEmojiSpace}
            />
          </GrowingContent>
        </Paper>
      );
    },
);
