import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { AlertDialog } from "@kraaft/shared/components/alertDialog";
import { CenterMediaMarker } from "@kraaft/shared/components/geolocation/centerMediaMarker";
import { MapDisplayer } from "@kraaft/shared/components/placeSelection/mapDisplayer";
import { MapDisplayerHandle } from "@kraaft/shared/components/placeSelection/mapDisplayer/mapDisplayerProps";
import { PlaceSearchBar } from "@kraaft/shared/components/placeSelection/placeSearchBar";
import { GeoLocation } from "@kraaft/shared/core/types";
import { formatAddress } from "@kraaft/shared/core/utils";
import {
  Button,
  ColorStyle,
  FontSize,
  Icon,
  Radius,
  Sheet,
  Spacing,
  Text,
} from "@kraaft/ui";

export type EditLocationDialogProps = {
  defaultValue?: GeoLocation;
  thumbnailUrl?: string;
  title: string;
  onLocationChange: (geolocation: GeoLocation | null | undefined) => void;
};

export const EditLocationSheet = Sheet({
  native: "bottom",
  web: "popup",
})
  .create<EditLocationDialogProps>(
    ({ Content, Footer, Header, Paper }) =>
      ({ defaultValue, title, thumbnailUrl, onClose, onLocationChange }) => {
        const { t } = useTranslation();

        const [location, setLocation] = useState<GeoLocation | undefined>(
          defaultValue,
        );

        const mapRef = useRef<MapDisplayerHandle>(null);

        const handleDelete = useCallback(() => {
          AlertDialog.alert(
            t("placeSelection.deleteLocationTitle"),
            t("placeSelection.deleteLocationMessage"),
            [
              {
                style: "destructive",
                text: t("confirm"),
                onPress: () => {
                  onLocationChange?.(undefined);
                  onClose?.();
                },
              },
              { style: "cancel", text: t("cancel") },
            ],
          );
        }, [onClose, onLocationChange, t]);

        const handleCancel = useCallback(() => {
          setLocation(defaultValue);
          onClose();
        }, [defaultValue, setLocation, onClose]);

        const changeLocation = useCallback(
          (newLocation: GeoLocation | undefined) => {
            setLocation(newLocation);
            if (newLocation) {
              mapRef.current?.focusOnLocation(newLocation);
            }
          },
          [],
        );

        const handleSubmit = useCallback(() => {
          onLocationChange(location);
          onClose();
        }, [location, onClose, onLocationChange]);

        const isDeletable = !!defaultValue;

        return (
          <Paper>
            <Header onClose={onClose}>
              <Text>{title}</Text>
            </Header>
            <Content>
              <View style={styles.content}>
                <PlaceSearchBar setLocation={changeLocation} />
                <View style={styles.mapContainer}>
                  <View style={styles.map}>
                    <MapDisplayer
                      ref={mapRef}
                      location={location}
                      isEditing
                      setLocation={setLocation}
                      centerMarker={
                        thumbnailUrl && (
                          <CenterMediaMarker thumbnailUrl={thumbnailUrl} />
                        )
                      }
                    />
                  </View>
                  <View style={styles.footer}>
                    <View style={styles.address}>
                      <Icon name="marker-pin-01" color="FONT_HIGH_EMPHASIS" />
                      <Text style={styles.addressLabel} numberOfLines={2}>
                        {formatAddress(location)}
                      </Text>
                    </View>
                    {isDeletable && (
                      <Button
                        accessibilityLabel={t("del")}
                        icon="trash-03"
                        size="SMALL"
                        variant="TERTIARY"
                        onPress={handleDelete}
                        destructive
                      />
                    )}
                  </View>
                </View>
              </View>
            </Content>
            <Footer>
              <Button
                variant="SECONDARY"
                text={t("cancel")}
                onPress={handleCancel}
              />
              <Button
                text={t("placeSelection.confirm")}
                onPress={handleSubmit}
                disabled={!location}
              />
            </Footer>
          </Paper>
        );
      },
  )
  .withDefaults({ size: "LARGE" });

const MAP_HEIGHT = 384;

export const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    flexShrink: 1,
  },
  mapContainer: {
    borderRadius: Radius.SMALL,
    overflow: "hidden",
  },
  map: {
    height: MAP_HEIGHT,
    overflow: "hidden",
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    paddingHorizontal: Spacing.S16,
    paddingVertical: Spacing.S8,
  },
  address: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    minHeight: 32,
  },
  addressLabel: {
    flexGrow: 1,
    flexWrap: "wrap",
    fontSize: FontSize.BODY,
    fontWeight: "500",
    color: ColorStyle.FONT_HIGH_EMPHASIS,
    flexShrink: 1,
    marginLeft: Spacing.S8,
  },
});
