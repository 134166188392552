import { TaskManagerUtils } from "@kraaft/offline";
import { errorReporter } from "@kraaft/shared/core/modules/errorReporter";
import { network } from "@kraaft/shared/core/modules/network/network.provider";
import { storage } from "@kraaft/shared/core/modules/storage/storage.provider";
import { backgroundService } from "@kraaft/shared/core/services/backgroundService/backgroundService.provider";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import {
  NetworkError,
  TransientError,
} from "@kraaft/shared/core/services/firebase/networkError";

import { trackEvent } from "../../utils/tracking/trackEvent";

export const taskManagerUtils = new TaskManagerUtils(storage, (error) => {
  const isNetworkError = error instanceof NetworkError;

  if (isNetworkError) {
    return "halt";
  }

  errorReporter.reportError(error);

  if (error instanceof TransientError) {
    return "halt";
  }

  if (error instanceof HttpError) {
    return "skip";
  }
  return "skip";
});

taskManagerUtils.onTaskAppended.register(() =>
  backgroundService.registerBackgroundFetch(),
);

// Retry strategy
network.onStateChange.register((state) => {
  if (state.isInternetReachable) {
    trackEvent({
      eventName: "Offline",
      level: "info",
      log: "Network state change",
      data: {
        isInternetReachable: state.isInternetReachable,
        networkType: state.networkType,
      },
    });
    console.log(
      "Retrying on network state change",
      state.isInternetReachable,
      state.networkType,
    );
    taskManagerUtils.executeAll();
  }
});
