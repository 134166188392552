import { forwardRef, useImperativeHandle } from "react";

import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { DownloadReportFormat } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.utils";

import { useDownloadReport } from "./useDownloadReport";

type Props = {
  schemaId: string;
  modularFolderId: string;
};

export interface DownloadReportRef {
  downloadReport: (
    reportTemplate: ReportTemplate,
    format: DownloadReportFormat,
  ) => Promise<void>;
  isGenerating: boolean;
}

export const DownloadReportComponent = forwardRef<DownloadReportRef, Props>(
  ({ schemaId, modularFolderId }, ref) => {
    const hookResult = useDownloadReport({ schemaId, modularFolderId });

    useImperativeHandle(ref, () => hookResult);

    return null;
  },
);
