/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible
import { getAppEngineApiBaseUrl } from "@kraaft/shared/constants/environment/environment.utils";
import { API_VERBOSE } from "@kraaft/shared/constants/global";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { auth } from "@kraaft/shared/core/services/firebase/sdk";
import { FirebaseSDK } from "@kraaft/shared/core/services/firebase/sdk/common";
import { AnyUnexplained } from "@kraaft/shared/core/types";
import { kfetch } from "@kraaft/shared/core/utils/kfetch";

const FIREBASE_API_VERSION = "v70";

interface HttpsCallable {
  // we can send any kind of data and we don't know the result
  // biome-ignore lint/style/useShorthandFunctionType: <explanation>
  (data?: any): Promise<{ data: any }>;
}

export function httpsCallableRaw(functionName: string): HttpsCallable {
  const fullFunctionName = `${FIREBASE_API_VERSION}-${functionName}`;
  // TODO type the params based on the function name
  return (data?: any) => callApi(fullFunctionName, data, true, {});
}

export function httpsCallable(functionName: string): HttpsCallable {
  const fullFunctionName = `${FIREBASE_API_VERSION}-${functionName}`;
  // TODO type the params based on the function name
  return (data?: any) => callApi(fullFunctionName, data, false);
}

export function httpsCallableUnprefixed(functionName: string): HttpsCallable {
  // TODO type the params based on the function name
  return (data?: any) => callApi(functionName, data, false);
}

// TODO correct types based on functionName
async function callApi(
  functionName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = {},
  preventStringifyData = false,
  overrideHeaders?: Record<string, AnyUnexplained>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const date = new Date();
  const requestUrl = `${getAppEngineApiBaseUrl()}/app/${functionName}`;
  const headers: { [name: string]: string } = overrideHeaders ?? {
    "Content-Type": "application/json",
  };

  try {
    const idToken = await auth().currentUser?.getIdToken();

    if (idToken) {
      headers.Authorization = `Bearer ${idToken}`;
    }
  } catch (error) {
    throw FirebaseSDK.interpretError(error);
  }

  const response = await kfetch(requestUrl, {
    method: "POST",
    body: preventStringifyData ? data : JSON.stringify({ data }),
    headers,
  });

  if (API_VERBOSE) {
    console.log(
      `> ${functionName} ${new Date().getTime() - date.getTime()}ms ${
        response.status
      }`,
    );
  }

  if (API_VERBOSE) {
    console.debug(`< ${functionName}, args:`, data);
  }

  if (!response.ok) {
    let message: string | undefined;
    let details: { code: string } | undefined;
    const text = await response.text();
    if (text) {
      try {
        ({
          error: { message, details },
        } = JSON.parse(text));
      } catch (e) {}
    }

    if (!message) {
      message = text ?? response.statusText;
    }

    const error = new HttpError(
      response.status,
      message,
      details?.code,
      details,
    );
    console.warn("HttpError:", error, "details=", details);
    throw error;
  }

  const { result } = await response.json();

  if (API_VERBOSE) {
    console.debug(`>> ${functionName}, result:`, result);
  }

  return { data: result };
}
