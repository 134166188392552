import { EditOneOperationBuilder } from "@kraaft/offline";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const editPresentationSampleReportOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ sampleReportFile: ModernFile<LocalPath> | null }>()
    .expected((aggregate, payload) => {
      if (payload.sampleReportFile !== null) {
        aggregate.presentation.sampleReport = {
          filename: payload.sampleReportFile.filename,
          downloadUrl: payload.sampleReportFile.path,
        };
      } else {
        aggregate.presentation.sampleReport = null;
      }

      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaPresentationSampleReport(
        {
          librarySchemaId: payload.id,
          sampleReportFile: payload.sampleReportFile,
        },
      );

      return { updatedAt };
    });
