import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { difference } from "lodash";

import { EditMembersPanelContent } from "@kraaft/shared/components/editMembersPanelContent";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { changeRoomUsersRequest } from "@kraaft/shared/core/modules/room/roomActions";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { selectAllPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";

interface Props {
  room: Room;
  portalHostname?: string;
  isVisible?: boolean;
  onClose?: () => void;
}

const EditMembersPanel = ({
  room,
  portalHostname,
  isVisible,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const availableUsers = useSelector(selectAllPoolUsers(room.poolId, true));

  const memberIds = useMemo(() => Object.keys(room.members), [room.members]);

  const canEditMembers = Guard.use("Room.editMembers");

  const handleSubmitMembersEdition = useCallback(
    (newSelectedUserIds: string[]) => {
      const removedUserIds = difference(memberIds, newSelectedUserIds);
      const addedUserIds = difference(newSelectedUserIds, memberIds);

      dispatch(
        changeRoomUsersRequest({
          roomId: room.id,
          addedUserIds,
          removedUserIds,
        }),
      );
    },
    [dispatch, memberIds, room.id],
  );

  return (
    <SlidePanel
      name="RoomEditMembers"
      portalHostname={portalHostname}
      open={isVisible}
      onClose={onClose}
    >
      <EditMembersPanelContent
        roomId={room.id}
        poolId={room.poolId}
        selectedUserIds={memberIds}
        availableUsers={availableUsers}
        onClose={onClose}
        onSubmit={handleSubmitMembersEdition}
        allowEdition={canEditMembers}
      />
    </SlidePanel>
  );
};

export { EditMembersPanel };
