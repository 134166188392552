import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

import { PoolAddonsQueries } from "../../poolAddons.queries";
import { PoolAddons } from "../../poolAddons.state";
import { PoolAddonsActions } from "../poolAddons.actions";

export function* subscribeToPoolAddonsSaga() {
  yield takeCountedDeep(
    PoolAddonsActions.subscribe,
    PoolAddonsActions.unsubscribe,
    subscribe,
    unsubscribe,
    (action) => action.payload.poolId,
  );
}

type Meta = EventChannel<{ poolAddons: PoolAddons | undefined }>;

function* subscribe(
  registerMeta: (meta: Meta) => void,
  { payload: { poolId } }: ReturnType<typeof PoolAddonsActions.subscribe>,
) {
  const channel = eventChannel<{ poolAddons: PoolAddons | undefined }>((emit) =>
    PoolAddonsQueries.subscribe(poolId, (poolAddons) => emit({ poolAddons })),
  );

  registerMeta(channel);

  yield* takeEvery(
    channel,
    function* ({ poolAddons }: { poolAddons: PoolAddons | undefined }) {
      yield* put(PoolAddonsActions.set({ poolAddons }));
    },
  );
}

function* unsubscribe(meta?: Meta) {
  yield* put(PoolAddonsActions.reset());
  meta?.close();
}
