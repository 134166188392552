import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { HoverableRenderProps } from "@kraaft/shared/components/hoverable/types";
import { ButtonProps, ColorStyle, Radius, Spacing } from "@kraaft/ui";

import mapModularFolders from "../../assets/images/mapModularFolders.png";
import mapModularFoldersDetails from "../../assets/images/mapModularFoldersDetails.png";
import mapPhotos from "../../assets/images/mapPhotos.png";
import mapRooms from "../../assets/images/mapRooms.png";

const HEIGHT = 60;

const IMAGE: Record<MapButtonBackgroundProps["type"], string | number> = {
  modularFolders: mapModularFolders,
  modularFoldersDetails: mapModularFoldersDetails,
  photos: mapPhotos,
  rooms: mapRooms,
};

export type MapButtonBackgroundProps = Required<
  Pick<ButtonProps, "onPress">
> & {
  type: "modularFolders" | "modularFoldersDetails" | "photos" | "rooms";
  isSelected?: boolean;
  children: ReactNode;
  center?: boolean;
};

export const MapButtonBackground = ({
  type,
  isSelected,
  onPress,
  children,
  center,
}: MapButtonBackgroundProps) => {
  const { t } = useTranslation();

  const accessibilityLabel = t("openMap");

  const renderContent = useCallback(
    ({ hoverProps, isHovered }: HoverableRenderProps): JSX.Element => {
      return (
        <TouchableOpacity
          accessibilityLabel={accessibilityLabel}
          onPress={onPress}
        >
          <View
            style={[
              styles.card,
              isSelected && styles.cardSelected,
              isHovered && styles.cardHovered,
            ]}
            {...hoverProps}
          >
            <ImageBackground
              source={IMAGE[type] as ImageSourcePropType}
              resizeMode="cover"
              style={[
                styles.image,
                center ? styles.imageCentered : styles.imageRight,
              ]}
            >
              {children}
            </ImageBackground>
          </View>
        </TouchableOpacity>
      );
    },
    [accessibilityLabel, onPress, isSelected, type, center, children],
  );

  return <Hoverable>{renderContent}</Hoverable>;
};

const styles = StyleSheet.create({
  card: {
    borderRadius: Radius.SMALL,
    overflow: "hidden",
    borderWidth: 2,
    borderColor: ColorStyle.BACKGROUND_STANDARD,
  },
  cardSelected: {
    borderColor: ColorStyle.ACTION_CTA,
  },
  cardHovered: {
    opacity: 0.8,
  },
  image: {
    height: HEIGHT,
  },
  imageCentered: {
    alignItems: "center",
    justifyContent: "center",
    paddingRight: Spacing.S16,
  },
  imageRight: {
    alignItems: "flex-end",
    justifyContent: "center",
    paddingRight: Spacing.S16,
  },
});
