import { useCallback, useMemo } from "react";
import { StatusBar } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { keyBy } from "lodash";

import { compactMap } from "@kraaft/helper-functions";
import { CarouselImage } from "@kraaft/shared/components/carousel/placeholders/carouselImage";
import { CarouselVideo } from "@kraaft/shared/components/carousel/placeholders/carouselVideo";
import { UploadAttachmentContext } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import {
  fetchUnknownMessages,
  MessageActions,
} from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessages } from "@kraaft/shared/core/modules/message/messageSelectors";
import { canShowRemoveMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { MiniMediaTemporaryCarouselImage } from "@kraaft/shared/core/modules/miniMedia/components/miniMediaCarousel/miniMediaTemporaryCarouselImage";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectCurrentUserAccountOwner,
  selectCurrentUserIsSuperadmin,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { GeoLocation } from "@kraaft/shared/core/types";
import { useRemoveMessage } from "@kraaft/shared/core/utils/useRemoveMessage";
import {
  Carousel,
  CarouselUtils,
  Spacing,
  useMaxSafeAreaInsets,
} from "@kraaft/ui";
import { CarouselItemRenderer } from "@kraaft/ui/src/display/carousel/carousel.types";

import { Guard } from "../../../auth";

interface MiniMediaCarouselContentProps {
  roomId: string;
  medias: MiniMedia[];
  initialIndex: number;
  onClose(): void;
}

function miniMediaKeyExtractor(miniMedia: MiniMedia) {
  return miniMedia.id;
}

export const MiniMediaCarouselContent = ({
  roomId,
  initialIndex,
  medias,
  onClose,
}: MiniMediaCarouselContentProps) => {
  const dispatch = useDispatch();
  const messages = keyBy(
    useSelector(selectMessages(roomId)),
    (message) => message.id,
  );
  const poolId = useSelector(selectCurrentPoolId);
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const isAccountOwner = useSelector(
    selectCurrentUserAccountOwner(poolId ?? ""),
  );

  const uploadContext = useMemo<UploadAttachmentContext>(
    () => ({
      type: "room",
      roomId,
    }),
    [roomId],
  );

  const checkCanEditGeolocation = Guard.useGuard("Message.editGeolocation");

  const removeMessage = useRemoveMessage({ roomId });

  const handleGeolocationChange = useCallback(
    (media: MiniMedia, geolocation: GeoLocation | null | undefined) => {
      dispatch(
        MessageActions.updateAttachmentGeolocation({
          roomId,
          messageId: media.messageId,
          geolocation: geolocation ?? undefined,
        }),
      );
    },
    [dispatch, roomId],
  );

  const renderItem = useCallback<CarouselItemRenderer<MiniMedia>>(
    ({ index, item }) => {
      const message = messages[item.messageId];

      if (message !== undefined && MessageHelper.isUserMessage(message)) {
        const canShowDelete = canShowRemoveMessage(
          message,
          isSuperadmin,
          isAccountOwner,
        );
        const handleDelete = canShowDelete
          ? () => removeMessage(message)
          : undefined;

        const [canShowRelocate] = checkCanEditGeolocation(message);

        if (message.type === "image") {
          return (
            <CarouselImage
              index={index}
              item={item}
              attachment={message.attachment}
              onClose={onClose}
              onLocationChange={
                canShowRelocate ? handleGeolocationChange : undefined
              }
              roomId={roomId}
              uploadContext={uploadContext}
              onDelete={handleDelete}
            />
          );
        }
        if (message.type === "video") {
          return (
            <CarouselVideo
              index={index}
              item={item}
              attachment={message.attachment}
              onClose={onClose}
              onLocationChange={
                canShowRelocate ? handleGeolocationChange : undefined
              }
              roomId={roomId}
              uploadContext={uploadContext}
              onDelete={handleDelete}
            />
          );
        }
        return null;
      }
      if (item.type === "image") {
        return <MiniMediaTemporaryCarouselImage miniMedia={item} />;
      }
      return null;
    },
    [
      messages,
      isSuperadmin,
      isAccountOwner,
      checkCanEditGeolocation,
      removeMessage,
      onClose,
      handleGeolocationChange,
      roomId,
      uploadContext,
    ],
  );

  const handleFocusedChange = useCallback(
    (focused: number) => {
      const items = compactMap(
        CarouselUtils.getIndexesToRender(focused),
        (index) => medias[index]?.messageId,
      );
      if (items.length === 0) {
        return;
      }
      dispatch(fetchUnknownMessages({ roomId, messageIds: items }));
    },
    [dispatch, medias, roomId],
  );

  const insets = useMaxSafeAreaInsets({
    top: Spacing.S8,
    bottom: Spacing.S8,
  });

  return (
    <>
      <StatusBar backgroundColor="black" barStyle="dark-content" />
      <Carousel
        insets={insets}
        initialIndex={initialIndex}
        items={medias}
        open
        onClose={onClose}
        keyExtractor={miniMediaKeyExtractor}
        renderItem={renderItem}
        onFocusedChange={handleFocusedChange}
      />
    </>
  );
};
