import { StorageFile } from "@kraaft/shared/core/modules/common/storageFile";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import {
  ColumnDefinition,
  ColumnValue,
} from "@kraaft/shared/core/modules/schema/modularTypes/columns/column/column";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { Optimistic } from "@kraaft/shared/core/types";

export type KSchemaType = "checkbox" | "custom";

export const folderSchemaIcons = [
  "unchecked",
  "flag",
  "gallery_empty",
  "calendar",
  "forms",
  "key",
  "briefcase",
  "tools",
  "clip",
  "highlighter",
  "paste",
  "bar-chart",
] as const;

export const schemaIcons = [...folderSchemaIcons, "message-circle-01"] as const;

export type KSchemaIcon = (typeof schemaIcons)[number];

export interface KSchemaElementDescription {
  text: string;
  image: StorageFile | undefined;
  document: StorageFile | undefined;
}

export interface KSchemaBaseElement extends Optimistic {
  name: string;
  key: string;
  index: number;
  reportKey: string;
  condition?: CompositeCondition | undefined;
  description?: KSchemaElementDescription | undefined;
}

export interface KSchemaColumnBase extends KSchemaBaseElement {
  elementType: "column";
  nonDeletable?: boolean;
  nonEditableName?: boolean;
}

export type KSchemaColumn<T extends KColumnType = KColumnType> = T extends any
  ? Extract<KSchemaColumnBase & ColumnDefinition, { type: T }>
  : never;

export type KSchemaColumnNot<T extends KColumnType = KColumnType> =
  KSchemaColumn<Exclude<KColumnType, T>>;

export type KSchemaColumnDefinition<T extends KColumnType = KColumnType> =
  Extract<ColumnDefinition, { type: T }>;

export type KSchemaColumnValue<T extends KColumnType = KColumnType> = Extract<
  ColumnValue,
  { columnType: T }
>;

export type KSchemaColumnLiteralValue<T extends KColumnType = KColumnType> =
  KSchemaColumnValue<T>["value"];

export type KSchemaElement = KSchemaSection | KSchemaColumn;

export interface KSchemaSection extends KSchemaBaseElement {
  elementType: "section";
  color: string;
  elements: {
    [key: string]: KSchemaElement;
  };
}

export type SchemaCollection = "folder" | "room";

interface BaseSchema {
  updatedAt: Date;
  id: string;
  poolId: string;
  collection: SchemaCollection;

  name: string;

  rootSection: KSchemaSection;
}

export interface KRoomSchema extends BaseSchema {
  icon: KSchemaIcon;
  collection: "room";
  roomCardDisplayColumns: string[];
}

export type FolderSchemaType = "checkbox" | "custom";
export type SchemaDefaultVisibility = "visible" | "hidden";

export interface KFolderSchema extends BaseSchema {
  collection: "folder";

  index: number;
  description: string | undefined;
  autoIncrement: number;

  icon: KSchemaIcon;
  color: string;

  type: FolderSchemaType;
  highlightedCheckbox: string | undefined;
  defaultVisibility: SchemaDefaultVisibility;
}

export type KSchema = KRoomSchema | KFolderSchema;

export type SchemaColumnWithPath<T extends KColumnType = KColumnType> = {
  path: KSchemaSection[];
  element: KSchemaColumn<T>;
  absoluteIndex: number;
};
type SchemaSectionWithPath = {
  path: KSchemaSection[];
  element: KSchemaSection;
};

export type SchemaElementWithPath<T extends KColumnType = KColumnType> =
  | SchemaColumnWithPath<T>
  | SchemaSectionWithPath;
