import { useDispatch, useSelector } from "react-redux";

import { RoomActions } from "@kraaft/shared/core/modules/room/roomActions";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useRoomPinning(roomId: string) {
  const dispatch = useDispatch();

  const isPinnable = useSelector(RoomCardSelectors.selectIsPinnable(roomId));
  const isPinned = useSelector(RoomCardSelectors.selectIsPinned(roomId));

  const pin = useCallbackRealtime(
    ([_roomId, _isPinned]) => {
      trackEvent({
        eventName: "Pin Room",
        room_id: _roomId,
      });
      dispatch(RoomActions.pin({ roomId: _roomId }));
    },
    [dispatch],
    [roomId, isPinned],
  );

  const unpin = useCallbackRealtime(
    ([_roomId, _isPinned]) => {
      trackEvent({
        eventName: "Unpin Room",
        room_id: _roomId,
      });
      dispatch(RoomActions.unpin({ roomId: _roomId }));
    },
    [dispatch],
    [roomId, isPinned],
  );

  return { pin, unpin, isPinnable, isPinned };
}
