import { createReducer } from "@reduxjs/toolkit";

import { UserActions } from "@kraaft/shared/core/modules/user/userActions";

import { mergeInPlace } from "../../store/mergeInPlace";
import { OfflinePoolMemberStateActions } from "./poolMember.offline";
import { PoolMemberState } from "./poolMember.state";

const initialState: PoolMemberState = {
  poolMembers: {},
};

export const poolMemberReducers = createReducer(initialState, ({ addCase }) => {
  addCase(UserActions.userDisconnectedFromFirebase, () => initialState);

  addCase(OfflinePoolMemberStateActions.set, (state, { payload }) => {
    mergeInPlace(state.poolMembers, payload.aggregates);
  });
});
