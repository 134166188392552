import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../services/auth/useAuth";
import { FirestorePoolMemberPreview } from "../poolMember/collections/poolMemberPreview";
import { selectCurrentUserIsSuperadmin } from "../user/user.selector";
import { Actor } from "./actor";
import { setActor } from "./actor.redux";

export const ActorContext = createContext<Actor | undefined>(undefined);

type PoolRoles = Record<string, { roles: string[] }>;

export const ActorProvider = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const [roles, setRoles] = useState<PoolRoles>({});

  useEffect(() => {
    if (!auth?.id) {
      setRoles({});
      return;
    }

    return FirestorePoolMemberPreview.memberships(auth.id, (previews) => {
      setRoles(
        Object.fromEntries(
          previews.map((p) => [p.poolId, { roles: p.roles || [] }] as const),
        ),
      );
    });
  }, [auth?.id]);

  const actor = useMemo(() => {
    if (!auth?.id) {
      return;
    }
    return {
      userId: auth.id,
      claims: {
        support: isSuperadmin,
        pools: roles,
      },
    } as Actor;
  }, [auth?.id, isSuperadmin, roles]);

  useEffect(() => {
    dispatch(setActor(actor));
  }, [actor, dispatch]);

  return (
    <ActorContext.Provider value={actor}>{children}</ActorContext.Provider>
  );
};
