import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import isEqual from "fast-deep-equal";

import { isNative } from "@kraaft/helper-functions";
import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { getMaxWidthOfMessage } from "@kraaft/shared/components/message/messageSize";
import { TextBubble } from "@kraaft/shared/components/message/messageText/textBubble";
import {
  formatDraggableText,
  isReplyOrForward,
  MessageRendererProps,
} from "@kraaft/shared/components/message/messageUtils";
import { MessageWithHeader } from "@kraaft/shared/components/message/messageWithHeader";
import { getUsernameFromUserId } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { Spacing } from "@kraaft/ui";

import { styles as baseStyles } from "../message.styles";

type Props = MessageRendererProps<Messages.Text> & {
  sizerWidth: number;
};

const MessageText_ = (props: Props) => {
  const { t } = useTranslation();
  const { roomId, message, answer, onLongPress, sizerWidth } = props;
  const { isReply, textModifiedAt, text } = message;

  const isMessageReplyOrForward = isReplyOrForward(message);

  const handleLongPress = useCallback(() => {
    onLongPress?.(message);
  }, [message, onLongPress]);

  const rootStyle = useMemo(
    () => [
      baseStyles.messageBox,
      { maxWidth: getMaxWidthOfMessage(isNative(), sizerWidth) },
    ],
    [sizerWidth],
  );

  return (
    <View style={rootStyle}>
      <MessageWithHeader
        roomId={roomId}
        message={message}
        answerToMessage={answer}
        onLongPressPreview={handleLongPress}
        withBubbleStyle={isMessageReplyOrForward}
      >
        <TextBubble
          isReply={isReply}
          text={message.text}
          modifiedText={textModifiedAt ? t("modified") : undefined}
          style={styles.bubble}
          withBubbleStyle={!isMessageReplyOrForward}
          hasReactions={messageHasReactions(message)}
          isFontSizeLargeBody
          onLongPress={handleLongPress}
          draggableElement={
            <DraggableMessage
              messageId={message.id}
              messageType={DraggableMessageType.MessageText}
              renderText={() =>
                formatDraggableText(
                  message.createdAt,
                  InputPartitionHelper.inputPartitionsToText(
                    text,
                    getUsernameFromUserId,
                  ),
                )
              }
              style={StyleSheet.absoluteFillObject}
            />
          }
        />
      </MessageWithHeader>
    </View>
  );
};

const styles = StyleSheet.create({
  bubble: {
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S12,
  },
});

export const MessageText = memo(MessageText_, isEqual);
