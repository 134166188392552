import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  type Room,
  RoomType,
} from "@kraaft/shared/core/modules/room/roomState";
import { selectCurrentUserAccountOwner } from "@kraaft/shared/core/modules/user/userSelectors";

import { DeleteRoomButton } from "./deleteRoomButton";

interface Props {
  room: Room;
  setDisplayed?: () => void;
}

const DeleteSection = (props: Props) => {
  const { room, setDisplayed } = props;
  const poolId = room.poolId;
  const canDelete =
    useSelector(selectCurrentUserAccountOwner(poolId)) &&
    room.type !== RoomType.EVERYONE;

  useEffect(() => {
    if (canDelete) {
      setDisplayed?.();
    }
  }, [setDisplayed, canDelete]);

  return canDelete ? <DeleteRoomButton room={room} /> : null;
};

export { DeleteSection };
