import { registeredLogger } from "../../logger";
import type { Task } from "../../taskStore/task";
import type { BaseAggregate } from "../optimistic.types";
import { Builder, type get } from "./builder";

export class EditOneOperationBuilder<A extends BaseAggregate> extends Builder {
  state = this.init<{
    input: {};
    param: { id: string };
    result: { updatedAt: Date };
  }>()({
    type: "custom" as const,
    replaceId: (input: { id: string }, oldId: string, newId: string) => {
      if (input.id === oldId) {
        input.id = newId;
      }
    },
    gatherIds: (input: { id: string }) => [input.id],
    shouldAcknowledge: (
      datas: Record<string, A>,
      input: { id: string },
      result: { updatedAt: Date },
    ) => {
      if (Number.isNaN(result.updatedAt.getTime())) {
        registeredLogger.current.error(
          "EditOneOperation received invalid date as the result of the mutate call",
        );
        return true;
      }

      return (
        (datas[input.id]?.updatedAt.getTime() ?? 0) >=
        result.updatedAt.getTime()
      );
    },
  });

  payload<P>() {
    return this.add<{ param: P }>().cast();
  }

  dependsOn<D>() {
    return this.when<{ param: any }>().add<{ dependencies: D }>().cast();
  }

  augment<Augment>(
    augment: (
      payload: get<this, "input"> & get<this, "param">,
      dependencies: get<this, "dependencies">,
    ) => Augment,
  ) {
    return this.when<{ dependencies: any }>()
      .add({ augment })
      .add<{ augmented: Partial<Augment> }>()
      .cast();
  }

  creates(count: (payload: get<this, "param">) => number) {
    return this.add({ creates: count })
      .add<{
        input: { allocatedIds: string[] };
        result: { ids: string[] };
      }>()
      .cast();
  }

  expected(
    expected: (
      aggregate: A,
      payload: get<this, "input"> & get<this, "param"> & get<this, "augmented">,
    ) => void,
  ) {
    return this.when<{ param: any }>()
      .add({
        expected: (
          aggregates: Record<string, A>,
          payload: get<this, "input"> &
            get<this, "param"> &
            get<this, "augmented">,
        ) => {
          const aggregate = aggregates[payload.id];
          if (!aggregate) {
            return;
          }
          expected(aggregate, payload);
        },
      })
      .cast();
  }

  mutate<R extends get<this, "result">>(
    mutate: (
      payload: get<this, "param"> & get<this, "param">,
      task: Task,
    ) => Promise<R>,
  ) {
    return this.when<{ param: any; expected: any }>()
      .add({ mutate })
      .add<{ result: R }>()
      .cast();
  }

  static create<A extends BaseAggregate>() {
    return new this<A>().cast();
  }

  acknowledgeOn(
    acknowledgeOn: (
      datas: Record<string, A>,
      payload: get<this, "param">,
      mutateResult: get<this, "result">,
    ) => boolean,
  ) {
    return this.when<{ mutate: any }>()
      .add({ shouldAcknowledge: acknowledgeOn })
      .cast();
  }

  getOperation() {
    return this.when<{
      param: any;
      expected: any;
      mutate: any;
    }>().build();
  }
}
