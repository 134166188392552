import {
  ExtendedRefs,
  FloatingContext,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  ReferenceType,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import { clsx } from "clsx";
import { CSSProperties, ReactNode, useCallback, useEffect } from "react";

import { usePrevious } from "@kraaft/shared/core/utils/hooks";

import { OverlaySheetDefinition } from "./overlaySheet.definition/overlaySheet.definition.types";

export const ANIMATION_DURATION = 150;

export const OverlaySheetHost: OverlaySheetDefinition["Host"] = ({
  id = "ide2e-overlay",
  children,
  open,
  onClose,
  onClosed,
  withoutBackground = false,
  withoutPointerEvents = false,
}) => {
  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose],
  );

  const nodeId = useFloatingNodeId();
  const { context, refs, floatingStyles } = useFloating({
    open,
    nodeId,
    onOpenChange: handleOpenChange,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: ANIMATION_DURATION,
    initial: {
      transform: "scale(0.75)",
      opacity: 0,
    },
    common: {
      transformOrigin: "center",
      transform: "scale(1)",
      opacity: 1,
    },
  });
  const wasMounted = usePrevious(isMounted);

  useEffect(() => {
    if (wasMounted && !isMounted) {
      onClosed?.();
    }
  }, [isMounted, wasMounted, onClosed]);

  return (
    <FloatingNode id={nodeId}>
      {isMounted ? (
        <MountedOverlaySheetHost
          open={open}
          context={context}
          floatingStyles={floatingStyles}
          refs={refs}
          styles={styles}
          id={id}
          withoutBackground={withoutBackground}
          withoutPointerEvents={withoutPointerEvents}
        >
          {children}
        </MountedOverlaySheetHost>
      ) : null}
    </FloatingNode>
  );
};

const useStyles = makeStyles({
  overlay: {
    zIndex: 1400,
    display: "flex",
  },

  overlayWithBackground: {
    backgroundColor: "rgba(0,0,0,.25)",
  },

  withoutPointerEvents: {
    pointerEvents: "none",
  },

  overlayFadeIn: {
    animation: "$fadeIn",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  overlayFadeOut: {
    animation: "$fadeOut",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  content: {
    width: "100vw",
    height: "100vh",
    display: "flex",
  },

  "@global": {
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes fadeOut": {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
  },
});

const MountedOverlaySheetHost = ({
  open,
  context,
  floatingStyles,
  refs,
  styles,
  id,
  children,
  withoutBackground,
  withoutPointerEvents,
}: {
  open: boolean;
  context: FloatingContext<ReferenceType>;
  floatingStyles: CSSProperties;
  refs: ExtendedRefs<ReferenceType>;
  styles: CSSProperties;
  id: string;
  children: ReactNode;
  withoutBackground: boolean;
  withoutPointerEvents: boolean;
}) => {
  const classes = useStyles();

  const dismiss = useDismiss(context, {
    bubbles: false, // allows the sheet to be dismissed without dismissing the parent. Ex: database filter menu
    escapeKey: true,
    outsidePress: false,
    enabled: true,
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={clsx(
          classes.overlay,
          !withoutBackground && classes.overlayWithBackground,
          withoutPointerEvents && classes.withoutPointerEvents,
          open ? classes.overlayFadeIn : classes.overlayFadeOut,
        )}
        lockScroll
      >
        <FloatingFocusManager
          context={context}
          closeOnFocusOut={true}
          visuallyHiddenDismiss
        >
          <div
            style={floatingStyles}
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <div className={classes.content} style={styles} id={id}>
              {children}
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};
