import { ReactNode, useCallback, useMemo } from "react";
import { noop } from "ts-essentials";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { DraggableLineDecoration } from "@kraaft/web/src/components/legacyDraggableLineDecoration";
import { ElementDrag } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag";
import {
  ColumnDropItem,
  FormBuilderDropTypes,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { getTransitionFromIndexes } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditor.utils";
import { ElementsEditorColumnItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorColumnItem";
import { ElementsEditorSectionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionItem/elementsEditorSectionItem";
import { SchemaBuilderContext } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

export function useEditorItems(
  section: KSchemaSection,
  columnsContext: ModularColumnsContext,
  availableColumnTypes: KColumnType[],
  onInsert: (key: string, newName: string, hasEditor: boolean) => void,
) {
  const {
    editColumnDefinition,
    currentElementEditionKey,
    setCurrentElementEditionKey,
    renameElement,
    deleteElement,
    currentElementCreation,
    currentHoveredKey,
    setCurrentHoveredKey,
    lockOnEnd,
    resetCreation,
    currentDraggedKey,
    setCurrentDraggedKey,
    addElement,
    reorderElement,
    resetDrag,
    schema,
    recordsCount,
  } = useMeshContext(SchemaBuilderContext);

  const editorItems = useMemo(
    () =>
      Object.values(section.elements)
        .sort(KSchemaUtils.orderByIndex)
        .reduce<Record<string, ReactNode>>((acc, element) => {
          if (element.elementType === "section") {
            acc[element.key] = (
              <ElementsEditorSectionItem
                key={element.key}
                section={element}
                columnsContext={columnsContext}
                hideParentSection={false}
                availableColumnTypes={availableColumnTypes}
              />
            );
            return acc;
          }
          const columnShouldBeDisplayed = availableColumnTypes.includes(
            element.type,
          );
          if (!columnShouldBeDisplayed) {
            return acc;
          }
          const context = columnsContext.columns[element.key];
          acc[element.key] = (
            <ElementsEditorColumnItem
              collection={schema.collection}
              key={element.key}
              onClick={setCurrentElementEditionKey}
              column={element}
              onDelete={deleteElement}
              editing={currentElementEditionKey === element.key}
              onChangeName={renameElement}
              nonDraggable={context?.nonReorderable ?? false}
              onChangeDefinition={editColumnDefinition}
              recordsCount={recordsCount}
            />
          );
          return acc;
        }, {}),
    [
      availableColumnTypes,
      columnsContext,
      currentElementEditionKey,
      deleteElement,
      editColumnDefinition,
      recordsCount,
      renameElement,
      schema.collection,
      section.elements,
      setCurrentElementEditionKey,
    ],
  );

  const draggedKeyIndex = useMemo(() => {
    return Object.values(section.elements)
      .sort(KSchemaUtils.orderByIndex)
      .findIndex((element) => element.key === currentDraggedKey);
  }, [currentDraggedKey, section.elements]);

  const hoverKeyIndex = useMemo(() => {
    return Object.values(section.elements)
      .sort(KSchemaUtils.orderByIndex)
      .findIndex((element) => element.key === currentHoveredKey?.key);
  }, [currentHoveredKey?.key, section.elements]);

  const creationEditor = useMemo(() => {
    if (!currentElementCreation) {
      return null;
    }
    const key =
      currentElementCreation.type === "column"
        ? currentElementCreation.column.key
        : currentElementCreation.section.key;

    const editorElement =
      currentElementCreation.type === "column" ? (
        <ElementsEditorColumnItem
          collection={schema.collection}
          creating
          editing={
            currentElementCreation.column.key === currentElementEditionKey
          }
          column={currentElementCreation.column}
          onDelete={resetCreation}
          onChangeName={onInsert}
          onChangeDefinition={noop}
          forceInstantDeletion
          nonDraggable={false}
          recordsCount={recordsCount}
        />
      ) : (
        <ElementsEditorSectionItem
          section={currentElementCreation.section}
          forceInstantDeletion
          columnsContext={columnsContext}
          hideParentSection={false}
          availableColumnTypes={availableColumnTypes}
        />
      );
    return (
      <DraggableLineDecoration key={key}>
        {editorElement}
      </DraggableLineDecoration>
    );
  }, [
    availableColumnTypes,
    columnsContext,
    currentElementCreation,
    currentElementEditionKey,
    onInsert,
    recordsCount,
    resetCreation,
    schema.collection,
  ]);

  const onDragStart = useCallback(
    (key: string) => {
      setCurrentElementEditionKey(undefined);
      setCurrentDraggedKey(key);
      setCurrentHoveredKey({ key, placement: "after" });
    },
    [setCurrentDraggedKey, setCurrentElementEditionKey, setCurrentHoveredKey],
  );

  const onDragEnd = useCallbackRealtime(
    (
      [hoverKey],
      item: ColumnDropItem | undefined,
      droppedOn: string | undefined,
    ) => {
      if (item?.type === FormBuilderDropTypes.INSERT) {
        if (item.insertType === "column") {
          addElement(
            { type: "column", columnType: item.columnType },
            hoverKey,
            droppedOn,
          );
        } else {
          addElement({ type: "section" }, hoverKey, droppedOn);
        }
      } else if (item?.type === FormBuilderDropTypes.REORDER && hoverKey) {
        reorderElement(hoverKey, item.elementKey, droppedOn);
      }
      resetDrag();
    },
    [addElement, reorderElement, resetDrag],
    [currentHoveredKey],
  );

  return useMemo(() => {
    const elements = Object.values(section.elements).sort(
      KSchemaUtils.orderByIndex,
    );

    if (
      elements.length === 0 &&
      currentElementCreation?.inSection === section.key
    ) {
      return [creationEditor];
    }

    return (
      // eslint-disable-next-line complexity
      elements.reduce<ReactNode[]>((acc, element, index) => {
        const columnContext = columnsContext.columns[element.key];

        const transition = getTransitionFromIndexes(
          currentHoveredKey,
          index,
          hoverKeyIndex,
          draggedKeyIndex,
        );
        const item = editorItems[element.key];

        if (
          currentElementCreation &&
          currentElementCreation.placement.placement === "before" &&
          element.key === currentElementCreation.placement.key &&
          (!currentElementCreation.inSection ||
            currentElementCreation.inSection === section.key)
        ) {
          acc.push(creationEditor);
        }

        if (item) {
          acc.push(
            <ElementDrag
              key={element.key}
              isSection={element.elementType === "section"}
              parentKey={section.key}
              lockOnEnd={lockOnEnd}
              isAboveThreshold={0.2}
              highlightHover={element.elementType === "section"}
              onDrag={onDragStart}
              onEnd={onDragEnd}
              transition={transition}
              setCurrentHoverKey={setCurrentHoveredKey}
              elementKey={element.key}
              preventDrag={columnContext?.nonReorderable ?? false}
            >
              {item}
            </ElementDrag>,
          );
        }

        if (
          currentElementCreation &&
          currentElementCreation.placement.placement === "after" &&
          element.key === currentElementCreation.placement.key &&
          (!currentElementCreation.inSection ||
            currentElementCreation.inSection === section.key)
        ) {
          acc.push(creationEditor);
        }
        return acc;
      }, [])
    );
  }, [
    section.elements,
    section.key,
    currentElementCreation,
    creationEditor,
    columnsContext,
    currentHoveredKey,
    hoverKeyIndex,
    draggedKeyIndex,
    editorItems,
    lockOnEnd,
    onDragStart,
    onDragEnd,
    setCurrentHoveredKey,
  ]);
}
