import { useCallback, useMemo, useState } from "react";

import { useInfoMenuItems } from "@kraaft/shared/components/message/menuMessageItems/info/useInfoMenuItems";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageInfoPopup } from "@kraaft/web/src/components/menuMessageContainer/messageInfoPopup";

export function useWebInfoMenuItems(roomId: string, message: AnyUserMessage) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onPress = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const infoMenuItems = useInfoMenuItems(roomId, message, onPress);

  return useMemo(
    () => ({
      infoMenuItems,
      infoDialog: (
        <MessageInfoPopup
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          roomId={roomId}
          messageId={message.id}
        />
      ),
    }),
    [infoMenuItems, isDialogOpen, message.id, roomId],
  );
}
