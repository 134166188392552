import { groupBy, keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { assert } from "ts-essentials";
import { debounce, put, select, take } from "typed-redux-saga/macro";

import { OfflineModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import {
  ModularFolderActions,
  ModularFolderStateActions,
} from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import {
  ModularFolderVisibility,
  ModularFolderVisibilityType,
} from "@kraaft/shared/core/modules/modularFolder/types";
import { isAtLeastPoolAdmin } from "@kraaft/shared/core/modules/pool/poolUtil";
import { RoomVisibility } from "@kraaft/shared/core/modules/room/roomState";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { WithId } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import {
  selectCurrentUserId,
  selectCurrentUserRole,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import * as Types from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToPoolModularFoldersSaga() {
  yield takeCountedDeep(
    ModularFolderActions.subscribeForPool,
    ModularFolderActions.unsubscribeForPool,
    subscribeSaga,
    unsubscribeSaga,
    (action) => action.payload.poolId,
  );
}

type Payload = WithId<Types.FirestoreModularFolder>[];

function* subscribeSaga(
  registerMeta: (
    channel: [EventChannel<Payload>, EventChannel<Payload>],
  ) => void,
  {
    payload: { poolId },
  }: ReturnType<typeof ModularFolderActions.subscribeForPool>,
) {
  const currentUserId = yield* select(selectCurrentUserId);
  assert(currentUserId, "no currentUserId");

  const userRole = yield* select(selectCurrentUserRole(poolId));
  const visibilities: RoomVisibility[] = isAtLeastPoolAdmin(userRole)
    ? ["administrator", "pool"]
    : ["pool"];

  const userVisibleChannel = createUserVisibleModularFoldersChannel(
    poolId,
    currentUserId,
  );

  const poolVisibleChannel = createPoolVisibleModularFoldersChannel(
    poolId,
    visibilities,
  );

  registerMeta([userVisibleChannel, poolVisibleChannel]);

  const userVisibleRecords = yield* take(userVisibleChannel);
  yield receiveModularFolders({ type: ModularFolderVisibilityType.User })(
    userVisibleRecords,
  );
  yield* debounce(
    200,
    userVisibleChannel,
    receiveModularFolders({ type: ModularFolderVisibilityType.User }),
  );

  const poolVisibleRecords = yield* take(poolVisibleChannel);
  yield receiveModularFolders({
    type: ModularFolderVisibilityType.Pool,
    poolId,
  })(poolVisibleRecords);
  yield* debounce(
    200,
    poolVisibleChannel,
    receiveModularFolders({ type: ModularFolderVisibilityType.Pool, poolId }),
  );
}

function* unsubscribeSaga(
  channels: [EventChannel<Payload>, EventChannel<Payload>],
) {
  for (const channel of channels) {
    channel.close();
  }
}

const receiveModularFolders = (visibility: ModularFolderVisibility) =>
  function* (folders: Payload) {
    const foldersBySchemaId = groupBy(folders, (a) => a.schemaId);
    const convertedFolders: ModularFolder[] = [];

    for (const [schemaId, folderForSchemaId] of Object.entries(
      foldersBySchemaId,
    )) {
      convertedFolders.push(
        ...(yield* KSchemaConversion.toModularFolders(
          schemaId,
          folderForSchemaId,
        )),
      );
    }

    yield* put(
      ModularFolderStateActions.setIdsForVisibility({
        visibility,
        ids: convertedFolders.map((modularFolder) => modularFolder.id),
      }),
    );

    yield* put(
      OfflineModularFolderStateActions.receive(
        keyBy(convertedFolders, (modularFolder) => modularFolder.id),
      ),
    );
  };

function createUserVisibleModularFoldersChannel(
  poolId: string,
  userId: string,
): EventChannel<Payload> {
  return eventChannel((emit) =>
    Firestore.subscribeToUserVisibleModularFolders(poolId, userId, emit),
  );
}

function createPoolVisibleModularFoldersChannel(
  poolId: string,
  visibilities: RoomVisibility[],
): EventChannel<Payload> {
  return eventChannel((emit) =>
    Firestore.subscribeToPoolVisibleModularFolders(poolId, visibilities, emit),
  );
}
