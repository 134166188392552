import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { canMessageBeForwarded } from "@kraaft/shared/components/message/messageUtils";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { toggleSelectMessages } from "@kraaft/shared/core/modules/message/messageActions";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";

export function useForwardMenuItems(roomId: string, message: AnyUserMessage) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (MessageHelper.isPersisted(message) && canMessageBeForwarded(message)) {
      items.push({
        label: t("forwardMessage"),
        onPress: () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "forward",
          });

          dispatch(
            toggleSelectMessages({
              selectionType: "forward",
              selectionSource: "conversation",
              roomId,
              messageIds: [message.id],
              selectState: true,
            }),
          );
        },
        icon: "corner-up-right",
      });
    }
    return items;
  }, [message, t, roomId, dispatch]);
}
