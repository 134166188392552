import { Platform, StyleSheet } from "react-native";

import { ColorStyle, Spacing } from "@kraaft/ui";

export const TEXT_INPUT_MAX_HEIGHT = 130;

export const LARGE_PADDING = Platform.OS === "android" ? 0 : 11;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  textInputLayout: {
    flex: 1,
  },
  textInputContainer: {
    flexGrow: 1,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    maxHeight: TEXT_INPUT_MAX_HEIGHT,
    borderRadius: 20,
  },
  largeTextInputContainer: {
    flexGrow: 1,
    flexShrink: 1,
    minHeight: 44,
    maxHeight: TEXT_INPUT_MAX_HEIGHT,
    borderRadius: 22,
  },
  overflowing: {
    overflow: "auto" as "visible", // Fix IE => 'auto' is not a valid property in React-Native
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  leftIcon: {
    justifyContent: "center",
    marginRight: Spacing.S8,
  },
});

export { styles };
