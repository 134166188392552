import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import {
  connectRoomToExternal,
  disconnectRoomToExternal,
} from "@kraaft/shared/core/modules/room/roomActions";
import { selectRoom } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectCurrentUserAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { useLoader } from "@kraaft/shared/core/utils/useLoader";
import { Button } from "@kraaft/ui";
import {
  detailsSharepointStyleSheet,
  useDetailsSharepointStyles,
} from "@kraaft/web/src/components/editRoomSharepointLink/editRoomSharepointLink.style";
import { useDetailsScreenStyles } from "@kraaft/web/src/views/messenger/detailsDrawer/detailsDrawer.styles";

import {
  sharepointSyncMessage,
  SharepointUrlErrorBox,
  SharepointUrlInput,
  SharepointUrlValidationBox,
} from "./sharepointUrlInput";

interface Props {
  roomId: string;
  poolId?: string;
  goToUrl: (url: string) => void;
}

const EditRoomSharepointLink = (props: Props) => {
  const { roomId, poolId, goToUrl } = props;

  const isAtLeastAdminOrSuperadmin = useSelector(
    selectCurrentUserAtLeastAdminOrSuperadmin(poolId),
  );
  const { loaderId, loading } = useLoader({
    prefix: "SharepointDetailsRoute",
  });
  const dispatch = useDispatch();
  const [url, setUrl] = useState<{ value: string; isValid: boolean }>({
    value: "",
    isValid: false,
  });
  const classes = {
    ...useDetailsScreenStyles(),
    ...useDetailsSharepointStyles(),
  };
  const { t } = useTranslation();
  const room = useSelector(selectRoom(roomId));
  const microsoftStorage = room?.external?.microsoftStorage;
  const persistedUrl = microsoftStorage?.url ?? "";

  useTrackPage("EditRoomSharepoint", { identifier: roomId });

  // clean input when room changes
  useEffect(() => {
    setUrl({ value: "", isValid: false });
  }, [roomId]);

  useEffect(() => {
    if (persistedUrl) {
      setUrl({ value: persistedUrl, isValid: true });
    }
  }, [persistedUrl]);

  const update = useCallback(() => {
    dispatch(
      connectRoomToExternal({
        roomId,
        microsoftStorage: url.value,
        loaderId,
      }),
    );
  }, [roomId, url, dispatch, loaderId]);

  const disconnect = useCallback(() => {
    newConfirmationAlert({
      title: t("disconnectMicrosoftStorageTitle"),
      message: t("disconnectMicrosoftStorageRoomContent"),
      confirmText: t("confirm"),
      onConfirm: () => {
        dispatch(
          disconnectRoomToExternal({
            roomId,
            microsoftStorage: true,
          }),
        );
      },
    });
  }, [roomId, dispatch, t]);

  const error = microsoftStorage?.error;

  const synchronizedActions = persistedUrl ? (
    <>
      <Button
        variant="TERTIARY"
        accessibilityLabel={t("microsoftStorageRoomStop")}
        text={t("microsoftStorageRoomStop")}
        onPress={disconnect}
        style={{}}
      />
      <div className={classes.stopSpacer} />
      <Button
        accessibilityLabel={t("microsoftStorageRoomAccessTo")}
        text={t("microsoftStorageRoomAccessTo")}
        onPress={() => goToUrl(persistedUrl)}
      />
    </>
  ) : null;

  const isUrlPersisted = url.value === persistedUrl;
  const isSynchronized = isUrlPersisted && Boolean(url.value);
  const canSynchronize = !isUrlPersisted && url.isValid;

  return (
    <div className={classes.container}>
      <div className={classes.guideLine}>
        {t("roomSharepointConnectorGuideline")}
      </div>
      <SharepointUrlInput
        url={url.value}
        setUrl={setUrl}
        loading={loading}
        disabled={!isAtLeastAdminOrSuperadmin}
        synchronized={isSynchronized}
        style={detailsSharepointStyleSheet.input}
      />
      {isSynchronized && (
        <SharepointUrlValidationBox
          message={sharepointSyncMessage(t, microsoftStorage?.synchronizedAt)}
          status="validated"
        />
      )}

      {isSynchronized && error && <SharepointUrlErrorBox error={error} />}

      <div className={classes.spacer} />
      {canSynchronize ? (
        <Button
          accessibilityLabel={t("validate")}
          text={t("validate")}
          onPress={update}
          loading={loading}
        />
      ) : (
        synchronizedActions
      )}
    </div>
  );
};

export { EditRoomSharepointLink };
