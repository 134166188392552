import type { Task } from "../../taskStore/task";
import type { BaseAggregate } from "../optimistic.types";
import { Builder, type get } from "./builder";

export class CreateOneOperationBuilder<
  A extends BaseAggregate,
> extends Builder {
  state = this.init<{
    input: { ids: string[] };
    wrapped: { id: string };
  }>()({
    type: "creations" as const,
    count: () => 1,
  });

  payload<P>(description: {
    replaceId: (payload: P, oldId: string, newId: string) => void;
    gatherExternalIds?: (payload: P) => string[];
  }) {
    return this.add<{ param: P; augmented: P }>()
      .add({
        gatherExternalIds: description.gatherExternalIds,
        replaceId: (
          payload: P & get<this, "input">,
          oldId: string,
          newId: string,
        ) => {
          description.replaceId(payload, oldId, newId);
          for (let i = 0; i < payload.ids.length; i += 1) {
            if (payload.ids[i] === oldId) {
              payload.ids[i] = newId;
            }
          }
        },
      })
      .cast();
  }

  dependsOn<D>() {
    return this.when<{ param: any }>().add<{ dependencies: D }>().cast();
  }

  augment<Augment>(
    augment: (
      payload: get<this, "input"> & get<this, "param">,
      dependencies: get<this, "dependencies">,
    ) => Augment,
  ) {
    return this.when<{ dependencies: any }>()
      .add<{ augmented: Partial<Augment> }>()
      .add({ augment })
      .cast();
  }

  expected(
    expected: (payload: get<this, "wrapped"> & get<this, "augmented">) => A,
  ) {
    return this.when<{ param: any }>()
      .add({
        expected: (augmented: get<this, "input"> & get<this, "augmented">) => {
          const id = augmented.ids[0];
          if (!id) {
            return [];
          }
          return [expected({ ...augmented, id })];
        },
      })
      .cast();
  }

  mutate(
    mutate: (
      payload: get<this, "param">,
      task: Task,
    ) => Promise<string | string[]>,
  ) {
    return this.when<{ expected: any; param: any }>()
      .add({
        mutate: async (input: get<this, "param">, task: Task) => {
          const createdIds = await mutate(input, task);
          if (Array.isArray(createdIds)) {
            return createdIds;
          }
          return [createdIds];
        },
      })
      .cast();
  }

  static create<A extends BaseAggregate>() {
    return new this<A>().cast();
  }

  getOperation() {
    return this.when<{
      param: any;
      expected: any;
      mutate: any;
    }>().build();
  }

  creates(count: (payload: get<this, "param">) => number) {
    return this.add({ creates: count })
      .add<{
        wrapped: { allocatedIds: string[] };
        result: { ids: string[] };
      }>()
      .cast();
  }
}
