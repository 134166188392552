import { Color, ColorStyle } from "./color";

export class KColors {
  static Colors = [
    "#EC5F4F", // RED
    Color.ORANGE_BASIC,
    Color.YELLOW_KRAAFT,
    Color.GREEN_LAGOON,
    Color.BLUE_AZURE, // BLUE
    "#DD35CC", // PINK
    "#920773", // DARK PINK
    "#863B10", // BROWN
    Color.GREY_FRENCH,
    Color.GREY_TAUPE,
    Color.GREY_JET,
  ] as const;

  private static TextColorFromBackgroundColor: Record<string, string> = {
    "#EC5F4F": Color.WHITE,
    [Color.ORANGE_BASIC]: Color.WHITE,
    [Color.YELLOW_KRAAFT]: ColorStyle.FONT_HIGH_EMPHASIS,
    [Color.GREEN_LAGOON]: Color.WHITE,
    [Color.BLUE_AZURE]: Color.WHITE,
    "#DD35CC": Color.WHITE,
    "#920773": Color.WHITE,
    "#863B10": Color.WHITE,
    [Color.GREY_FRENCH]: ColorStyle.FONT_HIGH_EMPHASIS,
    [Color.GREY_TAUPE]: Color.WHITE,
    [Color.GREY_JET]: Color.WHITE,
  };

  static textColorFromBackgroundColor(backgroundColor: string) {
    return KColors.TextColorFromBackgroundColor[backgroundColor] ?? Color.WHITE;
  }
}

export type KColor = (typeof KColors)["Colors"][number];
