import { View } from "react-native";
import Lottie from "lottie-react";

import { LottieManagerProps } from "@kraaft/shared/components/lottieManager/lottieManager.props";

export const LottieManager = ({
  source,
  style,
  loop,
  initialSegment,
  autoPlay = true,
  lottieRef,
  onComplete,
}: LottieManagerProps) => {
  return (
    <View style={style}>
      <Lottie
        lottieRef={lottieRef as any}
        autoplay={autoPlay}
        animationData={source}
        loop={loop}
        initialSegment={initialSegment}
        onComplete={onComplete}
      />
    </View>
  );
};
