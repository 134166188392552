import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { KInput } from "@kraaft/shared/components/input/KInput";
import { KDialog } from "@kraaft/shared/components/kDialog";

import { useMemberManagement } from "./manageMembers/manageMembers.context";

const useStyles = makeStyles({
  dialogContentContainer: {
    flex: 1,
  },
});

interface AddUserDialogProps {
  poolId: string;
  open: boolean;
  onClose: () => void;
}

const AddUserDialog = ({ open, onClose }: AddUserDialogProps) => {
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const classes = useStyles();

  const { addMember } = useMemberManagement();

  const title = t("superadminAddUser");

  const content = (
    <div className={classes.dialogContentContainer}>
      <KInput label={t("id")} value={input} onChangeText={setInput} />
    </div>
  );

  const onConfirm = () => {
    addMember(input);
    onClose();
  };

  const actions: FooterActions = [
    {
      accessibilityLabel: t("confirm"),
      text: t("confirm"),
      onPress: onConfirm,
      variant: "PRIMARY",
    },
    {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    },
  ];

  return (
    <KDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      actions={actions}
    />
  );
};

export { AddUserDialog };
