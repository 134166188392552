import { createAction } from "@reduxjs/toolkit";

import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import {
  DocumentData,
  QueryDocumentSnapshot,
} from "@kraaft/shared/core/services/firebase/modularQuery";
import { LinkedList } from "@kraaft/shared/core/utils/useBidirectional/createLinkedLists";

export const MessageDataActions = {
  subscribe: createAction<{ roomId: string }>("@messageData/SUBSCRIBE"),
  unsubscribe: createAction<{ roomId: string }>("@messageData/UNSUBSCRIBE"),
};

export const MessageDataStateActions = {
  addMessages: createAction<{
    roomId: string;
    messages: Record<string, AnyMessage>;
    messageDocs: Record<string, QueryDocumentSnapshot<DocumentData>>;
    shouldActAsNewMessage: boolean;
  }>("@messageData/state/ADD_MESSAGES"),
  addLinkedList: createAction<{
    roomId: string;
    linkedList: LinkedList;
  }>("@messageData/state/ADD_LINKED_LIST"),
};
