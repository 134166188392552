import { StyleSheet, View } from "react-native";

import { MessageMatchProps } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageMatchProps";
import { SearchTextResult } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/searchTextResult";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { Icon, Spacing } from "@kraaft/ui";

export const MessageTextMatch = ({
  message,
  searchResult,
}: MessageMatchProps<Messages.Text>) => {
  return (
    <View style={styles.container}>
      <Icon name="message-circle-01" />
      <SearchTextResult
        style={styles.text}
        text={message.text}
        result={searchResult}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: Spacing.S8,
  },
  text: {
    flexShrink: 1,
    marginTop: Spacing.S2,
  },
});
