/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import moment from "moment";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { ensureAccessToRoomSchemaColumns } from "@kraaft/shared/core/modules/room/roomUtils";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaRemarkableColumns } from "@kraaft/shared/core/modules/schema/schema.columns";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { User } from "@kraaft/shared/core/modules/user/userState";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { getEmoji } from "@kraaft/shared/core/utils/emoji";
import { filterNullableChildren } from "@kraaft/shared/core/utils/filterNullableChildren";
import { Color } from "@kraaft/ui";

export enum RoomCardType {
  EXTERNAL = "external",
  DEFAULT = "default",
  EVERYONE = "everyone",
}

export function isRoomPrivate(roomOrRoomCard?: AnyRoomCard | Room): boolean {
  if (!roomOrRoomCard) {
    return false;
  }
  return roomOrRoomCard.visibility === "private";
}

export function useRoomColor(roomCard: AnyRoomCard): string {
  const roomSchema = useSelector(selectRoomSchema(roomCard.poolId));
  const { statusId } = roomCard;

  const def = Color.ORANGE_BASIC;

  if (!statusId) {
    return def;
  }

  if (ensureAccessToRoomSchemaColumns(roomSchema)) {
    const statusColumn = KSchemaUtils.findColumn(
      roomSchema.rootSection,
      KSchemaRemarkableColumns.ROOM_STATUS,
      KColumnType.selectSingle,
    );
    return statusColumn?.options[statusId]?.color ?? def;
  }
  return def;
}

export function useRoomDescription(
  roomCard: AnyRoomCard,
  creator?: User,
  sender?: User,
): string | undefined {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!roomCard) {
      return undefined;
    }

    if (!roomCard.lastMessageInfo) {
      if (roomCard.isEveryoneRoom) {
        return t("everyoneRoomFirstMessagePreview");
      }
      const creatorName = creator?.username || "";
      return `${getEmoji("writing_hand")} ${creatorName} ${t(
        "createdTheTicket",
      )} ${roomCard.title}`;
    }

    const { text, type } = roomCard.lastMessageInfo;
    const cleanText = text || "";

    const senderPrefix = getUsername(sender);

    switch (type) {
      case "document":
        return `${getEmoji("page_with_curl")} ${senderPrefix}${
          senderPrefix.length > 0 ? " " : ""
        }${t("sentDocument")}`;
      case "image":
        return `${getEmoji("camera")} ${senderPrefix}${
          senderPrefix.length > 0 ? " " : ""
        }${t("sentPhoto")}`;
      case "video":
        return `${getEmoji("movie_camera")} ${senderPrefix}${
          senderPrefix.length > 0 ? " " : ""
        }${t("sentVideo")}`;
      case "audio":
        return `${senderPrefix}${senderPrefix.length > 0 ? ": " : ""}${getEmoji(
          "microphone",
        )} ${cleanText}`;
      default:
        return `${senderPrefix}${
          senderPrefix.length > 0 ? ": " : ""
        }${cleanText}`;
    }
  }, [creator?.username, roomCard, sender, t]);
}

// @TODO fix a strange bug, only reproduced on android release build! :-(
// when changing multiple times from English pool to French pool and vice-versa, the roomcard date can be in the wrong language
export function useHomeDateFormat(dateToFormat?: Date): string {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!dateToFormat) {
      return "";
    }
    const date = moment(dateToFormat);
    // Same day
    if (date.isSame(moment().startOf("day"), "d")) {
      return date.format("LT");
    }
    // 1 day before
    if (date.isSame(moment().subtract(1, "days").startOf("day"), "d")) {
      return t("yesterday");
    }
    // 2-7 days before
    if (date.isAfter(moment().subtract(7, "days").startOf("day"))) {
      return date.format("dddd");
    }
    // +7 days before
    return date.format("L");
  }, [dateToFormat, t]);
}

export function getRoomCardType(
  roomCard: AnyRoomCard,
  showExternalIndicator: boolean,
) {
  if (roomCard.isEveryoneRoom) {
    return RoomCardType.EVERYONE;
  }
  if (roomCard.hasExternalMembers && showExternalIndicator) {
    return RoomCardType.EXTERNAL;
  }
  return RoomCardType.DEFAULT;
}

export function arePillsEmpty(pills: JSX.Element | null | undefined) {
  return Boolean(pills && filterNullableChildren(pills));
}
