import { SchemaOfflineFeature } from "@kraaft/shared/core/modules/schema/schema.offlineFeature";
import { offlineReduxBundle } from "@kraaft/shared/core/store/offline";

export interface HoverState {
  key: string;
  placement: "after" | "before";
}

export const {
  Actions: OfflineSchemaActions,
  Selectors: OfflineSchemaSelectors,
  StateActions: OfflineSchemaStateActions,
} = offlineReduxBundle.create(
  SchemaOfflineFeature,
  {
    selectRawAggregate: (state: any) => state.schema.schemas,
  },
  () => ({}),
);
