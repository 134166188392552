import { useTranslation } from "react-i18next";
import { SafeAreaView, TouchableWithoutFeedback } from "react-native";

import { MemberList } from "@kraaft/shared/components/memberList";
import { MissingSomeone } from "@kraaft/shared/components/memberList/missingSomeone";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { SelectableUser } from "@kraaft/shared/core/modules/user/userTypes";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { RoomInvitationSource } from "@kraaft/shared/core/utils/tracking/trackingEvent.types";
import { Button, KeyboardAvoidingView } from "@kraaft/ui";

import { styles } from "./selectRoomMembers.styles";

interface Props {
  poolId: string;
  roomId: string | undefined;
  currentUserId: string;
  users: SelectableUser[];
  onSubmit?: () => void;
  selectedUsers: SelectableUser[];
  setSelectedUsers: (newUsers: SelectableUser[]) => void;
  allowEdition?: boolean;
  freezeCurrentUser?: boolean;
  source: RoomInvitationSource;
  hideAvatarList?: boolean;
  buttonLabel?: string;
}

const SelectRoomMembers = ({
  poolId,
  roomId,
  currentUserId,
  users,
  onSubmit,
  selectedUsers,
  setSelectedUsers,
  allowEdition = false,
  freezeCurrentUser = false,
  source,
  hideAvatarList,
  buttonLabel,
}: Props) => {
  const { t } = useTranslation();

  const canInviteMembers = Guard.use("Room.inviteMembers");

  const text = buttonLabel || t("confirm");

  return (
    <>
      <TouchableWithoutFeedback
        accessibilityLabel=""
        onPress={dismissNativeKeyboard}
      >
        <KeyboardAvoidingView style={styles.screen}>
          <SafeAreaView style={styles.safeAreaView}>
            <MemberList
              currentUserId={currentUserId}
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              allowEdition={allowEdition}
              freezeCurrentUser={freezeCurrentUser}
              allowSearch
              showAvatarList={!hideAvatarList}
              footer={
                canInviteMembers ? (
                  <MissingSomeone
                    poolId={poolId}
                    roomId={roomId}
                    source={source}
                  />
                ) : null
              }
            />
            <Button
              accessibilityLabel={text}
              text={text}
              onPress={onSubmit}
              style={styles.button}
            />
          </SafeAreaView>
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    </>
  );
};

export { SelectRoomMembers };
