import { EditOneOperationBuilder } from "@kraaft/offline";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const editPresentationCoverPictureOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ coverPictureFile: ModernFile<LocalPath> | null }>()
    .expected((aggregate, payload) => {
      if (payload.coverPictureFile !== null) {
        aggregate.presentation.coverPictureUrl = payload.coverPictureFile.path;
      } else {
        aggregate.presentation.coverPictureUrl = null;
      }

      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaPresentationCoverPicture(
        {
          librarySchemaId: payload.id,
          coverPictureFile: payload.coverPictureFile,
        },
      );

      return { updatedAt };
    });
