import findLast from "lodash/findLast";
import { call, select } from "typed-redux-saga/macro";

import { selectIsActive } from "@kraaft/shared/core/modules/app/appSelector";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { MessageDataStateActions } from "@kraaft/shared/core/modules/message/messageData/messageData.actions";
import { sortMessages } from "@kraaft/shared/core/modules/message/messageUtils";
import { focusRoom } from "@kraaft/shared/core/modules/room/roomActions";
import {
  RoomSelectors,
  selectRoom,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors";
import { Api } from "@kraaft/shared/core/services/api";
import { takeLatestDeep, waitFor } from "@kraaft/shared/core/utils/sagas";

export function* acknowledgeReadingMessageSaga() {
  yield* takeLatestDeep(
    MessageDataStateActions.addMessages,
    acknowledgeReadingNewMessage,
    ({ payload: { roomId } }) => roomId,
  );

  yield* takeLatestDeep(
    focusRoom,
    acknowledgeReadingUnreadRoom,
    ({ payload: { roomId } }) => roomId,
  );
}

function* acknowledgeReadingUnreadRoom({
  payload: { roomId },
}: ReturnType<typeof focusRoom>) {
  const roomUserHistory = yield* select(selectRoomUserHistory(roomId));
  if (!roomUserHistory?.isMarkedUnread) {
    return;
  }

  const room = yield* select(selectRoom(roomId));
  const latestMessage = room?.lastMessage;
  if (!latestMessage) {
    return;
  }

  yield* acknowledgeReadingMessage({
    latestUserMessageId: latestMessage.id,
    roomId: room.id,
  });
}

const lastReadMessagesByRoom: {
  // Store latest createdAt read by room id
  [roomId: string]: number;
} = {};

function* acknowledgeReadingNewMessage({
  payload: { roomId, messages },
}: ReturnType<typeof MessageDataStateActions.addMessages>) {
  const isInRoom = yield* select(
    RoomSelectors.selectIsCurrentUserMember(roomId),
  );

  if (!isInRoom) {
    return;
  }

  // Wait for browser tab to be focused
  yield* waitFor(selectIsActive);

  const lastReadForRoomCreatedAt = lastReadMessagesByRoom[roomId] ?? 0;

  const latestUserMessage = findLast(
    sortMessages(Object.values(messages)),
    (message): message is AnyUserMessage =>
      MessageHelper.isUserMessage(message) &&
      MessageHelper.isPersisted(message),
  );
  if (!latestUserMessage) {
    return;
  }
  const latestUserMessageCreatedAt = latestUserMessage.createdAt.getTime();
  if (latestUserMessageCreatedAt < lastReadForRoomCreatedAt) {
    return;
  }

  lastReadMessagesByRoom[roomId] = latestUserMessageCreatedAt;

  // If we happen to have the user room and it indicates us that we already
  // read a newer message, dont bother acknownledging
  const userRoom = yield* select(selectRoomUserHistory(roomId));
  if (
    userRoom?.lastReadMessageCreatedAt &&
    userRoom.lastReadMessageCreatedAt.getTime() >= latestUserMessageCreatedAt
  ) {
    return;
  }

  yield* acknowledgeReadingMessage({
    roomId,
    latestUserMessageId: latestUserMessage.id,
  });
}

function* acknowledgeReadingMessage({
  roomId,
  latestUserMessageId,
}: { roomId: string; latestUserMessageId: string }) {
  const isInRoom = yield* select(
    RoomSelectors.selectIsCurrentUserMember(roomId),
  );

  if (!isInRoom) {
    return;
  }

  try {
    yield* call(Api.acknowledgeReading, {
      roomId,
      messageId: latestUserMessageId,
    });
  } catch (e) {}
}
