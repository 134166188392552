import { StyleSheet, TextInput, View } from "react-native";

import { ColorStyle, Radius, Spacing } from "../../constants";
import { Icon } from "../../display";

interface SearchBarProps {
  value: string;
  onChangeText(text: string): void;
  placeholder: string;
}

export const SearchBar = ({
  value,
  onChangeText,
  placeholder,
}: SearchBarProps) => {
  return (
    <View style={styles.root}>
      <Icon name="search-md" color="FONT_LOW_EMPHASIS" />
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
        value={value}
        onChangeText={onChangeText}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    flexDirection: "row",
    gap: Spacing.S4,
    padding: Spacing.S4,
    borderRadius: Radius.SMALL,
  },
  input: {
    width: "100%",
  },
});
