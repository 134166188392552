import { EditOneOperationBuilder } from "@kraaft/offline";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { reorderElement } from "@kraaft/shared/core/modules/schema/schema.utils";
import { Api } from "@kraaft/shared/core/services/api";

export const reorderElementOperation = EditOneOperationBuilder.create<KSchema>()
  .payload<{
    targetKey: string;
    afterKey: string | undefined;
    sectionKey: string;
  }>()
  .expected((aggregate, payload) => {
    reorderElement(aggregate, payload);
    return aggregate;
  })
  .mutate(async (payload) => {
    const { updatedAt } = await Api.moveSchemaElement({
      schemaId: payload.id,
      targetKey: payload.targetKey,
      afterKey: payload.afterKey,
      sectionKey: payload.sectionKey,
    });
    return { updatedAt };
  });
