import { Pressable, StyleSheet, View } from "react-native";
import { Color, ColorStyle, KColor, PixelSize, Radius } from "../../constants";
import { Icon } from "../../display";

interface ColorLineProps {
  colors: string[];
  value: string;
  onChange: (value: KColor) => void;
  margin: number;
  isOpen: boolean;
  toggleOpen: () => void;
}

export const COLOR_DOT_SIZE = PixelSize.S24;

export const ColorLine = ({
  colors,
  value,
  toggleOpen,
  margin,
  isOpen,
  onChange,
}: ColorLineProps) => {
  return (
    <View style={styles.line}>
      {colors.map((color, index) => {
        if (color === "button") {
          return (
            <Pressable
              key={color}
              onPress={toggleOpen}
              style={[styles.moreButton, { marginHorizontal: margin }]}
            >
              <Icon
                name={isOpen ? "chevron-up" : "chevron-down"}
                size="SMALL"
              />
            </Pressable>
          );
        }
        return (
          <Pressable
            key={color}
            onPress={() => onChange(color as KColor)}
            style={[
              styles.color,
              {
                backgroundColor: color,
                marginHorizontal: margin,
                marginLeft: index === 0 ? 0 : undefined,
                marginRight: index === colors.length - 1 ? 0 : undefined,
              },
            ]}
          >
            {color === value ? <View style={styles.dot} /> : null}
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  moreButton: {
    height: COLOR_DOT_SIZE,
    width: COLOR_DOT_SIZE,
    borderRadius: Radius.MAXIMUM,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    justifyContent: "center",
    alignItems: "center",
  },
  color: {
    height: COLOR_DOT_SIZE,
    width: COLOR_DOT_SIZE,
    borderRadius: Radius.MAXIMUM,
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    height: PixelSize.S8,
    width: PixelSize.S8,
    backgroundColor: Color.WHITE,
    borderRadius: Radius.MAXIMUM,
  },
  line: {
    flexDirection: "row",
  },
});
