import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { KDialogContent } from "@kraaft/shared/components/kDialog/kDialogContent";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import {
  applyRenderUpdatesToSchemaTemplate,
  requestSchemaTemplateRender,
  setSchemaTemplateRender,
} from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateActions";
import { EditedSchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { selectCurrentContextAtLeastAdminOrSuperadmin } from "@kraaft/shared/core/modules/user/userSelectors";
import { uuid } from "@kraaft/shared/core/utils";
import { Preloader } from "@kraaft/ui";
import { makeDialogStyle } from "@kraaft/web/src/components/checklistTemplate/checklistTemplateReview/checklistTemplateReview.styles";
import { KDialogProps } from "@kraaft/web/src/components/kDialog/KDialogContent.types";
import { SimplifiedTableRecords } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableRecords";
import { useRouteService } from "@kraaft/web/src/core/services/navigation/useNavigationService";

interface ChecklistTemplateReviewProps {
  onApply: () => void;
  schemaId: string;
  schemaTemplateId: string;
  schemaTemplate: EditedSchemaTemplate;
}

const AVAILABLE_COLUMNS = [
  KColumnType.shortText,
  KColumnType.longText,
  KColumnType.currency,
  KColumnType.date,
  KColumnType.geolocation,
  KColumnType.number,
  KColumnType.selectMultiple,
  KColumnType.selectSingle,
  KColumnType.user,
  KColumnType.checkbox,
];

export const ChecklistTemplateReview = ({
  schemaId,
  schemaTemplateId,
  schemaTemplate,
  onApply,
}: ChecklistTemplateReviewProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = makeDialogStyle();

  const routeService = useRouteService<"Conversation">();
  const roomId = routeService.getParam("roomId");

  const canUpdateChecklistTemplate = useSelector(
    selectCurrentContextAtLeastAdminOrSuperadmin,
  );

  const schema = useSelector(selectSchema(schemaId));

  const finalSchema = useMemo(
    () =>
      schema
        ? KSchemaUtils.withSpecificColumns(schema, (column) =>
            AVAILABLE_COLUMNS.includes(column.type),
          )
        : undefined,
    [schema],
  );

  useEffect(() => {
    if (!schemaTemplate.rendered) {
      dispatch(requestSchemaTemplateRender({ schemaTemplateId }));
    }
  }, [dispatch, schemaTemplate.rendered, schemaTemplateId]);

  const rendered = schemaTemplate.rendered;

  const onSaveToSchemaTemplate = useCallback(() => {
    if (!finalSchema) {
      return;
    }
    dispatch(
      applyRenderUpdatesToSchemaTemplate({
        schema: finalSchema,
        schemaTemplateId,
      }),
    );
  }, [dispatch, finalSchema, schemaTemplateId]);

  const onRecordsChange = useCallback(
    (newRecords: ModularRecord[]) => {
      if (!schema) {
        return;
      }
      dispatch(
        setSchemaTemplateRender({
          schemaTemplateId,
          rendered: newRecords.map((record) => ({
            id: uuid(),
            poolId: schema.poolId,
            roomId,
            schemaId,
            properties: record.properties,
          })),
        }),
      );
    },
    [dispatch, roomId, schema, schemaId, schemaTemplateId],
  );

  const actions: KDialogProps["actions"] = useMemo(() => {
    const act: KDialogProps["actions"] = [
      {
        onPress: onApply,
        accessibilityLabel: t("import"),
        text: t("import"),
        variant: "PRIMARY",
      },
    ];
    if (canUpdateChecklistTemplate) {
      act.push({
        onPress: onSaveToSchemaTemplate,
        accessibilityLabel: t("updateTaskList"),
        text: t("updateTaskList"),
      });
    }
    return act;
  }, [canUpdateChecklistTemplate, onApply, onSaveToSchemaTemplate, t]);

  if (!rendered) {
    return <Preloader />;
  }

  if (!finalSchema) {
    return <Preloader />;
  }

  return (
    <KDialogContent
      title={schemaTemplate.name}
      content={
        <div className={classes.dialogContentContainer}>
          <SimplifiedTableRecords
            schema={finalSchema}
            records={rendered}
            onRecordsChange={onRecordsChange}
            canAddRecord
            canDeleteRecord
            recordType="modularFolder"
            noCheckboxConfirmation
          />
        </div>
      }
      actions={actions}
    />
  );
};
