import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";

import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Icon, Spacing } from "@kraaft/ui";
import { EditableText } from "@kraaft/web/src/components/editableText";

type EditableTagRowProps = {
  tag: SchemaLibraryTag;
  onNameChange: (tag: SchemaLibraryTag) => void;
  onDelete: (tag: SchemaLibraryTag) => void;
};
export const EditableTagRow = ({
  onNameChange,
  tag,
  onDelete,
}: EditableTagRowProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState(tag.name);

  const handleOnBlur = useCallback(() => {
    onNameChange({ ...tag, name });
  }, [name, onNameChange, tag]);

  const handleOnDelete = useCallback(() => {
    onDelete(tag);
  }, [onDelete, tag]);

  const [editing, setEditing] = useState(false);

  return (
    <View style={style.row}>
      <EditableText
        id={tag.id}
        editing={editing}
        setEditing={setEditing}
        numberOfLines={1}
        textInputProps={{
          placeholder: t("schemaLibraryViewTags.settings.tagName"),
          onChangeText: setName,
          onBlur: handleOnBlur,
          value: name,
        }}
      />
      <Pressable
        accessibilityLabel={t("camera.authorizeAudioRecording")}
        onPress={handleOnDelete}
      >
        <Icon name="trash-04" size="MEDIUM" color="GREY_TAUPE" />
      </Pressable>
    </View>
  );
};

const style = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    columnGap: Spacing.S8,
  },
});
