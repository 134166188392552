import { EditOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { optimisticSchemaRenameColumn } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";

export const renameSchemaColumnOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ columnKey: string; name: string }>()
    .expected((aggregate, payload) => {
      optimisticSchemaRenameColumn(aggregate.schema, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.editLibrarySchemaColumn({
        librarySchemaId: payload.id,
        columnKey: payload.columnKey,
        update: {
          name: payload.name,
        },
      });
      return { updatedAt };
    });
