import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import cl from "clsx";
import isEqual from "fast-deep-equal";

import { ModularFolderGeolocationCarouselProps } from "@kraaft/shared/components/mapController/modularFolderMap/carousel/modularFolderGeolocationCarouselProps";
import { getIndexedGeolocatedModularFolderCarouselItems } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { IndexedAttachmentWithGPS } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { OfflineModularFolderSelectors } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { ModularFolderStateActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { geolocationContextSelect } from "@kraaft/shared/core/modules/room/roomActions";
import { selectGeolocationContext } from "@kraaft/shared/core/modules/room/roomSelectors";
import { getVideoMedia } from "@kraaft/shared/core/utils/index.web";
import {
  Carousel,
  CarouselHandle,
  CarouselItemRenderer,
  Image,
  VideoPlayer,
} from "@kraaft/ui";

import { useStyles } from "./modularFolderGeolocationCarousel.styles";

const ModularFolderGeolocationCarousel_ = ({
  roomId,
  modularFolderId,
}: ModularFolderGeolocationCarouselProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const ref = useRef<CarouselHandle>(null);
  const modularFolder = useSelector(
    OfflineModularFolderSelectors.select(modularFolderId),
  );
  const geolocationContext = useSelector(selectGeolocationContext);

  const items = useMemo(() => {
    if (modularFolder) {
      return getIndexedGeolocatedModularFolderCarouselItems(modularFolder);
    }
    return [];
  }, [modularFolder]);

  const onItemClick = useCallback(
    (index: number) => {
      const clickedItem = items[index];

      if (clickedItem && modularFolderId) {
        dispatch(
          ModularFolderStateActions.openCarousel({
            roomId,
            folderId: modularFolderId,
            initialIndex: clickedItem.originalIndex,
          }),
        );
      }
    },
    [dispatch, roomId, modularFolderId, items],
  );

  const closeCarousel = useCallback(() => {
    dispatch(
      geolocationContextSelect({
        selected: false,
      }),
    );
  }, [dispatch]);

  const handleCarouselFocusChanged = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        dispatch(
          geolocationContextSelect({
            id: item.id,
            selected: true,
            actions: [
              {
                type: "centerOn",
                coords: item.geolocation.coords,
              },
            ],
          }),
        );
      }
    },
    [dispatch, items],
  );

  const selectedId = useMemo(
    () => items.findIndex(({ id }) => id === geolocationContext.select?.id),
    [items, geolocationContext],
  );

  useEffect(() => {
    const firstItemId = items[0]?.id;
    setTimeout(
      () =>
        dispatch(
          geolocationContextSelect({
            selected: true,
            id: firstItemId,
          }),
        ),
      0,
    );
  }, [items, dispatch]);

  useEffect(() => {
    if (selectedId !== -1 && ref.current !== null) {
      ref.current.goto(selectedId);
    }
  }, [selectedId, ref]);

  const renderItem = useCallback<
    CarouselItemRenderer<IndexedAttachmentWithGPS>
  >(({ item }) => {
    if (item.type === "image") {
      return (
        <Image
          preferredSize="large"
          style={styles.grow}
          contentFit="contain"
          source={item.original.downloadUrl}
        />
      );
    }
    if (item.type === "video") {
      return (
        <VideoPlayer
          style={styles.grow}
          autoplay={false}
          url={getVideoMedia(item).downloadUrl}
        />
      );
    }
    return null;
  }, []);

  return (
    <div
      className={cl(
        classes.carouselContainer,
        selectedId === -1 && classes.carouselHidden,
      )}
    >
      <div className={classes.carouselAspectRatio}>
        <div className={cl(classes.carousel, classes.carouselContent)}>
          <Carousel
            items={items}
            keyExtractor={(item) => item.id}
            open
            renderItem={renderItem}
            handle={ref}
            onFocusedChange={handleCarouselFocusChanged}
            smallUi
          />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

export const ModularFolderGeolocationCarousel = React.memo(
  ModularFolderGeolocationCarousel_,
  isEqual,
);
