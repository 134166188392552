import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import compact from "lodash/compact";

import { useUserJob } from "@kraaft/shared/components/onboarding/askUserJob/useUserJob";
import { intercom } from "@kraaft/shared/core/modules/intercom/intercom.provider";
import {
  selectCurrentPool,
  selectCurrentPoolId,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectCurrentUserIsSuperadmin,
  selectCurrentUserRole,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { compareNumbers, compareStrings } from "@kraaft/shared/core/utils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import {
  Button,
  ColorStyle,
  FontSize,
  Icon,
  PixelSize,
  Preloader,
  Spacing,
  Text,
} from "@kraaft/ui";
import { KDropdown } from "@kraaft/web/src/components/dropdown/kDropdown";
import {
  KTable,
  KTableColumn,
  KTableConfig,
} from "@kraaft/web/src/components/kTable";
import { ensureColumnType } from "@kraaft/web/src/components/kTable/kTableUtils";
import { PageHeader } from "@kraaft/web/src/components/pageHeader";
import { PageHeaderAction } from "@kraaft/web/src/components/pageHeader/pageHeader.types";
import { dashEmpty } from "@kraaft/web/src/core/utils";
import { AddUserDialog } from "@kraaft/web/src/views/settings/addUserDialog";
import { ImportUserDialog } from "@kraaft/web/src/views/settings/importUserDialog";
import { InviteOrImportCollaborators } from "@kraaft/web/src/views/settings/manageMembers/inviteOrImportCollaborators/inviteOrImportCollaborators";
import { JobColumn } from "@kraaft/web/src/views/settings/manageMembers/jobColumn";
import { useRoleColumn } from "@kraaft/web/src/views/settings/manageMembers/useRoleColumn";

import { Box } from "../../../components/box";
import { CopiableText } from "../../../components/copiableText";
import { Toggle } from "../../../components/toggle";
import {
  MemberManagementProvider,
  useMemberManagement,
} from "./manageMembers.context";
import { ManageMembersInfo } from "./manageMembersInfo";
import { OptionButton } from "./optionButton";

import { useSettingsStyles } from "../settings.styles";
import { useStyles } from "./manageMembers.styles";

const ManageMembers = () => {
  const poolId = useSelector(selectCurrentPoolId);
  if (!poolId) {
    return null;
  }
  return (
    <MemberManagementProvider poolId={poolId}>
      <ManageMembersPage poolId={poolId} />
    </MemberManagementProvider>
  );
};

const ManageMembersPage = ({ poolId }: { poolId: string }) => {
  const { t } = useTranslation();
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();

  const pool = useSelector(selectCurrentPool);

  const { formatJob } = useUserJob();

  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);

  const [dialogOpen, setDialogOpen] = useState<
    "invite" | "import" | "add" | "none"
  >("none");
  const currentUserRole = useSelector(selectCurrentUserRole(poolId));

  const isAccountOwner = currentUserRole === UserPoolRole.OWNER;
  const isPoolSSO = !!pool?.identityProviderId;

  useTrackPage("SettingsMembers");

  const {
    members,
    refetch,
    setCanCreateConversation,
    setPoolMemberPricingScheme,
  } = useMemberManagement();

  const conditionalMenuEntry = (
    item: KTableColumn<(typeof members)[number]>,
    value: boolean,
  ) => (value ? item : undefined);

  const config: KTableConfig<(typeof members)[number]> = {
    defaultOrderBy: "name",
    columns: compact([
      conditionalMenuEntry(
        ensureColumnType({
          id: "id",
          label: t("id"),
          value: (item) => item.userId,
          compare: compareStrings,
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (item) => (
            <CopiableText key={item} ellipsizeMode="head" textToCopy={item}>
              {item}
            </CopiableText>
          ),
        }),
        isSuperadmin,
      ),
      ensureColumnType({
        id: "name",
        label: t("name"),
        value: (item) => dashEmpty(item.username),
        compare: compareStrings,
        width: "20%",
        style: { column: { fontWeight: "bold" } },
      }),
      ensureColumnType({
        id: "email",
        label: t("emailAddress"),
        value: (item) => dashEmpty(item.email),
        compare: compareStrings,
        width: "30%",
      }),
      ensureColumnType({
        id: "phone",
        label: t("phone"),
        value: (item) => dashEmpty(item.phone),
        compare: compareStrings,
        width: "20%",
      }),
      conditionalMenuEntry(
        ensureColumnType({
          id: "sso",
          label: t("sso.members.tableHeader"),
          value: ({ ssoProviders }) =>
            ssoProviders
              ? ssoProviders.map((sso) => (
                  <div key={sso.ssoId}>
                    {sso.name} {sso.email ? `(${sso.email})` : ""}
                  </div>
                ))
              : "-",

          width: "20%",
        }),
        isPoolSSO,
      ),
      conditionalMenuEntry(
        ensureColumnType({
          id: "ssoDebug",
          label: "SSO 🦸",
          value: ({ ssoProviders }) =>
            ssoProviders
              ? ssoProviders.map((sso) => (
                  <div key={sso.ssoId}>
                    {sso.providerId} ({sso.ssoId})
                  </div>
                ))
              : "-",
          width: "20%",
        }),
        isSuperadmin,
      ),
      conditionalMenuEntry(
        ensureColumnType({
          id: "roomCount",
          label: t("roomCount"),
          style: {
            header: {
              padding: 0,
              fontSize: FontSize.MINI,
              whiteSpace: "normal",
              textAlign: "center",
              lineHeight: "1",
            },
          },
          value: (item) => item.poolRoomCount ?? 0,
          compare: compareNumbers,
        }),
        isSuperadmin,
      ),
      conditionalMenuEntry(
        ensureColumnType({
          id: "otherRoomCount",
          label: t("otherRoomCount"),
          style: {
            header: {
              padding: 0,
              fontSize: FontSize.MINI,
              textAlign: "center",
              whiteSpace: "normal",
              lineHeight: "1",
            },
          },
          value: (item) =>
            (item.totalRoomCount ?? 0) - (item.poolRoomCount ?? 0),
          compare: compareNumbers,
          width: "20%",
        }),
        isSuperadmin,
      ),
      conditionalMenuEntry(
        ensureColumnType({
          id: "poolCount",
          label: t("poolCount"),
          style: {
            header: {
              padding: 0,
              fontSize: FontSize.MINI,
              textAlign: "center",
              whiteSpace: "normal",
              lineHeight: "1",
            },
          },
          value: (item) => item.poolCount ?? 0,
          compare: compareNumbers,
          width: "20%",
        }),
        isSuperadmin,
      ),
      useRoleColumn({
        width: "30%",
        currentUserRole: currentUserRole,
      }),
      conditionalMenuEntry(
        ensureColumnType({
          width: "150px",
          id: "pricing",
          label: t("settingsBilling"),
          value: (item) => item,
          style: {
            column: isSuperadmin
              ? { display: "flex", width: "150px" }
              : {
                  width: "150px",
                },
          },
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (item) =>
            isSuperadmin && item.pricing ? (
              <KDropdown
                fullWidth
                items={[
                  {
                    label: t(
                      `billing.pricing.auto.${item.pricing.computed}` as any,
                    ),
                    value: "automatic",
                  },
                  { label: t("billing.pricing.offered"), value: "offered" },
                  {
                    label: t("billing.pricing.chargeable"),
                    value: "chargeable",
                  },
                ]}
                selectedItemIds={[item.pricing.scheme]}
                onSelectionChange={(selectedItemIds) => {
                  const selection = selectedItemIds?.[0];
                  if (poolId && selection) {
                    setPoolMemberPricingScheme(item.userId, selection);
                  }
                }}
              />
            ) : item.pricing ? (
              t(`billing.pricing.${item.pricing.computed}`)
            ) : (
              <Preloader size="small" />
            ),
        }),
        isAccountOwner || isSuperadmin,
      ),
      conditionalMenuEntry(
        ensureColumnType({
          id: "job",
          label: t("userJob.job"),
          value: (item) => item,
          compare: (a, b) =>
            compareStrings(
              a.job ? formatJob(a.job) : "",
              b.job ? formatJob(b.job) : "",
            ),
          width: "20%",
          // eslint-disable-next-line react/no-unstable-nested-components
          component: (value) => {
            return (
              pool && (
                <JobColumn
                  reload={refetch}
                  poolId={pool?.id}
                  job={value.job}
                  userId={value.userId}
                />
              )
            );
          },
          style: {
            header: { padding: 0 },
            column: { padding: 0 },
          },
        }),
        isSuperadmin,
      ),
      ensureColumnType({
        id: "createConversations",
        label: t("createConversations"),
        value: (item) => item,
        compare: (a, b) => Number(a) - Number(b),

        // eslint-disable-next-line react/no-unstable-nested-components
        component: (value) => {
          const roles = value.roles || [];
          const isStandardOrExternal =
            roles.includes("std") || roles.includes("ext");
          return (
            pool && (
              <Box grow items="center" mr="S32">
                <Toggle
                  disabled={!isStandardOrExternal || !value.isEditable}
                  value={value.canCreateConversations}
                  setValue={(v) => setCanCreateConversation(value.userId, v)}
                />
              </Box>
            )
          );
        },
        style: {
          header: { padding: 0 },
          column: { padding: 0 },
        },
      }),
      conditionalMenuEntry(
        ensureColumnType({
          id: "supportnameagain",
          label: t("name"),
          value: (item) => dashEmpty(item.username),
          compare: compareStrings,
          width: "20%",
          style: { column: { fontWeight: "bold" } },
        }),
        isSuperadmin,
      ),
      ensureColumnType({
        id: "actions",
        label: "",
        value: (item) => item,
        // eslint-disable-next-line react/no-unstable-nested-components
        component: (value, context) => {
          return (
            pool?.id && (
              <OptionButton
                reload={refetch}
                disabled={!value.isEditable}
                isSuperadmin={isSuperadmin}
                poolId={pool.id}
                value={value}
                context={context}
              />
            )
          );
        },
        style: {
          header: { padding: 0 },
          column: { padding: 0 },
        },
      }),
    ]),
    enableHover: true,
    style: {
      columns: {
        color: ColorStyle.FONT_HIGH_EMPHASIS,
        fontSize: FontSize.MEDIUM,
        paddingLeft: Spacing.S16,
      },
      rows: {
        height: PixelSize.S40,
      },
    },
  };

  const closeDialog = useCallback(() => {
    setDialogOpen("none");
  }, []);

  const openInviteDialog = useCallback(() => {
    setDialogOpen("invite");
  }, []);

  const openImportDialog = useCallback(() => {
    setDialogOpen("import");
  }, []);

  const openAddDialog = useCallback(() => {
    setDialogOpen("add");
  }, []);

  const conditionalHeaderEntry = useCallback(
    (item: PageHeaderAction, conditionValue: boolean) =>
      conditionValue ? item : undefined,
    [],
  );

  const handleContactSupport = useCallback(() => {
    trackEvent({
      eventName: "Click On Contact Support Button",
      from_page: "members tab",
    });
    intercom.openChat();
  }, []);

  const membersActions: Array<PageHeaderAction> = useMemo(
    () =>
      compact([
        {
          id: "settings-members-invite-collaborators",
          text: isSuperadmin
            ? t("superadminInviteCollaborators")
            : t("inviteCollaborators"),
          onPress: openInviteDialog,
          variant: "PRIMARY",
        },
        conditionalHeaderEntry(
          {
            text: t("superadminImportUsers"),
            onPress: openImportDialog,
            variant: "PRIMARY",
          },
          isSuperadmin,
        ),
        conditionalHeaderEntry(
          {
            text: t("superadminAddUser"),
            onPress: openAddDialog,
            variant: "PRIMARY",
          },
          isSuperadmin,
        ),
      ]),
    [
      conditionalHeaderEntry,
      isSuperadmin,
      openAddDialog,
      openImportDialog,
      openInviteDialog,
      t,
    ],
  );

  const renderMembersActions = useCallback(
    () =>
      membersActions.map(({ id, text, ...buttonProps }) => (
        <Button
          key={id}
          accessibilityLabel={text}
          text={text}
          {...buttonProps}
        />
      )),
    [membersActions],
  );

  return (
    <>
      <div className={clsx(settingsClasses.pageContainer, classes.container)}>
        <PageHeader
          title={t("settingsMembers")}
          right={renderMembersActions()}
          titleElementId="settings-members-title"
        />
        <div className={classes.tableAndContactSupportContainer}>
          <div className={classes.tableWrapper}>
            {isSuperadmin && <ManageMembersInfo members={members} />}
            <KTable
              data={members}
              extractKey={(member) => member.userId}
              config={config}
              tableContainerClassName={classes.kTable}
            />
          </div>
          <div className={classes.contactSupportContainer}>
            <div className={classes.contactSupportLeft}>
              <Icon name="user-edit" size="XLARGE" />
              <div className={classes.contactSupportText}>
                <Text size="BODY" weight="medium">
                  {t(
                    "contactSupportProfileEdition.wannaEditYourMembersProfiles",
                  )}
                </Text>
                <Text size="MEDIUM">
                  <Trans
                    i18nKey="contactSupportProfileEdition.contactSupport"
                    components={{
                      blue: <Text color="BLUE_KRAAFT" />,
                    }}
                  />
                </Text>
              </div>
            </div>
            <Button
              onPress={handleContactSupport}
              text={t("menuContactSupport")}
              accessibilityLabel={t("menuContactSupport")}
              variant="SECONDARY"
            />
          </div>
        </div>
      </div>
      <>
        <InviteOrImportCollaborators
          open={dialogOpen === "invite"}
          onClose={closeDialog}
          poolId={poolId}
          isSuperadmin={isSuperadmin}
        />
        <ImportUserDialog
          poolId={poolId}
          open={dialogOpen === "import"}
          onClose={closeDialog}
        />
        <AddUserDialog
          poolId={poolId}
          open={dialogOpen === "add"}
          onClose={closeDialog}
        />
      </>
    </>
  );
};

export { ManageMembers };
