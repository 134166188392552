import { EditOneOperationBuilder } from "@kraaft/offline";
import {
  KSchema,
  SchemaDefaultVisibility,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Api } from "@kraaft/shared/core/services/api";

export const setDefaultVisibilityOperation =
  EditOneOperationBuilder.create<KSchema>()
    .payload<{ defaultVisibility: SchemaDefaultVisibility }>()
    .expected((aggregate, payload) => {
      if (aggregate.collection === "folder") {
        aggregate.defaultVisibility = payload.defaultVisibility;
      }
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.setSchemaDefaultVisibility({
        schemaId: payload.id,
        defaultVisibility: payload.defaultVisibility,
      });
      return { updatedAt };
    });
