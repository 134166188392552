import { reducer as toastr } from "react-redux-toastr";
import { StateFromReducersMapObject } from "@reduxjs/toolkit";

import { librarySchemaReducers } from "@kraaft/shared/core/modules/librarySchema";
import { pollingReducers } from "@kraaft/shared/core/modules/polling";
import { previewReducers } from "@kraaft/shared/core/modules/preview";
import { schemaLibraryTagSlice } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTag.reducers";
import { schemaViewReducers } from "@kraaft/shared/core/modules/schemaView";
import { workflowReducers } from "@kraaft/shared/core/modules/workflows";
import { poolAdminReducers } from "@kraaft/web/src/core/modules/poolAdmin";

import { commonReducers } from "./commonReducers";

const WEB_reducers = {
  toastr,
  poolAdmin: poolAdminReducers,
  polling: pollingReducers,
  workflow: workflowReducers,
  preview: previewReducers,
  schemaView: schemaViewReducers,
  librarySchema: librarySchemaReducers,
  schemaLibraryTag: schemaLibraryTagSlice.reducer,
  ...commonReducers,
};

export type RootState = StateFromReducersMapObject<typeof WEB_reducers>;

export { WEB_reducers as platformReducers };
