import { View } from "react-native";

import { ImageAttachment } from "@kraaft/shared/core/modules/folder/attachmentTypes";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { Image } from "@kraaft/ui";

import { styles } from "./replyPreview.style";

interface ImagePreviewProps {
  message: Messages.Image;
  attachment: ImageAttachment;
}

export const ImagePreview = (props: ImagePreviewProps) => {
  const { attachment } = props;

  return (
    <View style={styles.fileContainer}>
      <Image
        preferredSize="thumbnail"
        contentFit="cover"
        style={styles.imagePreview}
        source={attachment.original.downloadUrl}
      />
    </View>
  );
};
