import { DeclareOfflineFeature } from "@kraaft/offline";
import { createSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/createSchemaLibraryTagOperation";
import { deleteSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/deleteSchemaLibraryTagOperation";
import { renameSchemaLibraryTagOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/renameSchemaLibraryTagOperation";
import { reorderSchemaLibraryTagsOperation } from "@kraaft/shared/core/modules/schemaLibraryTag/operations/reorderSchemaLibraryTagsOperation";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { taskManagerUtils } from "@kraaft/shared/core/store/offline/taskManagerUtils.provider";

export const SchemaLibraryTagOfflineFeature =
  DeclareOfflineFeature<SchemaLibraryTag>("SchemaLibraryTag")(
    {
      reorderSchemaLibraryTags: reorderSchemaLibraryTagsOperation,
      renameSchemaLibraryTag: renameSchemaLibraryTagOperation,
      deleteSchemaLibraryTag: deleteSchemaLibraryTagOperation,
      createSchemaLibraryTag: createSchemaLibraryTagOperation,
    },
    taskManagerUtils.create("SchemaLibraryTag"),
  );
