import { useCallback, useEffect, useRef, useState } from "react";

import { useAddCallback } from "./useAddCallback";

export function useInputDefaultValueBehavior(
  defaultValue: string,
  autoFocus?: boolean,
) {
  const [value, setValue] = useState(defaultValue);
  const isEditing = useRef(autoFocus);

  useEffect(() => {
    if (isEditing.current) {
      return;
    }
    setValue(defaultValue);
  }, [defaultValue]);

  const setValueProxy = useAddCallback(
    setValue,
    useCallback(() => {
      isEditing.current = true;
    }, []),
  );

  const overloadedOnBlur = useCallback(() => {
    isEditing.current = false;
  }, []);

  return [
    value,
    setValueProxy,
    {
      onBlur: overloadedOnBlur,
      autoFocus,
    },
  ] as const;
}
