import { MarkRequired } from "ts-essentials";

import { AnyMessage, Messages } from "./any.message";
import { AnyUserMessage } from "./any.user.message";

export type ForwardedUserMessage = MarkRequired<AnyUserMessage, "forwarded">;
export type AnsweringUserMessage = MarkRequired<AnyUserMessage, "answerTo">;
export type DeletedMessage = MarkRequired<AnyMessage, "deletedAt">;
export type DeletedLogMessage = MarkRequired<Messages.Log, "deletedAt">;
export type DeletedUserMessage = MarkRequired<AnyUserMessage, "deletedAt">;
export type PersistedUserMessage = AnyUserMessage & {
  sendingStatus: "persisted";
};
export type MessageWithTextCapability =
  | Messages.Text
  | Messages.Image
  | Messages.Video
  | Messages.Audio;
export type MessageWithCaptionEditionCapability =
  | Messages.Image
  | Messages.Video;
export type MessageWithAttachment =
  | Messages.Image
  | Messages.Video
  | Messages.Document
  | Messages.Audio;
export type MessageWithGeolocatedAttachment = Messages.Image | Messages.Video;

export class MessageHelper {
  static isText(message: AnyMessage | undefined): message is Messages.Text {
    return message !== undefined && message.type === "text";
  }

  static isImage(message: AnyMessage | undefined): message is Messages.Image {
    return message !== undefined && message.type === "image";
  }

  static isVideo(message: AnyMessage | undefined): message is Messages.Video {
    return message !== undefined && message.type === "video";
  }

  static isDocument(
    message: AnyMessage | undefined,
  ): message is Messages.Document {
    return message !== undefined && message.type === "document";
  }

  static isAudio(message: AnyMessage | undefined): message is Messages.Audio {
    return message !== undefined && message.type === "audio";
  }

  static isGeolocation(
    message: AnyMessage | undefined,
  ): message is Messages.Geolocation {
    return message !== undefined && message.type === "geolocation";
  }

  static isLog(message: AnyMessage | undefined): message is Messages.Log {
    return message !== undefined && message.type === "log";
  }

  static isForwarded(message: AnyUserMessage): message is ForwardedUserMessage {
    return message.forwarded !== undefined;
  }

  static isAnswering(message: AnyUserMessage): message is AnsweringUserMessage {
    return message.answerTo !== undefined;
  }

  static isDeleted(message: Messages.Log): message is DeletedLogMessage;
  static isDeleted(message: AnyUserMessage): message is DeletedUserMessage;
  static isDeleted(message: AnyMessage): message is DeletedMessage {
    return message.deletedAt !== undefined;
  }

  static isPersisted(message: AnyUserMessage): message is PersistedUserMessage {
    return message.sendingStatus === "persisted";
  }

  static isReply(message: AnyUserMessage): boolean {
    return message.isReply === true;
  }

  private static userMessageTypesSet = new Set(
    Object.keys({
      text: "text",
      image: "image",
      audio: "audio",
      video: "video",
      document: "document",
      geolocation: "geolocation",
    } satisfies { [key in AnyUserMessage["type"]]: key }),
  );
  static isUserMessage(msg: AnyMessage): msg is AnyUserMessage {
    return MessageHelper.userMessageTypesSet.has(msg.type);
  }

  static hasTextCapability(
    message: AnyUserMessage,
  ): message is MessageWithTextCapability {
    return (
      MessageHelper.isText(message) ||
      MessageHelper.isImage(message) ||
      MessageHelper.isVideo(message) ||
      MessageHelper.isAudio(message)
    );
  }

  static hasCaptionEditionCapability(
    message: AnyUserMessage,
  ): message is MessageWithCaptionEditionCapability {
    return MessageHelper.isImage(message) || MessageHelper.isVideo(message);
  }

  static hasAttachment(message: AnyMessage): message is MessageWithAttachment {
    return (
      MessageHelper.isImage(message) ||
      MessageHelper.isVideo(message) ||
      MessageHelper.isDocument(message) ||
      MessageHelper.isAudio(message)
    );
  }

  static hasGeolocatedAttachment(
    message: AnyMessage,
  ): message is MessageWithGeolocatedAttachment {
    return MessageHelper.isImage(message) || MessageHelper.isVideo(message);
  }
}
