import type firebase from "firebase/compat/app";

import { isNative } from "@kraaft/helper-functions";
import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import {
  NetworkError,
  TransientError,
} from "@kraaft/shared/core/services/firebase/networkError";

export type User = { id: string; email?: string };

interface SSOProvider {
  id: string;
  offlineAccess: boolean;
}

export abstract class FirebaseSDK {
  constructor(private readonly auth: typeof firebase.auth) {
    this.init();
    if (getEnvironment().FIREBASE.ENABLE_AUTH_TESTING) {
      this.enableAuthTesting();
    }
  }

  private static FIREBASE_NETWORK_ERRORS = ["auth/network-request-failed"];

  private static FIREBASE_TRANSIENT_ERRORS = [
    "auth/too-many-requests",
    "auth/user-token-expired",
    "auth/id-token-expired",
    "auth/id-token-revoked",
    "auth/internal-error",
    "auth/invalid-id-token",
    "auth/too-many-requests",
    "auth/invalid-user-token",

    "storage/quota-exceeded",
    "storage/retry-limit-exceeded",
    "storage/internal-error",
    "storage/canceled",
  ];

  private static getErrorCodeAndMessage(error: any) {
    const code = error?.code;
    if (typeof code !== "string") {
      return undefined;
    }
    let message: string | undefined;
    const errorMessage = error?.message;
    if (typeof errorMessage === "string") {
      message = errorMessage;
    }
    return { code, message };
  }

  static interpretError(error: any) {
    const errorInfo = FirebaseSDK.getErrorCodeAndMessage(error);

    if (!errorInfo) {
      return error;
    }

    const { code, message } = errorInfo;

    if (FirebaseSDK.FIREBASE_NETWORK_ERRORS.includes(code)) {
      return new NetworkError({
        cause: error,
        message,
      });
    }

    if (
      FirebaseSDK.FIREBASE_TRANSIENT_ERRORS.includes(code) ||
      (code === "storage/unknown" &&
        message &&
        !message.includes("Permission Denial"))
    ) {
      return new TransientError({ message, cause: error });
    }

    return error;
  }

  abstract init(): void;
  abstract enableAuthTesting(): void;

  getCurrentUser() {
    return this.auth().currentUser;
  }

  onIdTokenChanged(fn: (token: null | { providerId: string }) => void) {
    return this.auth().onIdTokenChanged(async (user) => {
      const result = await user?.getIdTokenResult();
      result?.signInProvider;
      if (result?.signInProvider) {
        return fn({ providerId: result.signInProvider });
      }
      return fn(null);
    });
  }

  getLoginMethods(email: string) {
    return this.auth().fetchSignInMethodsForEmail(email);
  }

  onAuthStateChanged(fn: (user: User | null) => void) {
    return this.auth().onAuthStateChanged((user) => {
      if (user) {
        return fn({
          id: user.uid,
          email: user.email || undefined,
        });
      }
      return fn(null);
    });
  }

  async triggerSSOLinking(providerInfo: SSOProvider) {
    const user = this.auth().currentUser;

    if (!user) {
      return;
    }

    const provider = new this.auth.OAuthProvider(providerInfo.id);
    if (providerInfo.offlineAccess) {
      provider.addScope("offline_access");
    }

    provider.setCustomParameters(this.buildOauthCustomParameters(user.email));

    const creds = await user.linkWithPopup(provider);
    if (creds.credential) {
      await user.reauthenticateWithCredential(creds.credential);
    }
  }

  async triggerSSOLogin(providerInfo: SSOProvider, suggestedEmail?: string) {
    const provider = new this.auth.OAuthProvider(providerInfo.id);
    if (providerInfo.offlineAccess) {
      provider.addScope("offline_access");
    }

    provider.setCustomParameters(
      this.buildOauthCustomParameters(suggestedEmail),
    );

    await this.auth().signInWithPopup(provider);
  }

  private buildOauthCustomParameters(email: string | null | undefined) {
    return isNative()
      ? {
          login_hint: email,
          prompt: "select_account", // let the user change his account
        }
      : {
          login_hint: email,
        };
  }
}
