import { spawn } from "typed-redux-saga/macro";

import { downloadDirectorySaga } from "@kraaft/shared/core/modules/directory/sagas/downloadDirectory.saga";
import { moveDirectorySaga } from "@kraaft/shared/core/modules/directory/sagas/moveDirectory.saga";
import { newDirectorySaga } from "@kraaft/shared/core/modules/directory/sagas/newDirectory.saga";
import { removeDirectorySaga } from "@kraaft/shared/core/modules/directory/sagas/removeDirectory.saga";
import { renameDirectorySaga } from "@kraaft/shared/core/modules/directory/sagas/renameDirectory.saga";
import { setDefaultTreeForPoolSaga } from "@kraaft/shared/core/modules/directory/sagas/setDefaultTreeForPool.saga";
import { subscribeToRoomDirectoriesSaga } from "@kraaft/shared/core/modules/directory/sagas/subscribeToRoomDirectories.saga";

export function* directorySagas() {
  yield* spawn(subscribeToRoomDirectoriesSaga);
  yield* spawn(downloadDirectorySaga);
  yield* spawn(moveDirectorySaga);
  yield* spawn(newDirectorySaga);
  yield* spawn(removeDirectorySaga);
  yield* spawn(renameDirectorySaga);
  yield* spawn(setDefaultTreeForPoolSaga);
}
