import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import { ColorStyle, Icon } from "@kraaft/ui";

import { KTableColumn, Order, OrderLabel } from "../kTableTypes";
import { isCurrentColumnOrder } from "./kTableHeaderUtils";

import { useStyles } from "./kTableHeader.styles";

type KTableHeaderProps<T> = {
  columns: Array<KTableColumn<T>>;
  order: Order;
  orderBy: string;
  onRequestSort: (property: string) => void;
};

const KTableHeader = <T,>({
  columns,
  order,
  orderBy,
  onRequestSort,
}: KTableHeaderProps<T>) => {
  const classes = useStyles();
  const createSortHandler = (property: string) => () => {
    onRequestSort(property);
  };

  function renderIcon(column: { id: string }) {
    const isActive = isCurrentColumnOrder(column, order, orderBy);
    const iconColor = isActive
      ? ColorStyle.ACTION_CTA
      : ColorStyle.FONT_LOW_EMPHASIS;

    return (props: { className?: string }) => (
      <span {...props}>
        <Icon name="chevron-down" size="MINI" color={iconColor} />
      </span>
    );
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: KTableColumn<T>) => {
          const isActive = isCurrentColumnOrder(column, order, orderBy);
          const colStyle: React.CSSProperties = {
            width: column.width || undefined,
            whiteSpace: "nowrap",
            ...column.style?.header,
          };

          return (
            <TableCell key={column.id} align="left" style={colStyle}>
              {column.compare === undefined ? (
                column.label
              ) : (
                <TableSortLabel
                  classes={{
                    root: classes.tableSortLabelRoot,
                    active: classes.tableSortLabelActive,
                  }}
                  active={isActive}
                  direction={OrderLabel[order]}
                  IconComponent={renderIcon(column)}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export { KTableHeader };
