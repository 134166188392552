import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import { GradientLogHolder } from "@kraaft/shared/components/message/messageLog/holders/gradientLogHolder";
import { FolderLogWrapper } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/folderLogWrapper";
import { LogText } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/logText";
import { OpenWithArrow } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/openWithArrow";
import { PrependWithSchemaIcon } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/prependWithSchemaIcon";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { FolderCheckedEvent } from "@kraaft/shared/core/modules/message/core/log.message";
import { selectUser } from "@kraaft/shared/core/modules/user/userSelectors";
import { getUsername } from "@kraaft/shared/core/modules/user/userUtils";
import { Color, Text } from "@kraaft/ui";

interface FolderCheckedProps {
  roomId: string;
  message: Messages.Log;
  event: FolderCheckedEvent;
}

export const FolderChecked = ({
  roomId,
  message,
  event,
}: FolderCheckedProps) => {
  const user = useSelector(selectUser(message.senderId));

  return (
    <GradientLogHolder>
      <FolderLogWrapper event={event} roomId={roomId}>
        <Text size="MEDIUM" style={styles.center}>
          <PrependWithSchemaIcon event={event} />
          <LogText
            color={Color.WHITE}
            i18nKey="logFolderChecked"
            userId={message.senderId}
            values={{
              username: getUsername(user),
              folderName: event.folderName,
            }}
            userColor={Color.WHITE}
          />
        </Text>
        <OpenWithArrow />
      </FolderLogWrapper>
    </GradientLogHolder>
  );
};

const styles = StyleSheet.create({
  center: {
    textAlign: "center",
  },
});
