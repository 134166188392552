import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { noop } from "ts-essentials";

import { ModularDetailsEditorContext } from "@kraaft/shared/components/modular/details/editors/types";
import { useModularDetails } from "@kraaft/shared/components/modular/details/useModularDetails";
import { ModularTableValueUpdate } from "@kraaft/shared/components/modular/details/utils";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { useOnRoomCellupdate } from "@kraaft/shared/core/modules/room/roomRecordUtils";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { selectRoomPoolId } from "@kraaft/shared/core/modules/room/selectors";
import { KSchemaSection } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { useRoomModularColumnContext } from "@kraaft/shared/core/modules/schema/modularTypes/useRoomModularColumnContext";
import { ConversationDetailsPanel } from "@kraaft/shared/core/services/navigation/navigationParams";

export type ModularRoomDetailsProps = {
  id?: string;
  schemaId: string;
  rootSection: KSchemaSection;
  room: Room;
  portalHostname: string | undefined;
  isStickyPanel?: boolean;
  onPanelClose?: () => void;
  openedPanel: ConversationDetailsPanel | undefined;
  offsetIndices?: number;
};

export const useModularRoomDetails = ({
  id,
  schemaId,
  room,
  portalHostname,
  isStickyPanel,
  onPanelClose,
  openedPanel,
  offsetIndices,
  rootSection,
}: ModularRoomDetailsProps) => {
  const canEdit = Guard.use("Room.editRecord");
  const roomPoolId = useSelector(selectRoomPoolId(room.id));
  const roomDict = useMemo(() => ({ [room.id]: room }), [room]);

  const onCellUpdate = useOnRoomCellupdate(roomPoolId, roomDict);

  const handleUpdateRecord = useCallback(
    (update: ModularTableValueUpdate) => {
      onCellUpdate([update]);
    },
    [onCellUpdate],
  );

  const columnsContext = useRoomModularColumnContext(schemaId);

  const context = useMemo(
    (): ModularDetailsEditorContext => ({
      portalHostname,
      roomId: room.id,
      isStickyPanel,
      onClose: onPanelClose,
      openedPanel,
    }),
    [isStickyPanel, onPanelClose, openedPanel, portalHostname, room.id],
  );

  return useModularDetails({
    testId: id ?? `editor-${room.id}`,
    id: room.id,
    rootSection,
    recordProperties: room.record.properties,
    updateRecord: handleUpdateRecord,
    // Cant sign on a room
    updateRecordSignature: noop,
    editorContext: context,
    columnsContext: columnsContext,
    offsetIndices,
    disabled: !canEdit,
  });
};
