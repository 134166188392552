import moment from "moment";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, ScrollView, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { isNative } from "@kraaft/helper-functions";
import { DetailsSection } from "@kraaft/shared/components/conversationDetails/detailsSection";
import { SectionHeader } from "@kraaft/shared/components/conversationDetails/sectionHeader";
import { KInputEmail } from "@kraaft/shared/components/input/kInputEmail";
import { KSelectableListItem } from "@kraaft/shared/components/kSelectionList/kSelectionListProps";
import { KSelectionListUncontrolled } from "@kraaft/shared/components/kSelectionList/kSelectionListUncontrolled";
import { FolderSelectionForSchema } from "@kraaft/shared/components/report/folderSelectionForSchema";
import { useRoomReportFilters } from "@kraaft/shared/components/report/useRoomReportFilters";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { SlidePanel } from "@kraaft/shared/components/slidePanel";
import { selectSavedExportEmail } from "@kraaft/shared/core/modules/app/appSelector";
import { selectOrderedModularFoldersWithRoomIdAndSchemaId } from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { ReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { selectPoolFolderSchemasEnabledForRoom } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.selectors";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button } from "@kraaft/ui";

import { ReportDates } from "./reportDates";
import { ReportElements } from "./reportElements";
import { Toggles } from "./toggles";

import { styles } from "./reportGenerator.styles";

enum Selection {
  WholePool = "whole_pool",
  Dates = "dates",
  Elements = "elements",
}

enum ExportFormat {
  Email = "email",
  Download = "download",
}

export interface SelectedFoldersState {
  [schemaId: string]: string[];
}

export type BaseReportGeneratorProps = {
  close?: () => void;
  portalHostname: string | undefined;
};

type Props = BaseReportGeneratorProps & {
  room: Room;
};

const ConnectedReportGenerator = (props: Props) => {
  const { room, close, portalHostname } = props;
  const { t } = useTranslation();

  const shareReportButtonRef = useRef(null);
  const downloadReportButtonRef = useRef(null);

  const [openenedSelectionPanel, setOpenenedSelectionPanel] = useState<
    string | undefined
  >(undefined);
  const [exportFormat, setExportFormat] = useState(
    isNative() ? ExportFormat.Email : ExportFormat.Download,
  );
  const [email, setEmail] = useState("");
  const [selectedFolders, setSelectedFolders] = useState<SelectedFoldersState>(
    {},
  );
  const {
    filters,
    updateFilters,
    updateDateFilter,
    setFolderIds,
    setSelectingByElements,
    sendRoomReport,
    isSendingRoomReport,
    downloadRoomReport,
    isDownloadingRoomReport,
  } = useRoomReportFilters(room.id);

  const [selection, setSelectionState] = useState(Selection.WholePool);

  const savedEmail = useSelector(selectSavedExportEmail);
  const schemas = useSelector(
    selectPoolFolderSchemasEnabledForRoom(room.poolId, room.id),
  );
  const roomSchema = useSelector(selectRoomSchema(room?.poolId));

  const { folders: roomFolders } = useSelector(
    selectOrderedModularFoldersWithRoomIdAndSchemaId({
      roomId: room.id,
      schemaId: undefined,
    }),
  );

  useTrackPage("GenerateRoomReport", { identifier: room.id });

  useEffect(() => {
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, [savedEmail]);

  const openSelectionPanel = useCallback(
    (schemaId: string) => () => setOpenenedSelectionPanel(schemaId),
    [],
  );

  const closeSelectionPanel = useCallback(
    () => setOpenenedSelectionPanel(undefined),
    [],
  );

  const setSelection = useCallback(
    (stage: Selection) => {
      if (stage === Selection.Elements) {
        setSelectingByElements(true);
      } else {
        setSelectingByElements(false);
      }
      setSelectionState(stage);
    },
    [setSelectingByElements, setSelectionState],
  );

  const handleOnFolderSelect = useCallback(
    (schemaId: string, selected: string[]) => {
      const newValue = { ...selectedFolders };
      newValue[schemaId] = selected;
      setSelectedFolders(newValue);

      setFolderIds(Object.values(newValue).flat(1));
    },
    [selectedFolders, setFolderIds],
  );

  useEffect(() => {
    if (selection === Selection.Dates) {
      const createdAt = room?.createdAt;
      if (createdAt) {
        createdAt.setHours(0);
        createdAt.setMinutes(0);
        createdAt.setSeconds(0);
      }
      updateFilters("dateRange")({
        from: createdAt ? createdAt : new Date(),
        until: new Date(),
      });
    } else {
      updateFilters("dateRange")(undefined);
    }
  }, [selection, room?.createdAt, updateDateFilter, updateFilters]);

  const itemsDelivery = useMemo<KSelectableListItem<ExportFormat>[]>(
    () => [
      {
        label: t("generateReportDownloadDirectly"),
        identifier: ExportFormat.Download,
      },
      {
        label: t("generateReportSendByEmail"),
        identifier: ExportFormat.Email,
      },
    ],
    [t],
  );

  const setSelectedExportFormat = useCallback((value: ExportFormat[]) => {
    const newExportFormat = value[0];
    if (newExportFormat) {
      setExportFormat(newExportFormat);
    }
  }, []);

  const itemsSelection: KSelectableListItem<Selection>[] = useMemo(() => {
    const shouldEnableSelectByElements = roomFolders.some((modularFolder) =>
      schemas.some((schema) => schema.id === modularFolder.schemaId),
    );

    return [
      {
        label: t("generateReportSelectAll"),
        identifier: Selection.WholePool,
      },
      {
        label: t("generateReportSelectByDate"),
        identifier: Selection.Dates,
      },
      ...(shouldEnableSelectByElements
        ? [
            {
              label: t("generateReportSelectByElements"),
              identifier: Selection.Elements,
            },
          ]
        : []),
    ];
  }, [roomFolders, schemas, t]);

  const setSelectedSelection = useCallback(
    (value: Selection[]) => {
      const newSelection = value[0];
      if (newSelection) {
        setSelection(newSelection);
      }
    },
    [setSelection],
  );

  const handleShareReport = useCallback(
    async (reportTemplate: ReportTemplate) => {
      await sendRoomReport(email, reportTemplate);
      close?.();
    },
    [sendRoomReport, email, close],
  );

  const handleDownloadReport = useCallback(
    async (reportTemplate: ReportTemplate) => {
      await downloadRoomReport(reportTemplate);
      close?.();
    },
    [downloadRoomReport, close],
  );

  const {
    ReportTemplateSelection: ShareReportTemplateSelection,
    openReportTemplateSelection: openShareReportTemplateSelection,
  } = useReportTemplateSelection({
    schemaId: roomSchema?.id,
    callback: handleShareReport,
    anchorRef: shareReportButtonRef,
    allowPDF: false,
  });

  const {
    ReportTemplateSelection: DownloadReportTemplateSelection,
    openReportTemplateSelection: openDownloadReportTemplateSelection,
  } = useReportTemplateSelection({
    schemaId: roomSchema?.id,
    callback: handleDownloadReport,
    anchorRef: downloadReportButtonRef,
    allowPDF: false,
  });

  const explanation =
    selection === Selection.WholePool
      ? t("generateReportExplanationFull")
      : selection === Selection.Dates
        ? t("generateReportExplanationRange", {
            fromDate: moment(filters.dateRange?.from).format("L"),
            untilDate: moment(filters.dateRange?.until).format("L"),
          })
        : selection === Selection.Elements
          ? t("generateReportExplanationObjects")
          : null;

  const renderFolderSelection = () => {
    return schemas.map((schema) => {
      const onSelect = (selected: string[]) => {
        handleOnFolderSelect(schema.id, selected);
      };

      return (
        <SlidePanel
          name="FolderSelection"
          key={schema.id}
          title={schema.name}
          open={openenedSelectionPanel === schema.id}
          onClose={closeSelectionPanel}
          zIndex={1}
          portalHostname={portalHostname}
        >
          <FolderSelectionForSchema
            roomId={room.id}
            schemaId={schema.id}
            defaultValues={selectedFolders[schema.id]}
            onSelect={onSelect}
            onClose={closeSelectionPanel}
          />
        </SlidePanel>
      );
    });
  };

  return (
    <>
      <View style={styles.container}>
        <ScrollView style={styles.scrollviewContainer}>
          <DetailsSection>
            <SectionHeader title={t("export")} />
            <View style={styles.innerSectionContent}>
              <KSelectionListUncontrolled
                noPadding
                minSelection={1}
                items={itemsDelivery}
                defaultValue={exportFormat}
                isMultiple={false}
                style={styles.selectionList}
                onSelect={setSelectedExportFormat}
                disableScroll
              />

              {exportFormat === ExportFormat.Email && (
                <>
                  <Text style={styles.textDescription}>
                    {t("generateReportEmailWillBeSent")}
                  </Text>
                  <KInputEmail
                    value={email}
                    disableAccessibility
                    error={false}
                    inputContainerStyle={styles.email}
                    label={t("email")}
                    onChangeText={setEmail}
                    showEditIcon
                    autoCapitalize="none"
                    keyboardType="default"
                    returnKeyType="next"
                    nativeID="ide2e-reportGenerator-email"
                  />
                </>
              )}
            </View>
          </DetailsSection>

          <DetailsSection>
            <SectionHeader title={t("select")} />
            <View style={styles.innerSectionContent}>
              <KSelectionListUncontrolled
                noPadding
                minSelection={1}
                items={itemsSelection}
                defaultValue={selection}
                isMultiple={false}
                style={styles.selectionList}
                onSelect={setSelectedSelection}
                disableScroll
              />

              {selection === Selection.Dates && (
                <ReportDates
                  dateRange={filters.dateRange}
                  updateDateFilter={updateDateFilter}
                />
              )}
              {selection === Selection.Elements && (
                <ReportElements
                  roomId={room.id}
                  schemas={schemas}
                  selectedFolders={selectedFolders}
                  openSelectionPanel={openSelectionPanel}
                />
              )}
            </View>
          </DetailsSection>

          <DetailsSection>
            <SectionHeader title={t("generateReportContent")} />
            <View style={styles.innerSectionContent}>
              <Toggles
                includeDocuments={filters.includeDocuments}
                includeMessages={filters.includeMessages}
                updateFilters={updateFilters}
              />
              <Text style={[styles.textDescription, styles.textCentered]}>
                {explanation}
              </Text>
            </View>
          </DetailsSection>
        </ScrollView>
        <View style={styles.bottomButtonContainer}>
          {exportFormat === ExportFormat.Email && (
            <>
              <Button
                ref={shareReportButtonRef}
                icon="share-02"
                accessibilityLabel={t("generateReportSend")}
                text={t("generateReportSend")}
                disabled={email.length === 0}
                loading={isSendingRoomReport}
                onPress={openShareReportTemplateSelection}
              />
              {ShareReportTemplateSelection}
            </>
          )}
          {exportFormat === ExportFormat.Download && (
            <>
              <Button
                ref={downloadReportButtonRef}
                icon="download-01"
                accessibilityLabel={t("generateReportDownloadReport")}
                text={t("generateReportDownloadReport")}
                loading={isDownloadingRoomReport}
                onPress={openDownloadReportTemplateSelection}
              />
              {DownloadReportTemplateSelection}
            </>
          )}
        </View>
      </View>
      {renderFolderSelection()}
      <SafeAreaView style={styles.safeAreaView} />
    </>
  );
};

export { ConnectedReportGenerator };
