import { RefObject, useCallback, useRef, useState } from "react";
import { VirtualElement } from "@floating-ui/react";

import {
  DownloadReportComponent,
  DownloadReportRef,
} from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/downloadReportComponent";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";

import {
  ModularFolderContextMenuSheet,
  ModularFolderContextMenuSource,
} from "./modularFolderContextMenu.sheet";

type UseModularFolderContextMenuProps = {
  roomId: string;
  schemaId: string;
  source: ModularFolderContextMenuSource;
};

export function useModularFolderContextMenu(
  props: UseModularFolderContextMenuProps,
) {
  const { roomId, schemaId, source } = props;

  const [modularFolderId, setModularFolderId] = useState<string>();
  const [anchor, setAnchor] = useState<RefObject<Element | VirtualElement>>(
    useRef(null),
  );
  const downloadComponentRef = useRef<DownloadReportRef>(null);

  const noopCallback = useCallback(async () => {
    console.error("no download component");
  }, []);

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId,
      callback: downloadComponentRef.current?.downloadReport ?? noopCallback,
      allowPDF: true,
      anchorRef: anchor,
    });

  const { element: contextMenuSheet, open: openContextMenu } =
    ModularFolderContextMenuSheet.use({
      roomId,
      schemaId,
      openReportTemplateSelection,
      source,
    });

  const sheets = (
    <>
      {modularFolderId && (
        <DownloadReportComponent
          ref={downloadComponentRef}
          schemaId={schemaId}
          modularFolderId={modularFolderId}
        />
      )}
      {contextMenuSheet}
      {ReportTemplateSelection}
    </>
  );

  const open = useCallback(
    ({
      modularFolderId: _modularFolderId,
      anchor: _anchor,
    }: {
      modularFolderId: string;
      anchor: RefObject<Element | VirtualElement>;
    }) => {
      setModularFolderId(_modularFolderId);
      setAnchor(_anchor);
      openContextMenu({ modularFolderId: _modularFolderId, anchor: _anchor });
    },
    [openContextMenu],
  );

  return {
    element: sheets,
    open,
  };
}
