import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { OrderableOption } from "@kraaft/web/src/components/sortableListWithAddAndDelete/orderableOptionRenderer/orderableOption";
import { UnlabeledTextInput } from "@kraaft/web/src/components/unlabeledTextInput";

export const OrderableOptionInput = <Option extends OrderableOption>(props: {
  option: Option;
  onPressEnter: (id: string) => void;
  onChange: (id: string, value: Option) => void;
  onDirtyChange?: (isDirty: boolean) => void;
}) => {
  const { option, onPressEnter, onChange, onDirtyChange } = props;
  const isFocused = useRef(false);

  const initialValue = useRef(option.text);

  const [tempText, setTempText] = useState(option.text);

  useEffect(() => {
    if (!isFocused.current) {
      setTempText(option.text);
    }
  }, [option.text]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        onChange(option.id, { ...option, text: tempText });
        onPressEnter(option.id);
      }
    },
    [onChange, onPressEnter, option, tempText],
  );

  const handleBlur = useCallback(() => {
    isFocused.current = false;
    onChange(option.id, { ...option, text: tempText });
    onDirtyChange?.(false);
  }, [onChange, onDirtyChange, option, tempText]);

  const handleFocus = useCallback(() => {
    isFocused.current = true;
  }, []);

  const handleChangeText = useCallback(
    (text: string) => {
      if (text !== initialValue.current) {
        onDirtyChange?.(true);
      } else {
        onDirtyChange?.(false);
      }
      setTempText(text);
    },
    [onDirtyChange],
  );

  return (
    <UnlabeledTextInput
      id={`orderable-option-${option.id}`}
      data-testid="ide2e-orderable-option-input"
      placeholder=""
      onChangeText={handleChangeText}
      value={tempText}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};
