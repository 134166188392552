/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type LastParameter<Fn extends (...params: any[]) => any> =
  Parameters<Fn> extends [...any[], infer P] ? P : never;

type FirstParameter<Fn extends (...params: any[]) => any> =
  Parameters<Fn> extends [infer P, ...any[]] ? P : never;

export function useLiveQuery<
  Fn extends (...p: any[]) => () => void,
  Callback extends LastParameter<Fn> extends (...args: any[]) => any
    ? LastParameter<Fn>
    : never,
  T extends FirstParameter<Callback>,
  Params extends Parameters<Fn> extends [...infer P, any] ? P : never,
>(subscription: Fn, params: Params) {
  const queryKey = useMemo(
    () => [subscription.name, ...params] as const,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...params, subscription.name],
  );

  const client = useQueryClient();

  const unsubscribe = useRef<null | (() => void)>(null);

  // when the component unmounts we need to unsubscribe from the query
  useEffect(
    () => () => {
      if (client.getQueryCache().find(queryKey)?.getObserversCount() === 0) {
        unsubscribe.current?.();
      }
    },
    [client, queryKey],
  );

  return useQuery(
    queryKey,
    () =>
      new Promise<T>((resolve) => {
        // when the query changes we need to unsubscribe from the previous one
        unsubscribe.current?.();
        unsubscribe.current = subscription(...params, (data: T) => {
          client.setQueryData(queryKey, () => data);
          resolve(data);
        });
      }),
    { refetchOnWindowFocus: false },
  );
}
