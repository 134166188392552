import { AvatarEmojiPicker } from "@kraaft/shared/components/avatarEmojiPicker";
import {
  getRoomCardType,
  isRoomPrivate,
} from "@kraaft/shared/components/roomCard/roomCardUtils";
import { useSetRoomEmoji } from "@kraaft/shared/components/roomCard/useSetRoomEmoji";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { AnyRoomCard } from "@kraaft/shared/core/modules/roomCard/roomCard.state";

type PressableRoomAvatarProps = {
  roomCard: AnyRoomCard;
};

export const PressableRoomAvatar = ({ roomCard }: PressableRoomAvatarProps) => {
  const setRoomEmoji = useSetRoomEmoji(roomCard.roomId);
  const isPrivate = isRoomPrivate(roomCard);
  const showExternalIndicator = Guard.useInPool(
    roomCard.poolId,
    "Room.seeExternalIndicator",
  );
  const roomCardType = getRoomCardType(roomCard, showExternalIndicator);

  return (
    <AvatarEmojiPicker
      emoji={roomCard.emoji}
      setEmoji={setRoomEmoji}
      isPrivate={isPrivate}
      roomCardType={roomCardType}
      recentEmojiSpace="room_emoji"
    />
  );
};
