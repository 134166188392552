import { getStore, injectReducer, injectSaga } from "@kraaft/shared/core/store";
import { offlineReduxBundle } from "@kraaft/shared/core/store/offline";
import { platformReducers } from "@kraaft/shared/core/store/reducers";
import { platformSagas } from "@kraaft/shared/core/store/sagas";

export function injectSharedRedux() {
  for (const [key, value] of Object.entries(platformReducers)) {
    injectReducer(key, value);
  }
  injectSaga(platformSagas);
  offlineReduxBundle.inject(
    () => getStore().getState(),
    injectReducer,
    injectSaga,
  );
}
