import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import {
  DirectoryActions,
  DirectoryStateActions,
} from "@kraaft/shared/core/modules/directory/directoryActions";
import { selectDirectory } from "@kraaft/shared/core/modules/directory/directorySelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* removeDirectorySaga() {
  yield* takeEvery(DirectoryActions.remove, removeDirectory);
}

function* removeDirectory({
  payload: { roomId, directoryId },
}: ReturnType<(typeof DirectoryActions)["remove"]>) {
  const directory = yield* select(selectDirectory(directoryId));
  if (!directory) {
    return;
  }

  yield* put(DirectoryStateActions.delete({ directoryId }));

  try {
    yield* call(Api.removeDirectory, { roomId, directoryId });
  } catch (e) {
    yield* put(DirectoryStateActions.add({ directory }));
    yield* put(showError({ title: i18n.t("directory.cannotRemove") }));
  }
}
