import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { RoomCardActions } from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { SchemaActions } from "@kraaft/shared/core/modules/schema/schema.actions";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import {
  useCallbackRealtime,
  useEffectRealtime,
} from "@kraaft/shared/core/utils/hooks";
import { useDebounce } from "@kraaft/shared/core/utils/useDebounce";

import { RoomCardQueryContext } from "../roomCard.state";

interface UseRoomCardsProps {
  poolId: string | undefined;
  filters: RoomFilters;
  loadAll?: boolean;
  filterIdPrefix?: string;
  justReadRoomId?: string;
}

export function useRoomCards({
  poolId,
  filters,
  loadAll,
  filterIdPrefix,
  justReadRoomId,
}: UseRoomCardsProps) {
  const dispatch = useDispatch();
  const userId = useSelector(selectCurrentUserId);

  const {
    searchText,
    labelIds,
    visibility,
    responsibleId,
    statusId,
    showArchived,
    readingStatus,
  } = filters;

  const queryContext = useMemo<RoomCardQueryContext>(
    () => ({
      poolId: poolId ?? "",
      userId: userId ?? "",
      filters,
      filterIdPrefix,
    }),
    [filterIdPrefix, filters, poolId, userId],
  );

  const filterId = useMemo(
    () =>
      poolId && userId
        ? RoomCardUtils.computeSubscriptionFilterId({
            filters,
            poolId,
            userId,
            filterIdPrefix,
          })
        : undefined,
    [filters, poolId, userId, filterIdPrefix],
  );

  const pinnedRoomCards = useSelector(
    RoomCardSelectors.selectFilteredPinned(queryContext, justReadRoomId),
  );
  const otherRoomCards = useSelector(
    RoomCardSelectors.selectFiltered(queryContext, justReadRoomId),
  );

  const isLoading = useSelector(RoomCardSelectors.selectIsLoading(filterId));
  const arePinnedLoading = useSelector(RoomCardSelectors.selectIsLoadingPinned);
  const isThereMore = useSelector(RoomCardSelectors.selectHasMore(filterId));
  const isLoadingMore = useSelector(
    RoomCardSelectors.selectIsLoadingMore(filterId),
  );
  const currentSearchText = useSelector(
    RoomCardSelectors.selectSearchText(filterId),
  );
  const hasError = useSelector(RoomCardSelectors.selectHasError(filterId));

  const debouncedSearchText = useDebounce(searchText, 400);

  const isTyping = debouncedSearchText !== searchText;

  useEffect(() => {
    if (!poolId) {
      return;
    }

    dispatch(SchemaActions.subscribe({ poolId }));

    return () => {
      dispatch(SchemaActions.unsubscribe({ poolId }));
    };
  }, [poolId, dispatch]);

  useEffect(() => {
    if (!poolId || !userId) {
      return;
    }

    dispatch(RoomCardActions.subscribeToPinned({ poolId, userId }));

    return () => {
      dispatch(RoomCardActions.unsubscribeFromPinned({ poolId, userId }));
    };
  }, [dispatch, poolId, userId]);

  useEffectRealtime(
    ([_searchText]) => {
      if (!poolId || !userId) {
        return;
      }

      dispatch(
        RoomCardActions.subscribe({
          poolId: poolId,
          userId: userId,
          filters: {
            labelIds,
            visibility,
            responsibleId,
            statusId,
            showArchived,
            readingStatus,
            searchText: _searchText,
          },
          filterIdPrefix,
        }),
      );

      return () => {
        dispatch(
          RoomCardActions.unsubscribe({
            poolId: poolId,
            userId: userId,
            filters: {
              labelIds,
              visibility,
              responsibleId,
              statusId,
              showArchived,
              readingStatus,
              searchText: _searchText,
            },
            filterIdPrefix,
          }),
        );
      };
    },
    [
      labelIds,
      visibility,
      responsibleId,
      readingStatus,
      statusId,
      showArchived,
      poolId,
      userId,
      filterIdPrefix,
    ],
    [searchText],
  );

  useEffect(() => {
    if (filterId && searchText !== currentSearchText) {
      dispatch(
        RoomCardActions.updateSearchText({ filterId, value: searchText }),
      );
    }
  }, [currentSearchText, dispatch, filterId, searchText]);

  useEffectRealtime(
    ([_isThereMore, _isLoadingMore]) => {
      if (_isThereMore && !_isLoadingMore && filterId) {
        dispatch(RoomCardActions.loadMore({ filterId }));
      }
    },
    [debouncedSearchText, dispatch, filterId],
    [isThereMore, isLoadingMore],
  );

  useEffect(() => {
    if (loadAll && isThereMore && !isLoadingMore && filterId && !isTyping) {
      dispatch(RoomCardActions.loadMore({ filterId }));
    }
  }, [dispatch, filterId, isLoadingMore, isThereMore, isTyping, loadAll]);

  useEffectRealtime(
    ([_otherRoomCards]) => {
      if (
        readingStatus === "unread" &&
        justReadRoomId &&
        _otherRoomCards.some((roomCard) => roomCard.roomId === justReadRoomId)
      ) {
        dispatch(
          RoomCardActions.subscribeToRoomMemberCard({ roomId: justReadRoomId }),
        );

        return () => {
          dispatch(
            RoomCardActions.unsubscribeFromRoomMemberCard({
              roomId: justReadRoomId,
            }),
          );
        };
      }
    },
    [dispatch, justReadRoomId, readingStatus],
    [otherRoomCards],
  );

  const loadMore = useCallbackRealtime(
    ([_searchText, _debouncedSearchText]) => {
      if (
        isThereMore &&
        !isLoadingMore &&
        _debouncedSearchText === _searchText &&
        filterId
      ) {
        dispatch(RoomCardActions.loadMore({ filterId }));
      }
    },
    [dispatch, filterId, isLoadingMore, isThereMore],
    [searchText, debouncedSearchText],
  );

  return {
    pinnedRoomCards,
    otherRoomCards,
    isLoading: isLoading || arePinnedLoading,
    isThereMore,
    isLoadingMore,
    loadMore,
    isTyping,
    hasError,
  };
}
