import { useCallback, useMemo } from "react";
import { type DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import type { DroppableItem, UseDroppableProps } from "./useDroppable.types";

export const useLegacyDroppable = ({
  acceptType,
  acceptSystemFile,
  onDrop,
  onDropFiles,
  shallow,
}: UseDroppableProps) => {
  const accept = useMemo(() => {
    const result: string[] = [];
    if (acceptSystemFile) {
      result.push(NativeTypes.FILE);
    }
    if (acceptType === undefined) {
      return result;
    }
    if (Array.isArray(acceptType)) {
      result.push(...acceptType);
    } else {
      result.push(acceptType);
    }
    return result;
  }, [acceptType, acceptSystemFile]);

  const handleDrop = useCallback(
    (item: DroppableItem, monitor: DropTargetMonitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if (item.files) {
        onDropFiles?.(item.files);
      } else {
        onDrop?.(item);
      }
    },
    [onDrop, onDropFiles],
  );

  const [{ canDrop, isOver }, dropContainerRef] = useDrop({
    accept,
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow }),
      canDrop: monitor.canDrop(),
    }),
  });

  return [dropContainerRef, { canDrop, isOver }] as const;
};
