import { makeStyles } from "@mui/styles";

import { Color, Radius, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  root: {
    overflow: "auto",
    height: "100%",
  },
  counter: {
    zIndex: 1,
    position: "absolute",
    bottom: Spacing.S16,
    right: Spacing.S16,
    gap: Spacing.S2,
    display: "flex",
    justifyContent: "end",
    padding: `${Spacing.S4}px ${Spacing.S8}px`,
    backgroundColor: Color.GREY_ANTIFLASH,
    borderRadius: Radius.LARGE,
    transition: "box-shadow 500ms",
    cursor: "pointer",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  counterWithThresholdWarning: {
    backgroundColor: Color.RED_TOMATO,
  },
});
