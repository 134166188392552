import { ActionCreator } from "redux";
import { Saga } from "redux-saga";

import { registeredLogger } from "../logger";
import {
  BaseAggregate,
  DependenciesFromDeclaredOperations,
  UserDeclaredOperations,
} from "../optimistic/optimistic.types";
import { NamedTaskManager } from "../taskStore/taskManager";
import { CreateReduxBundleActions } from "./reduxBundle.actions";
import {
  FeatureOptimisticState,
  IdCorrespondance,
  MutateResults,
  OfflineReduxBundle,
} from "./reduxBundle.init";
import { CreateReduxBundleSaga } from "./reduxBundle.saga";
import { CreateReduxBundleSelectors } from "./reduxBundle.selectors";
import {
  ReduxActionsFromDeclaredOperations,
  ReduxErrorAction,
  ReduxExtraGenerator,
  ReduxSelector,
  ReduxSelectorsFromDeclaredOperations,
  ReduxStateActions,
  UserProvidedSelectors,
} from "./reduxBundle.types";

export const createReduxBundle =
  <
    Aggregate extends BaseAggregate,
    DeclaredOperations extends UserDeclaredOperations,
  >(
    name: string,
    declaredOperations: DeclaredOperations,
    taskProcessor: NamedTaskManager,
    // internal
    getState: () => any,
    selectMutateResults: ReduxSelector<MutateResults>,
    selectOptimisticFeature: ReduxSelector<FeatureOptimisticState>,
    selectIdCorrespondance: ReduxSelector<IdCorrespondance>,
    actions: OfflineReduxBundle["actions"],
  ) =>
  (
    userProvidedSelectors: UserProvidedSelectors<Aggregate>,
    errorAction: ReduxErrorAction,
    resetAction: ActionCreator<any>,
    extra:
      | ReduxExtraGenerator<
          Aggregate,
          DependenciesFromDeclaredOperations<DeclaredOperations>
        >
      | undefined,
  ): {
    Actions: ReduxActionsFromDeclaredOperations<DeclaredOperations>;
    StateActions: ReduxStateActions<Aggregate>;
    Selectors: ReduxSelectorsFromDeclaredOperations<Aggregate>;
    saga: Saga;
  } => {
    const bundleActions = CreateReduxBundleActions<Aggregate>(
      name,
      declaredOperations,
    );
    const selectors = CreateReduxBundleSelectors(
      name,
      declaredOperations,
      userProvidedSelectors,
      selectOptimisticFeature,
      selectIdCorrespondance,
    );

    let builtDependencies:
      | DependenciesFromDeclaredOperations<DeclaredOperations>
      | undefined;
    const getDependencies = () => {
      if (builtDependencies) {
        return builtDependencies;
      }
      builtDependencies = extra?.(
        getState,
        selectors.selectors.select,
        selectors.selectors.selectAll,
      ) as DependenciesFromDeclaredOperations<DeclaredOperations>;
      return builtDependencies;
    };

    const saga = CreateReduxBundleSaga(
      name,
      taskProcessor,
      declaredOperations,
      bundleActions,
      userProvidedSelectors,
      errorAction,
      resetAction,
      getDependencies,
      selectMutateResults,
      selectOptimisticFeature,
      selectIdCorrespondance,
      actions,
      registeredLogger.current.createSubLogger(["ReduxBundle", "Saga"]),
    );

    return {
      Actions: bundleActions.actions,
      StateActions: bundleActions.stateActions,
      Selectors: selectors.selectors,
      saga,
    };
  };
