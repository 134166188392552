import {
  combineReducers,
  configureStore,
  createReducer,
  Reducer,
} from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { Saga } from "redux-saga";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { allStoresRehydrated } from "@kraaft/shared/core/modules/memory/memoryActions";
import { enableBatching } from "@kraaft/shared/core/store/batchingReducer";
import { sagaMiddleware } from "@kraaft/shared/core/store/sagaMiddleware";

import { store } from "./store.instance";

const reducers: Record<string, Reducer> = {};

function initializeStore() {
  const _store = configureStore({
    reducer: combineReducers({ init: createReducer(0, () => {}) } as Record<
      string,
      Reducer
    >),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
    devTools:
      getEnvironment().DEPLOYMENT_ENVIRONMENT !== "prod"
        ? { maxAge: 100 }
        : false,
  });

  persistStore(_store, null, () => {
    _store.dispatch(allStoresRehydrated());
  });

  return _store;
}

export function injectReducer(key: string, reducer: Reducer) {
  reducers[key] = reducer;
  const _store = getStore();
  _store.replaceReducer(enableBatching(combineReducers(reducers)));
  persistStore(_store, null, () => {
    _store.dispatch(allStoresRehydrated());
  });
}

export function injectSaga(saga: Saga) {
  sagaMiddleware.run(saga);
}

export function getStore() {
  if (!store.current) {
    const _store = initializeStore();
    store.current = _store;
  }
  return store.current;
}

export type StoreType = ReturnType<typeof initializeStore>;
export type AppDispatch = StoreType["dispatch"];
