import { RefObject, useCallback, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { VirtualElement } from "@floating-ui/react";

import { InteractionsProps } from "@kraaft/shared/components/modularFolderCard/types";
import { ModularFolderItemProps } from "@kraaft/shared/components/modularFolderList/modularFolderItem/types";
import {
  useIsModularFoldersSelectionEnabled,
  useOnSelectModularFolder,
} from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import {
  selectModularFolderCompletedValue,
  selectModularFolderGeolocation,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.selectors";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { geolocationContextHover } from "@kraaft/shared/core/modules/room/roomActions";
import { selectGeolocationIsHovered } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectInternalPoolUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { dismissNativeKeyboard } from "@kraaft/shared/core/utils/platformUtils";
import { ContextMenus } from "@kraaft/ui";

import { ModularFolderItem } from "./modularFolderItem";

export type ModularFolderItemConnectorProps = Pick<
  ModularFolderItemProps,
  "schemaId" | "modularFolderId" | "rightIcon" | "openCarousel"
> & {
  onCheckboxPress: (id: string, newValue: boolean) => void;
  openModularFolderCardLongPressActionSheet: (args: {
    modularFolderId: string;
    anchor: RefObject<Element | VirtualElement>;
  }) => void;
  roomId: string;
};

export const ModularFolderItemConnector = ({
  roomId,
  schemaId,
  modularFolderId,
  rightIcon,
  openCarousel,
  openModularFolderCardLongPressActionSheet,
  onCheckboxPress,
}: ModularFolderItemConnectorProps) => {
  const dispatch = useDispatch();

  const currentPoolId = useSelector(selectCurrentPoolId);

  const users = useSelector(selectInternalPoolUsers(currentPoolId));
  const isActive = useSelector(selectGeolocationIsHovered(modularFolderId));

  const currentCompletionStatus = Boolean(
    useSelector(selectModularFolderCompletedValue(modularFolderId)),
  );
  const geolocation = useSelector(
    selectModularFolderGeolocation(modularFolderId),
  );

  const isSelectionEnabled = useIsModularFoldersSelectionEnabled();
  const onSelect = useOnSelectModularFolder();

  const navigationService = useNavigationService();

  const navigateToDetails = useCallback(() => {
    dismissNativeKeyboard();
    navigationService.navigate("ModularFolderDetails", {
      roomId,
      schemaId,
      folderId: modularFolderId,
    });
  }, [navigationService, roomId, schemaId, modularFolderId]);

  const handleHovered = useCallback(
    (hovered: boolean) => {
      if (geolocation) {
        dispatch(
          geolocationContextHover({
            id: modularFolderId,
            hovered,
            actions: [
              {
                type: "centerOn",
                coords: geolocation.coords,
              },
            ],
          }),
        );
      }
    },
    [dispatch, geolocation, modularFolderId],
  );

  const wrapperRef = useRef(null);

  const handleLongPress = useCallback(() => {
    openModularFolderCardLongPressActionSheet?.({
      modularFolderId,
      anchor: wrapperRef,
    });
  }, [modularFolderId, openModularFolderCardLongPressActionSheet]);

  const handleLeftIconPress = useCallback(() => {
    onCheckboxPress(modularFolderId, !currentCompletionStatus);
  }, [currentCompletionStatus, modularFolderId, onCheckboxPress]);

  const interactionProps: InteractionsProps = useMemo(
    () =>
      isSelectionEnabled
        ? {
            enableInteractions: true,
            onClickCard: () => onSelect(modularFolderId),
          }
        : {
            enableInteractions: true,
            onHovered: handleHovered,
            onLongPress: handleLongPress,
            onClickLeftIcon: handleLeftIconPress,
            onClickCard: navigateToDetails,
            onClickRightIcon: navigateToDetails,
            isActive,
          },
    [
      handleHovered,
      handleLeftIconPress,
      handleLongPress,
      isActive,
      isSelectionEnabled,
      modularFolderId,
      navigateToDetails,
      onSelect,
    ],
  );

  ContextMenus.useFromActionSheet(
    wrapperRef,
    useCallback(
      (anchor) =>
        openModularFolderCardLongPressActionSheet({
          modularFolderId,
          anchor: { current: anchor },
        }),
      [modularFolderId, openModularFolderCardLongPressActionSheet],
    ),
  );

  return (
    <View ref={wrapperRef} style={styles.container}>
      <ModularFolderItem
        roomId={roomId}
        schemaId={schemaId}
        modularFolderId={modularFolderId}
        rightIcon={rightIcon}
        openCarousel={openCarousel}
        users={users}
        interactionProps={interactionProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
  },
});
