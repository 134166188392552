import { RootState } from "@kraaft/shared/core/store";
import { createSelector } from "@reduxjs/toolkit";

function selectState(state: RootState) {
  return state.survey.npsSurvey;
}

export const selectNpsSurveyResponseId = createSelector(
  selectState,
  ({ npsSurveyResponseId }) => npsSurveyResponseId,
);

export const selectShouldDisplayNpsSurveyForm = createSelector(
  selectState,
  ({ shouldDisplayNpsSurveyForm }) => shouldDisplayNpsSurveyForm,
);
