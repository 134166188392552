import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";

import { Actor } from "./actor";
import { getRoomPermissions } from "./guards.tools";

const h24 = 1000 * 60 * 60 * 24;

function getMessageContext(actor: Actor, poolId: string, message: AnyMessage) {
  const perms = getRoomPermissions(actor, poolId);

  const moderator = perms?.moderate || false;
  const corrector = moderator || perms?.correct || false;
  const talker = corrector || perms?.talk || false;

  const owned = message.senderId === actor.userId;
  const recent = message.createdAt.getTime() > Date.now() - h24;
  const text = message.type === "text";

  return { moderator, corrector, talker, owned, recent, text };
}

const canEditMessage = (actor: Actor, poolId: string, message: AnyMessage) => {
  const { corrector, talker, owned, recent, text } = getMessageContext(
    actor,
    poolId,
    message,
  );

  if (owned && recent && talker) {
    // Talkers can edit own text messages within 24h
    return [true] as const;
  }

  if (owned && corrector) {
    // Correctors can edit own messages anytime
    return [true] as const;
  }

  if (!text && corrector) {
    // Correctors can edit anyone caption anytime
    return [true] as const;
  }

  if (!owned) {
    return [false, "ownership"] as const;
  }

  if (!recent) {
    return [false, "time"] as const;
  }

  return [false, "unauthorized"] as const;
};

const canDeleteMessage = (
  actor: Actor,
  poolId: string,
  message: AnyMessage,
) => {
  const { moderator, talker, owned, recent, corrector } = getMessageContext(
    actor,
    poolId,
    message,
  );

  if (moderator) {
    // Moderators can delete messages anytime
    return [true] as const;
  }

  if (owned && recent && talker) {
    // Talkers can edit own text messages within 24h
    return [true] as const;
  }

  if (owned && corrector) {
    // Correctors can delete own messages anytime
    return [true] as const;
  }

  if (!owned) {
    return [false, "ownership"] as const;
  }

  if (!recent) {
    return [false, "time"] as const;
  }

  return [false, "unauthorized"] as const;
};

export const MessageGuards = {
  editText: canEditMessage,
  editCaption: canEditMessage,
  editGeolocation: canEditMessage,
  delete: canDeleteMessage,
};
