import { ReactNode, useMemo } from "react";
import { useDragLayer } from "react-dnd";

import { ColorStyle, Icon } from "@kraaft/ui";
import { useDragLayerStyles } from "@kraaft/web/src/components/dragAndDrop/dragLayerBase.styles";
import { getTranslationStyle } from "@kraaft/web/src/components/kanban/board/kanbanDragLayer/kanbanDragLayer.utils";
import { ORDERABLE_LIST_ROW_TYPE } from "@kraaft/web/src/components/orderableList/orderableList.utils";

import { OrderableListDragItem } from "../orderableList.types";

import { useStyles } from "./orderableListDragLayer.styles";

interface OrderableListDragLayerProps<T = unknown> {
  listIdentifier: string;
  renderRow: (props: T) => ReactNode;
  withHandle?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const OrderableListDragLayer = <T extends {}>(
  props: OrderableListDragLayerProps<T>,
) => {
  const { listIdentifier, renderRow, withHandle } = props;
  const dragLayerClasses = useDragLayerStyles();

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => {
    const dragItem = monitor.getItem() as OrderableListDragItem<T> | null;
    return {
      item: dragItem,
      currentOffset: monitor.getSourceClientOffset(),
      isDragging:
        monitor.getItemType() === ORDERABLE_LIST_ROW_TYPE &&
        dragItem?.listIdentifier === listIdentifier,
    };
  });
  const classes = useStyles({ width: item?.width });

  const content = useMemo(
    () => (item !== null ? renderRow(item.data) : null),
    [renderRow, item],
  );

  if (!isDragging) {
    return null;
  }

  return (
    <div className={dragLayerClasses.layerBase}>
      <div style={getTranslationStyle(currentOffset)}>
        <div className={classes.itemContainer}>
          {withHandle && (
            <Icon
              className={classes.handleIcon}
              name="dots-six"
              color={ColorStyle.FONT_LOW_EMPHASIS}
            />
          )}
          {content}
        </div>
      </div>
    </div>
  );
};

export { OrderableListDragLayer };
