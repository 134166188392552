import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ModularFolderList } from "@kraaft/shared/components/modularFolderList";
import { ModularFolderListSelectionFooter } from "@kraaft/shared/components/modularFolderList/modularFolderList/modularFolderListSelectionFooter";
import { ModularFolderListSelectionHeader } from "@kraaft/shared/components/modularFolderList/modularFolderList/modularFolderListSelectionHeader";
import { ModularFoldersMapButton } from "@kraaft/shared/components/modularFolderList/modularFolderList/modularFoldersMapButton";
import { useFilteredModularFolders } from "@kraaft/shared/components/modularFolderList/modularFolderList/useFilteredModularFolders";
import { useTrackSearchModularFolders } from "@kraaft/shared/components/modularFolderList/modularFolderList/useTrackSearchModularFolders";
import { ModularFolderQuickCreator } from "@kraaft/shared/components/modularFolderList/modularFolderQuickCreator/modularFolderQuickCreator";
import { useIsModularFoldersSelectionEnabled } from "@kraaft/shared/components/modularFolders/modularFoldersSelection.context";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { OfflineModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { useSubscribeToModularFolders } from "@kraaft/shared/core/modules/modularFolder/useSubscribeToModularFolders";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import { selectSchemaName } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { useNavigationService } from "@kraaft/shared/core/services/navigation";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackPage } from "@kraaft/shared/core/utils/tracking/useTrackEvent";

interface Props {
  id?: string;
  search: string | undefined;
  schemaId: string;
  roomId: string;
}

export const ModularFolders = (props: Props) => {
  const { roomId, schemaId, id, search } = props;

  const navigationService = useNavigationService();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRoomExternal = useSelector(RoomSelectors.selectHasExternals(roomId));
  const filteredFolders = useFilteredModularFolders(roomId, schemaId, search);
  const isSelectionEnabled = useIsModularFoldersSelectionEnabled();

  const schemaName = useSelector(selectSchemaName(schemaId));

  useTrackPage("ModularFolders", { identifier: roomId });

  useSubscribeToModularFolders(roomId);

  useTrackSearchModularFolders({
    roomId,
    schemaId,
    schemaName: schemaName ?? "",
    searchText: search,
  });

  const addModularFolder = useCallback(() => {
    trackEvent({
      eventName: "Create Object Record",
      room_id: roomId,
      is_shared: isRoomExternal,
      schema_id: schemaId,
      schema_name: schemaName ?? "",
      record_title: null,
      from: "information_panel",
    });

    try {
      const { payload } = dispatch(
        OfflineModularFolderActions.create({
          roomId,
          schemaId,
          properties: [{}],
        }),
      );
      const [firstId] = payload.ids;
      if (!firstId) {
        return;
      }
      navigationService.navigate("ModularFolderDetails", {
        roomId: roomId,
        schemaId,
        folderId: firstId,
      });
    } catch (err) {
      dispatch(showError({ title: t("modularFolderAddError") }));
    }
  }, [
    roomId,
    isRoomExternal,
    schemaId,
    schemaName,
    dispatch,
    navigationService,
    t,
  ]);

  return (
    <ModularFolderList
      roomId={roomId}
      schemaId={schemaId}
      id={id}
      search={search}
      filteredFolders={filteredFolders}
      header={
        isSelectionEnabled ? (
          <ModularFolderListSelectionHeader modularFolders={filteredFolders} />
        ) : (
          <ModularFoldersMapButton
            roomId={roomId}
            schemaId={schemaId}
            modularFolders={filteredFolders}
          />
        )
      }
      footer={
        isSelectionEnabled ? (
          <ModularFolderListSelectionFooter
            roomId={roomId}
            schemaId={schemaId}
          />
        ) : (
          <ModularFolderQuickCreator onAddRecord={addModularFolder} />
        )
      }
    />
  );
};
