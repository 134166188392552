import { DeleteOneOperationBuilder } from "@kraaft/offline";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";

export const deleteOperation =
  DeleteOneOperationBuilder.create<ModularFolder>().mutate(
    async (payload, task) => {
      await Api.deleteModularFolder({
        requestId: task.id,
        folderId: payload.id,
      });
    },
  );
