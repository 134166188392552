import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isAtLeastPoolAdmin,
  isRoleHigher,
} from "@kraaft/shared/core/modules/pool/poolUtil";
import { PoolMemberActions } from "@kraaft/shared/core/modules/poolMember/poolMember.actions";
import { OfflinePoolMemberActions } from "@kraaft/shared/core/modules/poolMember/poolMember.offline";
import { selectPoolMembers } from "@kraaft/shared/core/modules/poolMember/poolMember.selectors";
import {
  PoolMemberDetails,
  PoolMemberIdHelper,
} from "@kraaft/shared/core/modules/poolMember/poolMember.types";
import {
  selectCurrentUserIsSuperadmin,
  selectCurrentUserRole,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { SuperadminApi } from "@kraaft/shared/core/services/api/superadminApi";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { compareStrings } from "@kraaft/shared/core/utils/utils";

const gradeToRoleId = (grade: UserPoolRole) =>
  grade === UserPoolRole.OWNER
    ? "own"
    : grade === UserPoolRole.ADMIN
      ? "adm"
      : grade === UserPoolRole.STANDARD
        ? "std"
        : "ext";

function useManageMembersSetup(poolId: string) {
  const dispatch = useDispatch();

  const currentUserRole = useSelector(selectCurrentUserRole(poolId));
  const isSuperadmin = useSelector(selectCurrentUserIsSuperadmin);
  const previews = useSelector(selectPoolMembers(poolId));

  useEffect(() => {
    dispatch(PoolMemberActions.subscribe({ poolId }));
    return () => {
      dispatch(PoolMemberActions.unsubscribe({ poolId }));
    };
  }, [dispatch, poolId]);

  const [details, setDetails] = useState<Record<string, PoolMemberDetails>>({});

  const refetch = useCallback(() => {
    Api.getPoolMembers({ poolId, counts: true })
      .then(setDetails)
      .catch(console.error);
  }, [poolId]);

  useEffect(refetch, [refetch]);

  const members = useMemo(() => {
    return previews
      .map((member) => {
        const grade = member.grade;
        const hasRoomRole = member.roles?.includes("room") ?? false;

        return {
          ...(details[member.userId] || {}),
          ...member,
          phone: member.phone,
          email: member.email,
          username: `${member.firstname || "-"} ${member.lastname || "-"}`,
          canCreateConversations: isAtLeastPoolAdmin(grade) || hasRoomRole,
          isEditable:
            isSuperadmin ||
            (isAtLeastPoolAdmin(currentUserRole) &&
              isRoleHigher(currentUserRole, grade)),
        };
      })
      .sort((a, b) => compareStrings(a.username, b.username));
  }, [previews, details, currentUserRole, isSuperadmin]);

  const setGrade = useCallback(
    (userId: string, grade: UserPoolRole) => {
      dispatch(
        OfflinePoolMemberActions.assignRole({
          id: PoolMemberIdHelper.create(poolId, userId),
          value: gradeToRoleId(grade),
        }),
      );
    },
    [dispatch, poolId],
  );

  const setCanCreateConversation = useCallback(
    (userId: string, value: boolean) => {
      if (value) {
        dispatch(
          OfflinePoolMemberActions.assignRole({
            id: PoolMemberIdHelper.create(poolId, userId),
            value: "room",
          }),
        );
      } else {
        dispatch(
          OfflinePoolMemberActions.unassignRole({
            id: PoolMemberIdHelper.create(poolId, userId),
            value: "room",
          }),
        );
      }
    },
    [dispatch, poolId],
  );

  const setPoolMemberPricingScheme = useCallback(
    (userId: string, scheme: "chargeable" | "offered" | "automatic") => {
      Api.setPoolMemberPricing({ poolId, userId, pricing: scheme })
        .then(refetch)
        .catch(console.error);
    },
    [poolId, refetch],
  );

  const addMember = useCallback(
    (userId: string) => {
      SuperadminApi.addMemberToPool({ poolId, userId })
        .then(refetch)
        .catch(console.error);
    },
    [poolId, refetch],
  );

  const removeMember = useCallback(
    (userId: string) => {
      Api.removeMemberFromPool({ poolId, userId })
        .then(refetch)
        .catch(console.error);
    },
    [poolId, refetch],
  );

  return useMemo(
    () => ({
      members,
      refetch,
      setGrade,
      setCanCreateConversation,
      setPoolMemberPricingScheme,
      addMember,
      removeMember,
    }),
    [
      members,
      refetch,
      setGrade,
      setCanCreateConversation,
      setPoolMemberPricingScheme,
      addMember,
      removeMember,
    ],
  );
}

const ManageMemberContext = createContext<
  ReturnType<typeof useManageMembersSetup>
>({
  members: [],
  refetch: () => {},
  setGrade: () => {},
  setCanCreateConversation: () => {},
  setPoolMemberPricingScheme: () => {},
  addMember: () => {},
  removeMember: () => {},
});

export const MemberManagementProvider = ({
  poolId,
  children,
}: {
  poolId: string;
  children: React.ReactNode;
}) => {
  const value = useManageMembersSetup(poolId);
  return (
    <ManageMemberContext.Provider value={value}>
      {children}
    </ManageMemberContext.Provider>
  );
};

export const useMemberManagement = () => useContext(ManageMemberContext);
