import { useEffect, useState } from "react";

import { Api } from "@kraaft/shared/core/services/api";

export function useSchemaModularFolderCount(schemaId: string) {
  const [count, setCount] = useState<number>();

  useEffect(() => {
    async function fetchCount() {
      const countFetched = await Api.countModularFoldersForSchema({
        schemaId,
      });
      setCount(countFetched.count);
    }
    fetchCount().catch(console.error);
  }, [schemaId]);

  return count;
}
