import { Listeners } from "@kraaft/helper-functions";
import { TaskResult } from "@kraaft/shared/core/services/backgroundService/offlineTaskManager";

import { Task } from "./task";

/**
 * Strategy to adopt on task failure
 * skip: will ignore the task just failed, won't trigger listeners!
 * delete: will delete the task
 * delete-related: will delete any task having a common dependency with the one that just failed
 * halt: cancel the queue for now, without deleting the task that failed
 */
export type TaskFailStrategy = "skip" | "delete-related" | "halt";

export const taskExecutionStopReasons = [
  "never-ran",
  "halt",
  "finished",
  "timeout",
] as const;
export type TaskExecutionStopReason = (typeof taskExecutionStopReasons)[number];

export class TaskExecutionError extends Error {
  constructor(
    public strategy: TaskFailStrategy,
    error: Error,
  ) {
    if (error) {
      super(error.message, { cause: error });
    } else {
      super();
    }
    this.name = "TaskExecutionError";
  }
}

export interface TaskExecutor {
  execute(): Promise<TaskResult>;
  stop(): Promise<boolean>;
}

export interface TaskProcessor {
  enqueue(task: Task): Promise<void>;
  reset(): Promise<void>;
  register(
    name: string,
    handler: (payload: any, task: Task) => Promise<any>,
  ): void;
  edit(editor: (task: Task) => void): Promise<void>;
  getQueue(): Promise<Task[]>;

  onTaskSucceeded: Listeners<(task: Task, result: any) => void>;
  onTaskAdded: Listeners<(task: Task) => void>;
  onTaskFailed: Listeners<(task: Task, error: any) => void>;
  onTaskDelayed: Listeners<(task: Task) => void>;
  onTaskSkipped: Listeners<(task: Task, error: any) => void>;
}
