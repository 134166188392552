import { type PropsWithChildren, useMemo, useState } from "react";

import {
  createMeshContext,
  useMeshContext,
  useMeshContextSetup,
} from "@kraaft/helper-hooks";

import type { AnimationState as BaseAnimationState } from "./animation.types";

type AnimationState = "undetermined" | BaseAnimationState;

// Context
type AnimationContextValue = {
  animationState: AnimationState;
};

export const AnimationContext = createMeshContext<AnimationContextValue>();

interface AnimationContextProviderProps {
  animationState?: AnimationState;
}
export const AnimationContextProvider = ({
  children,
  animationState,
}: PropsWithChildren<AnimationContextProviderProps>) => {
  const contextValue = useMeshContextSetup<AnimationContextValue>({
    animationState: animationState ?? "undetermined",
  });

  return (
    <AnimationContext.Provider value={contextValue}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimationContext = () => {
  return useMeshContext(AnimationContext);
};

// Hook
export type AnimationHandlers = {
  onEntering: () => void;
  onEntered: () => void;
  onExiting: () => void;
  onExited: () => void;
};

interface UseCreateAnimationStateProps {
  defaultAnimationState: BaseAnimationState;
}

export const useCreateAnimationState = ({
  defaultAnimationState,
}: UseCreateAnimationStateProps): [AnimationState, AnimationHandlers] => {
  const [animationState, setAnimationState] = useState<AnimationState>(
    defaultAnimationState,
  );

  const animationHandlers = useMemo<AnimationHandlers>(
    () => ({
      onEntering: () => {
        setAnimationState("entering");
      },

      onEntered: () => {
        setAnimationState("entered");
      },

      onExiting: () => {
        setAnimationState("exiting");
      },

      onExited: () => {
        setAnimationState("exited");
      },
    }),
    [],
  );

  return [animationState, animationHandlers];
};
