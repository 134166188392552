import { useCallback } from "react";
import { Pressable, PressableStateCallbackType, View } from "react-native";

import { Icon, IconName, Image, ImageProps, Text } from "@kraaft/ui";

import { DetailedActionCardHoverWrapper } from "./detailedActionCardHoverWrapper";

import { styles } from "./detailedActionCard.styles";

export interface DetailedActionCardProps {
  icon?: IconName;
  iconColor?: string;
  image?: ImageProps["source"];
  title: string;
  subtitle: string;
  titleColor?: string;
  rightIcon?: IconName | null;
  withShadow?: boolean;
  onPress?: () => void;
}

const DetailedActionCard = ({
  icon,
  iconColor,
  image,
  title,
  subtitle,
  onPress,
  titleColor,
  rightIcon = "chevron-right",
  withShadow,
}: DetailedActionCardProps) => {
  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      styles.container,
      withShadow && styles.containerWithShadow,
      state.pressed && styles.pressedContainer,
    ],
    [withShadow],
  );

  return (
    <DetailedActionCardHoverWrapper active={Boolean(onPress)}>
      <Pressable
        accessibilityLabel={title}
        onPress={onPress}
        disabled={!onPress}
        style={getPressableStyle}
        testID="ide2e-detailed-action-card"
      >
        {icon ? (
          <Icon
            name={icon}
            color={iconColor}
            size="MEDIUM"
            style={styles.iconContainer}
          />
        ) : null}
        {image ? (
          <Image source={image} style={styles.imageContainer} local />
        ) : null}
        <View style={styles.textContainer}>
          <Text
            size="BODY"
            color={titleColor ?? "FONT_HIGH_EMPHASIS"}
            style={styles.title}
            weight="medium"
          >
            {title}
          </Text>
          {subtitle !== undefined && (
            <Text size="MEDIUM" color="FONT_LOW_EMPHASIS">
              {subtitle}
            </Text>
          )}
        </View>
        {rightIcon !== null && (
          <Pressable accessibilityLabel={title} onPress={onPress}>
            <Icon name={rightIcon} size="SMALL" />
          </Pressable>
        )}
      </Pressable>
    </DetailedActionCardHoverWrapper>
  );
};

export { DetailedActionCard };
