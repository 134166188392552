import { isNative } from "@kraaft/helper-functions";
import { FirestoreTimestamp } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { parseDate } from "@kraaft/shared/core/services/firestore/parseDate";

import { onSnapshotQuery } from "../../../services/firestore/firestore.utils";
import { PoolMemberId, PoolMemberPreview } from "../poolMember.types";
import { roleIdsToGrade } from "../poolMemberUtils";
import { FirestoreCollection } from "./collection";

interface FirestorePoolMemberPreview {
  id: string;
  poolId: string;
  userId: string;
  updatedAt: FirestoreTimestamp;
  email?: string;
  phone?: string;
  grade?: string;
  roles?: string[];
  firstname?: string;
  lastname?: string;
  isCurrentMember?: boolean;
  isDemoUser?: boolean;
  isDeletedUser?: boolean;
}

function parseFirestorePoolMemberPreview(
  data: FirestorePoolMemberPreview,
): PoolMemberPreview {
  return {
    ...data,
    id: data.id as PoolMemberId,
    grade: roleIdsToGrade(data.roles),
    updatedAt: parseDate(data.updatedAt),
  };
}

class PoolMemberPreviewCollection extends FirestoreCollection<FirestorePoolMemberPreview> {
  collectionName = "poolMemberPreview-1n";

  memberships = (
    userId: string,
    callback: (results: PoolMemberPreview[]) => void,
  ) => {
    const query = this.collection
      .where("userId", "==", userId)
      .where("isCurrentMember", "==", true);

    return onSnapshotQuery<FirestorePoolMemberPreview>(
      "memberships",
      query,
      (snapshot) => {
        const results = snapshot.docs.map((doc) => doc.data());
        callback(results.map(parseFirestorePoolMemberPreview));
      },
    );
  };

  poolmembers = (
    poolId: string,
    callback: (results: PoolMemberPreview[]) => void,
  ) => {
    const query = this.collection
      .where("poolId", "==", poolId)
      .where("isCurrentMember", "==", true);

    return onSnapshotQuery<FirestorePoolMemberPreview>(
      "poolmembers",
      query,
      (snapshot) => {
        /**
         * When resubscribing, we get a snapshot containing only the member preview for the current user.
         *
         * This makes the member settings page flash:
         * - render with all members from the redux cache
         * - render with only the current user from the firestore snapshot overwriting the redux cache
         * - render with all members from next snapshot
         *
         * It is due to the fact that for some reason, firestore forgets about its own cached query result,
         * and reruns the query locally, returning only the current user which is in the local cache because of the `memberships` query.
         *
         * Until we can deduce the actor from the token,
         * we are always listening on memberships so we can build the actor.
         *
         * TODO: investigate why local cache from firestore is not permanent. Are we really using the "persistentCache" and not the default one ?
         */
        if (snapshot.metadata.fromCache && !isNative()) {
          return;
        }
        const results = snapshot.docs.map((doc) => doc.data());
        const result = results.map(parseFirestorePoolMemberPreview);
        callback(result);
      },
    );
  };
}

export const FirestorePoolMemberPreview = new PoolMemberPreviewCollection();
