import { createSelector } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import memoize from "lodash/memoize";
import orderBy from "lodash/orderBy";

import { compactMap } from "@kraaft/helper-functions";
import { ReadingStatus } from "@kraaft/shared/core/modules/filter/filterState";
import {
  AnyRoomCard,
  RoomCardQueryContext,
  RoomMemberCard,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { RootState } from "@kraaft/shared/core/store";
import {
  getEmptyArray,
  lodashKeyResolver,
} from "@kraaft/shared/core/utils/utils";

export class RoomCardSelectors {
  private static selectState = ({ roomCard }: RootState) => roomCard;

  private static selectPage = memoize((filterId: string | undefined) =>
    createSelector(this.selectState, ({ pages }) =>
      filterId ? pages[filterId] : undefined,
    ),
  );

  private static selectPinnedIds = memoize((poolId: string | undefined) =>
    createSelector(this.selectState, (state) => {
      if (poolId === undefined) {
        return getEmptyArray<string>();
      }
      return state.pinnedRoomCardIds[poolId] ?? getEmptyArray<string>();
    }),
  );

  static selectAll = createSelector(
    this.selectState,
    ({ roomCards }) => roomCards,
  );

  static select = memoize((roomId: string) =>
    createSelector(this.selectAll, (roomCards) => roomCards[roomId]),
  );

  static selectAlreadyMoreLoaded = memoize((filterId: string) =>
    createSelector(
      this.selectPage(filterId),
      (page) => page?.cursorContext?.alreadyMoreLoaded,
    ),
  );

  static selectQueryContext = memoize((filterId: string) =>
    createSelector(this.selectPage(filterId), (page) => page?.query),
  );

  static selectCursorContext = memoize((filterId: string) =>
    createSelector(this.selectPage(filterId), (page) => page?.cursorContext),
  );

  static selectCursorForFirstLoaded = memoize((filterId: string) =>
    createSelector(
      this.selectCursorContext(filterId),
      (cursorContext) => cursorContext?.firstLoaded,
    ),
  );

  static selectIsLoading = memoize((filterId: string | undefined) =>
    createSelector(this.selectPage(filterId), (page) => page?.isLoading),
  );

  static selectIsLoadingMore = memoize((filterId: string | undefined) =>
    createSelector(this.selectPage(filterId), (page) => page?.isLoadingMore),
  );

  static selectIsLoadingPinned = createSelector(
    this.selectState,
    (state) => state.arePinnedRoomCardsLoading,
  );

  static selectFilteredPinned = memoize(
    (queryContext: RoomCardQueryContext, justReadRoomId?: string) =>
      createSelector(
        this.selectAll,
        this.selectPinnedIds(queryContext.poolId),
        (allRoomCards, pinnedIds) => {
          const pinnedRoomCards = compactMap(
            pinnedIds,
            (id) => allRoomCards[id],
          ) as RoomMemberCard[];

          return orderBy(
            pinnedRoomCards.filter(
              RoomCardUtils.filter({
                filters: queryContext.filters,
                pinned: true,
                justReadRoomId,
              }),
            ),
            (roomCard) => roomCard.pinnedAt,
            "desc",
          );
        },
      ),
    lodashKeyResolver,
  );

  static selectFiltered = memoize(
    (queryContext: RoomCardQueryContext, justReadRoomId?: string) => {
      const filterId = RoomCardUtils.computeSubscriptionFilterId(queryContext);
      return createSelector(
        this.selectAll,
        this.selectPage(filterId),
        (allRoomCards, page): AnyRoomCard[] => {
          if (!page) {
            return getEmptyArray();
          }

          const array =
            justReadRoomId &&
            queryContext.filters.readingStatus === ReadingStatus.UNREAD
              ? uniq([
                  justReadRoomId,
                  ...page.idsFromSubscription,
                  ...page.idsFromLoaded,
                ])
              : [...page.idsFromSubscription, ...page.idsFromLoaded];

          const roomCards = compactMap(array, (id) => allRoomCards[id]);

          return orderBy(
            roomCards.filter(
              RoomCardUtils.filter({
                filters: queryContext.filters,
                pinned: false,
                justReadRoomId: justReadRoomId,
              }),
            ),
            (roomCard) => roomCard.lastEventAt,
            "desc",
          );
        },
      );
    },
    lodashKeyResolver,
  );

  static selectHasMore = memoize((filterId: string | undefined) =>
    createSelector(
      this.selectPage(filterId),
      (page) => page?.cursorContext.hasMore,
    ),
  );

  static selectHasError = memoize((filterId: string | undefined) =>
    createSelector(
      this.selectPage(filterId),
      (page) => page?.cursorContext.hasError,
    ),
  );

  static selectSearchText = memoize((filterId: string | undefined) =>
    createSelector(
      this.selectPage(filterId),
      (page) => page?.query.filters.searchText,
    ),
  );

  static selectIsPinnable = memoize((roomId: string) =>
    createSelector(
      this.select(roomId),
      (roomCard) => roomCard?.type === "member" && !roomCard.archived,
    ),
  );

  static selectIsPinned = memoize((roomId: string) =>
    createSelector(this.select(roomId), (roomCard) => {
      return roomCard?.pinned;
    }),
  );

  static selectPinnedAmount = memoize((poolId: string) =>
    createSelector(
      this.selectState,
      (state) => state.pinnedRoomCardIds[poolId]?.length ?? 0,
    ),
  );

  static selectArchivedForAll = memoize((roomId: string) =>
    createSelector(
      this.select(roomId),
      (roomCard) => roomCard?.isArchivedForAll,
    ),
  );

  static selectArchivedForUser = memoize((roomId: string) =>
    createSelector(
      this.select(roomId),
      (roomCard) => roomCard?.type === "member" && roomCard.isArchivedForUser,
    ),
  );

  static selectIsCurrentUserMember = memoize((roomId: string) =>
    createSelector(
      this.select(roomId),
      (roomCard) => roomCard?.type === "member",
    ),
  );
}
