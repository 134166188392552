import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CornerWithIcon } from "@kraaft/shared/components/cornerWithIcon";
import { useGeolocatedMediaSheet } from "@kraaft/shared/components/geolocatedMediaDialog/useGeolocatedMediaSheet";
import { getMediaGeolocation } from "@kraaft/shared/components/mediaViewer/utils";
import { Guard } from "@kraaft/shared/core/modules/auth";
import { imageHelper } from "@kraaft/shared/core/modules/file/imageHelper";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { MessageActions } from "@kraaft/shared/core/modules/message/messageActions";
import { useNavigationService } from "@kraaft/shared/core/services/navigation/index";
import { GeoLocation } from "@kraaft/shared/core/types";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { Portal } from "@kraaft/ui";

type CornerWithIconProps = {
  message: Messages.Image | Messages.Video;
  roomId: string;
};

export const MessageIcon = ({
  children,
  message,
  roomId,
}: PropsWithChildren<CornerWithIconProps>) => {
  const canEditGeolocation = Guard.use("Message.editGeolocation", message);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigationService = useNavigationService();

  const coords = useMemo(
    () => getMediaGeolocation(message.attachment)?.coords,
    [message.attachment],
  );
  const hasCoords = Boolean(coords);

  const dialogTooltip = t(
    message.type === "image"
      ? "nonGeolocatedMediaDialog.titleForImage"
      : "nonGeolocatedMediaDialog.titleForVideo",
  );

  const mapTooltip = t("openMap");

  const openImageMessageMap = useCallback(() => {
    navigationService.openRoomMapOnPhotoGallery({ roomId, coords });
    trackEvent({
      eventName: "Click On Message Map Button",
      room_id: roomId,
      message_id: message.id,
      message_type: message.type,
    });
  }, [navigationService, roomId, coords, message.id, message.type]);

  const handleMediaLocationChange = useCallback(
    (geolocation: GeoLocation | null | undefined) => {
      dispatch(
        MessageActions.updateAttachmentGeolocation({
          roomId,
          messageId: message.id,
          geolocation: geolocation ?? undefined,
        }),
      );
    },
    [dispatch, roomId, message.id],
  );

  const { openEditor, GeolocatedMediaDialog } = useGeolocatedMediaSheet({
    media: message.attachment,
    roomId,
    source: "conversation",
    onLocationChange: handleMediaLocationChange,
  });

  const messageAttachementSize =
    message.type === "image" && message.attachment.size;

  const childrenWithHdLogo = useMemo(() => {
    if (
      messageAttachementSize &&
      imageHelper.getQualityFromDimension(messageAttachementSize) === "hd"
    ) {
      return (
        <CornerWithIcon
          accessibilityLabel={t("imageHD")}
          icon="hd-on-2"
          tooltipText={t("imageHD")}
          direction="bottomLeft"
        >
          {children}
        </CornerWithIcon>
      );
    }
    return children;
  }, [children, messageAttachementSize, t]);

  if (!MessageHelper.isPersisted(message)) {
    return childrenWithHdLogo;
  }

  const isLocationEditable = message.type === "image";

  if (!hasCoords) {
    return (
      <>
        <CornerWithIcon
          accessibilityLabel={t("openMap")}
          icon="question-marker"
          onIconPress={canEditGeolocation ? openEditor : undefined}
          tooltipText={dialogTooltip}
          direction="bottomRight"
        >
          {childrenWithHdLogo}
        </CornerWithIcon>
        <Portal>{GeolocatedMediaDialog}</Portal>
      </>
    );
  }

  if (!isLocationEditable) {
    return childrenWithHdLogo;
  }

  return (
    <CornerWithIcon
      accessibilityLabel={t("openMap")}
      icon="map-04"
      onIconPress={openImageMessageMap}
      tooltipText={mapTooltip}
      direction="bottomRight"
    >
      {childrenWithHdLogo}
    </CornerWithIcon>
  );
};
