import { EditOperationBuilder } from "@kraaft/offline";
import { incrementPendingOperations } from "@kraaft/shared/core/modules/modularFolder/operations/modularFolder.offline.tools";
import { ModularFolder } from "@kraaft/shared/core/modules/schema/modularTypes/modularFolder";
import { Api } from "@kraaft/shared/core/services/api";
import { ReorderedIds } from "@kraaft/ui";

export const reorderOperation = EditOperationBuilder.create<ModularFolder>()
  .payload<{
    roomId: string;
    modularFolderId: string;
    afterModularFolderId: string | undefined;
    reorderedIds: ReorderedIds;
  }>({
    gatherIds(payload) {
      const ids = [
        payload.modularFolderId,
        ...Object.keys(payload.reorderedIds),
      ];
      if (payload.afterModularFolderId) {
        ids.push(payload.afterModularFolderId);
      }
      return ids;
    },
    replaceId(payload, oldId, newId) {
      if (payload.modularFolderId === oldId) {
        payload.modularFolderId = newId;
      }
      if (payload.afterModularFolderId === oldId) {
        payload.afterModularFolderId = newId;
      }
      if (oldId in payload.reorderedIds) {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        payload.reorderedIds[newId] = payload.reorderedIds[oldId]!;
        delete payload.reorderedIds[oldId];
      }
      return payload;
    },
  })
  .expected((modularFolders, payload) => {
    for (const [id, index] of Object.entries(payload.reorderedIds)) {
      const modularFolder = modularFolders[id];
      if (!modularFolder) {
        continue;
      }
      incrementPendingOperations(modularFolder);
      modularFolder.index = index;
    }
  })
  .mutate((payload) =>
    Api.reorderModularFolder({
      roomId: payload.roomId,
      folderId: payload.modularFolderId,
      afterId: payload.afterModularFolderId,
    }),
  )
  .acknowledgeOn((modularFolders, payload, result) => {
    const target = modularFolders[payload.modularFolderId];
    if (target && target.updatedAt.getTime() >= result.updatedAt.getTime()) {
      return true;
    }
    if (!payload.afterModularFolderId) {
      return false;
    }
    const after = modularFolders[payload.afterModularFolderId];
    if (after && after.updatedAt.getTime() >= result.updatedAt.getTime()) {
      return true;
    }
    return false;
  });
