import { call, put, select } from "typed-redux-saga/macro";

import * as alertActions from "@kraaft/shared/core/modules/alert/alertActions";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import * as messageActions from "@kraaft/shared/core/modules/message/messageActions";
import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { selectCurrentUserAuth } from "@kraaft/shared/core/modules/user/userSelectors";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* handleReactionsToMessageSaga(
  action: ReturnType<typeof messageActions.reactToMessage>,
) {
  const { payload } = action;
  const user = yield* select(selectCurrentUserAuth);
  const message = yield* select(
    selectMessageInRoom(payload.roomId, payload.messageId),
  );

  if (!user || !message || !MessageHelper.isUserMessage(message)) {
    return;
  }

  yield* put(
    messageActions.MessageStateActions.reactToMessageOptimistic({
      ...payload,
      userId: user.uid,
    }),
  );

  const lastEmoji = message.reactions[user.uid]?.emoji;

  try {
    if (message.reactions[user.uid]?.emoji === payload.emoji) {
      yield* call(Api.unreactToMessage, payload);
    } else {
      yield* call(Api.reactToMessage, payload);
    }
  } catch (e) {
    yield* put(
      messageActions.reactToMessageFailed({
        ...payload,
        lastEmoji,
        userId: user.uid,
      }),
    );
    console.error(e);
    yield* put(alertActions.showError({ title: i18n.t("errorServer") }));
  }
}
