import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "typed-redux-saga/macro";

import { Directory } from "@kraaft/shared/core/modules/directory/directory";
import {
  DirectoryActions,
  DirectoryStateActions,
} from "@kraaft/shared/core/modules/directory/directoryActions";
import { Firestore } from "@kraaft/shared/core/services/firestore";
import { takeCountedDeep } from "@kraaft/shared/core/utils/sagas";

export function* subscribeToRoomDirectoriesSaga() {
  yield takeCountedDeep(
    DirectoryActions.subscribe,
    DirectoryActions.unsubscribe,
    subscribe,
    unsubscribe,
    (action) => action.payload.roomId,
  );
}

function* subscribe(
  registerUnsubscribe: (unsubscribe: () => void) => void,
  { payload }: ReturnType<(typeof DirectoryActions)["subscribe"]>,
) {
  const { roomId } = payload;

  const channel = yield* call(() =>
    eventChannel<Directory[]>((emit) =>
      Firestore.subscribeToRoomDirectories(roomId, emit),
    ),
  );

  registerUnsubscribe(() => channel.close());

  yield* takeEvery(channel, function* (directories) {
    yield* put(DirectoryStateActions.setForRoom({ roomId, directories }));
  });
}

function* unsubscribe(meta: (() => void) | undefined) {
  meta?.();
}
