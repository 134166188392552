import { useMemo } from "react";
import { GestureResponderEvent, Pressable, View } from "react-native";

import { styles } from "@kraaft/shared/components/roundSelectButton/roundSelectButton.styles";
import { TrackableViewProps } from "@kraaft/shared/core/types";

export type RoundSelectButtonProps = TrackableViewProps & {
  selected?: boolean;
  onPress: (event: GestureResponderEvent) => void;
  children: React.ReactNode;
  nativeId?: string;
  size?: "normal" | "small";
};

const RoundSelectButton = ({
  accessibilityLabel,
  children,
  onPress,
  selected,
  nativeId,
  size = "normal",
}: RoundSelectButtonProps) => {
  const finalStyles = useMemo(() => {
    return [
      styles.root,
      size === "small" && styles.small,
      selected && styles.selected,
    ];
  }, [selected, size]);

  return (
    <View style={finalStyles} nativeID={nativeId}>
      <Pressable accessibilityLabel={accessibilityLabel} onPress={onPress}>
        <View style={[styles.child, size === "small" && styles.small]}>
          {children}
        </View>
      </Pressable>
    </View>
  );
};

export { RoundSelectButton };
