import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CarouselStateActions } from "@kraaft/shared/core/modules/carousel/carousel.actions";
import {
  selectMiniMediaCarousel,
  selectMiniMediaCarouselData,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.selectors";
import { Sheet, useCarouselOpenClose } from "@kraaft/ui";

import { MiniMediaCarouselContent } from "./miniMediaCarouselContent";

interface MiniMediaCarouselProps {
  roomId: string;
}

const MiniMediaCarouselSheet = Sheet({
  default: "overlay",
}).create<MiniMediaCarouselProps>(({ Content }) => ({ roomId, onClose }) => {
  const data = useSelector(selectMiniMediaCarouselData(roomId));

  if (!data) {
    return null;
  }

  return (
    <Content>
      <MiniMediaCarouselContent
        initialIndex={data.initialIndex}
        medias={data.medias}
        onClose={onClose}
        roomId={roomId}
      />
    </Content>
  );
});

export const MiniMediaCarousel = () => {
  const dispatch = useDispatch();
  const miniMediaCarousel = useSelector(selectMiniMediaCarousel);
  const { open, onClose } = useCarouselOpenClose(miniMediaCarousel?.show);

  const closeCarousel = useCallback(() => {
    dispatch(CarouselStateActions.close());
  }, [dispatch]);

  const { element } = MiniMediaCarouselSheet.use({
    open,
    onClose,
    onClosed: closeCarousel,
    roomId: miniMediaCarousel.show ? miniMediaCarousel.roomId : undefined,
  });

  if (!miniMediaCarousel?.show) {
    return null;
  }

  return element;
};
