import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/selectors";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { compareStrings } from "@kraaft/shared/core/utils/utils";

type UseRoomMembersParams = {
  roomId: Room["id"];
};

export type Member = {
  id: string;
  name?: string;
  role?: string;
};

type UseRoomMembersResult = Member[];

export const useRoomMembers = ({
  roomId,
}: UseRoomMembersParams): UseRoomMembersResult => {
  const { t } = useTranslation();

  const selectedMembers = useSelector(
    RoomSelectors.selectMembersDetails(roomId),
  );

  return useMemo(() => {
    function translateMemberRole(role: UserPoolRole, isResponsible: boolean) {
      if (role === UserPoolRole.EXTERNAL) {
        return t("external");
      }

      if (isResponsible) {
        return t("responsible");
      }

      return;
    }

    return selectedMembers
      .map((member) => ({
        id: member.id,
        name: member.name,
        role: translateMemberRole(member.role, member.isResponsible),
      }))
      .sort((a, b) => (a.name && b.name ? compareStrings(a.name, b.name) : 0));
  }, [selectedMembers, t]);
};
