import { keyBy } from "lodash";
import { EventChannel, eventChannel } from "redux-saga";
import { put, takeEvery } from "typed-redux-saga/macro";

import { takeCountedDeep } from "../../utils/sagas";
import { FirestorePoolMemberPreview } from "./collections/poolMemberPreview";
import { PoolMemberActions } from "./poolMember.actions";
import { OfflinePoolMemberStateActions } from "./poolMember.offline";
import { PoolMemberPreview } from "./poolMember.types";

export function* poolMemberSagas() {
  yield takeCountedDeep(
    PoolMemberActions.subscribe,
    PoolMemberActions.unsubscribe,
    subscribe,
    unsubscribe,
    (action) => action.payload.poolId,
  );
}

function* subscribe(
  register: (channel: EventChannel<PoolMemberPreview[]>) => void,
  action: ReturnType<typeof PoolMemberActions.subscribe>,
) {
  const { poolId } = action.payload;

  const channel = eventChannel<PoolMemberPreview[]>((emit) =>
    FirestorePoolMemberPreview.poolmembers(poolId, emit),
  );

  register(channel);

  yield* takeEvery(channel, receivePoolMembers);
}

function* unsubscribe(channel: EventChannel<PoolMemberPreview[]> | undefined) {
  channel?.close();
}

function* receivePoolMembers(poolMembers: PoolMemberPreview[]) {
  yield* put(
    OfflinePoolMemberStateActions.receive(
      keyBy(poolMembers, (poolMember) => poolMember.id),
    ),
  );
}
