import { useMemo, useRef } from "react";
import withScrolling, { createVerticalStrength } from "react-dnd-scrolling";
import { StrictOmit } from "ts-essentials";

import { UnknownObject } from "@kraaft/shared/core/types";
import { uuid } from "@kraaft/shared/core/utils";

import {
  OrderableListProps,
  OrderableListScrollable,
} from "./orderableList.scrollable";
import { OrderableListDragLayer } from "./orderableListDragLayer";

import { useStyles } from "./orderableList.styles";

const defaultLinearVerticalStrength = createVerticalStrength(200);
const Scrolling = withScrolling("div");

/** @deprecated use OrderableList from kraaft/ui package */
const OrderableList = <T extends UnknownObject, K extends string = string>(
  props: StrictOmit<OrderableListProps<T, K>, "listIdentifier">,
) => {
  const { renderRow, withHandle, customScrollThreshold } = props;
  const listIdentifier = useRef(uuid()).current;

  const classes = useStyles();
  const verticalStrength = useMemo(
    () =>
      customScrollThreshold
        ? createVerticalStrength(customScrollThreshold)
        : defaultLinearVerticalStrength,
    [customScrollThreshold],
  );

  return (
    <>
      <Scrolling
        verticalStrength={verticalStrength}
        className={classes.container}
      >
        <OrderableListScrollable listIdentifier={listIdentifier} {...props} />
      </Scrolling>
      <OrderableListDragLayer
        listIdentifier={listIdentifier}
        renderRow={renderRow}
        withHandle={withHandle}
      />
    </>
  );
};

export { OrderableList };
