import { useRef } from "react";

import { isNative } from "@kraaft/helper-functions";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { usePrevious } from "@kraaft/shared/core/utils/hooks";
import { ScrollPosition } from "@kraaft/shared/core/utils/useBidirectional/implementations/bidirectionalList.props";

function isMessageNotifiable(message: AnyMessage) {
  return message.type !== "log";
}

export function getMessageToDisplayBannerAfter(
  loadedMessages: AnyMessage[],
  lastReadMessageId: string | undefined,
) {
  if (!lastReadMessageId) {
    return undefined;
  }
  const lastReadMessageIndex = loadedMessages.findIndex(
    (e) => e.id === lastReadMessageId,
  );
  const lastReadMessage = loadedMessages[lastReadMessageIndex];
  if (!lastReadMessage) {
    return undefined;
  }
  const messagesAfterLastReadMessage = loadedMessages.slice(
    0,
    lastReadMessageIndex,
  );
  if (
    messagesAfterLastReadMessage.length === 0 ||
    messagesAfterLastReadMessage.every(
      (message) => !isMessageNotifiable(message),
    )
  ) {
    return undefined;
  }
  return lastReadMessageId;
}

// eslint-disable-next-line complexity
export function useUnreadBanner(
  visible: boolean,
  displayedMessages: AnyMessage[],
  lastReadMessageId: string | undefined,
  scrollToElementId: (position: ScrollPosition, id: string) => void,
) {
  const lastTimeViewed = useRef(Date.now());
  const wasVisible = usePrevious(visible);
  const shouldScroll = useRef(false);

  if (!wasVisible && visible) {
    lastTimeViewed.current = Date.now();
  }

  const [latestMessageLoaded] = displayedMessages;
  const lastReadMessageIndex = displayedMessages.findIndex(
    (e) => e.id === lastReadMessageId,
  );
  const firstUnreadMessage = displayedMessages[lastReadMessageIndex - 1];
  const lastNotifiableReadMessageId = useRef<string | undefined>(undefined);
  if (
    firstUnreadMessage &&
    isMessageNotifiable(firstUnreadMessage) &&
    firstUnreadMessage.createdAt.getTime() < lastTimeViewed.current &&
    lastNotifiableReadMessageId.current !== lastReadMessageId
  ) {
    lastNotifiableReadMessageId.current = getMessageToDisplayBannerAfter(
      displayedMessages,
      lastReadMessageId,
    );
    shouldScroll.current = true;
  }
  if (
    firstUnreadMessage &&
    firstUnreadMessage.createdAt.getTime() >= lastTimeViewed.current
  ) {
    lastNotifiableReadMessageId.current = undefined;
  }

  const messageIdToDisplayBannerAfter = useRef<string | undefined>(undefined);

  messageIdToDisplayBannerAfter.current =
    lastNotifiableReadMessageId.current &&
    lastNotifiableReadMessageId.current !== latestMessageLoaded?.id
      ? lastNotifiableReadMessageId.current
      : undefined;

  if (
    !isNative() &&
    shouldScroll.current &&
    messageIdToDisplayBannerAfter.current
  ) {
    const messageDisplayedIndex = displayedMessages.findIndex(
      (message) => message.id === messageIdToDisplayBannerAfter.current,
    );
    const displayedMessage = displayedMessages[messageDisplayedIndex];
    if (displayedMessage) {
      shouldScroll.current = false;
      scrollToElementId(
        {
          index: displayedMessages.length - messageDisplayedIndex - 1,
          length: displayedMessages.length,
        },
        displayedMessage.id,
      );
    }
  }

  return { messageIdToDisplayBannerAfter };
}
