import { useTranslation } from "react-i18next";

import { KSchemaElement } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ActionSheet } from "@kraaft/ui";
import { useDuplicateActionItems } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useDuplicateActionItems";
import { useOptionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementActions/useOptionItem";

interface ElementMenuActionSheetProps {
  element: KSchemaElement;
  openOptions(): void;
  onDeleteIntent: () => void;
}

export const ElementMenuActionSheet =
  ActionSheet().create<ElementMenuActionSheetProps>(
    ({ ActionSheetContent, ActionSheetItem }) =>
      ({ element, openOptions, onDeleteIntent }) => {
        const { t } = useTranslation();

        const duplicateActions = useDuplicateActionItems(element);
        const optionsAction = useOptionItem({ openOptions });

        return (
          <ActionSheetContent>
            {duplicateActions}
            {optionsAction}
            {(element.elementType !== "column" || !element.nonDeletable) && (
              <ActionSheetItem
                onClick={onDeleteIntent}
                label={t("del")}
                destructive
              />
            )}
          </ActionSheetContent>
        );
      },
  );
