import { call, put, takeEvery } from "typed-redux-saga/macro";

import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { DirectoryActions } from "@kraaft/shared/core/modules/directory/directoryActions";
import { directoryUtils } from "@kraaft/shared/core/modules/directory/utils/utils.provider.web";
import { Api } from "@kraaft/shared/core/services/api";
import { i18n } from "@kraaft/shared/core/services/i18next";

export function* downloadDirectorySaga() {
  yield* takeEvery(DirectoryActions.downloadDirectory, downloadDirectory);
}

function* downloadDirectory(
  action: ReturnType<typeof DirectoryActions.downloadDirectory>,
) {
  const { roomId, directoryId } = action.payload;
  const { meta } = action;

  try {
    const { downloadUrl, filename } = yield* call(Api.downloadDirectory, {
      roomId,
      directoryId,
    });
    yield* call(directoryUtils.downloadDirectory, { downloadUrl, filename });
  } catch (error) {
    yield* put(
      showError({
        title: i18n.t("directory.couldNotDownloadDirectory"),
      }),
    );
    yield* put(DirectoryActions.downloadDirectorySelectionFailure(error, meta));
  }
  yield* put(DirectoryActions.downloadDirectorySelectionSuccess(meta));
}
