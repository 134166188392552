import { DimensionValue, Pressable, StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { CornerGradient } from "@kraaft/shared/components/cornerGradient";
import { Tooltip } from "@kraaft/shared/components/tooltip";
import { TrackableViewProps } from "@kraaft/shared/core/types";
import { Icon, IconName, Spacing } from "@kraaft/ui";

export type CornerWithIconProps = React.PropsWithChildren<
  TrackableViewProps & {
    icon: IconName;
    onIconPress?: () => void;
    tooltipText?: string;
    direction: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
  }
>;

export const CornerWithIcon = ({
  accessibilityLabel,
  children,
  icon,
  onIconPress,
  tooltipText,
  direction = "bottomRight",
}: CornerWithIconProps) => (
  <View style={styles.container} pointerEvents="box-none">
    {children}
    <CornerGradient direction={direction} />
    <Tooltip title={tooltipText ?? ""}>
      <Pressable
        accessibilityLabel={accessibilityLabel}
        style={[styles.iconContainer, styles[direction]]}
        onPress={onIconPress}
        disabled={!onIconPress}
      >
        <Icon name={icon} color="WHITE" size="MEDIUM" />
      </Pressable>
    </Tooltip>
  </View>
);

export const styles = StyleSheet.create({
  container: {
    position: "relative",
    height: isNative() ? undefined : ("inherit" as DimensionValue),
    alignSelf: "flex-start",
  },
  iconContainer: {
    position: "absolute",
    padding: Spacing.S8,
  },
  topLeft: {
    top: 0,
    left: 0,
  },
  topRight: {
    top: 0,
    right: 0,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
  },
});
