import { RefObject } from "react";

import { useMeshContext } from "@kraaft/helper-hooks";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import {
  KSchemaColumn,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularColumnsContext } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecordDisplayContext";
import { HoverState } from "@kraaft/shared/core/modules/schema/schema.offline";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { InsertElementDropItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDrag.utils";
import { ElementDragLayer } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementDrag/elementDragLayer";
import { ElementPickerDrop } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementPickerDrop";
import { ElementsEditorBottomItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorBottomItem";
import { ElementsEditorSectionItem } from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/elementsEditor/elementsEditorSectionItem";
import {
  SchemaBuilderContext,
  useSchemaBuilderRootSection,
} from "@kraaft/web/src/components/schemaBuilder/tabs/editSchema/schemaBuilder.context";

import { useStyles } from "./elementsList.styles";

interface ElementCreationBase {
  placement: HoverState;
  inSection?: string;
}

export interface SectionCreation extends ElementCreationBase {
  type: "section";
  section: KSchemaSection;
}

export interface ColumnCreation extends ElementCreationBase {
  type: "column";
  column: KSchemaColumn;
}

export type ElementCreation = SectionCreation | ColumnCreation;

interface ElementsListProps {
  availableColumnTypes: KColumnType[];
  columnsContext: ModularColumnsContext;
  columnListRef: RefObject<HTMLDivElement>;
}

const ElementsList = ({
  availableColumnTypes,
  columnsContext,
  columnListRef,
}: ElementsListProps) => {
  const styles = useStyles();
  const { addElement, currentHoveredKey, setCurrentHoveredKey } =
    useMeshContext(SchemaBuilderContext);
  const rootSection = useSchemaBuilderRootSection();

  const addOfType = useCallbackRealtime(
    ([_currentHoveredKey], item: InsertElementDropItem | undefined) => {
      if (!item || !_currentHoveredKey) {
        return;
      }
      if (item.insertType === "column") {
        addElement(
          { type: "column", columnType: item.columnType },
          _currentHoveredKey,
        );
      } else {
        addElement({ type: "section" }, _currentHoveredKey);
      }
    },
    [addElement],
    [currentHoveredKey],
  );

  const setLastKey = useCallbackRealtime(
    ([_rootSection]) => {
      const columns = Object.values(_rootSection.elements).sort(
        KSchemaUtils.orderByIndex,
      );
      const lastColumn = columns[columns.length - 1];
      if (!lastColumn) {
        return;
      }
      setCurrentHoveredKey((old) => {
        if (old?.key !== lastColumn.key || old.placement !== "after") {
          return { key: lastColumn.key, placement: "after" };
        }
        return old;
      });
    },
    [setCurrentHoveredKey],
    [rootSection],
  );

  return (
    <ElementPickerDrop className={styles.root} onDrop={addOfType}>
      <div ref={columnListRef}>
        <ElementsEditorSectionItem
          availableColumnTypes={availableColumnTypes}
          columnsContext={columnsContext}
          hideParentSection
          section={rootSection}
        />
      </div>
      <ElementsEditorBottomItem setLastKey={setLastKey} addAtLast={addOfType} />
      <ElementDragLayer />
    </ElementPickerDrop>
  );
};

export { ElementsList };
