import { EditOneOperationBuilder } from "@kraaft/offline";
import { showError } from "@kraaft/shared/core/modules/alert/alertActions";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { optimisticDuplicateSchemaElement } from "@kraaft/shared/core/modules/schema/schema.optimisticHelper";
import { Api } from "@kraaft/shared/core/services/api";
import { HttpError } from "@kraaft/shared/core/services/firebase/httpError";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { getStore } from "@kraaft/shared/core/store";

export const duplicateElementOperation =
  EditOneOperationBuilder.create<KSchema>()
    .payload<{ key: string }>()
    .expected((aggregate, payload) => {
      optimisticDuplicateSchemaElement(aggregate, payload);
      return aggregate;
    })
    .mutate(async (payload) => {
      try {
        const { updatedAt } = await Api.duplicateElement({
          schemaId: payload.id,
          key: payload.key,
        });
        return { updatedAt };
      } catch (e) {
        if (HttpError.isHttpErrorWithCode(e, "MaxColumnCountExceededError")) {
          getStore().dispatch(
            showError({
              title: i18n.t("formBuilder.schema.error.maxColumnCountExceeded"),
            }),
          );
        }
        throw e;
      }
    });
