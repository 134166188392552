import { createAction } from "@reduxjs/toolkit";

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { LocalPath, ModernFile } from "@kraaft/shared/core/modules/file/file";
import { GeoCoordinates, GeoLocation } from "@kraaft/shared/core/types";

export const errorSendingMessage = createAction<{
  roomId: string;
  optimisticId: string;
}>("@message/ERROR_SENDING_MESSAGE");

export const retrySendingMessage = createAction<{
  roomId: string;
  optimisticId: string;
}>("@message/RETRY_SENDING_MESSAGE");

export const deleteOptimisticMessage = createAction<{
  roomId: string;
  optimisticId: string;
}>("@message/DELETE_OPTIMISTIC_MESSAGE");

export const successSendingMessage = createAction<{
  roomId: string;
  optimisticId: string;
}>("@message/SUCCESS_SENDING_MESSAGE");

export const sendTextMessage = createAction(
  "@message/SEND_TEXT_MESSAGE",
  (payload: {
    roomId: string;
    optimisticId: string;
    text: InputPartition[];
    answerTo?: string;
  }) => ({
    payload,
  }),
);

export const updateSentMessagesCount = createAction(
  "@message/SENT_MESSAGES_COUNT",
  (payload: { roomId: string }) => ({ payload }),
);

export const sendFileMessage = createAction<{
  roomId: string;
  optimisticId: string;
  file: ModernFile<LocalPath>;
  answerTo?: string;
  caption?: InputPartition[];
  coords?: GeoCoordinates;
}>("@message/SEND_FILE_MESSAGE");

export const sendAudioMessage = createAction<{
  roomId: string;
  optimisticId: string;
  path: LocalPath;
  answerTo?: string;
}>("@message/SEND_AUDIO_MESSAGE");

export const sendGeolocationMessage = createAction<{
  roomId: string;
  optimisticId: string;
  geolocation: GeoLocation;
  answerTo?: string;
}>("@message/SEND_GEOLOCATION_MESSAGE");
