import { customAlphabet } from "nanoid";
import { Keyboard, Platform } from "react-native";
import { assert } from "ts-essentials";
import { v4 as uuidv4 } from "uuid";

import type { Falsy, Placement } from "@kraaft/helper-types";

/**
 * Detect if app is currently running in a mobile platform.
 */
export function isNative() {
  return Platform.select({
    ios: true,
    android: true,
    default: false,
  });
}

export function dismissNativeKeyboard() {
  if (isNative()) {
    Keyboard.dismiss();
  }
}

/**
 * Compares if two array contain the same references
 */
export function arrayShallowEqual(a: any[], b: any[]) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function compact<T>(array: (T | Falsy)[]): T[] {
  return array.filter(Boolean) as T[];
}

export function pick<O extends {}, K extends keyof O>(object: O, keys: K[]) {
  return keys.reduce(
    (newObject, key) => {
      if (newObject && Object.prototype.hasOwnProperty.call(object, key)) {
        newObject[key] = object[key];
      }
      return newObject;
    },
    {} as Pick<O, K>,
  );
}

export function omitSingle<O extends {}, K extends keyof O>(
  object: O,
  key: K,
): Omit<O, K> {
  const { [key]: omitted, ...rest } = object;
  return rest;
}

export function omit<O extends {}, K extends keyof O>(
  object: O,
  keys: K[],
): Omit<O, (typeof keys)[number]> {
  return keys.reduce((newObject, key) => {
    return omitSingle(newObject, key) as any;
  }, object);
}

export function identity<T>(value: T): T {
  return value;
}

export function isKeyOf<R extends Record<string, unknown>>(
  record: R,
  key: string,
): key is keyof R & string {
  return Boolean(record[key]);
}

export function assertNever(value: never): never {
  assert(false);
}

export function isNotEmptyString(value: string | undefined): value is string {
  return Boolean(value && value.trim().length > 0);
}

export function isNotFalsy<T>(value: T | Falsy): value is T {
  return Boolean(value);
}

export function uuid() {
  return uuidv4();
}

export const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  20,
);

export function isPlacementHorizontal(placement: Placement) {
  return placement.startsWith("left") || placement.startsWith("right");
}

export function isValueInRange(
  value: number,
  [leftBoundary, rightBoundary]: [number, number],
) {
  if (leftBoundary < rightBoundary) {
    return value >= leftBoundary && value <= rightBoundary;
  }
  return value >= rightBoundary && value <= leftBoundary;
}

export const arrayfy = <T>(value: T | T[]): T[] => {
  return Array.isArray(value) ? value : [value];
};

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function filterInPlace<T>(
  array: T[],
  predicate: (element: T, index: number, array: T[]) => boolean,
) {
  let index = 0;
  let newIndex = 0;

  while (index < array.length) {
    // biome-ignore lint/style/noNonNullAssertion: This will always exist
    const item = array[index]!;
    if (predicate(item, index, array)) {
      array[newIndex] = item;
      newIndex += 1;
    }
    index += 1;
  }

  array.length = newIndex;
  return array;
}

export function clamp(value: number, min: number, max: number) {
  "worklet";
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

/**
 * Acts as compact(array.map(item => value | undefined))
 * but only loops the array once
 */
export function compactMap<T, R>(
  array: Array<T>,
  predicate: (item: T, index: number) => R,
): Array<NonNullable<R>> {
  return array.reduce<Array<NonNullable<R>>>((acc, curr, index) => {
    const item = predicate(curr, index);
    if (item === undefined || item === null) {
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
}

/**
 * Creates an array of "count" length with a predicate function.
 * It's easier to use this instead of Array.from().fill().keys() nonsense
 */
export function times<T>(count: number, iteratee: (index: number) => T) {
  const items: Array<T> = [];

  for (let i = 0; i < count; i += 1) {
    items.push(iteratee(i));
  }

  return items;
}
