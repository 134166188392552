import { StyleSheet } from "react-native";
import { makeStyles } from "@mui/styles";

import { Color, ColorStyle } from "@kraaft/ui";

export const makeKTableStyle = makeStyles(() => ({
  tableContainer: {
    backgroundColor: Color.WHITE,
    maxHeight: "inherit",
    overflowX: "initial",
  },
  scroll: {
    overflowY: "auto",
  },
}));

export const makeKTableRowStyle = makeStyles(() => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
    },
    "&.Mui-hover": {
      "&:hover": {
        backgroundColor: Color.WHITE,
      },
    },
  },
  hover: {},
  selected: {},
}));

export const makeKTableCellStyle = makeStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: ColorStyle.ACTION_HOVERED,
    },
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const kTableStyles = StyleSheet.create({
  loader: { padding: 12 },
});
