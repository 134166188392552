import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { ShareFilePreview } from "@kraaft/shared/components/shareFilePreviewBar/shareFilePreview";
import { actions as actionsFileBucket } from "@kraaft/shared/core/modules/fileBucket";
import { selectFilesInSenderBucket } from "@kraaft/shared/core/modules/fileBucket/fileBucketSelectors";
import { unsetReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { selectReplyingSourceMessage } from "@kraaft/shared/core/modules/message/messageSelectors";
import { actions as actionsMessage } from "@kraaft/shared/core/modules/message/send";
import { uuid } from "@kraaft/shared/core/utils";
import { Icon } from "@kraaft/ui";

import { ShareNewFileButtonWrapper } from "./shareNewFileButtonWrapper";

import { styles } from "./shareFilePreviewBar.styles";

interface Props {
  roomId: string;
}

const ShareFilePreviewBar = (props: Props) => {
  const { roomId } = props;
  const dispatch = useDispatch();
  const shareFile = useSelector(selectFilesInSenderBucket(roomId));
  const answerTo = useSelector(selectReplyingSourceMessage(roomId));
  const shareFileList = shareFile?.list;
  const scrollViewRef = useRef<ScrollView>(null);
  const buttonRef = useRef<TouchableOpacity>(null);

  useEffect(() => {
    if (scrollViewRef?.current) {
      scrollViewRef.current.scrollToEnd();
    }
    if (buttonRef?.current) {
      buttonRef.current.focus();
    }
  }, [shareFileList]);

  const onFileRemoved = (index: number) => {
    dispatch(
      actionsFileBucket.removeFilesSenderBucket({
        id: roomId,
        index,
      }),
    );
  };

  const onAllFilesRemoved = () => {
    dispatch(
      actionsFileBucket.removeFilesSenderBucket({
        id: roomId,
        emptyAll: true,
      }),
    );
  };

  const onPublishFiles = () => {
    for (const file of shareFileList) {
      dispatch(
        actionsMessage.sendFileMessage({
          roomId: roomId,
          optimisticId: uuid(),
          file,
          coords: undefined,
          answerTo: answerTo?.id,
        }),
      );
    }
    dispatch(
      actionsFileBucket.removeFilesSenderBucket({
        id: roomId,
        emptyAll: true,
      }),
    );
    dispatch(unsetReplyingSourceMessage(roomId));
  };

  const { t } = useTranslation();

  return (
    <View style={styles.main}>
      <View style={styles.header}>
        <Text style={styles.title}>{t("fileListToSend")}</Text>
        <TouchableOpacity
          accessibilityLabel={t("removeAll")}
          onPress={onAllFilesRemoved}
        >
          <Icon name="x-sm" size="MINI" style={styles.dismissIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.senderContainer}>
        <ShareNewFileButtonWrapper roomId={roomId} />
        <ScrollView
          nativeID="ide2e-message-file-list"
          ref={scrollViewRef}
          horizontal
          style={styles.scrollContainer}
        >
          {shareFileList.map((file, index) => (
            <ShareFilePreview
              // files can have the same name
              // eslint-disable-next-line react/no-array-index-key
              key={`${file.filename}-${index}`}
              file={file}
              onClickRemoveFile={() => onFileRemoved(index)}
            />
          ))}
        </ScrollView>

        <View nativeID="ide2e-message-file-send">
          <TouchableOpacity
            accessibilityLabel={t("send")}
            ref={buttonRef}
            style={styles.sendButton}
            onPress={onPublishFiles}
          >
            <Icon name="send-03-fill" size={20} color="WHITE" />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export { ShareFilePreviewBar };
