import pickBy from "lodash/pickBy";
import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { RequiredKeys, UnionToIntersection } from "ts-essentials";

import { isSyntheticEvent } from "@kraaft/helper-functions";
import { useBooleanState, useMeshContextSetup } from "@kraaft/helper-hooks";
import { Without } from "@kraaft/helper-types";

import { DEFAULT_PORTAL_HOSTNAME, PortalManagementContext } from "../../layout";
import { usePortalKey } from "../../layout/portal/portal/usePortalKey";
import { ActionSheetContent, ActionSheetDefinition } from "./actionSheet";
import { ActionSheetContext } from "./actionSheet.context";
import { ActionSheetGroup } from "./actionSheetGroup";
import { ActionSheetItem } from "./actionSheetItem";
import { ActionSheetSeparator } from "./actionSheetSeparator";

export function ActionSheet() {
  const { Host } = ActionSheetDefinition;

  type PropsOf<T> = T extends React.ComponentType<infer P> ? P : never;

  type HostProps = UnionToIntersection<PropsOf<typeof Host>>;
  type PartialHostProps = Partial<HostProps>;

  // Merge the props of the Host component with the props of the wrapped component
  // Allows for easier usage of the sheets
  function Wrap<P, StaticProps extends PartialHostProps>(
    Component: React.FC<P>,
    staticProps?: StaticProps,
  ) {
    type WrappedComponentProps = HostProps & P;
    type OnlySetKeys = RequiredKeys<StaticProps>;
    type SheetContentProps = Omit<
      WrappedComponentProps,
      | OnlySetKeys
      | "onClosed"
      | "open"
      | "onClose"
      | "children"
      | "setPreventClose"
    > &
      Partial<StaticProps>;

    type ExternalOpenFunctionType<
      PartialProps extends Partial<SheetContentProps>,
    > = (
      ...args: RequiredKeys<
        Without<SheetContentProps, PartialProps>
      > extends never
        ? [] | [Partial<SheetContentProps>] | [params: SyntheticEvent]
        : [params: Without<SheetContentProps, PartialProps>]
    ) => void;

    const WrappedComponent = (props: WrappedComponentProps) => {
      const value = useMeshContextSetup({ onClose: props.onClose });

      return (
        <Host
          {...(staticProps ?? {})}
          {...(pickBy(props as any, (element) => element !== undefined) as any)}
        >
          <ActionSheetContext.Provider value={value}>
            <Component {...(props as any)} />
          </ActionSheetContext.Provider>
        </Host>
      );
    };

    function useSheet<PartialProps extends Partial<SheetContentProps>>(
      props: PartialProps,
    ): {
      isOpen: boolean;
      open: ExternalOpenFunctionType<PartialProps>;
      close: () => void;
      element: ReactNode;
    } {
      const preventClose = useRef(false);
      const [isOpen, setOpen, close] = useBooleanState();
      const [state, setState] = useState<
        Partial<SheetContentProps> | undefined
      >(undefined);

      const setPreventClose = useCallback((value: boolean) => {
        preventClose.current = value;
      }, []);

      const handleClose = useCallback(
        (params = { bypassPreventClose: false }) => {
          if (preventClose.current && !params.bypassPreventClose) {
            return;
          }
          close();
        },
        [close],
      );

      const open = useCallback(
        (params: Partial<SheetContentProps> | SyntheticEvent | undefined) => {
          if (!isSyntheticEvent(params)) {
            setState(params);
          }
          setOpen();
        },
        [setOpen],
      ) as ExternalOpenFunctionType<PartialProps>;

      return {
        isOpen,
        open,
        close: handleClose,
        element: (
          <WrappedComponent
            open={isOpen}
            onClose={handleClose}
            {...({
              ...pickBy(props, (element) => element !== undefined),
              ...pickBy(state, (element) => element !== undefined),
            } as any)}
            setPreventClose={setPreventClose}
          />
        ),
      };
    }

    const StaticSheetContent = ({
      props,
      removeElement,
    }: {
      props: any;
      removeElement: () => void;
    }) => {
      const [closed, setClosed] = useState(false);
      const preventClose = useRef(false);

      const close = useCallback(() => {
        setClosed(true);
      }, [setClosed]);

      const handleClose = useCallback(() => {
        if (preventClose.current) {
          return;
        }
        close();
      }, [close]);

      const handlePreventClose = useCallback(() => {
        preventClose.current = true;
      }, []);

      return (
        <WrappedComponent
          open={!closed}
          onClose={handleClose}
          onClosed={removeElement}
          setPreventClose={handlePreventClose}
          {...props}
        />
      );
    };

    WrappedComponent.use = useSheet;
    WrappedComponent.useOrphan = <
      PartialProps extends Partial<SheetContentProps>,
    >(
      props: PartialProps,
    ): {
      open: (
        ...args: RequiredKeys<
          Without<SheetContentProps, PartialProps>
        > extends never
          ? [] | [Partial<SheetContentProps>] | [params: SyntheticEvent]
          : [params: Without<SheetContentProps, PartialProps>]
      ) => void;
    } => {
      const { addElement, removeElement } = useContext(PortalManagementContext);
      const key = usePortalKey();

      const portalHostname =
        staticProps?.portalHostname ?? DEFAULT_PORTAL_HOSTNAME;

      const handleOpen = useCallback(
        (args: Partial<SheetContentProps> | SyntheticEvent | undefined) => {
          const sanitizedProps = {
            ...pickBy(props, (field) => field !== undefined),
            ...(isSyntheticEvent(args)
              ? {}
              : pickBy(args, (field) => field !== undefined)),
          } as any;

          const removeThisElement = () => {
            removeElement(key, portalHostname);
          };

          const element = (
            <StaticSheetContent
              props={sanitizedProps}
              removeElement={removeThisElement}
            />
          );

          addElement(element, key, portalHostname);
        },
        [addElement, key, portalHostname, props, removeElement],
      );

      return { open: handleOpen as any };
    };

    WrappedComponent.use = useSheet;

    WrappedComponent.withDefaults = <Se extends PartialHostProps>(props: Se) =>
      Wrap(Component, props);

    return WrappedComponent;
  }

  // Function to create an inlined sheet component
  const create = <P = HostProps>(
    createFn: (p: {
      ActionSheetContent: typeof ActionSheetContent;
      ActionSheetItem: typeof ActionSheetItem;
      ActionSheetGroup: typeof ActionSheetGroup;
      ActionSheetSeparator: typeof ActionSheetSeparator;
    }) => React.FC<P & HostProps>,
  ) => {
    return Wrap(
      createFn({
        ActionSheetContent,
        ActionSheetItem,
        ActionSheetGroup,
        ActionSheetSeparator,
      }),
    );
  };

  return { create };
}
