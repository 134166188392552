import { EditOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { Api } from "@kraaft/shared/core/services/api";

export const editPresentationDescriptionOperation =
  EditOneOperationBuilder.create<LibrarySchema>()
    .payload<{ description: string }>()
    .expected((aggregate, payload) => {
      aggregate.presentation.description = payload.description;
      return aggregate;
    })
    .mutate(async (payload) => {
      const { updatedAt } = await Api.setLibrarySchemaPresentationDescription({
        librarySchemaId: payload.id,
        description: payload.description,
      });

      return { updatedAt };
    });
