import { StyleSheet, View } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { MessageMatchProps } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/messageMatchProps";
import { SearchTextResult } from "@kraaft/shared/components/conversation/searchPanel/messageSearchResult/searchTextResult";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { conditionalObject } from "@kraaft/shared/core/utils";
import { Image, PixelSize, Radius, Spacing } from "@kraaft/ui";

export const MessageImageMatch = ({
  message,
  searchResult,
}: MessageMatchProps<Messages.Image>) => {
  const fileUrl = message.attachment.original.downloadUrl;

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        contentFit="cover"
        preferredSize="thumbnail"
        source={fileUrl}
      />
      <SearchTextResult
        style={styles.text}
        text={message.attachment.caption}
        result={searchResult}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: Spacing.S8,
  },
  image: {
    height: PixelSize.S24,
    aspectRatio: 1,
    borderRadius: Radius.MINI,
  },
  text: {
    ...conditionalObject({ wordBreak: "break-all" }, !isNative()),
    flexShrink: 1,
    marginTop: Spacing.S4,
  },
});
