import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DirectoryCarousel } from "@kraaft/shared/core/modules/directory/components/directoryCarousel";
import { deselectMessage } from "@kraaft/shared/core/modules/message/messageActions";
import { MiniMediaCarousel } from "@kraaft/shared/core/modules/miniMedia/components/miniMediaCarousel";
import { ModularFolderCarousel } from "@kraaft/shared/core/modules/modularFolder/components/modularFolderCarousel";
import { ModularFolderActions } from "@kraaft/shared/core/modules/modularFolder/modularFolderActions";
import { RoomCarousel } from "@kraaft/shared/core/modules/room/components/roomCarousel";
import {
  subscribeToRoom,
  subscribeToRoomUsers,
  unsubscribeFromRoom,
  unsubscribeFromRoomUsers,
} from "@kraaft/shared/core/modules/room/roomActions";
import {
  selectIsCreatingRoom,
  selectRoom,
} from "@kraaft/shared/core/modules/room/roomSelectors";
import {
  getExistingRoomRecordField,
  getRoomTrackingType,
} from "@kraaft/shared/core/modules/room/roomUtils";
import { useTrackCurrentRoom } from "@kraaft/shared/core/services/analytics/useTrackCurrentRoom";
import {
  useTrackEventOnceWithData,
  useTrackPage,
} from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Preloader } from "@kraaft/ui";
import { Sidebar } from "@kraaft/web/src/components/sidebar";
import {
  useNavigationService,
  useRouteService,
} from "@kraaft/web/src/core/services/navigation/useNavigationService";
import { ConversationPanel } from "@kraaft/web/src/views/messenger/conversationPanel";
import { MapPreview } from "@kraaft/web/src/views/messenger/mapPreview";
import { useConversationLayoutInfo } from "@kraaft/web/src/views/messenger/useConversationLayoutInfo";

import { DetailsDrawer } from "./detailsDrawer";

interface Props {
  roomId: string;
}

// eslint-disable-next-line complexity
const RoomContainer = (props: Props) => {
  const { roomId } = props;
  const dispatch = useDispatch();
  const navigationService = useNavigationService();
  const routeService = useRouteService();

  const room = useSelector(selectRoom(roomId));

  const isCreatingRoom = useSelector(selectIsCreatingRoom);

  const { isDetailsDrawerOpen, shouldHideConversation } =
    useConversationLayoutInfo();

  useEffect(() => {
    dispatch(subscribeToRoomUsers({ roomId }));
    dispatch(subscribeToRoom({ roomId }));
    dispatch(ModularFolderActions.subscribeForRoom({ roomId }));

    return () => {
      dispatch(unsubscribeFromRoomUsers({ roomId }));
      dispatch(unsubscribeFromRoom({ roomId }));
      dispatch(ModularFolderActions.unsubscribeForRoom({ roomId }));
    };
  }, [dispatch, roomId]);

  useTrackCurrentRoom(roomId);
  useTrackPage("Conversation", {
    identifier: roomId,
    shouldTrack: !isDetailsDrawerOpen,
  });
  useTrackPage("ConversationDetails", {
    identifier: roomId,
    shouldTrack: isDetailsDrawerOpen,
  });
  useTrackEventOnceWithData(
    room
      ? {
          eventName: "View Conversation",
          room_id: roomId,
          room_name: getExistingRoomRecordField(
            room.record.properties,
            "title",
            "",
          ),
          type: getRoomTrackingType(room),
        }
      : undefined,
  );

  const handleDetailsIconClick = useCallback(() => {
    navigationService.toggleConversationDetail({
      roomId,
      open: !isDetailsDrawerOpen,
    });
  }, [isDetailsDrawerOpen, navigationService, roomId]);

  const handleCloseSelection = useCallback(() => {
    if (roomId) {
      dispatch(deselectMessage({ roomId, all: true }));
    }
  }, [dispatch, roomId]);

  const isMapOpen = routeService.isMapOpen();

  if (isCreatingRoom) {
    return <Preloader absoluteFill transparent />;
  }

  return (
    <>
      {!shouldHideConversation && isMapOpen && <MapPreview roomId={roomId} />}
      {!isMapOpen && !shouldHideConversation && (
        <ConversationPanel
          roomId={roomId}
          onCloseSelection={handleCloseSelection}
          onDetailsIconClick={handleDetailsIconClick}
          isDetailsDrawerOpen={isDetailsDrawerOpen}
        />
      )}
      {routeService.isRoomsMapOpen() || (
        <Sidebar
          handleSide="left"
          collapsed={!isDetailsDrawerOpen}
          fullWidth={shouldHideConversation}
        >
          <DetailsDrawer
            roomId={roomId}
            hideConversation={shouldHideConversation}
          />
        </Sidebar>
      )}

      <RoomCarousel roomId={roomId} />
      <ModularFolderCarousel />
      <MiniMediaCarousel />
      <DirectoryCarousel />
    </>
  );
};

export { RoomContainer };
