import { createAction } from "@reduxjs/toolkit";

import { PoolAddons } from "../poolAddons.state";

export class PoolAddonsActions {
  static set = createAction<{ poolAddons: PoolAddons | undefined }>(
    "@poolAddons/set",
  );
  static subscribe = createAction<{ poolId: string }>("@poolAddons/subscribe");
  static unsubscribe = createAction<{ poolId: string }>(
    "@poolAddons/unsubscribe",
  );
  static reset = createAction("@poolAddons/reset");
}
