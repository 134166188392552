import { DeclareOfflineFeature } from "@kraaft/offline";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { deleteOperation } from "@kraaft/shared/core/modules/librarySchema/operations/delete.operation";
import { editOperation } from "@kraaft/shared/core/modules/librarySchema/operations/edit.operation";
import { editPresentationCoverPictureOperation } from "@kraaft/shared/core/modules/librarySchema/operations/editPresentationCoverPicture.operation";
import { editPresentationDescriptionOperation } from "@kraaft/shared/core/modules/librarySchema/operations/editPresentationDescription.operation";
import { editPresentationSampleReportOperation } from "@kraaft/shared/core/modules/librarySchema/operations/editPresentationSampleReport.operation";
import { editPresentationVideoOperation } from "@kraaft/shared/core/modules/librarySchema/operations/editPresentationVideo.operation";
import { reorderOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reorder.operation";
import { addReportTemplateOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/add.operation";
import { deleteReportTemplateOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/delete.operation";
import { renameReportTemplateOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/rename.operation";
import { reorderReportTemplatesOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/reorder.operation";
import { replaceReportTemplateFileOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/replaceFile.operation";
import { toggleReportTemplateOperation } from "@kraaft/shared/core/modules/librarySchema/operations/reportTemplates/toggle.operation";
import { addSchemaColumnOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/addColumn.operation";
import { addSchemaSectionOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/addSection.operation";
import { deleteSchemaColumnOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/deleteColumn.operation";
import { duplicateSchemaElementOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/duplicateElement.operation";
import { editSchemaElementDescriptionOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/editDescription.operation";
import { editSchemaMetadataOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/editMetadata.operation";
import { editSchemaSectionOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/editSection.operation";
import { renameSchemaOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/rename.operation";
import { renameSchemaColumnOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/renameColumn.operation";
import { reorderSchemaElementOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/reorderElement.operation";
import { setSchemaHighlightedCheckboxOperation } from "@kraaft/shared/core/modules/librarySchema/operations/schema/setHighlightedCheckbox.operation";
import { setCompanyOperation } from "@kraaft/shared/core/modules/librarySchema/operations/setCompany.operation";
import { setTagsOperation } from "@kraaft/shared/core/modules/librarySchema/operations/setTags.operation";
import { editColumnDefinitionOperation } from "@kraaft/shared/core/modules/schema/operations/editColumnDefinition.operation";
import { editConditionOperation } from "@kraaft/shared/core/modules/schema/operations/editCondition.operation";

import { taskManagerUtils } from "../../store/offline/taskManagerUtils.provider";

export const LibrarySchemaOfflineFeature = DeclareOfflineFeature<LibrarySchema>(
  "LibrarySchema",
)(
  {
    // Report template
    addReportTemplate: addReportTemplateOperation,
    deleteReportTemplate: deleteReportTemplateOperation,
    renameReportTemplate: renameReportTemplateOperation,
    reorderReportTemplates: reorderReportTemplatesOperation,
    replaceReportTemplateFile: replaceReportTemplateFileOperation,
    toggleReportTemplate: toggleReportTemplateOperation,

    // Schema
    addSchemaColumn: addSchemaColumnOperation,
    addSchemaSection: addSchemaSectionOperation,
    deleteSchemaColumn: deleteSchemaColumnOperation,
    duplicateSchemaElement: duplicateSchemaElementOperation,
    editSchemaColumnDefinition: editColumnDefinitionOperation,
    editSchemaElementCondition: editConditionOperation,
    editSchemaMetadata: editSchemaMetadataOperation,
    editSchemaSection: editSchemaSectionOperation,
    renameSchema: renameSchemaOperation,
    renameSchemaColumn: renameSchemaColumnOperation,
    reorderSchemaElement: reorderSchemaElementOperation,
    setSchemaHighlightedCheckbox: setSchemaHighlightedCheckboxOperation,
    editSchemaElementDescription: editSchemaElementDescriptionOperation,

    // Library schema
    delete: deleteOperation,
    edit: editOperation,
    editPresentationCoverPicture: editPresentationCoverPictureOperation,
    editPresentationDescription: editPresentationDescriptionOperation,
    editPresentationSampleReport: editPresentationSampleReportOperation,
    editPresentationVideo: editPresentationVideoOperation,
    reorder: reorderOperation,
    setCompany: setCompanyOperation,
    setTags: setTagsOperation,
  },
  taskManagerUtils.create("LibrarySchema"),
);
