import { Listeners } from "@kraaft/helper-functions";
import {
  OfflineTaskManagerInterface,
  TaskResult,
} from "@kraaft/shared/core/services/backgroundService/offlineTaskManager";

import { Logger, registeredLogger, registeredTracking } from "../logger";
import { Task } from "./task";
import { TaskStore } from "./taskStore";
import {
  TaskExecutionError,
  TaskExecutionStopReason,
  TaskExecutor,
  TaskFailStrategy,
  TaskProcessor,
} from "./taskStore.types";

export interface NamedTaskManager extends TaskExecutor, TaskProcessor {
  name: string;
}

export class TaskManager
  implements TaskExecutor, TaskProcessor, OfflineTaskManagerInterface
{
  constructor(
    public readonly name: string,
    public readonly taskStore: TaskStore,
    private readonly handleTaskError: (error: any) => TaskFailStrategy,
  ) {
    this.logger = registeredLogger.current.createSubLogger([
      "SimpleTaskManager",
      this.name,
    ]);
  }

  private lastStopReason: TaskExecutionStopReason | undefined;

  logger: Logger;

  onTaskSucceeded = new Listeners<(task: Task, result: any) => void>();
  onTaskAdded = new Listeners<(task: Task) => void>();
  onTaskFailed = new Listeners<(task: Task, error: any) => void>();
  onTaskDelayed = new Listeners<(task: Task) => void>();
  onTaskSkipped = new Listeners<(task: Task, error: any) => void>();

  private shouldStop = false;
  private registry: Record<string, (payload: any, task: Task) => any> = {};
  private executing: Promise<TaskResult> | undefined;

  async getQueue() {
    const all = await this.taskStore.loadAll();
    return all;
  }

  async getLastStopReason() {
    return await this.taskStore.getLastStopReason();
  }

  edit(editor: (task: Task) => void) {
    return this.taskStore.edit(editor);
  }

  async enqueue(task: Task) {
    await this.onTaskAdded.trigger(task);
    await this.taskStore.append(task);
    if (this.lastStopReason === "halt") {
      return;
    }
    this.execute().catch(console.error);
  }

  register(name: string, handler: (payload: any, task: Task) => Promise<any>) {
    this.registry[name] = handler;
  }

  private async handle(task: Task) {
    const handler = this.registry[task.name];
    if (!handler) {
      return;
    }
    return await handler(task.payload, task);
  }

  private async setLastStopReason(reason: TaskExecutionStopReason) {
    this.lastStopReason = reason;
    await this.taskStore.setLastStopReason(reason);
  }

  // eslint-disable-next-line complexity
  private async executeWithoutDeduplication(): Promise<TaskResult> {
    registeredTracking.current({
      feature: this.name,
      level: "info",
      log: "ExecuteWithoutDeduplication: starting",
    });
    let atLeastOne = false;
    while (true) {
      const task = await this.taskStore.take();
      if (!task) {
        if (atLeastOne) {
          await this.setLastStopReason("finished");
          registeredTracking.current({
            feature: this.name,
            level: "info",
            log: "ExecuteWithoutDeduplication: finished",
            data: {
              state: "success",
            },
          });
          return "success";
        }
        registeredTracking.current({
          feature: this.name,
          level: "info",
          log: "ExecuteWithoutDeduplication: finished",
          data: {
            state: "empty",
          },
        });
        return "empty";
      }
      atLeastOne = true;
      if (this.shouldStop) {
        await this.setLastStopReason("timeout");
        registeredTracking.current({
          feature: this.name,
          level: "info",
          log: "ExecuteWithoutDeduplication: finished",
          data: {
            state: "timed-out",
          },
        });
        return "timed-out";
      }
      try {
        const result = await this.handle(task);
        await this.onTaskSucceeded.trigger(task, result);
        await this.taskStore.delete((t) => t.id === task.id);
      } catch (error) {
        const failureStrategy =
          error instanceof TaskExecutionError
            ? error.strategy
            : this.handleTaskError(error);

        const name = typeof error.name === "string" ? error.name : "no name";
        const message =
          typeof error.message === "string" ? error.message : "no details";

        this.logger.warn(
          `Task error, Name(${
            task.name
          }) Strategy(${failureStrategy}) ErrorName(${name}) ErrorMessage(${message}) Error(${error.toString()})`,
        );

        registeredTracking.current({
          feature: this.name,
          level: "info",
          log: "ExecuteWithoutDeduplication: task error",
          data: {
            taskName: task.name,
            failureStrategy,
            errorName: name,
            errorMessage: message,
            errorAsString: error.toString(),
          },
        });

        if (failureStrategy === "delete-related") {
          await this.onTaskFailed.trigger(task, error);
          await this.taskStore.delete((t) => {
            return t.dependencies.some((dependency) =>
              task.dependencies.includes(dependency),
            );
          });
        } else if (failureStrategy === "skip") {
          await this.onTaskSkipped.trigger(task, error);
          await this.taskStore.delete((t) => t.id === task.id);
        } else if (failureStrategy === "halt") {
          await this.onTaskDelayed.trigger(task);
          await this.setLastStopReason("halt");
          return "partial-success";
        } else {
          this.logger.error(`Unknown strategy ${failureStrategy}`);
        }
      }
    }
  }

  async execute() {
    this.shouldStop = false;
    if (this.executing) {
      return this.executing;
    }
    this.executing = this.executeWithoutDeduplication().then((result) => {
      this.executing = undefined;
      return result;
    });
    return this.executing;
  }

  async stop() {
    this.shouldStop = true;
    return true;
  }

  async reset() {
    return this.taskStore.delete(() => true);
  }
}
