import { GeolocatedElement } from "@kraaft/shared/components/geolocation/types";
import { RoomFilters } from "@kraaft/shared/core/modules/filter/filterState";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { FirestoreRoomCard } from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import {
  AnyRoomCard,
  RoomCardCursor,
  RoomCardQueryContext,
  RoomCardWithGeolocation,
  RoomMemberCard,
  RoomPoolCard,
} from "@kraaft/shared/core/modules/roomCard/roomCard.state";
import { GeoLocation } from "@kraaft/shared/core/types";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";
import { nullId } from "@kraaft/shared/core/utils/utils";

export class RoomCardUtils {
  static computeSubscriptionFilterId(payload: RoomCardQueryContext) {
    const filterId =
      (payload.filterIdPrefix ? `${payload.filterIdPrefix}–` : "") +
      ("filters" in payload
        ? `visibility=${payload.filters?.visibility}, statusId=${
            payload.filters?.statusId
          }, labelIds=${payload.filters?.labelIds.join(",")}, responsibleId=${
            payload.filters?.responsibleId
          }, showArchived=${payload.filters?.showArchived}, readingStatus=${
            payload.filters.readingStatus
          }`
        : "");
    return `user-id=${payload.userId}, sub-poolId=${payload.poolId}, ${filterId}`;
  }

  static createCursorFromFirestoreRoomCard(
    firestoreRoomCard: FirestoreRoomCard | undefined,
  ) {
    return firestoreRoomCard
      ? ({
          id: firestoreRoomCard.id,
          lastEventAt: firestoreRoomCard.lastEventAt,
        } satisfies RoomCardCursor)
      : undefined;
  }

  static fromRoom(room: Room): RoomPoolCard {
    return {
      type: "pool",
      poolId: room.poolId,
      id: room.id,
      roomId: room.id,
      pinned: false,
      emoji: room.emoji,
      title: getExistingRoomRecordField(room.record.properties, "title") ?? "",
      responsibleId:
        getExistingRoomRecordField(
          room.record.properties,
          "responsible",
        )?.[0] ?? nullId,
      statusId:
        getExistingRoomRecordField(room.record.properties, "status")?.[0] ??
        nullId,
      labelIds:
        getExistingRoomRecordField(room.record.properties, "labels") ?? [],
      isEveryoneRoom: false,
      archived: false,
      lastMessageInfo: room.lastMessage
        ? {
            id: room.lastMessage.id,
            type: room.lastMessage.type,
            text: room.lastMessage.text,
            createdAt: room.lastMessage.createdAt,
            senderId: room.lastMessage.senderId,
          }
        : undefined,
      properties: room.record.properties,
      lastEventAt: room.lastEventAt,
      roomCreatedAt: room.createdAt,
      roomCreatedBy: room.creatorUserId,
      isArchivedForAll: false,
      hasExternalMembers: false,
      visibility:
        room.visibility && room.visibility !== "administrator"
          ? room.visibility
          : "pool",
    };
  }

  static hasGeolocation(
    roomCard: AnyRoomCard,
  ): roomCard is RoomCardWithGeolocation {
    const geolocation = getExistingRoomRecordField(
      roomCard.properties,
      "geolocation",
    );

    return geolocation !== undefined && geolocation !== null;
  }

  static filterNonGeolocatedRoomCards(roomCards: AnyRoomCard[]) {
    return roomCards.filter(this.hasGeolocation) as RoomCardWithGeolocation[];
  }

  static formatGeolocatedRoomCards(
    roomCards: RoomCardWithGeolocation[],
  ): GeolocatedElement<RoomCardWithGeolocation>[] {
    return roomCards.map((roomCard) => ({
      ref: roomCard,
      id: roomCard.roomId,
      geolocation: getExistingRoomRecordField(
        roomCard.properties,
        "geolocation",
      ) as GeoLocation,
    }));
  }

  static isMemberCard(roomCard: AnyRoomCard): roomCard is RoomMemberCard {
    return roomCard.type === "member";
  }

  static filter({
    filters,
    justReadRoomId,
    pinned,
  }: { filters: RoomFilters; pinned: boolean; justReadRoomId?: string }) {
    // eslint-disable-next-line complexity
    return (roomCard: AnyRoomCard) => {
      const {
        visibility,
        statusId,
        labelIds,
        responsibleId,
        searchText,
        readingStatus,
      } = filters;

      if (visibility !== "superadmin") {
        if (
          visibility === "all" &&
          roomCard.type === "pool" &&
          roomCard.visibility === "private"
        ) {
          return false;
        }

        if (visibility === "member" && roomCard.type !== "member") {
          return false;
        }
      }

      if (roomCard.archived !== filters.showArchived) {
        return false;
      }

      if (statusId && roomCard.statusId !== statusId) {
        return false;
      }

      if (
        labelIds.length > 0 &&
        !roomCard.labelIds.some((labelId) => labelIds.includes(labelId))
      ) {
        return false;
      }

      if (
        responsibleId !== undefined &&
        roomCard.responsibleId !== responsibleId
      ) {
        return false;
      }

      const normalizedSearchText = searchText
        ? normalizeTextForSearch(searchText)
        : undefined;

      if (
        normalizedSearchText &&
        !normalizeTextForSearch(roomCard.title).includes(normalizedSearchText)
      ) {
        return false;
      }

      if (
        readingStatus === "read" &&
        roomCard.type === "member" &&
        roomCard.unread
      ) {
        return false;
      }

      if (
        readingStatus === "unread" &&
        (roomCard.type === "pool" || !roomCard.unread)
      ) {
        if (justReadRoomId !== roomCard.id) {
          return false;
        }
      }

      return roomCard.pinned === pinned;
    };
  }
}
