import { keyBy, memoize } from "lodash";
import { createSelector } from "reselect";

import { compactMap } from "@kraaft/helper-functions";
import {
  MiniMedia,
  MiniMediaOfType,
} from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { onlyMiniMediaOfType } from "@kraaft/shared/core/modules/miniMedia/miniMedia.utils";
import { RoomSelectors } from "@kraaft/shared/core/modules/room/roomSelectors";
import {
  getGeolocatedMiniMedias,
  miniImageHasGeolocation,
} from "@kraaft/shared/core/modules/room/roomUtils";
import { RootState } from "@kraaft/shared/core/store";
import { lodashKeyResolver } from "@kraaft/shared/core/utils";
import { normalizeTextForSearch } from "@kraaft/shared/core/utils/stringUtils";

const selectMiniMediaState = (state: RootState) => state.miniMedia;

const selectMiniMedias = memoize((roomId: string) =>
  createSelector(selectMiniMediaState, (state) => state.medias[roomId]?.list),
);

export const selectMiniMediasIsLimitedResultForType = memoize(
  (roomId: string, type: MiniMedia["type"]) =>
    createSelector(
      selectMiniMediaState,
      (state) => state.medias[roomId]?.isLimitedResult[type] ?? false,
    ),
);

const EMPTY_LIST: MiniMedia[] = [];
export const selectMiniMediasOfType = memoize(
  <T extends MiniMedia["type"]>(roomId: string, types: T[]) =>
    createSelector(selectMiniMedias(roomId), (medias) =>
      onlyMiniMediaOfType(medias ?? EMPTY_LIST, types),
    ),
  lodashKeyResolver,
);

export const selectGeolocatedMiniMedias = memoize((roomId: string) =>
  createSelector(
    selectMiniMedias(roomId),
    (medias) => medias && getGeolocatedMiniMedias(medias),
  ),
);

export const selectMiniMediasCount = memoize(
  (roomId: string, type: MiniMedia["type"][]) =>
    createSelector(
      selectMiniMediasOfType(roomId, type),
      (media) => media?.length ?? 0,
    ),
  lodashKeyResolver,
);

export const selectShouldDisplayShowAllMiniImages = memoize((roomId: string) =>
  createSelector(
    selectMiniMediasOfType(roomId, ["image"]),
    RoomSelectors.selectMapVisibleMarkerIds(roomId),
    RoomSelectors.selectMapMinZoomReached(roomId),
    (miniMedias, visibleMarkerIds, isMinZoom) => {
      const mediasWithGeolocation = miniMedias?.filter(miniImageHasGeolocation);

      return (
        !isMinZoom &&
        (mediasWithGeolocation?.length ?? 0) !== visibleMarkerIds.length
      );
    },
  ),
);

export const selectMiniImagesWithMapContext = memoize(
  (roomId: string, filterWithMap: boolean) =>
    createSelector(
      selectMiniMediasOfType(roomId, ["image"]),
      RoomSelectors.selectMapVisibleMarkerIds(roomId),
      RoomSelectors.selectMapMinZoomReached(roomId),
      (miniMedias, visibleMarkerIds, isMinZoom) => {
        const mediasWithGeolocation = miniMedias?.filter(
          miniImageHasGeolocation,
        );
        const filteredMedias = miniMedias?.filter((miniMedia) =>
          visibleMarkerIds.includes(miniMedia.id),
        );
        if (
          !filterWithMap ||
          mediasWithGeolocation?.length === filteredMedias?.length ||
          isMinZoom
        ) {
          return miniMedias;
        }
        return filteredMedias;
      },
    ),
  lodashKeyResolver,
);

export const selectMiniMediaCarousel = createSelector(
  selectMiniMediaState,
  (state) => state.carousel,
);

export const selectMiniMediaCarouselData = memoize((roomId: string) =>
  createSelector(
    selectMiniMediaCarousel,
    selectMiniMediasOfType(roomId, ["image", "video"]),
    (carousel, medias) => {
      if (!carousel.show) {
        console.warn("This should not be called if carousel is not open");
        return undefined;
      }
      if (carousel.roomId !== roomId) {
        return undefined;
      }

      if (carousel.mediaIds) {
        const mediasDict = keyBy(medias, (media) => media.messageId);
        const wantedMedias = compactMap(
          carousel.mediaIds,
          (mediaId) => mediasDict[mediaId],
        );
        const initialIndex = wantedMedias.findIndex(
          (media) => media.messageId === carousel.messageId,
        );

        return {
          initialIndex: initialIndex === -1 ? 0 : initialIndex,
          medias: wantedMedias,
        };
      }

      const initialIndex = medias.findIndex(
        (media) => media.messageId === carousel.messageId,
      );

      return {
        initialIndex: initialIndex === -1 ? 0 : initialIndex,
        medias,
      };
    },
  ),
);

const selectSearch = createSelector(
  selectMiniMediaState,
  (state) => state.search,
);

export const selectSearchedMediasOfType = memoize(
  <T extends MiniMedia["type"]>(roomId: string, type: T) =>
    createSelector(
      selectMiniMediasOfType(roomId, [type]),
      selectSearch,
      (medias, search) => {
        const normalizedSearch = normalizeTextForSearch(search);
        return (medias || []).filter((media) =>
          normalizeTextForSearch(media.name).includes(normalizedSearch),
        ) as MiniMediaOfType<T>[];
      },
    ),
  lodashKeyResolver,
);
