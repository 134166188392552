import { orderBy } from "lodash";
import { useMemo } from "react";
import { assert } from "ts-essentials";

import { isValueInRange } from "@kraaft/helper-functions";
import type { UnknownObject } from "@kraaft/shared/core/types";

import {
  ORDERABLE_LIST_DRAG_ITEM_TYPE,
  type OrderableListDragItem,
  type OrderableListRow,
  type OrderableListRows,
  type OrderedListRows,
  type ReorderedIds,
  type ReorderPlacement,
  type SourceType,
  type TargetType,
  type TransitionDirection,
} from "./orderableList.types";

export function orderList<T extends OrderableListRow>(
  listRows: OrderableListRows<T>,
): OrderedListRows<T> {
  return orderBy(Object.entries(listRows), ([, data]) => data.index).map(
    ([key, data], index) => ({ key, computedIndex: index, data }),
  );
}

export function getNewSourceIndex(
  placement: ReorderPlacement,
  sourceIndex: number,
  targetIndex: number,
) {
  if (sourceIndex < targetIndex) {
    return placement === "before" ? targetIndex - 1 : targetIndex;
  }
  return placement === "before" ? targetIndex : targetIndex + 1;
}

function getNewIndexBefore(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (sourceIndex < targetIndex) {
    return getNewIndexBeforeAsc(row, sourceIndex, targetIndex);
  }
  return getNewIndexBeforeDesc(row, sourceIndex, targetIndex);
}

function getNewIndexBeforeAsc(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > sourceIndex && row.index < targetIndex) {
    return row.index - 1;
  }
  return undefined;
}

function getNewIndexBeforeDesc(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index >= targetIndex && row.index < sourceIndex) {
    return row.index + 1;
  }
  return undefined;
}

function getNewIndexAfter(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (sourceIndex < targetIndex) {
    return getNewIndexAfterAsc(row, sourceIndex, targetIndex);
  }
  return getNewIndexAfterDesc(row, sourceIndex, targetIndex);
}

function getNewIndexAfterAsc(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > sourceIndex && row.index <= targetIndex) {
    return row.index - 1;
  }
  return undefined;
}

function getNewIndexAfterDesc(
  row: OrderableListRow,
  sourceIndex: number,
  targetIndex: number,
) {
  if (row.index > targetIndex && row.index < sourceIndex) {
    return row.index + 1;
  }
  return undefined;
}

/** hard to reduce complexity and keep it readable here */
// eslint-disable-next-line complexity
export function getReorderDetails<T extends OrderableListRow>(
  rows: OrderableListRows<T>,
  sourceKey: string,
  placement: ReorderPlacement,
  targetKey: string,
): { afterId: string | undefined; reorderedIds: ReorderedIds } {
  const source = rows[sourceKey];
  const target = rows[targetKey];
  const reorderedIds: ReorderedIds = {};

  assert(source !== undefined, "getReorderDetails : source is missing");
  assert(target !== undefined, "getReorderDetails : target is missing");

  const sourceIndex = source.index;
  const targetIndex = target.index;
  const orderedRows = orderList(rows);
  let afterId: string | undefined =
    placement === "after" ? targetKey : undefined;

  for (const row of orderedRows) {
    if (
      placement === "before" &&
      row.data.index < targetIndex &&
      row.key !== sourceKey
    ) {
      afterId = row.key;
    }

    if (isValueInRange(row.data.index, [targetIndex, sourceIndex])) {
      let reorderedId: number | undefined;
      if (row.key === sourceKey) {
        reorderedId = getNewSourceIndex(placement, sourceIndex, targetIndex);
      } else if (placement === "before") {
        reorderedId = getNewIndexBefore(row.data, sourceIndex, targetIndex);
      } else if (placement === "after") {
        reorderedId = getNewIndexAfter(row.data, sourceIndex, targetIndex);
      }
      if (reorderedId !== undefined) {
        reorderedIds[row.key] = reorderedId;
      }
    }
  }

  return { afterId, reorderedIds };
}

export function getAfterId<T extends OrderableListRow>(
  rows: OrderableListRows<T>,
  sourceKey: string,
  placement: ReorderPlacement,
  targetKey: string,
) {
  const source = rows[sourceKey];
  const target = rows[targetKey];

  assert(source !== undefined, "getReorderDetails : source is missing");
  assert(target !== undefined, "getReorderDetails : target is missing");

  const targetIndex = target.index;
  const orderedRows = orderList(rows);
  let afterId: string | undefined =
    placement === "after" ? targetKey : undefined;

  for (const row of orderedRows) {
    if (
      placement === "before" &&
      row.data.index < targetIndex &&
      row.key !== sourceKey
    ) {
      afterId = row.key;
    }
  }

  return afterId;
}

function getTransitionDirectionBefore(
  index: number,
  draggedRowIndex: number,
  hoveredRowIndex: number,
): TransitionDirection | undefined {
  if (index < draggedRowIndex && index >= hoveredRowIndex) {
    return "down";
  }
  if (index > draggedRowIndex && index < hoveredRowIndex) {
    return "up";
  }
  return undefined;
}

function getTransitionDirectionAfter(
  index: number,
  draggedRowIndex: number,
  hoveredRowIndex: number,
): TransitionDirection | undefined {
  if (index < draggedRowIndex && index > hoveredRowIndex) {
    return "down";
  }
  if (index > draggedRowIndex && index <= hoveredRowIndex) {
    return "up";
  }
  return undefined;
}

// eslint-disable-next-line complexity
export function getDirectionFromIndexes(
  index: number,
  draggedRowIndex: number | undefined,
  hoveredRowIndex: number | undefined,
  hoveredRowPosition: ReorderPlacement | undefined,
  lastIndex: number,
  isExternal: boolean,
): TransitionDirection | undefined {
  if (
    hoveredRowIndex === undefined ||
    (!isExternal && index === draggedRowIndex)
  ) {
    return undefined;
  }

  if (isExternal || draggedRowIndex === undefined) {
    if (hoveredRowPosition === "before") {
      if (index >= hoveredRowIndex) {
        return "down";
      }
      if (index < hoveredRowIndex) {
        return undefined;
      }
    } else if (hoveredRowPosition === "after") {
      if (isExternal && hoveredRowIndex === lastIndex) {
        return "up";
      }
      if (index > hoveredRowIndex) {
        return "down";
      }
      if (index <= hoveredRowIndex) {
        return undefined;
      }
    }
    return undefined;
  }

  if (hoveredRowPosition === "before") {
    return getTransitionDirectionBefore(
      index,
      draggedRowIndex,
      hoveredRowIndex,
    );
  }
  if (hoveredRowPosition === "after") {
    return getTransitionDirectionAfter(index, draggedRowIndex, hoveredRowIndex);
  }
  return undefined;
}

function arrayFromTargetType(targetType: TargetType) {
  if (Array.isArray(targetType)) {
    return targetType;
  }
  return [targetType];
}

export function useDroppableAcceptTypes(
  sourceType: SourceType,
  targetType: TargetType | undefined,
) {
  return useMemo(
    () =>
      targetType !== undefined
        ? [sourceType, ...arrayFromTargetType(targetType)]
        : [sourceType],
    [targetType, sourceType],
  );
}

export function isOrderableListDragItem(
  item: UnknownObject,
): item is OrderableListDragItem<UnknownObject> {
  return item.type === ORDERABLE_LIST_DRAG_ITEM_TYPE;
}
