import {
  type DocumentData,
  DocumentSnapshot,
  type QueryDocumentSnapshot,
} from "firebase/firestore";

export {
  and,
  collection,
  doc,
  documentId,
  DocumentReference,
  endBefore,
  type FirestoreError,
  getDoc,
  getDocs,
  getDocsFromCache,
  limit,
  onSnapshot,
  or,
  orderBy,
  type Query,
  query,
  type QueryFilterConstraint,
  type QuerySnapshot,
  startAfter,
  where,
} from "firebase/firestore";

export { type DocumentData, DocumentSnapshot, type QueryDocumentSnapshot };

export function documentSnapshotExist(
  documentSnapshot: DocumentSnapshot,
): documentSnapshot is QueryDocumentSnapshot<DocumentData, DocumentData> {
  return documentSnapshot.exists();
}
