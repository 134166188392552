import { createSelector } from "@reduxjs/toolkit";
import memoize from "lodash/memoize";

import { hasRoomExternalMembers } from "@kraaft/shared/core/modules/room/roomRecordUtils";
import { DEFAULT_ROOM_MAP_DISPLAY_MODE } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import {
  selectRoom,
  selectRoomMembers,
  selectRoomsByPool,
  selectRoomUserHistory,
} from "@kraaft/shared/core/modules/room/selectors/directSelectors";
import { selectUsers } from "@kraaft/shared/core/modules/user/userSelectors";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { RootState } from "@kraaft/shared/core/store";
import { compareStrings, getEmptyArray } from "@kraaft/shared/core/utils";

import { getUserPoolRole } from "../../pool/poolUtil";
import { getUsername } from "../../user/userUtils";

export class RoomSelectors {
  private static selectMapContext = ({ room }: RootState) => room.ui.map;

  static selectAllOfPoolAlphaSorted = memoize((poolId: string) =>
    createSelector(selectRoomsByPool(poolId), (rooms) =>
      rooms.sort((a, b) =>
        compareStrings(
          getExistingRoomRecordField(a.record.properties, "title", ""),
          getExistingRoomRecordField(b.record.properties, "title", ""),
        ),
      ),
    ),
  );

  static selectIsCurrentUserMember = memoize((roomId: string) =>
    createSelector(
      selectRoomUserHistory(roomId),
      (userHistory) => userHistory !== undefined,
    ),
  );

  static selectHasExternals = memoize((roomId: string | undefined) =>
    createSelector([selectRoom(roomId), selectUsers], (room, users) => {
      return hasRoomExternalMembers(room, users);
    }),
  );

  static selectMapDisplayMode = memoize((roomId: string | undefined) =>
    createSelector([this.selectMapContext], (mapContext) => {
      if (roomId === undefined) {
        return {
          displayMode: DEFAULT_ROOM_MAP_DISPLAY_MODE,
          schemaId: undefined,
        };
      }
      const displayMode =
        mapContext[roomId]?.displayMode ?? DEFAULT_ROOM_MAP_DISPLAY_MODE;
      const schemaId = mapContext[roomId]?.schemaId;
      return { displayMode, schemaId };
    }),
  );

  static selectMapVisibleMarkerIds = memoize((roomId: string | undefined) =>
    createSelector([this.selectMapContext], (mapContext) => {
      if (roomId === undefined) {
        return [];
      }
      return mapContext[roomId]?.visibleMarkerIds ?? [];
    }),
  );

  static selectMapMinZoomReached = memoize((roomId: string | undefined) =>
    createSelector([this.selectMapContext], (mapContext) => {
      if (roomId === undefined) {
        return false;
      }
      return mapContext[roomId]?.isMinZoom ?? false;
    }),
  );

  static selectMembersDetails = memoize((roomId: string) =>
    createSelector(
      selectRoomMembers(roomId),
      selectUsers,
      selectRoom(roomId),
      (members, users, room): MemberDetails[] => {
        if (!room) {
          return getEmptyArray();
        }
        const responsibleId = getExistingRoomRecordField(
          room?.record.properties,
          "responsible",
        )?.[0];

        return members.map((member) => {
          const user = users[member.userId];
          return {
            id: member.userId,
            name: getUsername(user),
            role: getUserPoolRole(user, room.poolId),
            isResponsible: member.userId === responsibleId,
          };
        });
      },
    ),
  );

  static selectArchivedForAll = memoize((roomId: string) =>
    createSelector(selectRoom(roomId), (room) => room?.isArchivedForAll),
  );

  static selectArchivedForUser = memoize((roomId: string) =>
    createSelector(
      selectRoomUserHistory(roomId),
      (userHistory) => userHistory?.isArchived,
    ),
  );
}

type MemberDetails = {
  id: string;
  name: string;
  role: UserPoolRole;
  isResponsible: boolean;
};
