import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { DraggableMessage } from "@kraaft/shared/components/draggableMessage";
import { DraggableMessageType } from "@kraaft/shared/components/draggableMessage/types";
import { TextBubble } from "@kraaft/shared/components/message/messageText/textBubble";
import {
  formatDraggableText,
  useMessageStyles,
} from "@kraaft/shared/components/message/messageUtils";
import { getUsernameFromUserId } from "@kraaft/shared/components/textInputWithMentions/useMentionAwareTextState";
import { InputPartitionHelper } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import { Messages } from "@kraaft/shared/core/modules/message/core/any.message";
import { messageHasReactions } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectUsername } from "@kraaft/shared/core/modules/user/userSelectors";

import { styles } from "./messageCaption.styles";

interface Props {
  message: Messages.Image | Messages.Video;
  width: number;
  fixNonDeterministicSizeVideo?: boolean;
}

const MessageCaption = ({
  message,
  width,
  fixNonDeterministicSizeVideo,
}: Props) => {
  const { t } = useTranslation();
  const { isReply, attachment } = message;

  const hasReactions = messageHasReactions(message);
  const { messageBackgroundColor } = useMessageStyles({
    isReply,
    hasReactions,
  });

  const wrapperStyle = useMemo(
    () =>
      fixNonDeterministicSizeVideo
        ? styles.nonDeterministicContainer
        : undefined,
    [fixNonDeterministicSizeVideo],
  );

  const containerStyle = useMemo(
    () => [
      styles.container,
      {
        width,
        backgroundColor: messageBackgroundColor,
      },
    ],
    [messageBackgroundColor, width],
  );

  const modifier = useSelector(selectUsername(attachment.captionModifiedBy));

  if (
    !attachment.caption ||
    InputPartitionHelper.isEmpty(attachment.caption) ||
    width <= 0
  ) {
    return null;
  }

  const modifiedText = attachment.captionModifiedAt
    ? attachment.captionModifiedBy
      ? t("modifiedBy", { username: modifier })
      : t("modified")
    : undefined;

  return (
    <View style={wrapperStyle}>
      <View style={containerStyle}>
        <TextBubble
          withBubbleStyle={false}
          isReply={isReply}
          text={attachment.caption}
          draggableElement={
            <DraggableMessage
              messageId={message.id}
              messageType={DraggableMessageType.MessageText}
              renderText={() =>
                formatDraggableText(
                  message.createdAt,
                  attachment.caption
                    ? InputPartitionHelper.inputPartitionsToText(
                        attachment.caption,
                        getUsernameFromUserId,
                      )
                    : "",
                )
              }
              style={StyleSheet.absoluteFillObject}
            />
          }
          modifiedText={modifiedText}
          hasReactions={messageHasReactions(message)}
          isFontSizeLargeBody={true}
        />
      </View>
    </View>
  );
};

export { MessageCaption };
