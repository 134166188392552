import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import {
  AskPoolCompanyIndustryForm,
  OTHER_COMPANY_INDUSTRY_IDENTIFIER,
} from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/askPoolCompanyIndustryForm";
import { usePoolCompanyIndustry } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/usePoolCompanyIndustry";
import { appsFlyer } from "@kraaft/shared/core/services/appsflyer";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useTrackEventOnce } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { Button, Text } from "@kraaft/ui";

import { styles } from "./askPoolCompanyIndustry.styles";

const AskPoolCompanyIndustry = () => {
  const { t } = useTranslation();

  const { setPoolCompanyIndustry, isLoading, poolId } =
    usePoolCompanyIndustry();

  useTrackEventOnce({
    eventName: "Onboarding Need To Select Company Industry",
  });

  const [selected, setSelected] = useState<string>("");
  const [other, setOther] = useState("");

  const next = useCallback(() => {
    const companyIndustry =
      selected === OTHER_COMPANY_INDUSTRY_IDENTIFIER ? other : selected;

    setPoolCompanyIndustry(companyIndustry).catch(console.error);

    trackEvent({
      eventName: "Onboarding Selected Company Industry",
      companyIndustry,
    });

    if (companyIndustry !== OTHER_COMPANY_INDUSTRY_IDENTIFIER) {
      appsFlyer.logEvent("onboarding_selected_company_industry_btp", {
        poolId,
        companyIndustry,
      });
    }
  }, [other, poolId, selected, setPoolCompanyIndustry]);

  return (
    <View style={styles.root}>
      <Text weight="bold" size="H1" style={styles.title}>
        {t("poolCompanyIndustry.whatIsYourCompanyIndustry")}
      </Text>
      <AskPoolCompanyIndustryForm
        setSelected={setSelected}
        setOther={setOther}
        selected={selected}
        other={other}
      />
      <View style={styles.spacer} />
      <Button
        loading={isLoading}
        disabled={
          !selected ||
          (selected === OTHER_COMPANY_INDUSTRY_IDENTIFIER && !other)
        }
        style={styles.nextButton}
        accessibilityLabel={t("next")}
        text={t("next")}
        onPress={next}
      />
    </View>
  );
};

export { AskPoolCompanyIndustry };
