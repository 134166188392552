export class NetworkError extends Error {
  constructor({
    message,
    cause,
  }: {
    message?: string;
    cause?: Error;
  }) {
    super(message, { cause });
  }
}

export class FetchNetworkError extends NetworkError {
  public readonly url: string;
  public readonly elapsedDurationMs: number | undefined;

  constructor({
    url,
    message,
    cause,
    elapsedDurationMs,
  }: {
    url: string;
    message?: string;
    cause?: Error;
    elapsedDurationMs?: number;
  }) {
    super({ message, cause });
    this.url = url;
    this.elapsedDurationMs = elapsedDurationMs;
  }
}

export class TransientError extends Error {
  constructor({
    message,
    cause,
  }: {
    message?: string;
    cause?: Error;
  }) {
    super(message, { cause });
  }
}
