import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { useSubscribeToPoolAddons } from "@kraaft/shared/core/modules/poolAddons/hooks/useSubscribeToPoolAddons";
import { PoolAddonsAPI } from "@kraaft/shared/core/modules/poolAddons/poolAddons.api";
import { PoolAddonsSelectors } from "@kraaft/shared/core/modules/poolAddons/redux/poolAddons.selectors";
import { Toggle } from "@kraaft/web/src/components/toggle";

import { IntegrationSection } from "../integrationSection";

export const PlanningSection = () => {
  const { t } = useTranslation();

  useSubscribeToPoolAddons();

  const poolAddons = useSelector(PoolAddonsSelectors.select);
  const currentPoolId = useSelector(selectCurrentPoolId);

  const handleToggle = useCallback(
    (value: boolean) => {
      if (!currentPoolId) {
        return;
      }

      PoolAddonsAPI.togglePlanning(currentPoolId, value).catch(console.error);
    },
    [currentPoolId],
  );

  return (
    <IntegrationSection
      title={t("planning.integration")}
      details={<Toggle value={poolAddons.planning} setValue={handleToggle} />}
    />
  );
};
