import { Branded } from "@kraaft/helper-types";
import { UserJob } from "@kraaft/shared/components/onboarding/askUserJob/userJob";

import { UserPoolRole } from "../../services/firestore/firestoreTypes";

export type PoolMemberId = Branded<string, "PoolMemberId">;

export class PoolMemberIdHelper {
  static create(poolId: string, userId: string): PoolMemberId {
    return `${poolId}@${userId}` as PoolMemberId;
  }

  static split(id: PoolMemberId): { poolId: string; userId: string } {
    const [poolId, userId] = id.split("@");
    return {
      poolId: poolId ?? "PoolId split error",
      userId: userId ?? "UserId split error",
    };
  }
}

export interface PoolMemberPreview {
  /**
   * The id of the poolMember, `poolId@userId`
   */
  id: PoolMemberId;
  poolId: string;
  userId: string;
  updatedAt: Date;
  email?: string;
  phone?: string;
  grade: UserPoolRole;
  roles?: string[];
  firstname?: string;
  lastname?: string;
  isCurrentMember?: boolean;
  isDemoUser?: boolean;
  isDeletedUser?: boolean;
}

export interface PoolMemberDetails {
  userId: string;
  totalRoomCount?: number;
  poolRoomCount?: number;
  poolCount?: number;
  job?: UserJob;
  ssoProviders?: {
    name: string;
    providerId: string;
    ssoId: string;
    email?: string;
  }[];
  pricing?: {
    computed: "chargeable" | "offered";
    scheme: "chargeable" | "offered" | "automatic";
  };
}
