import { selectMessageInRoom } from "@kraaft/shared/core/modules/message/messageData/messageData.selectors";
import { ModularFolderOfflineFeature } from "@kraaft/shared/core/modules/modularFolder/modularFolder.offlineFeature";
import { selectSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { selectCurrentUserId } from "@kraaft/shared/core/modules/user/userSelectors";
import { offlineReduxBundle } from "@kraaft/shared/core/store/offline";

export const {
  Actions: OfflineModularFolderActions,
  Selectors: OfflineModularFolderSelectors,
  StateActions: OfflineModularFolderStateActions,
} = offlineReduxBundle.create(
  ModularFolderOfflineFeature,
  {
    selectRawAggregate: (state: any) => state.modularFolders.modularFolders,
  },
  (getState, selectModularFolder) => {
    return {
      getCurrentUserId() {
        return selectCurrentUserId(getState()) ?? "";
      },
      getSchema(id) {
        return selectSchema(id)(getState());
      },
      getModularFolder(id) {
        return selectModularFolder(id)(getState());
      },
      getMessage(roomId, id) {
        return selectMessageInRoom(roomId, id)(getState());
      },
    };
  },
);
