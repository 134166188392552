import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DeleteRoomSheet } from "@kraaft/shared/components/conversationDetails/detailsAdvancedSections/detailsAdvancedSections/deleteRoomSheet";
import { Room } from "@kraaft/shared/core/modules/room/roomState";
import { getExistingRoomRecordField } from "@kraaft/shared/core/modules/room/roomUtils";
import { Button } from "@kraaft/ui";

export const DeleteRoomButton = (props: { room: Room }) => {
  const { room } = props;
  const { t } = useTranslation();

  const roomName = useMemo(() => {
    return getExistingRoomRecordField(room.record.properties, "title") ?? "";
  }, [room.record.properties]);

  const { open, element } = DeleteRoomSheet.use({
    roomId: room.id,
    roomName,
  });

  return (
    <>
      <Button
        variant="QUATERNARY"
        destructive
        size="SMALL"
        alignContent="left"
        id="room-details-delete-room"
        accessibilityLabel={t("deleteRoom.card")}
        text={t("deleteRoom.card")}
        icon="trash-03"
        onPress={open}
      />
      {element}
    </>
  );
};
