import { PropsWithChildren, useState } from "react";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";
import { OTHER_COMPANY_INDUSTRY_IDENTIFIER } from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/askPoolCompanyIndustryForm";
import { PoolCompanySize } from "@kraaft/shared/components/onboarding/askPoolCompanySize/poolCompanySize";
import { OTHER_USER_JOB_IDENTIFIER } from "@kraaft/shared/components/onboarding/askUserJob/askUserJobForm";

interface CreatePoolFlowContext {
  poolName: string;
  setPoolName: (poolName: string) => void;
  isSubmitPoolNameDisabled: boolean;
  setIsSubmitPoolNameDisabled: (is: boolean) => void;
  companyIndustry: string;
  setCompanyIndustry: (companyIndustry: string) => void;
  otherCompanyIndustry: string;
  setOtherCompanyIndustry: (otherCompanyIndustry: string) => void;
  isSubmitCompanyIndustryDisabled: boolean;
  companySize: PoolCompanySize | undefined;
  setCompanySize: (size: PoolCompanySize) => void;
  isSubmitCompanySizeDisabled: boolean;
  creationReason: string;
  setCreationReason: (reason: string) => void;
  isSubmitCreationReasonDisabled: boolean;
  userJob: string;
  setUserJob: (userJob: string) => void;
  otherUserJob: string;
  setOtherUserJob: (otherUserJob: string) => void;
  isSubmitUserJobDisabled: boolean;
}

export const CreatePoolFlowContext = createMeshContext<CreatePoolFlowContext>();

export const CreatePoolFlowContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [poolName, setPoolName] = useState("");
  const [isSubmitPoolNameDisabled, setIsSubmitPoolNameDisabled] =
    useState(true);

  const [userJob, setUserJob] = useState("");
  const [otherUserJob, setOtherUserJob] = useState("");
  const isSubmitUserJobDisabled =
    !userJob || (userJob === OTHER_USER_JOB_IDENTIFIER && !otherUserJob);

  const [companyIndustry, setCompanyIndustry] = useState("");
  const [otherCompanyIndustry, setOtherCompanyIndustry] = useState("");
  const isSubmitCompanyIndustryDisabled =
    !companyIndustry ||
    (companyIndustry === OTHER_COMPANY_INDUSTRY_IDENTIFIER &&
      !otherCompanyIndustry);

  const [companySize, setCompanySize] = useState<PoolCompanySize>();
  const isSubmitCompanySizeDisabled = !companySize;

  const [creationReason, setCreationReason] = useState("");
  const isSubmitCreationReasonDisabled = !creationReason;

  const contextValue = useMeshContextSetup<CreatePoolFlowContext>({
    poolName,
    setPoolName,
    isSubmitPoolNameDisabled,
    setIsSubmitPoolNameDisabled,
    companyIndustry,
    setCompanyIndustry,
    otherCompanyIndustry,
    setOtherCompanyIndustry,
    isSubmitCompanyIndustryDisabled,
    companySize,
    setCompanySize,
    isSubmitCompanySizeDisabled,
    creationReason,
    setCreationReason,
    isSubmitCreationReasonDisabled,
    userJob,
    setUserJob,
    otherUserJob,
    setOtherUserJob,
    isSubmitUserJobDisabled,
  });

  return (
    <CreatePoolFlowContext.Provider value={contextValue}>
      {children}
    </CreatePoolFlowContext.Provider>
  );
};
