import { call, put, select, takeEvery } from "typed-redux-saga/macro";

import { MAX_ROOM_CARDS_PER_PAGE } from "@kraaft/shared/core/modules/roomCard/const";
import { normalizeRoomCards } from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import { loadRoomCards } from "@kraaft/shared/core/modules/roomCard/queries/loadRoomCards";
import {
  RoomCardActions,
  RoomCardStateActions,
} from "@kraaft/shared/core/modules/roomCard/roomCard.actions";
import { RoomCardSelectors } from "@kraaft/shared/core/modules/roomCard/roomCard.selectors";
import { RoomCardUtils } from "@kraaft/shared/core/modules/roomCard/roomCard.utils";
import { selectRoomSchema } from "@kraaft/shared/core/modules/schema/schema.selectors";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import { BatchActions } from "@kraaft/shared/core/store/batchingReducer";
import { waitFor } from "@kraaft/shared/core/utils/sagas";

function* loadMoreRoomCards(
  action: ReturnType<typeof RoomCardActions.loadMore>,
) {
  const { filterId } = action.payload;
  const cursorContext = yield* select(
    RoomCardSelectors.selectCursorContext(filterId),
  );
  const currentFilters = yield* select(
    RoomCardSelectors.selectQueryContext(filterId),
  );
  const isLoadingMore = yield* select(
    RoomCardSelectors.selectIsLoadingMore(filterId),
  );
  const alreadyMoreLoaded = yield* select(
    RoomCardSelectors.selectAlreadyMoreLoaded(filterId),
  );

  if (!cursorContext?.lastAtAll || !currentFilters || isLoadingMore) {
    return;
  }

  const { poolId, userId, filters } = currentFilters;

  yield* put(
    RoomCardStateActions.setIsLoadingMore({
      filterId,
      isLoadingMore: true,
    }),
  );

  try {
    const firestoreRoomCards = yield* call(
      loadRoomCards,
      poolId,
      userId,
      filters,
      cursorContext.lastAtAll,
    );

    const schema = yield* waitFor(selectRoomSchema(poolId));

    const allColumns = schema
      ? KSchemaUtils.flattenColumnsDict(schema.rootSection)
      : {};

    const roomCards = normalizeRoomCards(firestoreRoomCards, allColumns);

    yield* put(
      RoomCardStateActions.addToLoaded({
        poolId,
        filterId,
        roomCards,
      }),
    );

    const firstDoc = firestoreRoomCards[0];
    const newLastDoc = firestoreRoomCards.at(-1);

    if (firstDoc && !alreadyMoreLoaded) {
      yield* put(
        RoomCardStateActions.setCursorForFirstLoadedDoc({
          filterId,
          cursor: RoomCardUtils.createCursorFromFirestoreRoomCard(firstDoc),
        }),
      );
    }

    if (firestoreRoomCards.length >= MAX_ROOM_CARDS_PER_PAGE) {
      yield* put(
        BatchActions({
          actions: [
            RoomCardStateActions.setCursorForLastAtAll({
              filterId,
              cursor:
                RoomCardUtils.createCursorFromFirestoreRoomCard(newLastDoc),
            }),
            RoomCardStateActions.setHasMore({ filterId, hasMore: true }),
          ],
        }),
      );
    } else {
      yield* put(
        BatchActions({
          actions: [
            RoomCardStateActions.setCursorForLastAtAll({
              filterId,
              cursor: undefined,
            }),
            RoomCardStateActions.setHasMore({ filterId, hasMore: false }),
          ],
        }),
      );
    }

    yield* put(
      BatchActions({
        actions: [
          RoomCardStateActions.setAlreadyMoreLoaded({
            filterId,
            alreadyMoreLoaded: true,
          }),
          RoomCardStateActions.setIsLoadingMore({
            filterId,
            isLoadingMore: false,
          }),
        ],
      }),
    );
  } catch (error) {
    yield* put(
      RoomCardStateActions.setHasError({
        filterId,
        hasError: true,
      }),
    );
  }
}

export function* loadMoreRoomCardsSaga() {
  yield* takeEvery(RoomCardActions.loadMore, loadMoreRoomCards);
}
