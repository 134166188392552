import { UserRoleRank } from "@kraaft/shared/core/modules/pool/poolUtil";
import { UserPoolRole } from "@kraaft/shared/core/services/firestore/firestoreTypes";

export const comparePoolMembers = (
  a: { grade?: UserPoolRole },
  b: { grade?: UserPoolRole },
): number => {
  return (
    UserRoleRank[b.grade || UserPoolRole.STANDARD] -
    UserRoleRank[a.grade || UserPoolRole.STANDARD]
  );
};
