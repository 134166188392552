import { memo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useMeshContext } from "@kraaft/helper-hooks";
import { PoolLogo } from "@kraaft/shared/components/poolLogo";
import { PoolSelectorImplementation } from "@kraaft/shared/components/poolSelectorImplementation";
import { useExternalAwarePoolName } from "@kraaft/shared/components/poolsMenu/poolsMenu.utils";
import { useExternalPoolVariant } from "@kraaft/shared/components/poolsMenu/poolsMenuItem/useExternalPoolVariant";
import { useIsCurrentUserExternalInAllPools } from "@kraaft/shared/components/poolsMenu/useIsCurrentUserExternalInAllPools";
import { Tooltip } from "@kraaft/shared/components/tooltip";
import { showSuccess } from "@kraaft/shared/core/modules/alert/alertActions";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { PoolActions } from "@kraaft/shared/core/modules/pool/poolActions";
import { selectHasUnreadPool } from "@kraaft/shared/core/modules/pool/poolAndRoomSelectors";
import {
  selectCurrentPoolLocation,
  selectOnePool,
  selectPoolLocations,
} from "@kraaft/shared/core/modules/pool/poolSelectors";
import { PoolLocation } from "@kraaft/shared/core/modules/pool/poolState";
import { withDependencyWrapper } from "@kraaft/shared/core/utils/withDepedencyWrapper";
import {
  ColorStyle,
  ColorValue,
  Icon,
  Radius,
  Spacing,
  Text,
} from "@kraaft/ui";
import { BaseNavigationElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/baseNavigationElement";
import { CollapsibleNavigationBarElement } from "@kraaft/web/src/components/layout/verticalNavigationBar/collapsibleNavigationBarElement";
import { VerticalNavigationContext } from "@kraaft/web/src/components/layout/verticalNavigationBar/verticalNavigation.context";

interface PoolSelectionElementProps {
  /** injected */
  currentPool: Pool;
  currentPoolLocation: PoolLocation;
}

const PoolSelectionElement_ = ({
  currentPool,
  currentPoolLocation,
}: PoolSelectionElementProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { isPoolSelectionOpened, setPoolSelectionOpened } = useMeshContext(
    VerticalNavigationContext,
  );

  const poolSelectionButtonRef = useRef(null);

  const locations = useSelector(selectPoolLocations);
  const hasMultipleLocationCandidates = !(
    locations?.length === 1 &&
    currentPoolLocation.poolId === locations[0]?.poolId
  );
  const isCurrentUserExternalInAllPools = useIsCurrentUserExternalInAllPools();

  const showMultiPoolButton =
    hasMultipleLocationCandidates || isCurrentUserExternalInAllPools;

  const showNotificationDot = useSelector(selectHasUnreadPool);
  const withExternalVariant = useExternalPoolVariant(
    currentPoolLocation.poolId,
  );

  const openPoolSelection = useCallback(() => {
    setPoolSelectionOpened(true);
  }, [setPoolSelectionOpened]);

  const closePoolSelection = useCallback(() => {
    setPoolSelectionOpened(false);
  }, [setPoolSelectionOpened]);

  const onChangePoolLocation = useCallback(
    (newLocation: PoolLocation, locationName: string) => {
      dispatch(PoolActions.switchPool(newLocation));
      dispatch(
        showSuccess({
          title: t("poolChanged", {
            name: locationName,
          }),
        }),
      );
    },
    [dispatch, t],
  );

  const poolLogoName = useExternalAwarePoolName(
    currentPool,
    withExternalVariant,
  );
  const poolLabelColor: ColorValue = isPoolSelectionOpened
    ? "ACTION_CTA"
    : "FONT_HIGH_EMPHASIS";

  return (
    <>
      <div
        id="navbar-pool"
        ref={poolSelectionButtonRef}
        className={clsx(
          classes.container,
          showMultiPoolButton && classes.pointerCursor,
        )}
        onClick={showMultiPoolButton ? openPoolSelection : undefined}
      >
        <div
          className={clsx(
            classes.poolLabelContainer,
            isPoolSelectionOpened && classes.poolLabelContainerActive,
          )}
        >
          <BaseNavigationElement>
            <PoolLogo
              id="navbar-pool-logo"
              poolId={currentPool.id}
              withExternalVariant={withExternalVariant}
              showNotificationDot={showNotificationDot}
            />
          </BaseNavigationElement>
          <CollapsibleNavigationBarElement>
            <Tooltip
              title={poolLogoName}
              enterDelay={1000}
              placement="bottom-start"
            >
              <div className={classes.poolLabel}>
                <Text size="BODY" color={poolLabelColor} numberOfLines={1}>
                  {poolLogoName}
                </Text>
                {showMultiPoolButton && (
                  <Icon
                    name="chevron-selector-vertical"
                    size="SMALL"
                    color={poolLabelColor}
                  />
                )}
              </div>
            </Tooltip>
          </CollapsibleNavigationBarElement>
        </div>
      </div>
      <PoolSelectorImplementation
        open={isPoolSelectionOpened}
        onClose={closePoolSelection}
        currentPoolLocation={currentPoolLocation}
        onChangePoolLocation={onChangePoolLocation}
        anchorRef={poolSelectionButtonRef}
      />
    </>
  );
};

export const PoolSelectionElement = memo(
  withDependencyWrapper(PoolSelectionElement_, () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const currentPoolLocation = useSelector(selectCurrentPoolLocation);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const currentPool = useSelector(selectOnePool(currentPoolLocation?.poolId));

    if (currentPool === undefined || currentPoolLocation === undefined) {
      return undefined;
    }

    return { currentPool, currentPoolLocation };
  }),
);

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: Spacing.S24,
  },

  pointerCursor: {
    cursor: "pointer",
  },

  poolLabel: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    marginLeft: Spacing.S4,
    gap: Spacing.S8,
    paddingRight: Spacing.S8,
  },

  poolLabelContainer: {
    display: "flex",
    alignItems: "center",
    padding: `${Spacing.S4}px 0`,
    borderRadius: Radius.SMALL,

    "&:hover": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
  },
  poolLabelContainerActive: {
    backgroundColor: ColorStyle.ACTION_SELECTED,
  },
});
