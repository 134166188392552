import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { canShowRemoveMessage } from "@kraaft/shared/core/modules/message/messageUtils";
import { selectCurrentPoolId } from "@kraaft/shared/core/modules/pool/poolSelectors";
import {
  selectCurrentUser,
  selectCurrentUserAccountOwner,
} from "@kraaft/shared/core/modules/user/userSelectors";
import { isUserSuperadmin } from "@kraaft/shared/core/modules/user/userUtils";
import { trackEvent } from "@kraaft/shared/core/utils/tracking/trackEvent";
import { useRemoveMessage } from "@kraaft/shared/core/utils/useRemoveMessage";

export function useRemoveMenuItems(roomId: string, message: AnyUserMessage) {
  const { t } = useTranslation();
  const removeMessage = useRemoveMessage({ roomId });
  const isSuperadmin = isUserSuperadmin(useSelector(selectCurrentUser));
  const poolId = useSelector(selectCurrentPoolId);
  const isAccountOwner = useSelector(selectCurrentUserAccountOwner(poolId));

  return useMemo(() => {
    const items: ActionSheetItem[] = [];

    if (
      MessageHelper.isPersisted(message) &&
      canShowRemoveMessage(message, isSuperadmin, isAccountOwner)
    ) {
      items.push({
        label: t("removeMessageButton"),
        onPress: () => {
          trackEvent({
            eventName: "Click Message Action Sheet Option",
            room_id: roomId,
            action: "delete",
          });

          removeMessage(message);
        },
        style: "destructive",
        icon: "trash-03",
      });
    }
    return items;
  }, [
    message,
    isSuperadmin,
    isAccountOwner,
    t,

    roomId,
    removeMessage,
  ]);
}
