import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { DocumentLoader } from "@kraaft/shared/components/documentLoader";
import { DetailsEditorFullscreenImageSheet } from "@kraaft/shared/components/modular/details/editors/detailsEditorFullscreenImage.sheet";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { KSchemaElementDescription } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaUtils } from "@kraaft/shared/core/modules/schema/schema.utils";
import {
  Color,
  ColorStyle,
  FontSize,
  Icon,
  Image,
  KColors,
  Radius,
  Spacing,
  Text,
} from "@kraaft/ui";

interface DetailsEditorDescriptionProps {
  description: KSchemaElementDescription | undefined;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
}

export const DetailsEditorDescription = ({
  description,
  backgroundColor,
  style,
}: DetailsEditorDescriptionProps) => {
  const { t } = useTranslation();
  const { element, open } = DetailsEditorFullscreenImageSheet.use({});

  const handleOpen = useCallback(() => {
    if (!description?.image) {
      return;
    }

    open({
      downloadUrl: description.image.downloadUrl,
      filename: description.image.filename,
    });
  }, [description?.image, open]);

  if (KSchemaUtils.isDescriptionEmpty(description)) {
    return null;
  }

  const textColor = backgroundColor
    ? KColors.textColorFromBackgroundColor(backgroundColor)
    : ColorStyle.FONT_HIGH_EMPHASIS;

  return (
    <>
      <View style={[styles.root, { backgroundColor }, style]}>
        <TextProcessor
          textStyle={{
            fontSize: FontSize.BODY,
            color: textColor,
          }}
          enableLinks
          enablePhone
          linkStyle={styles.link}
        >
          {description?.text}
        </TextProcessor>
        {description?.image && (
          <Pressable onPress={handleOpen} accessibilityLabel={t("open")}>
            <Image
              preferredSize="medium"
              contentFit="cover"
              source={{ uri: description.image.downloadUrl }}
              style={styles.image}
            />
            <Icon name="expand-06" style={styles.expand} color={Color.WHITE} />
          </Pressable>
        )}
        {description?.document && (
          <DocumentLoader
            type="view"
            trackingSource="schema element description"
            downloadUrl={description.document.downloadUrl}
            filename={description.document.filename}
          >
            <View style={styles.file}>
              <View style={styles.fileIcon}>
                <Icon name="file-04" />
              </View>
              <View style={styles.filenameContainer}>
                <Text numberOfLines={1} ellipsizeMode="tail" color={textColor}>
                  {description.document.filename}
                </Text>
              </View>
            </View>
          </DocumentLoader>
        )}
      </View>
      {element}
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    gap: Spacing.S8,
  },
  image: {
    flexGrow: 1,
    height: 164,
    borderRadius: Radius.SMALL,
  },
  filenameContainer: {
    flexShrink: 1,
  },
  file: {
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
  fileIcon: {
    padding: Spacing.S4,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
  },
  expand: {
    position: "absolute",
    right: Spacing.S8,
    bottom: Spacing.S8,
  },
  link: {
    color: Color.BLUE_KRAAFT,
  },
});
