import { createAction } from "@reduxjs/toolkit";

export const NpsSurveyActions = {
  createNpsSurveyResponse: createAction<{
    note: number;
  }>("@surveys/CREATE_NPS_SURVEY_RESPONSE"),
  updateNpsSurveyResponse: createAction<{
    target?: string;
    benefit?: string;
    missingFunctionality?: string;
  }>("@surveys/UPDATE_NPS_SURVEY_RESPONSE"),
};

export const NpsSurveyStateActions = {
  setNpsSurveyResponseId: createAction<{ id: string }>(
    "@surveys/state/SET_NPS_SURVEY_RESPONSE_ID",
  ),
  setShouldDisplayNpsSurveyForm: createAction<{
    shouldDisplayNpsSurveyForm: boolean;
  }>("@surveys/state/SET_SHOULD_DISPLAY_NPS_SURVEY_FORM"),
};
