import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeepWritable } from "ts-essentials";

import { FooterActions } from "@kraaft/shared/components/actionFooter/actionFooter";
import { useInviteCollaboratorsTrackingEvent } from "@kraaft/shared/components/inviteCollaborators/useInviteCollaboratorsTrackingEvent";
import { KDialog } from "@kraaft/shared/components/kDialog";
import { KDialogSize } from "@kraaft/shared/components/kDialog/kDialogProps";
import { Toggle } from "@kraaft/shared/components/toggle";
import {
  showError,
  showSuccess,
} from "@kraaft/shared/core/modules/alert/alertActions";
import { selectOnePool } from "@kraaft/shared/core/modules/pool/poolSelectors";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KColumnType } from "@kraaft/shared/core/modules/schema/modularTypes/columnType";
import { KSchemaColumnValue } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Api } from "@kraaft/shared/core/services/api";
import { useTrackEventOnceConditionnally } from "@kraaft/shared/core/utils/tracking/useTrackEvent";
import { PoolInvitationTargetParams } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";
import { Preloader } from "@kraaft/ui";
import { InviteLinkInput } from "@kraaft/web/src/components/inviteLinkInput";
import { SimplifiedTableRecords } from "@kraaft/web/src/components/simplifiedTable/simplifiedTableRecords";
import { getValidUsers } from "@kraaft/web/src/views/settings/manageMembers/inviteOrImportCollaborators/inviteOrImportCollaborators.utils";
import { useImportUsersSchema } from "@kraaft/web/src/views/settings/manageMembers/inviteOrImportCollaborators/useImportUsersSchema";

import { InviteCollaboratorDialog } from "../../../../components/kDialog/customs";

import { useStyles } from "./inviteOrImportCollaborators.styles";

interface InviteOrImportCollaboratorsProps {
  poolId: string;
  open: boolean;
  onClose: () => void;
  isSuperadmin: boolean;
}

const roleToApiRole: Record<string, string> = {
  standard: "standard",
  admin: "administrator",
  owner: "account owner",
  external: "external guest",
};

export const InviteOrImportCollaborators = ({
  poolId,
  open,
  onClose,
  isSuperadmin,
}: InviteOrImportCollaboratorsProps) => {
  const pool = useSelector(selectOnePool(poolId));

  const trackingEvent = useInviteCollaboratorsTrackingEvent("copy_link", {
    type: "pool",
    poolId,
    source: "settings_members",
  });

  useTrackEventOnceConditionnally(trackingEvent, open);

  const invitationTargetParams = useMemo<PoolInvitationTargetParams>(() => {
    return {
      type: "pool",
      poolId,
      source: "room_creation",
    };
  }, [poolId]);

  return isSuperadmin ? (
    <ImportCollaborators open={open} onClose={onClose} poolId={poolId} />
  ) : pool ? (
    <InviteCollaboratorDialog
      pool={pool}
      open={open}
      onClose={onClose}
      invitationTargetParams={invitationTargetParams}
    />
  ) : null;
};

const ImportCollaborators = ({
  poolId,
  open,
  onClose,
}: Pick<InviteOrImportCollaboratorsProps, "poolId" | "open" | "onClose">) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const styles = useStyles();

  const { schema, columnsContext } = useImportUsersSchema();
  const [warnings, setWarnings] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [sendSMS, setSendSMS] = useState(true);
  const [sendMail, setSendMail] = useState(true);

  const trackingEvent = useInviteCollaboratorsTrackingEvent("copy_link", {
    type: "pool",
    poolId,
    source: "settings_members",
  });
  useTrackEventOnceConditionnally(trackingEvent, open);

  const createDefaultEntries = useCallback(
    () =>
      Array.from({ length: 4 }).map(() => {
        const record = ModularRecordUtils.create(
          schema as DeepWritable<typeof schema>,
        );
        record.properties.role = {
          columnType: KColumnType.selectSingle,
          value: ["standard"],
        } as KSchemaColumnValue;
        return record;
      }),
    [schema],
  );

  const [records, setRecords] = useState<ModularRecord[]>(
    createDefaultEntries(),
  );
  const [link, setLink] = useState("");

  const computedCount = useMemo(() => getValidUsers(records).length, [records]);

  useEffect(() => {
    async function getLink() {
      const newLink = await Api.generatePoolInviteLinkForStandard({ poolId });
      setLink(newLink);
    }
    if (open) {
      setRecords(createDefaultEntries());
      setLink("");
      getLink().catch(console.error);
    }
  }, [createDefaultEntries, open, poolId]);

  const importUsers = useCallback(async () => {
    setLoading(true);
    try {
      const validUsers = getValidUsers(records).map((r) => ({
        email: r.properties.email?.value as string,
        firstName: r.properties.firstName?.value as string,
        lastName: r.properties.lastName?.value as string,
        phoneNumber: r.properties.phoneNumber?.value as string,
        role: roleToApiRole[
          (r.properties.role?.value as string[] | undefined)?.[0] ?? "standard"
        ],
      }));
      const { data } = await Api.importUsersFromTable(
        poolId,
        validUsers,
        sendMail,
        sendSMS,
      );
      if (!data.warnings || data.warnings.length === 0) {
        dispatch(
          showSuccess({
            title: t("inviteOrImportCollaborators.success", {
              count: validUsers.length,
            }),
          }),
        );
      } else {
        const successfulImportsCount = validUsers.length - data.warnings.length;
        setWarnings(data.warnings);
        dispatch(
          showError({
            title: t("inviteOrImportCollaborators.successPartial", {
              count: successfulImportsCount,
            }),
          }),
        );
      }

      if (!data.warnings?.length) {
        onClose();
      }
    } catch (e) {
      dispatch(showError({ title: t("serverError") }));
      console.error(e);
    }
    setLoading(false);
  }, [dispatch, onClose, poolId, records, sendMail, sendSMS, t]);

  const actions = useMemo<FooterActions>(() => {
    const cancelButton = {
      accessibilityLabel: t("cancel"),
      text: t("cancel"),
      onPress: onClose,
    };

    return [
      {
        accessibilityLabel: t("inviteOrImportCollaborators.actions.invite", {
          count: computedCount,
        }),
        text: t("inviteOrImportCollaborators.actions.invite", {
          count: computedCount,
        }),
        onPress: importUsers,
        loading,
      },
      cancelButton,
    ];
  }, [computedCount, importUsers, loading, onClose, t]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (warnings.length > 0) {
      const parent = ref.current?.parentElement;
      parent?.scrollTo({ top: parent.scrollHeight, behavior: "smooth" });
    }
  }, [warnings]);

  return (
    <KDialog
      size={KDialogSize.MEDIUM}
      title={t("inviteOrImportCollaborators.headers.invite")}
      content={
        <div className={styles.root} ref={ref}>
          <div>
            <SimplifiedTableRecords
              allowEdit
              canAddRecord
              canDeleteRecord
              disablePreview
              editableHeader={false}
              schema={schema}
              records={records}
              onRecordsChange={setRecords}
              columnsContext={columnsContext.columns}
              disableSort
              recordType="standalone"
              noCheckboxConfirmation
            />
          </div>
          <div className={styles.toggle}>
            <Toggle value={sendSMS} setValue={setSendSMS} />
            <span>{t("inviteOrImportCollaborators.options.sendSMS")}</span>
          </div>
          <div className={styles.toggle}>
            <Toggle value={sendMail} setValue={setSendMail} />
            <span>{t("inviteOrImportCollaborators.options.sendEmail")}</span>
          </div>
          <h2 className={styles.subheader}>
            {t("inviteOrImportCollaborators.headers.elseSendThisLink")}
          </h2>
          {!link ? (
            <Preloader />
          ) : (
            <InviteLinkInput
              label={t("inviteOrImportCollaborators.inputLabel")}
              link={link}
            />
          )}
          <div className={styles.warning}>
            {warnings.map((warning) => (
              <div key={warning}>{warning}</div>
            ))}
          </div>
        </div>
      }
      open={open}
      onClose={onClose}
      actions={actions}
    />
  );
};
