import moment, { Moment } from "moment";

import { useMemo } from "react";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import { isNative } from "@kraaft/helper-functions";
import { getMaxWidthOfMessage } from "@kraaft/shared/components/message/messageSize";
import { AnyMessage } from "@kraaft/shared/core/modules/message/core/any.message";
import { AnyUserMessage } from "@kraaft/shared/core/modules/message/core/any.user.message";
import { MessageHelper } from "@kraaft/shared/core/modules/message/core/message.helper";
import { MessageModularModuleAttribute } from "@kraaft/shared/core/modules/message/core/user.message";
import { MessageSelectionType } from "@kraaft/shared/core/modules/message/messageState";
import { i18n } from "@kraaft/shared/core/services/i18next";
import { conditionalObject } from "@kraaft/shared/core/utils";
import { FontSize } from "@kraaft/ui";

import { styles } from "./message.styles";

const JUMBO_SIZE = isNative() ? 50 : 64;

export interface MessageRendererProps<T extends AnyMessage> {
  roomId: string;
  message: T;
  answer?: AnyUserMessage;
  onLongPress?: (message: AnyMessage) => void;
}

export function formatDate(date: Date | number, isRelative?: boolean): string {
  const momentObj: Moment = moment(date);
  return isRelative === false
    ? momentObj.format("L")
    : momentObj.calendar({ sameElse: `L [${i18n.t("at")}] LT` });
}

export function useMessageStyles({
  isReply,
  hasReactions,
  isOnlyEmojis,
  isFontSizeLargeBody,
  sizerWidth,
}: {
  isReply: boolean;
  hasReactions: boolean;
  isOnlyEmojis?: boolean;
  isFontSizeLargeBody?: boolean;
  sizerWidth?: number;
}) {
  return useMemo(() => {
    const messageStyle = isReply ? styles.messageReply : styles.messageDefault;
    const messageBackgroundColor =
      StyleSheet.flatten(messageStyle).backgroundColor?.toString();

    const textStyle = {
      fontSize: isFontSizeLargeBody ? FontSize.BODY : FontSize.MEDIUM,
      ...(isReply ? styles.textReply : styles.textDefault),
      ...conditionalObject(
        {
          lineHeight: JUMBO_SIZE + 10,
          fontSize: JUMBO_SIZE,
        },
        Boolean(isOnlyEmojis),
      ),
    };

    const headerStyle = {
      ...textStyle,
      fontSize: FontSize.MEDIUM,
    };

    const textColor = StyleSheet.flatten<TextStyle>(textStyle).color as string;

    return {
      messageStyle: [
        styles.message,
        messageStyle,
        hasReactions ? { minWidth: 50 } : {},
        sizerWidth
          ? {
              maxWidth: getMaxWidthOfMessage(isNative(), sizerWidth),
            }
          : ({
              alignSelf: isReply ? "flex-start" : "flex-end",
            } as ViewStyle),
      ],
      messageBackgroundColor,
      textContainerStyle: styles.text,
      textStyle,
      headerStyle,
      textColor,
      modifiedStyle: isReply ? styles.modifiedReply : styles.modifiedDefault,
    };
  }, [isReply, hasReactions, isOnlyEmojis, isFontSizeLargeBody, sizerWidth]);
}

export function isMessageSelectable(message: AnyMessage) {
  return MessageHelper.isUserMessage(message);
}

export function canMessageBeAttached(message: AnyMessage) {
  return (
    MessageHelper.isUserMessage(message) &&
    MessageHelper.isPersisted(message) &&
    !MessageHelper.isDeleted(message) &&
    !MessageHelper.isGeolocation(message)
  );
}

export function isMessageAttached(message: AnyMessage) {
  if (
    !MessageHelper.isUserMessage(message) ||
    message.modularObjects === undefined
  ) {
    return false;
  }
  return Object.values(message.modularObjects).some(
    (object: MessageModularModuleAttribute) => object.isFlagged,
  );
}

export function formatDraggableText(createdAt: Date, text: string) {
  const formattedDate = moment(createdAt).format("LT L");
  const draggableText = `[${formattedDate}] ${text}`;
  return draggableText;
}

export function canMessageBeForwarded(message: AnyMessage) {
  return (
    MessageHelper.isUserMessage(message) && !MessageHelper.isDeleted(message)
  );
}

export function canMessageBeSelected(
  message: AnyMessage,
  selectionType: MessageSelectionType | undefined,
) {
  switch (selectionType) {
    case "notDefined":
      return false;
    case "attach":
      return canMessageBeAttached(message);
    case "forward":
      return canMessageBeForwarded(message);
    default:
      break;
  }
  return true;
}

export function isReplyOrForward(message: AnyUserMessage) {
  return (
    message.answerTo !== undefined ||
    (message.forwarded !== undefined &&
      message.forwarded.by !== message.senderId)
  );
}

export function messageHasTranscription(message: AnyMessage) {
  return (
    MessageHelper.isAudio(message) &&
    message.attachment.transcription !== undefined
  );
}
