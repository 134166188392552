import { StyleSheet, View } from "react-native";

import { GradientLogHolder } from "@kraaft/shared/components/message/messageLog/holders/gradientLogHolder";
import { OpenWithArrow } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/openWithArrow";
import { WorkflowLogWrapper } from "@kraaft/shared/components/message/messageLog/messageLogs/utils/workflowLogWrapper";
import { TextProcessor } from "@kraaft/shared/components/textProcessor/textProcessor";
import { WorkflowMessageEvent } from "@kraaft/shared/core/modules/message/core/log.message";
import { Trans } from "@kraaft/shared/core/services/i18next/trans";
import { Color, Icon, Spacing, Text } from "@kraaft/ui";

import { styles as commonStyles } from "./common.styles";

const AutomatedLabel = () => {
  return (
    <View style={styles.automated}>
      <Icon size="MINI" color="BLUE_SAVOY" name="flash-fill" />
      <Text size="SMALL" color="BLUE_SAVOY">
        <Trans i18nKey="workflow.automaticallyGenerated" />
      </Text>
    </View>
  );
};

interface WorkflowMessageProps {
  event: WorkflowMessageEvent;
  roomId: string;
}

export const WorkflowMessage = ({ event, roomId }: WorkflowMessageProps) => {
  return (
    <>
      <AutomatedLabel />
      <WorkflowLogWrapper event={event} roomId={roomId}>
        <GradientLogHolder>
          <TextProcessor
            enableLinks
            enablePhone
            textStyle={[commonStyles.text, styles.text]}
            linkStyle={styles.link}
          >
            {event.text}
          </TextProcessor>
          {event.source?.type === "modularFolder" && <OpenWithArrow />}
        </GradientLogHolder>
      </WorkflowLogWrapper>
    </>
  );
};

const styles = StyleSheet.create({
  automated: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    gap: Spacing.S4,
    marginBottom: Spacing.S4,
    alignItems: "center",
  },
  text: {
    color: Color.WHITE,
  },
  link: {
    color: Color.WHITE,
    textDecorationColor: Color.WHITE,
    textDecorationStyle: "solid",
    textDecorationLine: "underline",
  },
});
