import { CreateOneOperationBuilder } from "@kraaft/offline";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { Api } from "@kraaft/shared/core/services/api";

export const createSchemaLibraryTagOperation =
  CreateOneOperationBuilder.create<SchemaLibraryTag>()
    .payload<{ name: string; language: LibrarySchemaLanguage; index: number }>({
      replaceId: (_): void => {},
    })
    .expected((payload) => {
      return {
        id: payload.id,
        name: payload.name,
        updatedAt: new Date(),
        language: payload.language,
        index: payload.index,
      } satisfies SchemaLibraryTag;
    })
    .mutate(async ({ name, language }) => {
      const response = await Api.addSchemaLibraryTag({ name, language });
      return response.id;
    });
