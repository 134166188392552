import { Dictionary } from "ts-essentials";

export interface MessageState {
  rooms: Dictionary<MessageRoom>;
  messageSelectionByRoom: Dictionary<MessageSelection>;
}

export type MessageSelectionStatus = undefined | "inDialogFlow";

interface MessageSelectionShare {
  selectionType: "share";
  status: MessageSelectionStatus;
}

interface MessageSelectionAttach {
  selectionType: "attach";
  status: MessageSelectionStatus;
}

interface MessageSelectionForward {
  selectionType: "forward";
  status: MessageSelectionStatus;
}

interface MessageSelectionNotDefined {
  selectionType: "notDefined";
  status: MessageSelectionStatus;
}

export type MessageSelectionProperties =
  | MessageSelectionShare
  | MessageSelectionAttach
  | MessageSelectionForward
  | MessageSelectionNotDefined;

export type MessageSelectionType = MessageSelectionProperties["selectionType"];

export type MessageSelectionSource =
  | "conversation"
  | "photoGallery"
  | "documentGallery"
  | "directoryList"
  | undefined;

export type MessageSelection = {
  selection: Record<string, true>;
  selectionSource: MessageSelectionSource;
} & MessageSelectionProperties;

export type MaybeMessageSelection =
  | MessageSelection
  | {
      selectionSource: undefined;
      selectionType: undefined;
      selection: Record<string, never>;
    };

export interface MessageRoom {
  loadingStates: {
    [messageId: string]: true;
  };
  sentMessagesCount: number;
  replyingSourceMessageId: string | null;
}

export enum ReceiptStatus {
  NONE = 0,
  ALL = 1,
  SOME = 2,
}
