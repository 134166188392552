import { useSelector } from "react-redux";

import { selectShouldDisplayNpsSurveyForm } from "@kraaft/shared/core/modules/surveys/npsSurvey/npsSurvey.selectors";

export function useShouldDisplayNpsSurveyForm() {
  const shouldDisplayNpsSurveyForm = useSelector(
    selectShouldDisplayNpsSurveyForm,
  );

  return shouldDisplayNpsSurveyForm;
}
